import { useCallback, useEffect, useMemo } from "react";
import * as yup from "yup";
import { useDataProvider, useGetIdentity } from "@mydeal/core";
import {
  Card,
  Center,
  Divider,
  Text,
  Anchor,
  Box,
  Stack,
  Title,
} from "@mantine/core";
import { accountService } from "@services";
import { IChangePasswordModel } from "@types";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import {
  AuthenticatedForm,
  FormPasswordField,
  FormPasswordStrength,
  IPasswordStrengthOptions,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import { IconExternalLink } from "@tabler/icons-react";

const isNullOrUndefined = (value: any) => {
  return value === null || value === undefined;
};

const checkPasswordRules = (value: any, values: any) => {
  if (isNullOrUndefined(value)) return true;

  if (value.length < 12) return false;

  if ((value.match(/(.)\1{2,}/g) || []).length > 0) return false;

  let matchingCharacterTypes = 0;
  if ((value.match(/[a-z]/g) || []).length > 0) {
    matchingCharacterTypes++;
  }
  if ((value.match(/[A-Z]/g) || []).length > 0) {
    matchingCharacterTypes++;
  }
  if ((value.match(/[0-9]/g) || []).length > 0) {
    matchingCharacterTypes++;
  }
  if ((value.match(/[^a-zA-Z0-9]/g) || []).length > 0) {
    matchingCharacterTypes++;
  }

  if (matchingCharacterTypes < 3) return false;

  if (
    ((values.userFields as string[]) || [])
      .map((f) => f.split(/[@.]/g))
      .flat()
      .find((userPart) => new RegExp(userPart).test(value))
  )
    return false;

  return true;
};

const ChangePasswordSchema = yup.object<{
  [Property in keyof IChangePasswordModel]: any;
}>({
  Password: yup
    .string()
    .label("Password")
    .nullable()
    .required("Required")
    .test("password-rules", "Password rules not met", (value, context) =>
      checkPasswordRules(value, context.parent)
    ),
  ConfirmPassword: yup
    .string()
    .label("Confirm Password")
    .nullable()
    .oneOf([yup.ref("Password"), null], "Passwords must match")
    .required("Required"),
});

const positiveRequirements = [
  { re: /[0-9]/, label: "Must have at least 1 number" },
  { re: /[a-z]/, label: "Must have at least 1 lower case letter (a-z)" },
  { re: /[A-Z]/, label: "Must have at least 1 upper case letter (A-Z)" },
  {
    re: /[^a-zA-Z0-9]/,
    label: "Must have at least 1 special symbol (e.g. !@#$%^&*)",
  },
];

const negativeRequirements = [
  {
    condition: (value: string) => /(.)\1{2,}/.test(value),
    label: "Must not have more than 2 identical characters in a row",
  },
  {
    condition: (value: string, options: IPasswordStrengthOptions) =>
      (options.userFields || [])
        .map((f) => f.split(/[@.]/g))
        .flat()
        .find((userPart) => new RegExp(userPart).test(value))
        ? true
        : false,
    label: "Must not match username / email address or a part of it",
  },
];

interface IChangePasswordEditorModel extends IChangePasswordModel {
  userFields?: string[];
}

export interface IChangePasswordEditorProps {
  onboarding: boolean;
}

export const ChangePasswordEditor = ({
  onboarding,
}: IChangePasswordEditorProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );
  const form = useFocusableForm<Partial<IChangePasswordEditorModel>>({
    schema: ChangePasswordSchema,
    initialValues: {
      Password: "",
      ConfirmPassword: "",
    },
  });
  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const { data: user } = useGetIdentity();
  useEffect(() => {
    const uf: string[] = [];
    if (user?.name) {
      uf.push(user.name);
    }
    if (user?.email) {
      uf.push(user.email);
    }
    if (user?.nickname) {
      uf.push(user.nickname);
    }
    form.setFieldValue("userFields", uf);
    form.resetDirty({
      ...form.getValues(),
      userFields: uf,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  const onSubmit = useCallback(
    async ({ userFields, ...data }: Partial<IChangePasswordEditorModel>) => {
      return await _accountService.changePassword(data, onboarding);
    },
    [_accountService, onboarding]
  );

  const afterSubmit = useCallback(() => {
    form.reset();
    if (onboarding) {
      refreshOnboardingProgress();
    }
  }, [form, onboarding, refreshOnboardingProgress]);

  const formValues = form.getValues();

  return (
    <AuthenticatedForm
      dataProviderInstance={dataProviderInstance}
      form={form}
      submitLabel={onboarding ? "Save and Next" : "Update"}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      allowSubmitWithNoChanges={onboarding}
    >
      <Card data-testid="myaccount-changepassword">
        {!onboarding && (
          <Card.Section py="sm">
            <Title order={5} mb="xs">
              Change Password
            </Title>
            <Divider />
          </Card.Section>
        )}
        <Card.Section py="sm">
          <Stack>
            <FormPasswordStrength
              placeholder="Password"
              label="Password"
              fieldName="Password"
              parentForm={form}
              positiveRequirements={positiveRequirements}
              negativeRequirements={negativeRequirements}
              options={{
                minLength: 12,
                minPositiveRules: 3,
                userFields: formValues.userFields,
              }}
              additionalRestrictions={
                <>
                  <Text mt={5} size="sm">
                    Must be different from the last 5 passwords
                  </Text>
                  <Text mt={5} size="sm">
                    <Center inline>
                      <Box mr={5}>Must not be a</Box>
                      <Anchor
                        href="https://github.com/danielmiessler/SecLists/blob/master/Passwords/Common-Credentials/10k-most-common.txt"
                        target="_blank"
                      >
                        <Center inline>
                          <Box mr={5}>common password</Box> <IconExternalLink />
                        </Center>
                      </Anchor>
                    </Center>
                  </Text>
                </>
              }
            />

            <FormPasswordField
              label="Confirm Password"
              placeholder="Confirm Password"
              fieldName="ConfirmPassword"
              parentForm={form}
            />
          </Stack>
        </Card.Section>
      </Card>
    </AuthenticatedForm>
  );
};
