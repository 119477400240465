import { IFocusableForm } from "@mydeal/ui-mantine";
import {
  LocationField,
  ILocationFieldProps,
  ILocationItem,
} from "../LocationField";
import { useRef } from "react";

interface ILocationFieldMap<Values = Record<string, unknown>> {
  location?: keyof Values;
  postcode?: keyof Values;
  state?: keyof Values;
  suburb?: keyof Values;
}

export interface IFormLocationFieldProps<Values = Record<string, unknown>>
  extends ILocationFieldProps {
  fieldMap: ILocationFieldMap<Values>;
  parentForm: IFocusableForm<Values>;
}

export const FormLocationField: <Values = Record<string, unknown>>(
  props: IFormLocationFieldProps<Values>
) => JSX.Element = ({ fieldMap, parentForm, ...rest }) => {
  const elementRef = useRef<HTMLInputElement>(null);

  const fieldMapKeys = Object.keys(fieldMap) as Array<keyof typeof fieldMap>;

  fieldMapKeys
    .filter((fieldName) => fieldMap[fieldName])
    .forEach((fieldName) =>
      parentForm.registerField(fieldMap[fieldName]!, elementRef)
    );

  const inputPropsMap = Object.fromEntries(
    fieldMapKeys
      .map((fieldName) => ({ key: fieldName, value: fieldMap[fieldName] }))
      .filter((kvp) => kvp.value)
      .map((kvp) => [kvp.key, parentForm.getInputProps(kvp.value!)])
  ) as {
    [key in keyof typeof fieldMap]: ReturnType<typeof parentForm.getInputProps>;
  };

  const allInputProps = fieldMapKeys
    .filter((fieldName) => inputPropsMap[fieldName])
    .map((fieldName) => inputPropsMap[fieldName]!);

  const onChange = (location: ILocationItem | undefined) => {
    if (fieldMap.location) {
      parentForm.setFieldValue(fieldMap.location, location?.value as any);
      inputPropsMap.location?.onChange(location?.value);
    }
    if (fieldMap.postcode) {
      parentForm.setFieldValue(fieldMap.postcode, location?.postcode as any);
      inputPropsMap.postcode?.onChange(location?.postcode);
    }
    if (fieldMap.suburb) {
      parentForm.setFieldValue(fieldMap.suburb, location?.suburb as any);
      inputPropsMap.suburb?.onChange(location?.suburb);
    }
    if (fieldMap.state) {
      parentForm.setFieldValue(fieldMap.state, location?.state as any);
      inputPropsMap.state?.onChange(location?.state);
    }
  };

  const inputProps = {
    value: inputPropsMap.postcode?.value
      ? `${inputPropsMap.suburb?.value} ${inputPropsMap?.state?.value} ${inputPropsMap.postcode?.value}`
      : undefined,
    onChange: onChange,
    error: allInputProps
      .filter(({ error }) => error)
      .map((x) => x.error)
      .join(", "),
    onFocus: (e: any) => {
      allInputProps.forEach(({ onFocus }) => onFocus(e));
    },
    onBlur: (e: any) => {
      allInputProps.forEach(({ onBlur }) => onBlur(e));
    },
  };

  return <LocationField {...inputProps} {...rest} ref={elementRef} />;
};
