import { inheritInnerComments } from '@babel/types';
import { createStyles, Container, Group, Anchor, ActionIcon, Text, Title } from '@mantine/core';
import { IconBrandFacebook, IconBrandInstagram, IconBrandPinterest, IconBrandTwitter, IconBrandYoutube } from '@tabler/icons-react';

const useStyles = createStyles((theme) => ({
    footer: {
        paddingLeft: 300,
        borderTop: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[2]
            }`,
        [theme.fn.smallerThan('sm')]: {
            paddingLeft: 0,
        },
    },

    inner: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        paddingTop: theme.spacing.lg,
        paddingBottom: theme.spacing.lg,
        marginLeft: theme.spacing.md,
        marginRight: theme.spacing.md,
        maxWidth: "inherit",

        [theme.fn.smallerThan('lg')]: {
            flexDirection: 'column',
        },
    },

    links: {
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        [theme.fn.smallerThan('lg')]: {
            marginTop: theme.spacing.md,
            marginBottom: theme.spacing.md,
        },
    },
    socialTitle: {
        marginBottom:16,
        [theme.fn.largerThan('sm')]: {
            marginBottom: 0,
        },
    },
    socialIcons: {
        marginLeft: theme.spacing.md,
        [theme.fn.smallerThan('sm')]: {
            flexDirection: 'column',
        },
    },
}));

const links = [
    { link: "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new", label: "Contact Us" },
    { link: "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb", label: "Help" },
    { link: "https://www.mydeal.com.au/", label: "Go to MyDeal.com.au" },
]

interface FooterProps {
    links: { link: string; label: string }[];
}



export function Footer() {
    const { classes } = useStyles();
    const items = links.map((link) => (
        <Anchor
            color="dimmed"
            key={link.label}
            href={link.link}
            target="_blank"
            size="sm"
        >
            {link.label}
        </Anchor>
    ));

    return (
        <div className={classes.footer} data-testid="footer">
            <Container className={classes.inner}>
                <Group className={classes.links}>{items}</Group>
                <Group spacing={0} className={classes.socialIcons} position="right" noWrap>
                    <Title order={6} className={classes.socialTitle}>
                        Follow us on
                    </Title>
                    <Group noWrap spacing="xs" >
                        <ActionIcon component="a" href="https://www.facebook.com/mydealaustralia/" target="_blank" size="lg" radius="xl" variant="light" ml="md">
                            <IconBrandFacebook size={18} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon component="a" href="https://twitter.com/mydealaustralia" target="_blank" size="lg"  radius="xl" variant="light" ml="md">
                            <IconBrandTwitter size={18} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon component="a" href="https://www.pinterest.com.au/MyDeal_Australia/" target="_blank" size="lg"  radius="xl" variant="light" ml="md">
                            <IconBrandPinterest size={18} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon component="a" href="https://www.youtube.com/@MyDealAustralia" target="_blank" size="lg"  radius="xl" variant="light" ml="md">
                            <IconBrandYoutube size={18} stroke={1.5} />
                        </ActionIcon>
                        <ActionIcon component="a" href="https://www.instagram.com/mydealaustralia/" target="_blank" size="lg"  radius="xl" variant="light" ml="md">
                            <IconBrandInstagram size={18} stroke={1.5} />
                        </ActionIcon>
                    </Group>
                </Group>
            </Container>
        </div>
    );
}