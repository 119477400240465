import { PageTitle } from "@components/shared";
import { MultiFactorAuthenticationEditor } from "./MultiFactorAuthenticationEditor";

export const MultiFactorAuthentication = () => {
  return (
    <>
      <PageTitle
        title="Multi-Factor Authentication"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "User Settings" },
          { name: "Multi-Factor Authentication" },
        ]}
      />

      <MultiFactorAuthenticationEditor />
    </>
  );
};
