import { useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import { useDataProvider, useNotification } from "@mydeal/core";
import { IOrderViewModel } from "./types";
import { orderService } from "@services/order.service";
import { Alert, Button, Divider, Grid, Group, Stack } from "@mantine/core";
import { useFocusableForm, FormTextField } from "@mydeal/ui-mantine";
import { IconAlertCircle } from "@tabler/icons-react";
import { FormLocationField, Label } from "@components/shared";

const UpdateShippingSchema = yup.object({
  RedirectFName: yup.string().required("Required"),
  RedirectLName: yup.string().required("Required"),
  PostalAddress: yup.string().required("Required"),
  RedirectPhone: yup.string().required("Required"),
  PostalPostcode: yup.string().required("Required"),
});

interface IShippingModalProps {
  currentBidDealID?: number;
  order: IOrderViewModel;
  toggle: (reload?: boolean) => void;
}

export const ShippingModal = ({
  currentBidDealID,
  order,
  toggle,
}: IShippingModalProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );

  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [courier, setCourier] = useState<string | null>(order.DispatchCarrier);

  const form = useFocusableForm<any>({
    initialValues: {
      BidDealGroupId: order.BidDealGroupID,
      BidDealId: order.BidDealID,
      PostalPostcode: order.PostalPostcode,
      PostalState: order.PostalState,
      PostalSuburb: order.PostalSuburb,
    },
    schema: UpdateShippingSchema,
  });

  // const postalPostcode = useWatch({
  //   control: control,
  //   name: 'PostalPostcode',
  // });

  useEffect(() => {
    form.setValues({
      BidDealGroupID: order.BidDealGroupID,
      BidDealID: order.BidDealID,
      RedirectFName: order.RedirectFName,
      RedirectLName: order.RedirectLName,
      PostalCompanyName: order.PostalCompanyName,
      PostalAddress: order.PostalAddress,
      PostalAddress2: order.PostalAddress2,
      RedirectPhone: order.RedirectPhone,
      PostalSuburb: order.PostalSuburb,
      PostalPostcode: order.PostalPostcode,
      PostalState: order.PostalState,
    } as any);
  }, []);

  const onSubmit = (data: any) => {
    setLoading(true);

    _orderService
      .updateShippingAddress(data)
      .then((result) => {
        if (result?.Success) {
          notify?.({
            type: "success",
            message: "Shipping address have been changed successfully!",
            description: "",
          });
          toggle?.(true);
        } else {
          notify?.({
            type: "error",
            message: "Something went wrong. Please try again.",
            description: "",
          });
        }
      })
      .catch((err) => {
        notify?.({
          type: "error",
          message: "Something went wrong. Please try again.",
          description: "",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Divider mb="lg" />
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack spacing="sm">
          <Grid>
            <Grid.Col md={3}>
              <Label required>Name: </Label>
            </Grid.Col>
            <Grid.Col md={4}>
              <FormTextField
                placeholder="First name"
                fieldName="RedirectFName"
                parentForm={form}
              />
            </Grid.Col>
            <Grid.Col md={5}>
              <FormTextField
                placeholder="Last name"
                fieldName="RedirectLName"
                parentForm={form}
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={3}>
              <Label>Company:</Label>
            </Grid.Col>
            <Grid.Col md={9}>
              <FormTextField
                placeholder="Company"
                fieldName="PostalCompanyName"
                parentForm={form}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col md={3}>
              <Label required>Address: </Label>
            </Grid.Col>
            <Grid.Col md={9}>
              <Stack spacing="sm">
                <FormTextField
                  placeholder="Postal address"
                  fieldName="PostalAddress"
                  parentForm={form}
                />
                <FormTextField
                  placeholder="Postal address 2"
                  fieldName="PostalAddress2"
                  parentForm={form}
                />
              </Stack>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col md={3}>
              <Label required>Phone: </Label>
            </Grid.Col>
            <Grid.Col md={9}>
              <FormTextField
                placeholder="Phone number"
                fieldName="RedirectPhone"
                parentForm={form}
              />
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col md={3}>
              <Label required>Suburb/Postcode: </Label>
            </Grid.Col>
            <Grid.Col md={9}>
              <FormLocationField
                placeholder="Enter Postcode or Suburb"
                fieldMap={{
                  suburb: "PostalSuburb",
                  postcode: "PostalPostcode",
                  state: "PostalState",
                }}
                parentForm={form}
              />
            </Grid.Col>
          </Grid>

          {/* 

        <FormLocationField
          label="Suburb/Postcode:"
          control={control as any}
          name="PostcodeSuburb"
          required
          onChange={(item) => {
            setValue("PostalSuburb", item?.suburb);
            setValue("PostalPostcode", item?.postcode);
            setValue("PostalState", item?.state);
          }}
          inline
          labelSize={3}
          controlSize={9}
          placeholder={
            postalPostcode &&
            `${order.PostalSuburb} ${order.PostalState} ${order.PostalPostcode}`
          }
        />

        /> */}

          <Grid>
            <Grid.Col>
              <Alert color="yellow" icon={<IconAlertCircle size={14} />}>
                By clicking Change Shipping Address you will change the shipping
                address for Order No: {order.BidDealID}
              </Alert>
            </Grid.Col>
          </Grid>
          <Divider />
          <Group>
            <Button
              variant="light"
              onClick={() => {
                toggle();
              }}
            >
              Cancel
            </Button>
            <Button type="submit" loading={loading}>
              Change Shipping Address
            </Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
