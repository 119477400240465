import { useEffect, useState } from "react";
import {
  Badge,
  Group,
  Text,
  Paper,
  Select,
  TextInput,
  Anchor,
  ActionIcon,
  createStyles,
} from "@mantine/core";
import { SearchKeywordList } from "./enquiryInbox.types";
import { useDataProvider, useRouterContext } from "@mydeal/core";
import { enquiryInboxService } from "@services";
import { IconSearch } from "@tabler/icons-react";

import { TicketStatus } from "./enquiryInbox.types";

const useStyles = createStyles((theme) => ({}));

interface TicketStatusCardProps {
  ticketStatus: string;
  isSellerEscalation: boolean;
  isEscalation: boolean;
  hasFreightProtection: boolean;
}

export const TicketStatusCard = ({
  ticketStatus,
  isSellerEscalation,
  isEscalation,
  hasFreightProtection,
}: TicketStatusCardProps) => {
  return (
    <div style={{ width: "200px" }}>
      {ticketStatus === TicketStatus[TicketStatus.New].toLowerCase() && (
        <Badge size="xs" variant="filled" color="yellow">
          {ticketStatus}
        </Badge>
      )}
      {(ticketStatus === TicketStatus[TicketStatus.Open].toLowerCase() ||
        (ticketStatus === TicketStatus[TicketStatus.Pending].toLowerCase() &&
          isSellerEscalation)) && (
        <Badge size="xs" variant="filled" color="red">
          {ticketStatus}
        </Badge>
      )}
      {(ticketStatus === TicketStatus[TicketStatus.Pending].toLowerCase() && !isSellerEscalation)&& (
        <Badge size="xs" variant="filled" color="blue">
          {ticketStatus}
        </Badge>
      )}
      {ticketStatus === TicketStatus[TicketStatus.Solved].toLowerCase() && (
        <Badge size="xs" variant="filled" color="gray">
          {ticketStatus}
        </Badge>
      )}
      {ticketStatus === TicketStatus[TicketStatus.Closed].toLowerCase() && (
        <Badge size="xs" variant="filled" color="gray">
          {ticketStatus}
        </Badge>
      )}
      {ticketStatus === TicketStatus[TicketStatus.Hold].toLowerCase() && (
        <Badge size="xs" variant="filled" color="gray">
          {ticketStatus}
        </Badge>
      )}{" "}
      <span> </span>
      {isEscalation && (
        <Badge size="xs" variant="outline" color="red">
          {" "}
          Escalation{" "}
        </Badge>
      )}
      <span></span>
      {hasFreightProtection && (        
        <Badge size="xs" variant="filled" color="cyan">
          {" "}
          Priority Customer{" "}
        </Badge>
      )}
    </div>
  );
};
