import { createStyles, Text, Title, TextInput, Button, Image, TypographyStylesProvider, Container, Flex } from '@mantine/core';
import ImagePricePayment01 from "../../assets/images/landingTabs/pricingnpayment/sales-1.png";
import ImagePricePayment02 from "../../assets/images/landingTabs/pricingnpayment/sales-2.png";
import { CommentHtml } from './CommentHtml';


const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: `calc(${theme.spacing.xl} * 2)`,

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            flexDirection: 'column-reverse',
            padding: theme.spacing.xs,
        },
    },

    wrapperRightImageBody: {
        paddingRight: `calc(${theme.spacing.xl} * 1)`,
        paddingLeft: 0,
        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            paddingRight: 0,
        },
    },

    image: {
        maxWidth: '50%',

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            maxWidth: '100%',
        },
    },

    body: {
        maxWidth: '50%',
        paddingLeft: `calc(${theme.spacing.xl} * 2)`,

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            paddingLeft: 0,
            marginTop: theme.spacing.xl,
            maxWidth: '100%',
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1,
        marginBottom: theme.spacing.md,
    },
    centerContent: {
        display: "flex",
        justifyContent: "center",
    }
}));

export function TabPricingPayment() {
    const { classes } = useStyles();
    return (
        <>
            <div className={classes.wrapper}>
                <Image src={ImagePricePayment01} className={classes.image} />
                <div className={classes.body}>
                    <Title className={classes.title} order={4}>Low Costs</Title>
                    <Text size="md" color="dimmed">
                        MyDeal ensures that its fee structure remains as minimal as possible.
                    </Text>
                </div>
            </div>
            <div className={`${classes.wrapper}`}>
                <div className={`${classes.body} ${classes.wrapperRightImageBody}`}>
                    <Title className={classes.title} order={4}>Comprehensive Market Analysis</Title>
                    <Text size="md" color="dimmed">
                        Our ecommerce experts will work with you to make sure that your pricing is the best for both you and the customer. We provide ongoing feedback about competition, vertical news, and best pricing practices for various channels at different times of the year.
                    </Text>
                </div>
                <Image src={ImagePricePayment02} className={classes.image} />
            </div>
            <div className={classes.wrapper}>
                <div>
                    <CommentHtml
                        authorname="Leo Zaitsev- Klika"
                        authortitle="Director of Commercial Operations"
                        title="Second To None"
                        body="We have found Mydeal's payment collections and fraud review processes to be second to none, with a fully automated remittance and invoicing platform. In our line of business automation is key."
                    />
                </div>
                <div className={classes.body}>
                    <Title className={classes.title} order={4}>Seamless & Automated Payment Systems</Title>
                    <Text size="md" color="dimmed">
                        MyDeal takes the stress out of collecting payment from customers and pays you for your sales regularly. With comprehensive financial reporting and remittance notices, there’s no guesswork involved. Our advanced fraud review processes ensure that everyone is protected and only genuine sales reach you.
                    </Text>
                </div>
            </div>
        </>
    );
}