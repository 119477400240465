import { IDataContextProvider } from "@mydeal/core";
import { IDataTableRequestModel } from "@types";

export const adminService = (dataProvider: IDataContextProvider) => ({
  getUsers: async (dataTableRequest: IDataTableRequestModel) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/Admin/User`,
        query: {
          pageNo: dataTableRequest.PageIndex,
          perPage: dataTableRequest.PageSize
        }
      })
      .then((result) => {
        return result?.data;
      });
  },

  getInvitations: async (dataTableRequest: IDataTableRequestModel) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/Admin/Invitation`,
        query: {
          pageNo: dataTableRequest.PageIndex,
          perPage: dataTableRequest.PageSize
        }
      })
      .then((result) => {
        return result?.data;
      });
  },

  inviteUser: async (email: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Admin/Invitation`,
        payload: {},
        query: {
          email,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  deleteInvitation: async (invitationId: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "delete",
        url: `${baseUrl}/Admin/Invitation`,
        payload: {},
        query: {
          invitationId,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  setUserAdmin: async (
    userId: string,
    admin: boolean
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/Admin/User/Admin`,
        payload: {},
        query: {
          userId,
          admin
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  clearUserMfa: async (
    userId: string
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "delete",
        url: `${baseUrl}/Admin/User/Mfa`,
        payload: {},
        query: {
          userId,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  removeUser: async (
    userId: string
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "delete",
        url: `${baseUrl}/Admin/User`,
        payload: {},
        query: {
          userId,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },
});
