import {
  Table,
  Title,
  Box,
  Text,
  Divider,
  Anchor,
  Button,
  UnstyledButton,
} from "@mantine/core";

interface ImportResultProps {
  title: string;
  result: IImportResult;
  close: () => void;
}

interface IImportResult {
  items: any[];
  errorList: any[];
  isStockUpdate: boolean;
  contactUsUrl: string;
  sellerHelpUrl: string;
}

export const ImportResult = ({ title, result, close }: ImportResultProps) => {
  const { items, errorList, isStockUpdate, contactUsUrl, sellerHelpUrl } =
    result;

  var errorMessages = errorList as any[];
  var showErrorDetail = items?.filter((s) => s.Status !== 1).length > 0;
  var successItems =
    errorMessages.length === 0
      ? items?.filter((s) => s.Status === 1 || s.Status === 4).length
      : 0;
  var failedItems =
    errorMessages.length === 0
      ? items?.filter((s) => s.Status !== 1 && s.Status !== 4).length
      : 0;
  var success =
    successItems > 0 ? (
      <span style={{ color: "#006600" }}>{successItems} Updated</span>
    ) : (
      ""
    );
  var fail =
    failedItems > 0 ? (
      <label style={{ color: "red" }}> {failedItems} Errors</label>
    ) : (
      ""
    );
  var heading =
    successItems > 0 || failedItems > 0 ? (
      <Text mb="lg">
        Summary : {success} {fail}{" "}
      </Text>
    ) : (
      ""
    );

  return (
    <>
      {/* <Title order={2} mb="xs">
        {title}
      </Title> */}
      <Divider />
      <Anchor
        mt="md"
        size="sm"
        component="button"
        onClick={() => {
          close();
        }}
      >
        Back to My Deals
      </Anchor>
      <Box mt="xl" mih={300}>
        <div>
          <Title order={4}>{heading}</Title>

          <Text>
            You can verify your updates by exporting the latest product file
            again from the{" "}
            <Anchor
              mt="md"
              size="xs"
              component="button"
              onClick={() => {
                close();
              }}
            >
              My Deals page
            </Anchor>
          </Text>
          {errorMessages.length === 0 ? (
            showErrorDetail && (
              <>
                <p>
                  If you are experiencing issues please{" "}
                  <Anchor href={contactUsUrl} target="_blank">
                    contact us
                  </Anchor>{" "}
                  with a copy of the file attached and we will assist you
                  promptly.
                </p>
                <Title order={4} mb="xs" mt="lg">
                  Error Details:
                </Title>
                <Table withBorder withColumnBorders>
                  <colgroup>
                    <col width={90} />
                  </colgroup>
                  <thead className="thead-light">
                    <tr>
                      <th className="text-center">Line No.</th>
                      <th>Error Message</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items?.map((item: any, index: number) => {
                      if (item.Status === 0 && item.Message) {
                        item.Message = "Row contains error.";
                      }

                      let color = "White";
                      if (item.Status === 3) {
                        color = "#fdbd86";
                      } else if (item.Status === 4) {
                        color = "#ecf9ec";
                      } else {
                        color = "#fdcccd";
                      }

                      return (
                        <>
                          {item.Status !== 1 && (
                            <tr key={index}>
                              <td className="text-center">{index + 1}</td>
                              <td style={{ backgroundColor: color }}>
                                {item.Message}
                                {item.Status === 3 && (
                                  <span>
                                    Please{" "}
                                    <Anchor href={sellerHelpUrl}>
                                      contact MyDeal
                                    </Anchor>{" "}
                                    to update{" "}
                                    {isStockUpdate ? "stock" : "price"}.
                                  </span>
                                )}
                              </td>
                            </tr>
                          )}
                        </>
                      );
                    })}
                  </tbody>
                </Table>
              </>
            )
          ) : (
            <>
              <p>
                If you are experiencing issues please{" "}
                <Anchor href={sellerHelpUrl} target="_blank">
                  contact us
                </Anchor>{" "}
                with a copy of the file attached and we will assist you
                promptly.
              </p>
              <Title order={4}>Error Details</Title>
              <ul>
                {errorMessages.map((msg: string, index: number) => (
                  <li key={index}>{msg}</li>
                ))}
              </ul>
            </>
          )}
        </div>
      </Box>
    </>
  );
};
