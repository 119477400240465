import { IDataContextProvider } from "@mydeal/core";
import { IOnboardingStatus, OnboardingState } from "@pages/onboarding/types";

export const onboardingService = (dataProvider: IDataContextProvider) => ({
  getStatus: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider.custom<IOnboardingStatus>?.({
      method: "get",
      url: `${baseUrl}/Onboarding/Status`,
    }).then((result) => {
      return result?.data;
    });
  },

  changeStatus: async (onboardingState: OnboardingState) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "put",
        url: `${baseUrl}/Onboarding/Status`,
        payload: { onboardingState },
      })
      .then((result) => {
        return result?.data;
      });
  },

  getWelcomeStep: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/Onboarding/Welcome`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  saveWelcomeStep: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Onboarding/Welcome`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  saveShippingOption: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Onboarding/ShippingOption`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  getFinalStep: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/Onboarding/FinalStep`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  saveFinalStep: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Onboarding/FinalStep`,
      })
      .then((result) => {
        return result?.data;
      });
  },
});
