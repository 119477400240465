import { IDataContextProvider } from "@mydeal/core";
import { IDataTableRequestModel } from "../types";

export const JobService = (dataProvider: IDataContextProvider) => ({
  loadJobs: async (dataTableRequest: IDataTableRequestModel) => {
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/job/list`,
        payload: dataTableRequest,
      })
      .then((result) => {
        return result?.data;
      });
  },

  cancelJob: async (jobId: string) => {
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/job/${jobId}/cancel`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  loadProductImportResult: async (jobId: string,dataTableRequest: IDataTableRequestModel) => {
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/job/${jobId}/productImportResult`,
        payload: dataTableRequest,
      })
      .then((result) => {
        return result?.data;
      });
  },
  downloadErrorProductResult: async (productImportHistoryId: string) => {
    const baseUrl = dataProvider.getApiUrl();
    var resource = `job/${productImportHistoryId}/downloadErrorProductResult`;
    return dataProvider.download({ resource: resource }).then((result) => {
      return result;
    });
  },
  downloadNewProductResult: async (productImportHistoryId: string) => {
    const baseUrl = dataProvider.getApiUrl();
    var resource = `job/${productImportHistoryId}/downloadNewProductResult`;
    return dataProvider.download({ resource: resource }).then((result) => {
      return result;
    });
  },

});
