import {
  ActionIcon,
  Alert,
  Anchor,
  Flex,
  Group,
  Stack,
  Text,
  createStyles,
  Space,
  HoverCard,
  Grid,
} from "@mantine/core";
import * as yup from "yup";
import { useCallback, useMemo, useState } from "react";
import { CouponModel } from "./types";
import {
  IconCurrencyDollar,
  IconInfoCircle,
  IconPercentage,
} from "@tabler/icons-react";
import { useDataProvider } from "@mydeal/core";
import moment from "moment";
import { couponService } from "@services/coupon.service";
import {
  FocusableForm,
  FormDatePicker,
  FormNumberInput,
  useFocusableForm,
} from "@mydeal/ui-mantine";

const useStyles = createStyles((theme) => ({
  labels: {
    width: 290,
  },
  labelstwo: {
    width: 218,
  },
}));

const CouponInfoSchema = yup.object<any>({
  StartDate: yup.string().nullable().required("Required"),
  ExpiryDate: yup.string().nullable().required("Required"),
  DiscountPercentage: yup
    .number()
    .nullable()
    .required("Required")
    .test(
      "Is increment of 5%?",
      "Discount Percentage must be in increments of 5%",
      (value) => value % 5 == 0
    )
    .max(50, "Maximum Discount Percentage is 50%")
    .min(10, "Minimum Discount Percentage is 10%"),
});

interface CouponPromoModelProps {
  onClose: (reload?: boolean) => void;
  model?: CouponModel;
}

export const CouponPromoModel = ({ onClose, model }: CouponPromoModelProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _couponService = useMemo(
    () => couponService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { classes } = useStyles();
  const [minDate, setMinDate] = useState<any>(
    moment(new Date()).add(3, "d").toDate()
  );
  const [maxDate, setMaxDate] = useState<any>(
    moment(new Date()).add(60, "d").toDate()
  );

  const form = useFocusableForm<any>({
    schema: CouponInfoSchema,
    initialValues: {
      StartDate: moment(new Date()).add(1, "d").toDate(),
    },
    transformValues: (values) => ({
      ...values,
      StartDateStr: values.StartDate
        ? moment(values.StartDate).format("DD/MM/YYYY")
        : "",
      ExpiryDateStr: values.StartDate
        ? moment(values.ExpiryDate).format("DD/MM/YYYY")
        : "",
    }),
  });

  const onSubmit = useCallback(
    async (data: any) => {
      if (!model) {
        await _couponService.addCoupon(data);
        return { SuccessMessage: "Coupon added sucessfully." };
      } else {
        _couponService.editCoupon(data);
        return { SuccessMessage: "Coupon info updated sucessfully." };
      }
    },
    [_couponService, model]
  );
  const setValidDateRange = (startDateParam: any, expiryDateParam: any) => {
    var minDateVal = moment(new Date()).add(3, "d").toDate();
    var maxDateVal = moment(new Date()).add(60, "d").toDate();

    if (startDateParam != undefined) {
      minDateVal = moment(startDateParam).add(3, "d").toDate();
      maxDateVal = moment(startDateParam).add(60, "d").toDate();
    }
    setMaxDate(maxDateVal);
    setMinDate(minDateVal);

    if (
      expiryDateParam != undefined &&
      (moment(expiryDateParam).isBefore(minDateVal, "d") ||
        moment(expiryDateParam).isAfter(maxDateVal, "d"))
    ) {
      form.setValues({
        ExpiryDate: null,
      });
    }
  };

  const fetchData = useCallback(async () => {
    if (model) {
      setMaxDate(moment(model.StartDate).add(60, "d").toDate());
      setMinDate(moment(model.StartDate).add(3, "d").toDate());
    }
    return model
      ? { ...model }
      : {
          DiscountPercentage: 10,
        };
  }, [model]);

  const formValues = form.getValues();

  return (
    <FocusableForm
      form={form}
      fetchData={fetchData}
      readyToLoad
      submitLabel="Save"
      onSubmit={onSubmit}
      afterSubmit={() => onClose?.(true)}
      onCancel={() => onClose?.()}
      buttonSize="md"
    >
      <Grid>
        <Grid.Col span={4}>
          <Group className={classes.labels}>
            <Text size="sm">Coupon Duration:</Text>
            <HoverCard
              position="top"
              width={220}
              withArrow
              transitionProps={{
                transition: "fade",
                duration: 200,
              }}
              withinPortal
            >
              <HoverCard.Target>
                <ActionIcon>
                  <IconInfoCircle size={14} />
                </ActionIcon>
              </HoverCard.Target>

              <HoverCard.Dropdown>
                <Text>
                  Duration can be between 48 Hours and 60 days inclusively.
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
        </Grid.Col>
        <Grid.Col span={8}>
          <Stack>
            <Group>
              <FormDatePicker
                placeholder="Pick start date"
                label="Start"
                withAsterisk
                minDate={
                  formValues.startDate != undefined
                    ? moment(formValues.startDate).toDate()
                    : moment(new Date()).add(1, "d").toDate()
                }
                fieldName="StartDate"
                parentForm={form}
                onDateChange={(val) => {
                  setValidDateRange(val, formValues.ExpiryDate);
                }}
              />
              <FormDatePicker
                placeholder="Pick expiry date"
                label="Expire"
                withAsterisk
                minDate={minDate}
                maxDate={maxDate}
                fieldName="ExpiryDate"
                parentForm={form}
                onDateChange={(val) => {
                  setValidDateRange(formValues.StartDate, val);
                }}
              />
            </Group>
            <Alert color="gray">
              <strong>Note: </strong>Coupon will go active at 12AM AEST of Start
              date and will expire at 12AM AEST of Expire date.
            </Alert>
          </Stack>
        </Grid.Col>
      </Grid>

      <Grid>
        <Grid.Col span={4}>
          <Text size="sm" className={classes.labelstwo}>
            Coupon Discount Percentage:
          </Text>
        </Grid.Col>
        <Grid.Col span={8}>
          <Flex
            gap="sm"
            justify="flex-start"
            align="flex-start"
            direction="row"
          >
            <FormNumberInput
              label=""
              placeholder="Coupon Discount Percentage"
              icon={<IconPercentage size={18} />}
              fieldName="DiscountPercentage"
              parentForm={form}
            />
          </Flex>
        </Grid.Col>
      </Grid>
      <Grid gutter="xs">
        <Grid.Col span={4}>
          <Group>
            <Text size="sm">Minimum Cart Value (optional):</Text>
            <HoverCard
              position="top"
              width={220}
              withArrow
              transitionProps={{
                transition: "fade",
                duration: 200,
              }}
              withinPortal
            >
              <HoverCard.Target>
                <ActionIcon>
                  <IconInfoCircle size={14} />
                </ActionIcon>
              </HoverCard.Target>

              <HoverCard.Dropdown>
                <Text>
                  Minimum cart value can be between $1 to $100 inclusively.
                  Minimum cart value excludes shipping.
                </Text>
              </HoverCard.Dropdown>
            </HoverCard>
          </Group>
        </Grid.Col>
        <Grid.Col span={8}>
          <Flex
            gap="sm"
            justify="flex-start"
            align="flex-start"
            direction="row"
          >
            <FormNumberInput
              label=""
              placeholder="Minimum Cart Value"
              // min={0}
              // max={100}
              precision={2}
              icon={<IconCurrencyDollar size={18} />}
              fieldName="MinCartValue"
              parentForm={form}
            />
          </Flex>
        </Grid.Col>
      </Grid>
      <Space h="sm" />

      <Alert color="yellow" title="">
        <strong>Terms of Use: </strong>By submitting this coupon promotion, I
        declare that I am authorised to launch this coupon promotion by the
        merchant that I represent. On behalf of the merchant, I acknowledge that
        increasing product sale prices during the period of the coupon promotion
        is a breach of these Terms of Use. I agree to abide by consumer laws
        relating to price displays and discount vouchers{" "}
        <Anchor
          href="https://www.accc.gov.au/consumers/pricing/price-displays"
          target="_blank"
        >
          (for more information see here)
        </Anchor>
        .
      </Alert>
    </FocusableForm>
  );
};
