import {
  Button,
  Card,
  Grid,
  Image,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import * as yup from "yup";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
} from "@mydeal/core";
import { onboardingService } from "@services/onboarding.service";
import { IconArrowRight } from "@tabler/icons-react";
import { useMemo, useState } from "react";
import svgWelcome from "../../assets/images/onboarding-welcome-background.svg";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import { useApiErrorHandler, useFocusableForm } from "@mydeal/ui-mantine";

export const OnboardingWelcomePage = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _onboardingService = useMemo(
    () => onboardingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const { open: notify } = useNotification();
  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const form = useFocusableForm<any>({
    schema: yup.object(),
  });
  const apiErrorHandler = useApiErrorHandler(notify, form.setErrors);

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const onSubmit = (data: any) => {
    setSaving(true);

    _onboardingService
      .saveWelcomeStep()
      .then(() => {
        refreshOnboardingProgress();
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      <form onSubmit={form.onSubmit(onSubmit)}>
        {!loading && (
          <Grid mt="xl">
            <Grid.Col md={5}>
              <Image src={svgWelcome} />
            </Grid.Col>
            <Grid.Col md={6} offsetMd={1}>
              <Stack spacing="lg">
                <Title order={2}>Welcome to MyDeal</Title>
                <Card withBorder p="xl">
                  <Text fz="lg" mb="sm">
                    Your password has been successfully updated!
                  </Text>
                  <Text fz="lg" mb="sm">
                    Before we launch your account we need to know more about you
                    and your store.
                  </Text>
                  <Button type="submit" color="primary" disabled={saving}>
                    Get Started <IconArrowRight size={16} />
                  </Button>
                </Card>
              </Stack>
            </Grid.Col>
          </Grid>
        )}
      </form>
    </>
  );
};
