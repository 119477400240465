import {
  useFocusableForm,
  FormNumberInput,
  FormSwitch,
  FormTextField,
  AuthenticatedForm,
} from "@mydeal/ui-mantine";
import * as yup from "yup";
import { Alert, Anchor, Card, Divider, Text, Title } from "@mantine/core";
import { useDataProvider, useRouterContext } from "@mydeal/core";
import { accountService } from "@services";
import { IconAlertCircle, IconExternalLink } from "@tabler/icons-react";
import { IProductSettingModel } from "@types";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import { useMemo, useCallback, useEffect } from "react";

const ProductSettingsSchema = yup.object<{
  [Property in keyof IProductSettingModel]: any;
}>({
  IsSellPriceAndRrpIncludeGst: yup
    .boolean()
    .label("Sell Price and RRP Includes GST")
    .when(["IsPullSellerGroup", "IsPushSellerGroup", "IsOmnivoreSellerGroup"], {
      is: (
        isPullSellerGroup: boolean,
        isPushSellerGroup: boolean,
        isOmnivoreSellerGroup: boolean
      ) => isPullSellerGroup || isPushSellerGroup || isOmnivoreSellerGroup,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  IsShippingPriceIncludeGst: yup
    .boolean()
    .label("Shipping Price Includes GST")
    .when(["IsPushSellerGroup", "IsOmnivoreSellerGroup"], {
      is: (isPushSellerGroup: boolean, isOmnivoreSellerGroup: boolean) =>
        isPushSellerGroup || isOmnivoreSellerGroup,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  StockBuffer: yup
    .number()
    .label("Global Product Stock Buffer")
    .when("IsPullSellerGroup", {
      is: true,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  IsSupportPOBoxDelivery: yup
    .boolean()
    .label("Support PO Box Delivery")
    .required(),
  DeliveryDetails: yup.string().nullable(),
  IsFastDispatch: yup
    .boolean()
    .label("Fast Dispatch")
    .when(["IsPullSellerGroup", "IsMyDealApi", "IsOmnivoreSellerGroup"], {
      is: (
        isPullSellerGroup: boolean,
        isMyDealApi: boolean,
        isOmnivoreSellerGroup: boolean
      ) => isPullSellerGroup || isMyDealApi || isOmnivoreSellerGroup,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  MinDeliveryDay: yup
    .number()
    .typeError("Minimum Delivery Days must be a number")
    .label("Minimum Delivery Days")
    .when(["IsPullSellerGroup", "IsMyDealApi", "IsOmnivoreSellerGroup"], {
      is: (
        isPullSellerGroup: boolean,
        isMyDealApi: boolean,
        isOmnivoreSellerGroup: boolean
      ) => isPullSellerGroup || isMyDealApi || isOmnivoreSellerGroup,
      then: (schema) => schema.required().nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
  MaxDeliveryDay: yup
    .number()
    .typeError("Maximum Delivery Days must be a number")
    .label("Maximum Delivery Days")
    .when(["IsPullSellerGroup", "IsMyDealApi", "IsOmnivoreSellerGroup"], {
      is: (
        isPullSellerGroup: boolean,
        isMyDealApi: boolean,
        isOmnivoreSellerGroup: boolean
      ) => isPullSellerGroup || isMyDealApi || isOmnivoreSellerGroup,
      then: (schema) => schema.required().nullable(),
      otherwise: (schema) => schema.nullable(),
    }),
  DeliveryTimeFrame: yup
    .string()
    .label("Delivery Time-Frame")
    .when(["IsPullSellerGroup", "IsMyDealApi", "IsOmnivoreSellerGroup"], {
      is: (
        isPullSellerGroup: boolean,
        isMyDealApi: boolean,
        isOmnivoreSellerGroup: boolean
      ) => isPullSellerGroup || isMyDealApi || isOmnivoreSellerGroup,
      then: (schema) => schema.required(),
      otherwise: (schema) => schema.nullable(),
    }),
  IsPullSellerGroup: yup.boolean(),
  IsPushSellerGroup: yup.boolean(),
  IsOmnivoreSellerGroup: yup.boolean(),
  IsMyDealApi: yup.boolean(),
});

export interface IProductSettingsEditor {
  onboarding: boolean;
}

export const ProductSettingsEditor = ({
  onboarding,
}: IProductSettingsEditor) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<IProductSettingModel>({
    initialValues: {
      MinDeliveryDay: 1,
      MaxDeliveryDay: 3,
    },
    schema: ProductSettingsSchema,
  });

  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const { Link } = useRouterContext();

  const onSubmit = useCallback(
    async (data: Partial<IProductSettingModel>) => {
      return await _accountService.updateProductSettings(data, onboarding);
    },
    [_accountService, onboarding]
  );

  const afterSubmit = useCallback(() => {
    if (onboarding) {
      refreshOnboardingProgress();
    }
  }, [onboarding, refreshOnboardingProgress]);

  const {
    IsPullSellerGroup,
    IsPushSellerGroup,
    IsOmnivoreSellerGroup,
    IsMyDealApi,
    MinDeliveryDay,
    MaxDeliveryDay,
  } = form.getValues();

  useEffect(() => {
    if (onboarding) {
      form.setFieldValue(
        "DeliveryTimeFrame",
        `${MinDeliveryDay}-${MaxDeliveryDay} business days`
      );
    }
  }, [MinDeliveryDay, MaxDeliveryDay]);

  return (
    <Card withBorder mih={300} data-testid="myaccount-productsettings">
      {!onboarding && (
        <Card.Section py="sm" px="xl">
          <Title order={5} mb="xs">
            Product Settings
          </Title>
          <Divider />
        </Card.Section>
      )}
      <Card.Section py="sm" px="xl">
        {!onboarding && (
          <Alert
            color="yellow"
            icon={<IconAlertCircle size={16} />}
            title="Note"
            mb="lg"
          >
            MyDeal uses these settings to manage your products and orders on
            MyDeal. If you wish to change any of these settings please{" "}
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
              target="_blank"
            >
              submit a request <IconExternalLink size={14} />
            </Anchor>
          </Alert>
        )}
        <AuthenticatedForm
          dataProviderInstance={dataProviderInstance}
          form={form}
          fetchData={_accountService.loadProductSettings}
          submitLabel={onboarding ? "Save and Next" : "Update"}
          readOnly={!onboarding}
          onSubmit={onSubmit}
          afterSubmit={afterSubmit}
          allowSubmitWithNoChanges={onboarding}
        >
          {(IsPullSellerGroup ||
            IsPushSellerGroup ||
            IsOmnivoreSellerGroup) && (
            <>
              <Title order={6}>Price and Stock settings:</Title>
              <Divider />
              <FormSwitch
                size="lg"
                readOnly={!onboarding}
                onLabel="Yes"
                offLabel="No"
                label="Will your Sell price and RRP on MyDeal include GST?"
                parentForm={form}
                fieldName="IsSellPriceAndRrpIncludeGst"
              />
            </>
          )}
          {(IsPushSellerGroup || IsOmnivoreSellerGroup) && (
            <>
              <Divider />
              <FormSwitch
                size="lg"
                readOnly={!onboarding}
                onLabel="Yes"
                offLabel="No"
                label="Will your Shipping Price on MyDeal include GST?"
                parentForm={form}
                fieldName="IsShippingPriceIncludeGst"
              />
            </>
          )}
          {IsPullSellerGroup && (
            <>
              <Divider />
              <FormNumberInput
                readOnly={!onboarding}
                label="Global Product Stock Buffer"
                description="MyDeal will reduce the received product/variant stock value by this amount"
                parentForm={form}
                fieldName="StockBuffer"
              />
            </>
          )}
          <Divider />
          <Title order={6}>Delivery Information:</Title>
          {(IsPullSellerGroup || IsMyDealApi || IsOmnivoreSellerGroup) && (
            <>
              <Divider />
              <FormSwitch
                size="lg"
                readOnly={!onboarding}
                onLabel="Yes"
                offLabel="No"
                label="Fast Dispatch"
                description="When enabled a label will show on your products to indicate that orders will be dispatched within 48 hours of purchase"
                parentForm={form}
                fieldName="IsFastDispatch"
              />
            </>
          )}
          <Divider />
          <FormSwitch
            size="lg"
            readOnly={!onboarding}
            onLabel="Yes"
            offLabel="No"
            label="Can all your products be delivered to a PO Box address?"
            description={
              <>
                <Text>This is the default setting for your products.</Text>
                {!onboarding && (
                  <Text>
                    You can specify the deliverability of your products to a PO
                    Box address at product level via the{" "}
                    <Anchor component={Link} to="/mydeals">
                      My Deals
                    </Anchor>{" "}
                    page.
                  </Text>
                )}
              </>
            }
            parentForm={form}
            fieldName="IsSupportPOBoxDelivery"
          />

          {(IsPullSellerGroup || IsMyDealApi || IsOmnivoreSellerGroup) && (
            <>
              <Divider />

              <FormTextField
                label="Minimum number of days that customers can expect to wait to receive their order after purchase"
                readOnly={!onboarding}
                parentForm={form}
                fieldName="MinDeliveryDay"
                // onChange={(e) => {
                //   form.setFieldValue(
                //     "DeliveryTimeFrame",
                //     `${form.getValues().MinDeliveryDay}-${
                //       form.getValues().MaxDeliveryDay
                //     } business days`
                //   );
                // }}
              />
              <FormTextField
                label="Maximum number of days that customers can expect to wait to receive their order after purchase"
                readOnly={!onboarding}
                parentForm={form}
                fieldName="MaxDeliveryDay"
                // onChange={(e) => {
                //   form.setFieldValue(
                //     "DeliveryTimeFrame",
                //     `${form.getValues().MinDeliveryDay}-${
                //       form.getValues().MaxDeliveryDay
                //     } business days`
                //   );
                // }}
              />
              <FormTextField
                label="Delivery Time-Frame"
                readOnly={true}
                parentForm={form}
                fieldName="DeliveryTimeFrame"
              />
            </>
          )}
        </AuthenticatedForm>
      </Card.Section>
    </Card>
  );
};
