import {
  ActionIcon,
  createStyles,
  Group,
  Paper,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import * as yup from "yup";
import { IconSearch } from "@tabler/icons-react";
import { useNavigation } from "@mydeal/core";
import { FormTextField, useFocusableForm } from "@mydeal/ui-mantine";

const useStyles = createStyles((theme) => ({
  searchContainer: {
    gap: 0,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    borderRadius: 10,
    minWidth: 500,
    padding: 3,
  },
}));

export const Search = () => {
  const { classes } = useStyles();
  const { push } = useNavigation();
  interface ISearchForm {
    searchTerm: string;
  }
  const form = useFocusableForm<ISearchForm>({ schema: yup.object() });

  return (
    <Stack justify="flex-start" spacing="xs" data-testid="home-search">
      <Title order={3}>Search Orders</Title>
      <Paper p="md" withBorder>
        <Group position="apart">
          <Text>
            Search By Order Group No/Order No/Customer Name/Product SKU
          </Text>

          <form
            onSubmit={form.onSubmit((values) => {
              push(`/Search?query=${values.searchTerm || ""}`);
            })}
          >
            <Group
              className={classes.searchContainer}
              position="right"
              noWrap={true}
              mr="20px"
            >
              <FormTextField
                radius="md"
                placeholder="Search"
                size="sm"
                w="100%"
                icon={<IconSearch size={16} stroke={1.5} />}
                variant="unstyled"
                fieldName="searchTerm"
                parentForm={form}
              />
              <ActionIcon
                component="button"
                type="submit"
                color="brand"
                size="lg"
                radius="md"
                variant="filled"
              >
                <IconSearch size="16" />
              </ActionIcon>
            </Group>
          </form>
        </Group>
      </Paper>
    </Stack>
  );
};
