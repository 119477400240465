import { useApiErrorHandler, useFocusableForm } from "@mydeal/ui-mantine";
import * as yup from "yup";
import {
  Alert,
  Anchor,
  Button,
  Card,
  Divider,
  List,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
} from "@mydeal/core";
import { FreightList } from "@pages/shipping";
import { onboardingService } from "@services/onboarding.service";
import { IconAlertCircle } from "@tabler/icons-react";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import { useMemo, useState } from "react";

export const OnboardingShippingOption = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _onboardingService = useMemo(
    () => onboardingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const [loading, setLoading] = useState<boolean>(false);
  const [saving, setSaving] = useState<boolean>(false);
  const { open: notify } = useNotification();
  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const form = useFocusableForm<any>({
    schema: yup.object(),
  });
  const apiErrorHandler = useApiErrorHandler(notify, form.setErrors);

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const onSubmit = () => {
    setSaving(true);

    _onboardingService
      .saveShippingOption()
      .then(() => {
        refreshOnboardingProgress();
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setSaving(false);
      });
  };

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && (
        <Stack spacing="lg">
          <Title order={2}>MyDeal Shipping Option</Title>
          <Card withBorder mih={300} p="xl">
            <Text fz="md" mb="sm">
              MyDeal supports the following shipping options. These shipping
              options can also be later assigned to all your products:
            </Text>
            <List mb="sm">
              <List.Item>
                <Text weight={600}>Free Shipping</Text>
                <Text>Customer delivery is free of charge</Text>
              </List.Item>
              <List.Item>
                <Text weight={600}>Flat Rate</Text>
                <Text>
                  The shipping cost of the product is based on one flat rate per
                  unit. E.g. If FlatRate = $5, then purchasing 2 units of the
                  same product will incur a total shipping cost of $10
                </Text>
              </List.Item>
              <List.Item>
                <Text weight={600}>Flat Rate Any Quantity</Text>
                <Text>
                  Customer pays a flat rate shipping cost irrespective of
                  quantity ordered. E.g. If FlatAnyQty = $9, then purchasing
                  multiple units of the same product will incur a total shipping
                  cost of $9. NOTE: MyDeal does not currently support combined
                  shipping across different products/variants.
                </Text>
              </List.Item>
              <List.Item>
                <Text weight={600}>Shipping Calculator</Text>
                <Text>
                  The shipping cost is calculated based on the customer’s
                  delivery postcode and/or a product’s shipping weight or price.
                  A shipping calculator can be setup based on one of MyDeal's{" "}
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10008253707535"
                    target="_blank"
                  >
                    shipping rate tables
                  </Anchor>{" "}
                  or via one of the{" "}
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10008378401423"
                    target="_blank"
                  >
                    shipping APIs
                  </Anchor>{" "}
                  supported by MyDeal
                </Text>
              </List.Item>
            </List>
            <Alert icon={<IconAlertCircle size={16} />} color="yellow" mb="sm">
              Click "Add New" to setup a shipping calculator now or click next
              to skip and/or setup later
            </Alert>
            <FreightList onboarding={true} />
            <Divider my="sm" />
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Button type="submit" color="primary" disabled={saving}>
                Save and Next
              </Button>
            </form>
          </Card>
        </Stack>
      )}
    </>
  );
};
