import {
  createStyles,
  Text,
  Title,
  TextInput,
  Button,
  Image,
  TypographyStylesProvider,
  Container,
  Flex,
  Space,
} from "@mantine/core";
import { DataContextProvider } from "@mydeal/core";
import ImageHowit01 from "../../assets/images/landingTabs/Howitworks/furniture-imac.png";
import ImageHowit02 from "../../assets/images/landingTabs/Howitworks/mac-2_new.png";
import ImageHowit03 from "../../assets/images/landingTabs/Howitworks/mac-3.png";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: `calc(${theme.spacing.xl} * 2)`,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      flexDirection: "column-reverse",
      padding: theme.spacing.xs,
    },
  },

  wrapperRightImage: {
    "&body": {
      paddingRight: `calc(${theme.spacing.xl} * 2)`,
      paddingLeft: 0,
      [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
        paddingRight: 0,
      },
    },
  },

  image: {
    maxWidth: "40%",

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      maxWidth: "100%",
    },
  },

  body: {
    maxWidth: "60%",
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    [`@media (max-width: ${theme.breakpoints.sm}px)`]: {
      paddingLeft: 0,
      marginTop: theme.spacing.xl,
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function TabHowItWorks() {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.wrapper}>
        <Image src={ImageHowit01} className={classes.image} />
        <div className={classes.body}>
          <Title className={classes.title} order={4}>
            1 Apply For a MyDeal Marketplace Shopfront
          </Title>
          <Text size="md" color="dimmed">
            One of our partnership executives will contact you to begin the
            on-boarding process.
          </Text>
        </div>
      </div>
      <div className={classes.wrapper}>
        <Image src={ImageHowit02} className={classes.image} />
        <div className={classes.body}>
          <Title className={classes.title} order={4}>
            2 Build Your Shopfront
          </Title>
          <Text size="md" color="dimmed">
          We can seamlessly connect to your ecommerce platform and begin to list your products in real-time. You can provide the best value to MyDeal customers with an uncapped number of products on your shopfront.
          </Text>
          <Space h="md" />
          <Text size="md" color="dimmed">
            Our strategic marketing team will help you strategise and plan for
            the months and years ahead. They will serve with you as partners in
            driving growth and revenue for your business.
          </Text>
        </div>
      </div>
      <div className={classes.wrapper}>
        <Image src={ImageHowit03} className={classes.image} />
        <div className={classes.body}>
          <Title className={classes.title} order={4}>
            3 Start Selling
          </Title>
          <Text size="md" color="dimmed">
          Your MyDeal Marketplace account is the hub to your operations on the platform. You can track your orders, manage fulfilment and view financials.
          </Text>
          <Space h="md" />
          <Text size="md" color="dimmed">
            We will keep you updated on upcoming promotions, operational news
            and other industry developments in our regular seller newsletter.
          </Text>
        </div>
      </div>
    </>
  );
}
