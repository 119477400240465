import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import { Navbar, Code, Divider, ScrollArea } from "@mantine/core";
import {
  IconBuilding,
  IconBuildingStore,
  IconCircle,
  IconCreditCard,
  IconHome,
  IconMail,
  IconPassword,
  IconSettings,
  IconShieldLock,
  IconTruckDelivery,
  IconUserCircle,
  IconUsers,
} from "@tabler/icons-react";

import { ILinksGroupProps, LinksGroup } from "./NavbarLinks";
import { TicketSummaryContext } from "@pages/enquiryInbox";
import { ShippingSettingsContext } from "@pages/shipping";
import { useAuth0 } from "@auth0/auth0-react";
import { IndividualLoginAuth0Context } from "@providers/IndividualLoginAuth0Context";

interface SideNavProps {
  opened: boolean;
}

export const SideNav = ({ opened }: SideNavProps) => {
  const [openTicketCount, setOpenTicketCount] = useState<number | null>(0);
  const [openEscalationCount, setOpenEscalationCount] = useState<number | null>(
    0
  );

  const { user } = useAuth0(IndividualLoginAuth0Context);
  const userRoles = useMemo(
    () => (user?.["https://mydeal.com.au/roles"] as string[]) || [],
    [user]
  );
  const hasRole = useCallback(
    (...roles: string[]) => roles.some((r) => userRoles.includes(r)),
    [userRoles]
  );

  const shippingSettingsContext = useContext(ShippingSettingsContext);
  const ticketSummaryContext = useContext(TicketSummaryContext);

  useEffect(() => {
    setOpenTicketCount(
      ticketSummaryContext &&
        ticketSummaryContext.ticketSummaryModel &&
        ticketSummaryContext.ticketSummaryModel.OpenTicketsCount | 0
    );
    setOpenEscalationCount(
      ticketSummaryContext &&
        ticketSummaryContext.ticketSummaryModel &&
        ticketSummaryContext.ticketSummaryModel.OpenEscalationsCount | 0
    );
  }, [ticketSummaryContext]);

  const data: ILinksGroupProps[] = useMemo(
    () => [
      {
        icon: IconHome,
        label: "Home",
        route: "/dashboard",
      },
      { icon: IconBuildingStore, label: "My Deals", route: "/mydeals" },
      {
        icon: IconUserCircle,
        label: "User Settings",
        links: [
          {
            icon: IconPassword,
            label: "Change Password",
            route: "/user/change-password",
          },
          {
            icon: IconShieldLock,
            label: "Multi-Factor Authentication",
            route: "/user/mfa",
          },
        ],
      },
      ...(hasRole("SellerAdministrator")
        ? [
            {
              icon: IconSettings,
              label: "Administration",
              links: [
                {
                  icon: IconUsers,
                  label: "User Management",
                  route: "/admin/user-management",
                },
                {
                  icon: IconMail,
                  label: "Invitations",
                  route: "/admin/invitations",
                },
              ],
            },
          ]
        : []),
      {
        icon: IconBuilding,
        label: "My Company",
        links: [
          {
            icon: IconCircle,
            label: "Contact Details",
            route: "/myaccount/contact-details",
          },
          {
            icon: IconCircle,
            label: "Bank Details",
            route: "/myaccount/bank-details",
          },

          {
            icon: IconCircle,
            label: "Profile Page Settings",
            route: "/myaccount/profile",
          },
          {
            icon: IconCircle,
            label: "Integration Settings",
            route: "/myaccount/integration",
          },
          {
            icon: IconCircle,
            label: "Product Settings",
            route: "/myaccount/product-settings",
          },
        ],
      },
      {
        icon: IconTruckDelivery,
        label: "Shipping Settings",
        route: "/shipping",
        links: [
          {
            icon: IconCircle,
            label: "Manage Freight Schemes",
            route: "/shipping/manage-freight",
          },
          ...(shippingSettingsContext.shippingSettingsModel
            ?.IsEligibleForCombinedFreight
            ? [
                {
                  icon: IconCircle,
                  label: "Shipping Offers",
                  route: "/shipping/shipping-offers",
                },
                {
                  icon: IconCircle,
                  label: "Combined Shipping",
                  route: "/shipping/combined-shipping",
                },
              ]
            : []),
          ...(shippingSettingsContext.shippingSettingsModel?.IsPullSeller
            ? [
                {
                  icon: IconCircle,
                  label: "Shipping Assignment",
                  route: "/shipping/shipping-assignment",
                },
              ]
            : []),
        ],
      },

      {
        icon: IconMail,
        label: "Enquiry Inbox",
        links: [
          {
            icon: IconCircle,
            label: "Open Tickets",
            route: "/enquiryinbox/open-tickets",
            notifications: openTicketCount ? openTicketCount : 0,
          },
          {
            icon: IconCircle,
            label: "Pending Tickets",
            route: "/enquiryinbox/pending-tickets",
          },
          {
            icon: IconCircle,
            label: "Solved Tickets",
            route: "/enquiryinbox/solved-tickets",
          },
          {
            icon: IconCircle,
            label: "Escalations from MyDeal",
            route: "/enquiryinbox/escalations",
            notifications: openEscalationCount ? openEscalationCount : 0,
          },
          {
            icon: IconCircle,
            label: "Solved Escalations",
            route: "/enquiryinbox/solved-escalations",
          },
        ],
      },
      {
        icon: IconCreditCard,
        label: "Payments",
        route: "/payments",
      },
    ],
    [
      hasRole,
      openEscalationCount,
      openTicketCount,
      shippingSettingsContext.shippingSettingsModel
        ?.IsEligibleForCombinedFreight,
      shippingSettingsContext.shippingSettingsModel?.IsPullSeller,
    ]
  );

  const links = useMemo(
    () => data.map((item) => <LinksGroup {...item} key={item.label} />),
    [data]
  );

  return (
    <>
      <Navbar
        width={{ base: 300 }}
        p="md"
        hiddenBreakpoint="sm"
        hidden={!opened}
        data-testid="sidenav"
      >
        <Navbar.Section grow mt="xs" component={ScrollArea}>
          {links}
        </Navbar.Section>
        <Navbar.Section>
          <Divider my="sm" />
          <Code sx={{ fontWeight: 80 }}>v2.0</Code>
        </Navbar.Section>
      </Navbar>
    </>
  );
};
