import { useState } from "react";
import * as yup from "yup";
import { FormTextField } from "@mydeal/ui-mantine";
import { Group, TextInput, Center } from "@mantine/core";
import {
  IMfaEnrollmentModel,
  MfaEnrollmentState,
  MfaSecurityLevel,
} from "@types";
import { IconPhone, IconSquareAsterisk } from "@tabler/icons-react";
import { MfaMethodSetUp } from "@components/auth";
import { QRCodeSVG } from "qrcode.react";
import { Label } from "../../components";

const EnrollSmsMfaSchema = yup.object<{
  [Property in keyof IMfaEnrollmentModel]: any;
}>({
  AuthenticatorId: yup.string().nullable(),
  AuthenticatorName: yup
    .string()
    .label("Phone Number")
    .nullable()
    .when("$enrollmentState", {
      is: MfaEnrollmentState.ProvidingInput,
      then: (schema) => schema.required().matches(
        /^[0][1-9]\d{8}$|^[1-9]\d{8}$/i,
        "Please enter a valid phone number."
      ),
    }),
  AuthenticatorActive: yup.boolean().nullable(),
  ConfirmationCode: yup
    .string()
    .label("6-digits Confirmation Code")
    .nullable()
    .when("$enrollmentState", {
      is: MfaEnrollmentState.ProvidingConfirmationCode,
      then: (schema) => schema.required().matches(
        /^[0-9]{6}$/i,
        "Please enter a valid 6-digit code."
      ),
    }),
});

const EnrollOtpMfaSchema = yup.object<{
  [Property in keyof IMfaEnrollmentModel]: any;
}>({
  AuthenticatorId: yup.string().nullable(),
  AuthenticatorName: yup.string().label("Authenticator Name").nullable(),
  AuthenticatorActive: yup.boolean().nullable(),
  ConfirmationCode: yup
    .string()
    .nullable()
    .when("$enrollmentState", {
      is: MfaEnrollmentState.ProvidingConfirmationCode,
      then: (schema) => schema.required().matches(
        /^[0-9]{6}$/i,
        "Please enter a valid 6-digit code."
      ),
    }),
});

export const MultiFactorAuthenticationEditor = () => {
  const [smsOobCode, setSmsOobCode] = useState<string | undefined>();
  const [otpSecret, setOtpSecret] = useState<string | undefined>();
  const [otpBarcodeUri, setOtpBarcodeUri] = useState<string | undefined>();

  return (
    <Group grow={false}>
      <MfaMethodSetUp
        icon={IconSquareAsterisk}
        displayName="One-Time Password App"
        shortName="OTP"
        type="otp"
        description="Provide a one-time password using Google Authenticator or similar."
        formSchema={EnrollOtpMfaSchema}
        securityLevel={MfaSecurityLevel.Best}
        confirmationControls={
          <>
            <Label>Scan the QR Code with your autheticator app</Label>
            <Center>
              <QRCodeSVG value={otpBarcodeUri || ""} />
            </Center>
            <TextInput label="Or, enter the secret key in your authenticator app" value={otpSecret} readOnly />
          </>
        }
        submitInput={async (_data, service) => {
          var response = await service.enrollOtpMfa();
          setOtpSecret(response?.Secret);
          setOtpBarcodeUri(response?.BarcodeUri);
        }}
        submitConfirmation={async (data, service) => {
          await service.confirmOtpMfa(data.ConfirmationCode || "");
        }}
      />

      <MfaMethodSetUp
        icon={IconPhone}
        displayName="Mobile Phone SMS"
        shortName="SMS"
        type="sms"
        description="Receive a phone message with a verification code."
        formSchema={EnrollSmsMfaSchema}
        securityLevel={MfaSecurityLevel.Good}
        inputControls={(form, disabled) => (
          <>
            <FormTextField
              label="Phone Number"
              fieldName="AuthenticatorName"
              parentForm={form}
              disabled={disabled}
              placeholder="0412345678"
            />
          </>
        )}
        submitInput={async (data, service) => {
          var response = await service.enrollSmsMfa(
            data.AuthenticatorName || ""
          );
          setSmsOobCode(response?.OobCode);
        }}
        submitConfirmation={async (data, service) => {
          await service.confirmSmsMfa(
            smsOobCode || "",
            data.ConfirmationCode || ""
          );
        }}
      />
    </Group>
  );
};
