export interface IBankDetailModel {
  AccountName: string;
  BSB: string;
  Account: string;
  BankName: string;
  SwiftCode: string;
  CountryIsoCode: string;
  InternationalBankAddress: string;
  InternationalBankName: string;
  IsInternationalBank: boolean;
  InternationalAccountName: string;
  InternationalAccountNo: string;
}

export interface ISellerProfileModel {
  StoreDisplayName: string;
  ProfileDescription: string;
  WarrantyAndRefundDetails: string;
  HasSellFashionProduct: any;
  CareAndSizingDetails: string;
  HasLiquorProduct: any;
  LiquorLicenceName: string;
  LiquorLicenceNumber: string;
  LiquorLicenceAdditionalWording: string;
  DeliveryDetails: string;
  IsMyAccountPage: boolean;
  StoreProfileUrl: string;
}

export interface IIntegrationConfigModel {
  SourceChannel?: SourceChannel;
  SourceChannelName: string;
  IsExternalSourceChannel: boolean;
  AutoPushOrder?: boolean;
  AutoFetchTracking: boolean;
  AutoPriceUpdate: boolean;
  AutoStockUpdate: boolean;
  ShopifyAppStoreUrl: string;
  NetoAppStoreUrl: string;
  AutoPushOrderOptions: any[];
  PlatformChoiceOptions: any[];
  SellerId: number;
  SellerToken: string;
  IsFormDirty: boolean;
  MagentoStoreUrl: string;
  MagentoUsername: string;
  MagentoPassword: string;
  MagentoToken: string;
  Magento1StoreID: string;
  Magento2StoreID: string;
  CreateInvoiceForOrder: boolean;
  MagentoPaymentMethod: string;
  MagentoBrandAttributeCode: string;
  ImageBaseMediaUrl: string;
  DescriptionBaseMediaUrl: string;
  MagentoPaymentMethodOptions: any[];
  WooCommerceStoreUrl: string;
  WooCommerceConsumerKey: string;
  WooCommerceConsumerSecret: string;
  PriceCustomField: string;
  WooCommerceShippingMethod: string;
  WooCommercePaymentMethod: string;
}

export interface IChannelType {
  id: number;
  name: string;
  isExternal: boolean;
  isChannel: boolean;
}

export interface IProductSettingModel {
  IsSellPriceAndRrpIncludeGst?: boolean;
  IsShippingPriceIncludeGst?: boolean;
  StockBuffer?: number;
  IsSupportPOBoxDelivery: boolean;
  DeliveryDetails: string;
  IsFastDispatch?: boolean;
  MinDeliveryDay?: number;
  MaxDeliveryDay?: number;
  DeliveryTimeFrame: string;
  SourceChannel: SourceChannel;
  IsCsvSellerGroup: boolean;
  IsPushSellerGroup: boolean;
  IsPullSellerGroup: boolean;
  IsOmnivoreSellerGroup: boolean;
  IsMyDealApi: boolean;
}

export enum SourceChannel {
  CustomFile = 0,
  Shopify = 1,
  Neto = 2,
  Magento2 = 3,
  WooCommerce = 4,
  ShopifyApp = 5,
  IntelligentReach = 6,
  ChannelAdvisor = 7,
  CommerceConnect = 8,
  Magento = 9,
  CrazySales = 10,
  TomTop = 11,
  Booktopia = 12,
  Klika = 13,
  SmartChannel = 14,
  DeluxeProducts = 15,
  Shelights = 16,
  KitchenWareHouse = 17,
  LifeSpan = 18,
  DWI = 19,
  BestPoolSupplies = 20,
  MegaBoutique = 21,
  NorthHem = 22,
  FreshGroup = 23,
  OzyLifestyle = 24,
  Phonebot = 25,
  GetWinesDirect = 26,
  StrawberryNet = 27,
  AcosEcommerce = 28,
  Omnivore = 29,
  Cin7 = 30,
  EChampTrading = 31,
  BetterHome = 32,
  BigCommerce = 33,
  SunRoadBeverages = 34,
  EliteElectronics = 35,
  EdwardBowditch = 36,
  DigitelInternational = 37,
  KitchenHomewares = 38,
  DelightedMall = 39,
  SlaterSports = 40,
  BestDeals4U = 41,
  Rafa = 42,
  HousenLiving = 43,
  AdvancedGlobalTrading = 44,
  InFrontTechnologies = 45,
  LslLifestyle = 46,
  Pharmrec = 47,
  CosmeticsNow = 48,
  WonderlandStore = 49,
  KgElectronics = 50,
  Costway = 51,
  VacuumSpares = 52,
  NetoApp = 53,
  StandardApiIntegration_SKU = 54,
  StandardApiIntegration_ProductId = 55,
  ChannelEngine = 56,
  MagentoApp = 57,
  WooCommerceApp = 58,
}

export enum CombinedFreightOfferType {
  Free = "0",
  FlatRate = "1",
}

export enum AuditActionType {
  Created = 0,
  Updated = 1,
  Deleted = 2,
}

export interface IAuditTrailModel {
  UserName: string;
  EntityName: string;
  AuditActionType: AuditActionType;
  DateTimeStamp: string;
  AuditDeltaList: IAuditDeltaModel[];
}

export interface IAuditDeltaModel {
  FieldName: string;
  ValueBefore: string;
  ValueAfter: string;
}

export interface ICombinedFreightOfferModel {
  IsSupportCombinedFreightOffer: boolean | string;
  CombinedFreightOfferID: number;
  CompanyID: number;
  CombinedFreightOfferType: CombinedFreightOfferType;
  PriceFrom: number | null;
  FlatRateAmount: number | null;
  AuditTrailModels: IAuditTrailModel[];
}

export interface ICombinedFreightRateModel {
  IsSupportCombinedFreightRate: boolean | string;
  CombinedFreightRateID: number;
  CompanyID: number;
  CombinedFreightRateRule: CombinedFreightRateRule;
  PercentageDiscount: number | null;
  FlatRateAmount: number | null;
  ExclusionRuleType: ExclusionRuleType | null;
  ExclusionValue: number | null;
  ExclusionRuleTypeList: ISelectListItem[];
  AuditTrailModels: IAuditTrailModel[];
}

export enum CombinedFreightRateRule {
  FreeForAdditionalItem = "1",
  FlatRateForAdditionalItem = "2",
  PercentageDiscountForAdditionalItem = "3",
  CombinedWeight = "4",
  NoShippingDiscount = "5",
}

export enum ExclusionRuleType {
  NoExclusions = "0",
  WeightAbove = "1",
}

export interface ISelectListItem {
  Disabled: boolean;
  Group: ISelectListGroup;
  Selected: boolean;
  Text: string;
  Value: string;
}

export interface ISelectListGroup {
  Disabled: boolean;
  Name: string;
}

export interface IShippingAssignmentModel {
  ShippingAssignmentType: ShippingAssignmentType;
  GlobalSettingFreightType: FreightType | null;
  GlobalSettingFreightSchemeID: number | null;
  GlobalSettingFlatRateAmount: number | null;
  NextProductSyncAt: string | null;
  FlagPriceBasedFreightAvailable: boolean;
  PriceBasedFreightAssignmentSettingModels: IFreightAssignmentSettingModel[];
  WeightBasedFreightAssignmentSettingModels: IFreightAssignmentSettingModel[];
  GlobalFreightTypeSelectList: ISelectListItem[];
  FreightSchemeSelectList: ISelectListItem[];
}

export interface IFreightAssignmentSettingModel {
  FreightAssignmentSettingID: number;
  CompanyID: number;
  PricedBased: boolean;
  WeightBased: boolean;
  From?: number;
  To?: number;
  FreightType?: any;
  FreightSchemeID: number;
  FreightFlatRate?: number;
  IsDeleted: boolean;
  FromPlaceHolder: string;
  ToPlaceHolder: string;
}

export enum ShippingAssignmentType {
  GlobalSetting = 0,
  ProductPrice = 1,
  ProductWeight = 2,
}

export enum FreightType {
  ProductLevel = 0,
  RequestFreightQuote = 1,
  FreeShipping = 2,
  FlatRate = 3,
  Custom = 4,
  NotSet = 5,
  FlatAnyQty = 6,
}

export interface IChangePasswordModel {
  Password: string;
  ConfirmPassword: string;
}

export interface IContactDetailModel {
  BillingCountry?: string;
  BillingAddressLine1: string;
  BillingAddressLine2: string;
  BillingPostcode: string;
  BillingSuburb: string;
  BillingState: string;
  ReturnCountry?: string;
  ReturnAddressLine1: string;
  ReturnAddressLine2: string;
  ReturnPostcode: string;
  ReturnSuburb: string;
  ReturnState: string;
  CompanyName: string;
  FirstName: string;
  LastName: string;
  PhoneNumber: string;
  MobileNumber: string;
  PrimaryContactEmail: string;
  SecondaryContactEmail: string;
  CustomerServiceEmail: string;
  MarketingContactEmail: string;
  TechnicalContactEmail: string;
  PurchaseNotificationEmails: IPurchaseNotificationEmailModel[];
  AccountingNotificationEmails: IAccountingNotificationEmailModel[];
  IsReturnAddressSameBillingAddress: boolean;
  CountryList: ISelectListItem[];
  IsMyAccountPage: boolean;
}

export interface IAccountingNotificationEmailModel {
  EmailAddress: string;
}

export interface IPurchaseNotificationEmailModel {
  EmailAddress: string;
}

export interface IDashboardModel {
  EarningsLast30Days: number;
  TotalPendingOrderCount: number;
  TotalUnusedVoucherCount: number;
  LastProductSyncAt: string | null;
  ProductSyncFrequency: number | null;
  CompanyProfileUrl: string;
  CompanyName: string;
  CompanyCategoryImage: string;
  SellerScore: number | null;
  IsSellerScoreApproved: boolean;
  HasProduct: boolean;
}

export interface IDataTableRequestModel {
  PageIndex: number;
  PageSize: number;
  SortType?: string;
  Sort?: string;
}

// enum
export enum VoucherState {
  Processing = 0,
  CardApproved = 1,
  CardDeclined = 2,
  Refunded = 3,
  Purchased = 4,
}

enum DealType {
  Normal = 0,
  SupplierMisc = 2,
  SupplierFee = 6,
  SupplierSubscriptionFee = 7,
  SupplierShopnEarnCreditChargeBack = 10,
  SupplierCouponMisc = 11,
}

enum OrderProcessingType {
  None = 0,
  AutoFetch = 1,
  AutoPush = 2,
}

enum OrderType {
  Normal = 0,
  Addon = 1,
  Special = 2,
  CreditNote = 3,
  SupplierPayment = 4,
  SupplierDeduction = 5,
  CouponRebate = 6,
  MDRestockingFee = 7,
  InsufficentCredit = 8,
  SupplierFee = 9,
  SupplierSubscriptionFee = 10,
  RefundFee = 11,
  MyMoney = 12,
}

enum NoteTypes {
  SUPPLIER_NOTE = 1,
  ORDER_NOTE = 2,
  SUPPLIER_REBATE = 3,
  ORDER_REFERENCE_NOTE = 5,
}

export interface ISearchViewModel {
  SearchText: string;
  OrderViewItems: IOrderViewItem[];
  DealModels: DealModel[];
  TotalCount: number;
}

export interface IOrderViewItem {
  CustomerName: string;
  ShippingAddress: AddressViewItem;
  CompanyName: string;
  OrderDate: string;
  Status: VoucherState;
  OrderValue: number;
  PhoneNo: string;
  ProductSKU: string;
  FreightAmount: number;
  OrderAmount: number;
  OrderQty: number;
  CompanyTickUsed: boolean;
  TrackingNo: string;
  Courier: string;
  SupplierNotes: string;
  SelectedOption: string;
  IsPosted: boolean;
  DealId: number;
  BidDealId: number;
  HasAssocaitedFreight: boolean;
  IsDeliveryOnHold: boolean;
  TotalAmount: number;
  AcceptedTime: string;
  Expires: string | null;
  InvoiceName: string;
  IsPaid: boolean;
  NoteCount: number;
  AddonOrderViewItems: AddonOrderViewItem[];
  AddonOrderViewItemsCount: number;
  DealType: DealType;
  Reference: string;
  MainDeal: string;
  CustomerEmail: string;
  GoogleProductCategory: string;
  SellerName: string;
  SellPricePerUnit: number;
  TotalSalePrice: number;
  DispatchDate: string | null;
  BidDealGroupId: number;
  IsCombinedFreightOrder: boolean;
  CompanyId: number;
  Url: string;
  CustomerMarketplace: string;
}

export interface AddonOrderViewItem {
  BidDealId: number;
  InvoiceName: string;
  Status: VoucherState;
  IsPaid: boolean;
  Amount: number;
}

interface AddressViewItem {
  AddressLine1: string;
  AddressLine2: string;
  PostalCode: string;
  PostalState: string;
  Suburb: string;
  Country: string;
}

export interface DealModel {
  DealID: number;
  Quota: number;
  Url: string;
  MainDeal: string;
  CustomerPrice: number;
  CompanyPayPrice: number;
  RRP: number;
  S3Images: string;
  DealTitle: string;
  DealDetails: string;
  WhatYouGet: string;
  FinePrint: string;
  GoogleProductCategory: string;
  MaxDaysForDelivery: number | null;
  FreightSchemeID: number;
  Terminated: boolean;
  Posted: boolean;
  Instant: boolean;
  Approved: boolean;
  FlatRateFreight: number | null;
  LastQualifiedState: boolean;
  ExternalID: string;
  SKU: string;
  Discontinued: boolean;
  Company: CompanyModel;
  Brand: BrandModel;
  Products: ProductModel[];
  Freights: FreightModel[];
  BidDeals: BidDealModel[];
  Locations: LocationModel[];
  DealStartTime: string | null;
  DealEndTime: string | null;
  DaysToExpireIn: number | null;
  Expiary: string | null;
  CurrentQty: number;
  Preselling: boolean;
  DealOptions: string;
  ShowFreeShipping: boolean;
  AdGroupRunning: boolean;
  AdwordsMigrated: boolean;
  Condition: string;
  DescriptionForGoogleFeed: string;
  TitleForGoogleFeed: string;
  Gtin: string;
  Delivery: string;
  RequestFreightQuote: boolean;
  ShowPrice: boolean;
  FlatRateShippingText: string;
  FlatRateStateMetro: boolean;
  ShowBNBN: boolean;
  Show48Hour: boolean;
  Warranty: string;
  ShowCompanyDetails: boolean;
  Thumbnail: string;
  Image: string;
  VoucherInstructions: string;
  MetaTitle: string;
  IsLimitedFreeShipping: boolean;
  Weight: number | null;
  ExternalFreightID: string;
  Width: number | null;
  Length: number | null;
  Height: number | null;
  KSportsFreightTemplateID: string;
  KSportsFreightSku: string;
  IsVariant: boolean;
}

interface CompanyModel {
  CompanyID: number;
  IsActive: boolean;
  Description: string;
  Url: string;
  Username: string;
  WebsiteURL: string;
  Locations: LocationModel[];
  LocationDescription: string;
  ContactEmail: string;
  ContactName: string;
  ContactName2: string;
  PurchaseNotificationsEmails: string;
  FreightQuoteEmail: string;
  AdminName: string;
  ContactNumber1: string;
  ContactNumber2: string;
  CustomerServiceEmail: string;
  DisplayName: string;
  Info: string;
  Image: string;
  GlobalProductMessage: string;
  IsEligibleForFreightProtection: boolean;
  OrderProcessingType: OrderProcessingType;
  ContactNumber3: string;
  ReturnAddress: string;
  ReturnAddress2: string;
  ReturnCountry: string;
  ReturnState: string;
  ReturnSuburb: string;
  ReturnPostcode: string;
  StockContactEmail: string;
  Prepaid: boolean;
  ECommerceProvider: number | null;
}

interface LocationModel {
  GoogleAddress: string;
  Title: string;
  Address: string;
  Address2: string;
  Suburb: string;
  ContactNumber: string;
  State: string;
  Postcode: string;
  City: string;
}

interface BrandModel {
  BrandID: number;
  BrandName: string;
  S3Image: string;
  S3Thumbnail: string;
  Url: string;
  IsFeatured: boolean;
  SearchSignature: string;
  Description: string;
  HtmlMetaDescription: string;
  HtmlTitle: string;
  HtmlMetaTags: string;
  S3Image2: string;
  SubmitToGoogleIndex: boolean;
  Enable: boolean;
}

interface ProductModel {
  ProductID: number;
  Discontinued: boolean;
  PreSelling: boolean;
  DealOption: string;
  Invisible: boolean;
  Variants: VariantModel[];
  ProductAttributes: ProductAttributeModel[];
}

interface VariantModel {
  VariantId: number;
  CompanyPayPrice: number;
  SellPrice: number;
  PreSelling: boolean;
  Discontinued: boolean;
  StockLevel: number;
  CurrentQty: number;
  VariantOptions: VariantOptionModel[];
  RRP: number;
  SKU: string;
  OptionString: string;
  VariantID: number;
  IsDeleted: boolean;
}

interface VariantOptionModel {
  VariantOptionID: number;
  ProductAttribute: ProductAttributeModel;
  ProductAttributeOption: ProductAttributeOptionModel;
  IsDeleted: boolean;
}

interface ProductAttributeModel {
  ProductAttributeID: number;
  ProductAttributeName: string;
  ProductAttributeOptions: ProductAttributeOptionModel[];
}

interface ProductAttributeOptionModel {
  ProductAttributeOptionID: number;
  ProductAttributeOptionName: string;
}

interface BidDealModel {
  BidDealID: number;
  AcceptedTime: string;
  Qty: number;
  TotalAmount: number;
  Status: VoucherState;
  RedirectEmail: string;
  RedirectFName: string;
  RedirectLName: string;
  Expires: string | null;
  PostalAddress: string;
  PostalSuburb: string;
  PostalPostcode: string;
  CustomerTickUsed: boolean;
  CompanyTickUsed: boolean;
  PostalState: string;
  Refunded: string | null;
  TrackingCode: string;
  DispatchDate: string | null;
  PostalAddress2: string;
  PostalCompanyName: string;
  SelectedOption: string;
  DispatchCarrier: string;
  CustomerPrice: number;
  CompanyPayPrice: number;
  Notes: string;
  IsFreight: boolean;
  IsFreightProtection: boolean;
  FreightPostcode: string;
  RedirectPhone: string;
  SupplierNotes: string;
  LatestNote: string;
  ParentID: number | null;
  DeliveryOnHold: boolean;
  OrderType: OrderType;
  Customer: CustomerModel;
  Deal: DealModel;
  Delivery: DeliveryModel;
  FreightPostalState: PostalStateModel;
  Variant: VariantModel;
  Parent: BidDealModel;
  BidDealNotes: BidDealNoteModel[];
  SKU: string;
  BidDealGroupId: number | null;
  IsCombinedFreightOrder: boolean;
}

interface CustomerModel {
  CustomerID: number;
  Email: string;
  Registered: boolean;
  Firstname: string;
  Lastname: string;
  Address: string;
  Gender: number | null;
  Password: string;
  Postcode: number | null;
  RegisteredDate: string | null;
  Referrer: string;
  Phone: string;
  Suburb: string;
  LastLogin: string | null;
  Birthday: string | null;
  Address2: string;
  CompanyName: string;
  PopupSubscriber: boolean;
  State: string;
  BillingFirstName: string;
  BillingLastName: string;
  BillingCompanyName: string;
  BillingPhone: string;
  BillingAddress1: string;
  BillingAddress2: string;
  BillingSuburb: string;
  BillingState: string;
  BillingPostcode: number | null;
  BillingCountry: string;
  Country: string;
  ShippingFirstName: string;
  ShippingLastName: string;
  UtmMedium: string;
  UtmSource: string;
  UtmCampaign: string;
  BidDeals: BidDealModel[];
  City: CityModel;
  HasLoggedIn: boolean;
}

interface CityModel {
  CityID: number;
  CityName: string;
  MainDealID: number | null;
  State: string;
  Url: string;
}

interface DeliveryModel {
  DeliveryID: number | null;
  TrackingCode: string;
  Status: number | null;
}

interface PostalStateModel {
  PostalStateID: number;
  PostalStateAbbreviation: string;
  PostalStateName: string;
}

interface BidDealNoteModel {
  NoteId: number;
  NoteType: NoteTypes;
  NoteContent: string;
  TimeCreated: string | null;
}

interface FreightModel {
  FreightId: number;
  Amount: number;
  PostalState: PostalStateModel;
}

export interface IShippingSettingsModel {
  HasShippingOffers: boolean;
  HasCombinedShipping: boolean;
  HasShippingAssignment: boolean;
  IsEligibleForCombinedFreight: boolean;
  IsPullSeller: boolean;
}

export interface IUserManagementModel {}

export interface IInvitationsModel {
  InviteEmail: string;
}

export interface IMfaEnrollmentModel {
  AuthenticatorId: string | undefined;
  AuthenticatorName: string | undefined;
  AuthenticatorActive: boolean | undefined;
  ConfirmationCode: string | undefined;
}

export interface ISmsMfaEnrollmentResponse {
  OobCode: string;
}

export interface IOtpMfaEnrollmentResponse {
  Secret: string;
  BarcodeUri: string;
}

export enum MfaEnrollmentState {
  None,
  ProvidingInput,
  SubmittingInput,
  ProvidingConfirmationCode,
  SubmittingConfirmationCode,
}

export enum MfaSecurityLevel {
  Good,
  Best,
}
