import { Label } from "@components/shared";
import { FormSwitch, FormTextField, IFocusableForm } from "@mydeal/ui-mantine";

import {
  Anchor,
  Card,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { IChannelType, IIntegrationConfigModel, SourceChannel } from "@types";

export const MagentoIntegrationSettings = ({
  channelType,
  form,
  onboarding,
}: {
  channelType: IChannelType;
  form: IFocusableForm<Partial<IIntegrationConfigModel>>;
  onboarding: boolean;
}) => {
  const { AutoPushOrder, AutoPriceUpdate, AutoStockUpdate, AutoFetchTracking } =
    form.getValues();

  return (
    <Card withBorder mt="xl">
      <Card.Section p="sm">
        <Group position="apart" align="center">
          <Title order={5} mb="xs">
            Magento Configuration Settings
          </Title>
          {channelType.id === SourceChannel.Magento && (
            <Anchor
              href="--DELETED--"
              target="_blank"
              rel="noreferrer"
            >
              Click here to learn about setting up an integration with Magento
              v1 <IconExternalLink size={14} />
            </Anchor>
          )}
          {channelType.id === SourceChannel.Magento2 && (
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10026608579983"
              target="_blank"
              rel="noreferrer"
            >
              Click here to learn about setting up an integration with Magento
              v2 <IconExternalLink size={14} />
            </Anchor>
          )}
        </Group>
        <Divider variant="dashed" />
      </Card.Section>

      <Stack spacing="md">
        <Grid>
          <Grid.Col md={4} sm={6}>
            <Label>Magento Store URL</Label>
          </Grid.Col>
          <Grid.Col md={8} sm={6}>
            <FormTextField parentForm={form} fieldName="MagentoStoreUrl" />
          </Grid.Col>
        </Grid>
        {channelType.id === SourceChannel.Magento && (
          <>
            <FormTextField
              label="Magento API Username"
              readOnly={!onboarding}
              parentForm={form}
              fieldName="MagentoUsername"
            />
            <FormTextField
              label="Magento API Key"
              readOnly={!onboarding}
              parentForm={form}
              fieldName="MagentoPassword"
            />
            <FormTextField
              label="Magento Store ID"
              readOnly={!onboarding}
              parentForm={form}
              fieldName="Magento1StoreID"
            />
          </>
        )}

        {channelType.id === SourceChannel.Magento2 && (
          <>
            <FormTextField
              label="MyDeal API Access Token"
              readOnly={!onboarding}
              parentForm={form}
              fieldName="MagentoToken"
            />
            <FormTextField
              label="Magento Store ID"
              readOnly={!onboarding}
              parentForm={form}
              fieldName="Magento2StoreID"
            />
          </>
        )}

        <Divider />

        <FormSwitch
          size="lg"
          readOnly={!onboarding}
          label="Auto push orders"
          description="How your orders will be managed on MyDeal"
          onLabel="Yes"
          offLabel="No"
          parentForm={form}
          fieldName="AutoPushOrder"
        />
        {AutoPushOrder && (
          <Text>MyDeal pushes orders to your Magento store</Text>
        )}
        {typeof AutoPushOrder !== "undefined" && !AutoPushOrder && (
          <Text>
            You will manually fulfill orders via the MyDeal Seller Portal
          </Text>
        )}

        <Divider />

        <FormSwitch
          size="lg"
          readOnly={!onboarding}
          disabled={AutoPushOrder}
          label="Automated Price Update"
          onLabel="Yes"
          description={
            AutoPushOrder
              ? "Option not available when orders are pushed to your Magento Store"
              : "Your product prices on MyDeal will sync twice a day from your Magento store"
          }
          offLabel="No"
          parentForm={form}
          fieldName="AutoPriceUpdate"
        />
        {!AutoPushOrder && AutoPriceUpdate && (
          <Text>
            Your product prices on MyDeal will be the same as on your Magento
            store
          </Text>
        )}
        {(AutoPushOrder ||
          (typeof AutoPriceUpdate !== "undefined" && !AutoPriceUpdate)) && (
          <Text>
            Your products will be imported to MyDeal without any price and they
            will not go live until price values are assigned manually via the
            MyDeal Seller Portal
          </Text>
        )}

        <Divider />

        <FormSwitch
          size="lg"
          readOnly={!onboarding}
          disabled={AutoPushOrder}
          label="Automated Stock Update"
          onLabel="Yes"
          description={
            AutoPushOrder
              ? "Option not available when orders are pushed to your Magento Store"
              : "Your product stocks on MyDeal will sync twice a day from your Magento store"
          }
          offLabel="No"
          parentForm={form}
          fieldName="AutoStockUpdate"
        />
        {!AutoPushOrder && AutoStockUpdate && (
          <Text>
            Your stock value on MyDeal will be the same as your Magento store
            (unless global stock buffer added)
          </Text>
        )}
        {(AutoPushOrder ||
          (typeof AutoStockUpdate !== "undefined" && !AutoStockUpdate)) && (
          <Text>
            Your products will be imported to MyDeal without any stock and they
            will not go live until stock values are assigned manually via the
            MyDeal Seller Portal
          </Text>
        )}

        <Divider />

        <FormSwitch
          size="lg"
          readOnly={!onboarding}
          label="Automated Tracking"
          onLabel="Yes"
          offLabel="No"
          parentForm={form}
          fieldName="AutoFetchTracking"
        />
        {AutoFetchTracking && (
          <Text>
            Tracking details will be fetched automatically from your Magento
            store
          </Text>
        )}
        {typeof AutoFetchTracking !== "undefined" && !AutoFetchTracking && (
          <Text>
            You will manually add or upload tracking via the MyDeal Seller
            Portal
          </Text>
        )}

        <Divider />

        <FormSwitch
          size="lg"
          readOnly={!onboarding}
          label="Create an invoice for each order"
          onLabel="Yes"
          offLabel="No"
          parentForm={form}
          fieldName="CreateInvoiceForOrder"
        />

        <Divider />

        <Grid>
          <Grid.Col md={4} sm={6}>
            <Label>Magento Payment Method</Label>

            {channelType.id === SourceChannel.Magento && (
              <Text color="dimmed">
                <Anchor
                  href="--DELETED--"
                  target="_blank"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </Text>
            )}

            {channelType.id === SourceChannel.Magento2 && (
              <Text color="dimmed">
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10026761649295"
                  target="_blank"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </Text>
            )}
          </Grid.Col>
          <Grid.Col md={8} sm={6}>
            <FormTextField
              readOnly={!onboarding}
              parentForm={form}
              fieldName="MagentoPaymentMethod"
            />
          </Grid.Col>
        </Grid>

        {channelType.id === SourceChannel.Magento2 && (
          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label>Product Image Media Path</Label>
              <Text color="dimmed">
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10026800182671"
                  target="_blank"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </Text>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextField
                readOnly={!onboarding}
                parentForm={form}
                fieldName="ImageBaseMediaUrl"
              />
            </Grid.Col>
          </Grid>
        )}

        <Grid>
          <Grid.Col md={4} sm={6}>
            <Label>Product Description Media Path</Label>

            {channelType.id === SourceChannel.Magento && (
              <Text color="dimmed">
                <Anchor
                  href="--DELETED--"
                  target="_blank"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </Text>
            )}

            {channelType.id === SourceChannel.Magento2 && (
              <Text color="dimmed">
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10052663219087"
                  target="_blank"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </Text>
            )}
          </Grid.Col>
          <Grid.Col md={8} sm={6}>
            <FormTextField
              readOnly={!onboarding}
              parentForm={form}
              fieldName="DescriptionBaseMediaUrl"
            />
          </Grid.Col>
        </Grid>

        {channelType.id === SourceChannel.Magento2 && (
          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label>Brand Attribute Code</Label>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextField
                readOnly={!onboarding}
                parentForm={form}
                fieldName="MagentoBrandAttributeCode"
              />
            </Grid.Col>
          </Grid>
        )}
      </Stack>
    </Card>
  );
};
