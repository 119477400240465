import { IDataContextProvider } from "@mydeal/core";
import {
  IMfaEnrollmentModel,
  IOtpMfaEnrollmentResponse,
  ISmsMfaEnrollmentResponse,
} from "@types";

export const securityService = (dataProvider: IDataContextProvider) => ({
  getAuthenticationFactor: async (type: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/Security/AuthenticationFactor`,
        query: {
          type,
        },
      })
      .then((result) => {
        return result?.data as IMfaEnrollmentModel;
      });
  },

  enrollSmsMfa: async (phoneNumber: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Security/EnrollMfa/Sms`,
        payload: {},
        query: {
          phoneNumber,
        },
      })
      .then((result) => {
        return result?.data as ISmsMfaEnrollmentResponse;
      });
  },

  removeMfa: async (authenticatorId: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "delete",
        url: `${baseUrl}/Security/RemoveMFa`,
        payload: {},
        query: { authenticatorId },
      })
      .then((result) => {
        return result?.data;
      });
  },

  enrollOtpMfa: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Security/EnrollMfa/Otp`,
        payload: {},
      })
      .then((result) => {
        return result?.data as IOtpMfaEnrollmentResponse;
      });
  },

  confirmSmsMfa: async (oobCode: string, confirmationCode: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Security/ConfirmMfa/Sms`,
        payload: {
          oobCode,
          confirmationCode,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  confirmOtpMfa: async (otpCode: string) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Security/ConfirmMfa/Otp`,
        payload: {
          otpCode,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },
});
