import {
  Anchor,
  Card,
  Text,
  Stack,
  Group,
  Paper,
  TypographyStylesProvider,
} from "@mantine/core";
import { useRouterContext } from "@mydeal/core";
import { IconMapPin, IconPhone, IconUser } from "@tabler/icons-react";
import { formatAddress } from "../../utils/formatAddress";
import { DealType, IOrderViewItem, VoucherState } from "./info.types";
import { StaticDataTable } from "@mydeal/ui-mantine";
import { MarketplaceBadge } from "../../components/shared/MarketplaceBadge";

interface OrderViewItemProps {
  OrderViewItem: IOrderViewItem;
}

export const OrderViewItem = ({ OrderViewItem }: OrderViewItemProps) => {
  const { Link } = useRouterContext();

  return (
    <Paper>
      <Card>
        <StaticDataTable<any>
          data={[OrderViewItem]}
          hasPagination={false}
          mantineTableBodyRowProps={{}}
            columns={[
            {
              fieldName: "BidDealId",
              header: "Order No",
              size: 150,
              cellTemplate: (value: any, row: any) => (
                <Stack spacing={5}>
                    <MarketplaceBadge text={row.CustomerMarketplace}></MarketplaceBadge>
                    <Anchor component={Link} to={`/order/${value}`}>
                        {value}
                    </Anchor>
                </Stack>                
              ),
            },
            {
              fieldName: "CustomerName",
              header: "Recipient",
              size: 200,
              cellTemplate: (value: any) => (
                <Group>
                  <IconUser size={16} /> {value}
                </Group>
              ),
            },
            {
              fieldName: "ShippingAddress",
              header: "Address",
              size: 250,
              cellTemplate: (value: any, row: any) => (
                <>
                  {value && (
                    <Stack spacing="sm">
                      <Group>
                        <IconMapPin size={16} />
                        {formatAddress(value)}
                      </Group>
                      <Group>
                        <IconPhone size={16} /> {row.PhoneNo || "n/a"}
                      </Group>
                    </Stack>
                  )}
                </>
              ),
            },
            {
              fieldName: "Status",
              header: "Status",
              size: 120,
              cellTemplate: (value: any) => (
                <>
                  <Text>{VoucherState[value]}</Text>
                </>
              ),
            },
            {
              fieldName: "OrderValue",
              header: "Order Details",
              size: 200,
              cellTemplate: (value: any, row: any) => {
                const _row: IOrderViewItem = row;
                return (
                  <>
                    {_row.DealType === DealType.Normal && (
                      <Stack spacing={5} fz="xs">
                        <Group spacing={5}>
                          <Text fw="bold">Status: </Text>
                          {VoucherState[_row.Status]}
                        </Group>
                        <Group spacing={5}>
                          <Text fw="bold">Date: </Text>
                          {new Date(_row.AcceptedTime).toLocaleDateString(
                            "en-GB"
                          )}
                        </Group>
                        <Group spacing={5}>
                          <Text fw="bold">Order: </Text>
                          {_row.OrderQty}x (SKU: {_row.ProductSKU})
                        </Group>
                        <Group spacing={5}>
                          <Text fw="bold">Price(Per Unit): </Text>
                          {`$${_row.SellPricePerUnit.toFixed(2)}`}
                        </Group>
                        <Group spacing={5}>
                          <Text fw="bold">Shipping: </Text>
                          {`$${_row.FreightAmount.toFixed(2)}`}
                        </Group>
                        <Group spacing={5}>
                          <Text fw="bold">Total Sale Price: </Text>
                          {`$${_row.TotalSalePrice.toFixed(2)}`}
                        </Group>
                      </Stack>
                    )}
                  </>
                );
              },
            },

            {
              fieldName: "TrackingNo",
              header: "Sent",
              size: 200,
              cellTemplate: (value: any, row: any) => {
                const order = row as IOrderViewItem;
                return (
                  <>
                    <Stack spacing={5} fz="xs">
                      <Group spacing={5}>
                        <Text fw="bold">Tracking No: </Text>
                        {order.TrackingNo}
                      </Group>
                      <Group spacing={5}>
                        <Text fw="bold">Courier: </Text>
                        {order.Courier}
                      </Group>
                      <Group spacing={5}>
                        <Text fw="bold">Supplier Notes: </Text>
                        <TypographyStylesProvider>
                          <Text
                            size={"xs"}
                            dangerouslySetInnerHTML={{
                              __html: order.SupplierNotes,
                            }}
                          ></Text>
                        </TypographyStylesProvider>
                      </Group>
                    </Stack>
                  </>
                );
              },
            },
          ]}
        />
      </Card>
    </Paper>
  );
};
