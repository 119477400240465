import { useMemo, useCallback } from "react";
import * as yup from "yup";
import { useDataProvider } from "@mydeal/core";
import {
  CombinedFreightRateRule,
  ExclusionRuleType,
  ICombinedFreightRateModel,
} from "@types";
import { shippingService } from "@services/shipping.service";
import {
  useFocusableForm,
  FormNumberInput,
  FormMoneyInput,
  FormRadio,
  FormRadioGroup,
  AuthenticatedForm,
} from "@mydeal/ui-mantine";
import {
  Alert,
  Anchor,
  Box,
  Card,
  Divider,
  Grid,
  Group,
  List,
  Radio,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { openConfirmModal } from "@mantine/modals";
import { Label, PageTitle } from "@components/shared";

const CombinedShippingSchema = yup.object<{
  [Property in keyof ICombinedFreightRateModel]: any;
}>({
  FlatRateAmount: yup
    .number()
    .nullable()
    .when(["IsSupportCombinedFreightRate", "CombinedFreightRateRule"], {
      is: (isSupportCombinedFreightRate: any, combinedFreightRateRule: any) =>
        isSupportCombinedFreightRate === "true" &&
        combinedFreightRateRule ===
          CombinedFreightRateRule.FlatRateForAdditionalItem,
      then: (schema) =>
        schema
          .typeError("This field is required.")
          .required("This field is required.")
          .min(1, "The field Flat Rate Amount must be at least 1"),
    }),
  PercentageDiscount: yup
    .number()
    .nullable()
    .when(["IsSupportCombinedFreightRate", "CombinedFreightRateRule"], {
      is: (isSupportCombinedFreightRate: any, combinedFreightRateRule: any) =>
        isSupportCombinedFreightRate === "true" &&
        combinedFreightRateRule ===
          CombinedFreightRateRule.PercentageDiscountForAdditionalItem,
      then: (schema) =>
        schema
          .typeError("This field is required.")
          .required("This field is required.")
          .min(1, "The field Percentage Discount must be between 1.0 and 99.99")
          .max(
            99.99,
            "The field Percentage Discount must be between 1.0 and 99.99"
          ),
    }),
  ExclusionValue: yup
    .number()
    .nullable()
    .when(["IsSupportCombinedFreightRate", "ExclusionRuleType"], {
      is: (isSupportCombinedFreightRate: any, exclusionRuleType: any) =>
        isSupportCombinedFreightRate === "true" &&
        exclusionRuleType === ExclusionRuleType.WeightAbove,
      then: (schema) =>
        schema
          .typeError("This field is required.")
          .required("This field is required.")
          .min(1, "The field Weight must be must be greater than 0"),
    }),
});

export const CombinedShipping = () => {
  const form = useFocusableForm<ICombinedFreightRateModel>({
    schema: CombinedShippingSchema,
  });

  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _shippingService = useMemo(
    () => shippingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const fetchData = useCallback(async () => {
    const result = await _shippingService.getCombinedShipping();
    if (result) {
      result.IsSupportCombinedFreightRate =
        result.IsSupportCombinedFreightRate?.toString();
      result.CombinedFreightRateRule =
        result.CombinedFreightRateRule?.toString();
      result.ExclusionRuleType =
        result.ExclusionRuleType?.toString() ?? ExclusionRuleType.NoExclusions;
    }
    return result as ICombinedFreightRateModel;
  }, [_shippingService]);

  const onSubmit = useCallback(
    async (data: any) => {
      return await new Promise((resolve, reject) => {
        openConfirmModal({
          title: (
            <Text fw="bold" fz="sm">
              You are about to change the combined shipping settings for your
              store.
            </Text>
          ),
          children: (
            <Text size="sm">
              Changes will reflect immediately. Do you want to continue? Setting
              changes are recommended to be performed during off peak hours.
            </Text>
          ),
          labels: { confirm: "Proceed", cancel: "Cancel" },
          onConfirm: () => {
            _shippingService
              .updateCombinedShipping(data)
              .then(resolve)
              .catch(reject);
          },
          onCancel: () => reject({ ErrorMessage: "Cancelled save." }),
        });
      });
    },
    [_shippingService]
  );

  const formValues = form.getValues();

  return (
    <>
      <PageTitle
        title="Combined Shipping"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Shipping Settings", route: "/shipping" },
          { name: "Combined Shipping" },
        ]}
      />

      <Card withBorder mih={300} data-testid="shipping-combinedshipping">
        <Card.Section py="sm" px="xl">
          <Title order={5} mb="xs">
            Combined Shipping
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section py="sm" px="xl">
          <AuthenticatedForm
            dataProviderInstance={dataProviderInstance}
            form={form}
            fetchData={fetchData}
            submitLabel="Update"
            onSubmit={onSubmit}
          >
            <Alert color="blue">
              Opt in to provide combined shipping rates on products, specify
              pricing rules and exclusions.
              <Anchor
                href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10008574375823"
                target="_blank"
              >
                Read More
                <IconExternalLink size={14} />
              </Anchor>
            </Alert>

            <Grid>
              <Grid.Col md={2}>
                <Text fz="md" mt="xs">
                  Enable combined shipping
                </Text>
              </Grid.Col>
              <Grid.Col md={10}>
                <FormRadioGroup
                  fieldName="IsSupportCombinedFreightRate"
                  parentForm={form}
                >
                  <Group mt="sm" align="left">
                    <Radio label="Yes" value="true" />
                    <Radio label="No" value="false" />
                  </Group>
                </FormRadioGroup>
              </Grid.Col>
            </Grid>

            {formValues.IsSupportCombinedFreightRate === "true" && (
              <Stack spacing="sm" mt="md">
                <Text fz="md" mt="xs" fw="bold">
                  Select your combined shipping rule:
                </Text>

                <Text fz="md" my="sm">
                  From the list of items ordered:
                </Text>

                <Stack spacing="xs">
                  {formValues.CombinedFreightRateRule ===
                    CombinedFreightRateRule.NoShippingDiscount && (
                    <>
                      <FormRadio
                        label="No shipping discount"
                        defaultChecked={
                          formValues.CombinedFreightRateRule ===
                          CombinedFreightRateRule.NoShippingDiscount
                        }
                        name="CombinedFreightRateRule"
                        fieldName="CombinedFreightRateRule"
                        parentForm={form}
                        value={CombinedFreightRateRule.NoShippingDiscount}
                      />
                    </>
                  )}
                  <>
                    <FormRadio
                      label="Charge the highest shipping cost from the list of items ordered and offer free shipping for additional items in the order"
                      defaultChecked={
                        formValues.CombinedFreightRateRule ===
                        CombinedFreightRateRule.FreeForAdditionalItem
                      }
                      name="CombinedFreightRateRule"
                      fieldName="CombinedFreightRateRule"
                      parentForm={form}
                      value={CombinedFreightRateRule.FreeForAdditionalItem}
                    />
                  </>
                  <>
                    <FormRadio
                      label="Charge the highest shipping cost from the list of items ordered and add a flat rate for additional items"
                      defaultChecked={
                        formValues.CombinedFreightRateRule ===
                        CombinedFreightRateRule.FlatRateForAdditionalItem
                      }
                      name="CombinedFreightRateRule"
                      fieldName="CombinedFreightRateRule"
                      parentForm={form}
                      value={CombinedFreightRateRule.FlatRateForAdditionalItem}
                    />
                  </>

                  {formValues.CombinedFreightRateRule ===
                    CombinedFreightRateRule.FlatRateForAdditionalItem && (
                    <Box ml="xl">
                      <Label required mb="xs">
                        Flat rate per additional item:
                      </Label>
                      <FormMoneyInput
                        name="FlatRateAmount"
                        w={200}
                        fieldName="FlatRateAmount"
                        parentForm={form}
                        min={0}
                        max={1000}
                      />
                    </Box>
                  )}

                  <>
                    <FormRadio
                      label="Charge the highest shipping cost from the list of items ordered and offer a discount on the shipping rate of additional items"
                      defaultChecked={
                        formValues.CombinedFreightRateRule ===
                        CombinedFreightRateRule.PercentageDiscountForAdditionalItem
                      }
                      name="CombinedFreightRateRule"
                      fieldName="CombinedFreightRateRule"
                      parentForm={form}
                      value={Number(
                        CombinedFreightRateRule.PercentageDiscountForAdditionalItem
                      )}
                    />
                  </>
                  {formValues.CombinedFreightRateRule ===
                    CombinedFreightRateRule.PercentageDiscountForAdditionalItem && (
                    <Box ml="xl">
                      <Label required mb="xs">
                        Percentage discount off shipping per additional item:
                      </Label>
                      <FormNumberInput
                        rightSection={<Text>% off</Text>}
                        rightSectionWidth={80}
                        step={0.01}
                        precision={2}
                        w={250}
                        fieldName="PercentageDiscount"
                        parentForm={form}
                        min={1}
                        max={99.99}
                      />
                    </Box>
                  )}
                </Stack>

                <Divider />

                <Stack>
                  <Text fz="md" mt="xs" fw="bold">
                    (Optional) Define combined shipping exclusions:
                  </Text>

                  <Text fz="sm">
                    Exclude products from combined shipping based on:{" "}
                  </Text>

                  <FormRadioGroup
                    parentForm={form}
                    fieldName="ExclusionRuleType"
                  >
                    <Group>
                      <Radio
                        label="No exclusions"
                        value={ExclusionRuleType.NoExclusions}
                      />
                      <Radio
                        label="Weight"
                        value={ExclusionRuleType.WeightAbove}
                      />
                    </Group>
                  </FormRadioGroup>

                  {formValues.ExclusionRuleType ===
                    ExclusionRuleType.WeightAbove && (
                    <>
                      <FormNumberInput
                        label="Exclude products with weight greater than:"
                        step={0.01}
                        precision={2}
                        fieldName="ExclusionValue"
                        parentForm={form}
                        w={300}
                        min={1}
                        max={999999999}
                      />
                      <Alert>
                        <List fz="sm">
                          <List.Item>
                            The value entered must match your product data
                            weight unit measurement
                          </List.Item>
                          <List.Item>
                            When a product has no weight specified then it will
                            be excluded from combined shipping
                          </List.Item>
                          <List.Item>
                            When a variant product has no weight specified the
                            system will use the weight specified at the product
                            level
                          </List.Item>
                        </List>
                      </Alert>
                    </>
                  )}
                </Stack>
              </Stack>
            )}
          </AuthenticatedForm>
        </Card.Section>
      </Card>
    </>
  );
};
