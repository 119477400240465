import { Stack, Title } from "@mantine/core";
import { IntegrationSettingsEditor } from "@pages/myaccount/IntegrationSettingsEditor";

export const OnboardingIntegrationConfiguration = () => {
  return (
    <Stack spacing="lg">
      <Title order={2}>Integration Configuration</Title>
      <IntegrationSettingsEditor onboarding={true} />
    </Stack>
  );
};
