import { BoxArrowUpRight } from "@mydeal/icons";
import * as yup from "yup";
import { PropsWithChildren, useState, useMemo, useCallback } from "react";
import { useDataProvider, useNavigation, useNotification } from "@mydeal/core";
import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Group,
  LoadingOverlay,
  Modal,
  Box,
  SimpleGrid,
  Stack,
  Title,
} from "@mantine/core";
import { dealsService } from "@services/deals.service";
import { IconUpload } from "@tabler/icons-react";
import {
  useFocusableForm,
  FormFileInput,
  useApiErrorHandler,
} from "@mydeal/ui-mantine";
import { useDisclosure } from "@mantine/hooks";
import { ImportResult } from "./ImportResult";

export interface IExportImportCardProps {
  exportTitle: string;
  importTitle: string;
  instructionUrl: string;
  templateUrl?: string;
  exportUrl?: string;
  importUrl: string;
  enabled?: boolean;
  isProduct?: boolean;
  isUncategorised?: boolean;
  showResult?: boolean;
  redirectUrl?: string;
}
interface IImportModel {
  importFile?: File;
}

interface IExportModel {
  includeDiscountinuedProducts: boolean;
}

export const ExportImportCard: React.FC<
  PropsWithChildren<IExportImportCardProps>
> = ({
  exportTitle,
  importTitle,
  instructionUrl,
  templateUrl,
  exportUrl,
  importUrl,
  enabled = true,
  isProduct = false,
  isUncategorised = false,
  showResult = true,
  redirectUrl,
  children,
}) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _dealsService = useMemo(
    () => dealsService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const { push } = useNavigation();

  const formExport = useFocusableForm<IExportModel>({
    schema: yup.object(),
    initialValues: { includeDiscountinuedProducts: true },
  });
  const form = useFocusableForm<IImportModel>({
    schema: yup.object<{
      [Property in keyof IImportModel]: any;
    }>({
      importFile: yup.mixed().required("Select a file to import"),
    }),
  });
  const apiErrorHandler = useApiErrorHandler(notify);
  const [uploading, setUploading] = useState<boolean>(false);
  const [downloading, setDownloading] = useState<boolean>(false);
  const [
    importResultOpened,
    { open: openImportResult, close: closeImportResult },
  ] = useDisclosure(false);
  const [importResult, setImportResult] = useState<any>();
  const onImport = useCallback(
    (data: any) => {
      setUploading(true);

      _dealsService
        .importStuff(importUrl, data)
        .then((result: any) => {
            if (result.WarningMessage) {
                notify?.({
                    type: "error",
                    message: result.WarningMessage,
                    description: "",
                });
            }
          if (showResult) {
            setImportResult(result);
            openImportResult();
          } else {
            if (redirectUrl) {
              push(redirectUrl);
            }
            notify?.({
              type: "success",
              message:
                result.SuccessMessage || result.Message || "Import successful",
              description: "",
            });
          }
        })
        .catch(apiErrorHandler)
        .finally(() => {
          setUploading(false);
        });
    },
    [_dealsService, apiErrorHandler, importUrl, notify]
  );

  const onExport = useCallback(
    (data: Partial<IExportModel>) => {
      if (exportUrl) {
        setDownloading(true);

        if (isProduct) {
          if (isUncategorised) {
            _dealsService
              .exportUncategorisedProducts()
              .then((result: any) => {
                notify?.({
                  type: "success",
                  message:
                    result.SuccessMessage ||
                    result.Message ||
                    "Export successful",
                  description: "",
                });
              })
              .catch(apiErrorHandler)
              .finally(() => {
                setDownloading(false);
              });
          } else {
            _dealsService
              .exportProducts(data.includeDiscountinuedProducts ?? false)
              .then((result: any) => {
                if (redirectUrl) {
                  push(redirectUrl);
                }
                notify?.({
                  type: "success",
                  message:
                    result.SuccessMessage ||
                    result.Message ||
                    "Export successful",
                  description: "",
                });
              })
              .catch(apiErrorHandler)
              .finally(() => {
                setDownloading(false);
              });
          }
        } else {
          _dealsService
            .exportStuff(exportUrl, data.includeDiscountinuedProducts ?? false)
            .then((result: any) => {
              notify?.({
                type: "success",
                message:
                  result.SuccessMessage ||
                  result.Message ||
                  "Export successful",
                description: "",
              });
            })
            .catch(apiErrorHandler)
            .finally(() => {
              setDownloading(false);
            });
        }
      }
    },
    [_dealsService, apiErrorHandler, exportUrl, isProduct, notify]
  );

  return (
    <>
      {children}
      {enabled && (
        <Box mt="md">
          <SimpleGrid cols={2}>
            <Card withBorder>
              <Card.Section
                withBorder
                p="sm"
                sx={(theme) => ({
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[7]
                      : theme.colors.gray[1],
                })}
              >
                <Title order={5}>{exportTitle}</Title>
              </Card.Section>
              <Card.Section p="sm">
                <form onSubmit={formExport.onSubmit(onExport)} noValidate>
                  <LoadingOverlay visible={downloading} />
                  <Stack align="center">
                    {!isUncategorised && (
                      <Checkbox
                        label="Include Discontinued Products"
                        {...formExport.getInputProps(
                          "includeDiscountinuedProducts"
                        )}
                        defaultChecked={true}
                        disabled={!exportUrl}
                      />
                    )}
                    <Button
                      type="submit"
                      loading={downloading}
                      disabled={!exportUrl}
                    >
                      Export
                    </Button>
                  </Stack>
                </form>
              </Card.Section>
            </Card>

            <Card withBorder>
              <Card.Section
                withBorder
                p="sm"
                sx={(theme) => ({
                  backgroundColor:
                    theme.colorScheme === "dark"
                      ? theme.colors.dark[7]
                      : theme.colors.gray[1],
                })}
              >
                <Group position="apart">
                  <Title order={6}>{importTitle}</Title>
                  <div>
                    <Anchor href={instructionUrl} target="_blank" fw={400}>
                      (Instructions <BoxArrowUpRight size={12} /> )
                    </Anchor>
                    {templateUrl && (
                      <Anchor href={templateUrl} target="_blank" fw={400}>
                        (Download Template <BoxArrowUpRight size={12} /> )
                      </Anchor>
                    )}
                  </div>
                </Group>
              </Card.Section>
              <Card.Section p="sm">
                <LoadingOverlay visible={uploading} />
                <form onSubmit={form.onSubmit(onImport)} noValidate>
                  <Stack>
                    <FormFileInput
                      icon={<IconUpload size={14} />}
                      placeholder="Choose file..."
                      clearable
                      // clearButtonProps={{
                      //   dangerouslySetInnerHTML: { __html: "x" },
                      // }}
                      fieldName="importFile"
                      parentForm={form}
                    />
                    <Group position="center">
                      <Button type="submit" loading={uploading}>
                        Import
                      </Button>
                    </Group>
                  </Stack>
                </form>
              </Card.Section>
            </Card>
          </SimpleGrid>
        </Box>
      )}

      <Modal
        opened={importResultOpened}
        onClose={closeImportResult}
        title="Import Results"
        size="60%"
        closeOnClickOutside={false}
      >
        <ImportResult
          result={importResult}
          title="PO BOX Delivery Import Results"
          close={closeImportResult}
        />
      </Modal>
    </>
  );
};
