import { PageTitle } from "@components/shared";
import { ProductSettingsEditor } from "./ProductSettingsEditor";

export const ProductSettings = () => {
  return (
    <>
      <PageTitle
        title="Product Settings"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Company" },
          { name: "Product Settings" },
        ]}
      />

      <ProductSettingsEditor onboarding={false} />
    </>
  );
};
