import * as yup from "yup";
import { useDataProvider, useNotification } from "@mydeal/core";
import { IOrderTrackingModel } from "./types";
import { useMemo, useState } from "react";
import { Button, Group, LoadingOverlay, Select, Stack } from "@mantine/core";
import { orderService } from "@services/order.service";
import {
  useFocusableForm,
  FormSelect,
  FormTextField,
} from "@mydeal/ui-mantine";

const DispatchInfoSchema = yup.object({
  BidDealGroupId: yup.number(),
  TrackingNumber: yup.string().nullable().required("Required"),
  //CourierSelect: yup.string().required("Required"),
  Courier: yup.string().required("Required"),
});

interface ITrackingModalProps {
  trackingModel: IOrderTrackingModel;
  onClose: (reload?: boolean) => void;
}

const courierOptions = [
  { value: "Australia Post", label: "Australia Post" },
  { value: "Allied Express", label: "Allied Express" },
  { value: "Aramex", label: "Aramex" },
  { value: "Blue Star", label: "Blue Star" },
  { value: "Border Express", label: "Border Express" },
  { value: "Capital Transport", label: "Capital Transport" },
  { value: "China EMS", label: "China EMS" },
  { value: "China Post", label: "China Post" },
  { value: "Cope Sensitive Freight", label: "Cope Sensitive Freight" },
  { value: "Couriers Please", label: "Couriers Please" },
  { value: "DHL Express", label: "DHL Express" },
  { value: "Direct Freight Express", label: "Direct Freight Express" },
  { value: "E-go", label: "E-go" },
  { value: "Fastway", label: "Fastway" },
  { value: "FedEx", label: "FedEx" },
  { value: "Hunter Express", label: "Hunter Express" },
  { value: "La Poste", label: "La Poste" },
  { value: "Landmark Global", label: "Landmark Global" },
  { value: "New Zealand Post", label: "New Zealand Post" },
  { value: "Neway Transport", label: "Neway Transport" },
  { value: "PostNL", label: "PostNL" },
  { value: "Sendle", label: "Sendle" },
  { value: "Singapore Post", label: "Singapore Post" },
  { value: "Startrack", label: "Startrack" },
  { value: "TCS", label: "TCS" },
  { value: "TFM Xpress", label: "TFM Xpress" },
  { value: "TNT", label: "TNT" },
  { value: "Toll IPEC", label: "Toll IPEC" },
  { value: "UBI Smart Parcel", label: "UBI Smart Parcel" },
  { value: "99", label: "Other" },
];
export const TrackingModal = ({
  trackingModel,
  onClose,
}: ITrackingModalProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );

  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [courier, setCourier] = useState<string | null>(
    trackingModel.DispatchCarrier
  );

  const form = useFocusableForm<any>({
    initialValues: {
      BidDealGroupId: trackingModel.BidDealGroupID,
      BidDealId: trackingModel.BidDealID,
      TrackingNumber: trackingModel.TrackingCode,
      Courier: trackingModel.DispatchCarrier,
    },
    schema: DispatchInfoSchema,
  });

  const onSubmit = (data: any) => {
    setLoading(true);

    _orderService
      .updateDispatchInfo(data)
      .then((result) => {
        if (result?.Success) {
          notify?.({
            type: "success",
            message: "Dispatch Details Updated!",
            description: "",
          });
          onClose?.(true);
        } else {
          notify?.({
            type: "error",
            message: "Something went wrong. Please try again.",
            description: "",
          });
        }
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <LoadingOverlay visible={loading} />
        <Stack spacing={"sm"}>
          <FormTextField
            withAsterisk
            label="Tracking No:"
            fieldName="TrackingNumber"
            parentForm={form}
          />

          {/* <FormSelect
            data={courierOptions}
            label="Courier:"
            placeholder="-- Select --"
            fieldName="Courier"
            parentForm={form}
            onChange={(value) => {
              setCourier(value);
              // if (value !== "99") form.setFieldValue("Courier", value!);
              // else form.setFieldValue("Courier", "");
            }}
            withinPortal
          /> */}

          <Select
            data={courierOptions}
            label="Courier:"
            placeholder="-- Select --"
            withinPortal
            onChange={(value) => {
              setCourier(value);
              if (value !== "99") form.setFieldValue("Courier", value!);
              else form.setFieldValue("Courier", "");
            }}
            error={courier !== "99" && (form as any).getErrors().Courier}
          />

          {courier === "99" && (
            <FormTextField
              placeholder="Courier name"
              fieldName="Courier"
              parentForm={form}
            />
          )}
          {/* <FormGroup row className="mb-3">
              <Col md={12}>
                <FormSelect
                  label="Courier:"
                  placeholder="-- Select --"
                  required
                  control={control as any}
                  name="CourierSelect"
                  zIndex={9999}
                  styles={{}}
                  options={courierOptions}
                  inline
                  labelSize={4}
                  controlSize={8}
                  onChange={item => {
                    if (item?.key != '99') {
                      setValue('Courier', item?.key ?? '');
                    } else {
                      setValue('Courier', '');
                    }
                  }}
                />
              </Col>
            </FormGroup> */}
          {/* {courier === '99' && (
              <FormGroup row className="mb-3">
                <Col md={12}>
                  <FormTextField
                  label={` `}
                    placeholder="Courier name"
                    control={control as any}
                    name="Courier"
                    inline
                    labelSize={4}
                    controlSize={8}
                  />
                </Col>
              </FormGroup>
            )} */}
          <Group>
            <Button
              variant="light"
              onClick={() => {
                onClose?.();
              }}
            >
              Cancel
            </Button>
            <Button type="submit">Save</Button>
          </Group>
        </Stack>
      </form>
    </>
  );
};
