import {
  Anchor,
  CopyButton,
  Group,
  Modal,
  Stack,
  Table,
  Text,
  UnstyledButton,
  createStyles,
} from "@mantine/core";
import { IAddonOrderViewModel, IOrderViewModel, RefundType, VoucherState } from "./types";
import {
  IconCheck,
  IconCircleX,
  IconCopy,
  IconReceiptRefund,
} from "@tabler/icons-react";
import { openConfirmModal } from "@mantine/modals";
import { useMemo, useState } from "react";
import {
  useDataProvider,
  useNotification,
  useRouterContext,
} from "@mydeal/core";
import { orderService } from "@services/order.service";
import moment from "moment";
import { AddonRefundModal } from "./AddonRefundModal";

const useStyles = createStyles((theme) => ({}));

interface TabAdditionalPaymentsProps {
  order: IOrderViewModel;
  reloadOrderDetails: any;
  paymentLink: any;
}

export const TabAdditionalPayments = ({
  order,
  reloadOrderDetails,
  paymentLink,
}: TabAdditionalPaymentsProps) => {
  const { open: notify } = useNotification();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { Link } = useRouterContext();
  const [openAddonRefundModal, setOpenAddonRefundModal] =
    useState<boolean>(false);
    const [currentAddonOrder, setCurrentAddonOrder] = useState<IAddonOrderViewModel>();

  const toggleAddonRefundModal = (reload?: boolean) => {
    setOpenAddonRefundModal(!openAddonRefundModal);    
  };

  const handleCancelAddonClick = (jobId: string) => {
    openConfirmModal({
      title: (
        <Text fw="bold" fz="sm">
          Please Confirm
        </Text>
      ),
      children: (
        <Text size="sm">
          Are you sure you want to cancel this additional payment request?
        </Text>
      ),
      labels: { confirm: "Yes", cancel: "No" },
      onConfirm: () => {
        _orderService
          .cancelAddonPayment(jobId)
          .then((result: any) => {
            notify?.({
              type: "success",
              message: "AddOn Removed !",
              description: "",
            });
          })
          .catch((err) => {
            const {
              response: {
                data: { ErrorMessage, title },
              },
            } = err;
            notify?.({
              type: "error",
              message:
                ErrorMessage ??
                "Unable to process the request. Please try again later.",
              description: ErrorMessage ?? title,
            });
          })
          .finally(() => {
            reloadOrderDetails(true);
          });
      },
    });
  };

  return (
    <>
      {order.AddonOrders.length > 0 && (
        <>
          <Table withColumnBorders withBorder striped>
            <thead>
              <tr>
                <th>AddOn Order No.</th>
                <th>Status</th>
                <th>Customer Paid Date</th>
                <th>Amount</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {order.AddonOrders.map((addOnOrder, index) => (
                <>
                  <tr key={index}>
                    <td>
                      {addOnOrder.Status === VoucherState.Purchased ||
                      addOnOrder.Status === VoucherState.Refunded ? (
                        <span>{addOnOrder.BidDealID}</span>
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>
                      {addOnOrder.Status === VoucherState.Purchased ? (
                        <span
                          style={{
                            color: "#12b135",
                          }}
                        >
                          Paid
                        </span>
                      ) : addOnOrder.Status === VoucherState.Refunded ? (
                        <span>
                          Refunded (
                          <Anchor
                            id="viewAddOnRefunds"
                            data-refundorderid="@addOnOrder.BidDealID"
                             onClick={() => {
                                        setCurrentAddonOrder(addOnOrder);
                                        setOpenAddonRefundModal(true);
                                      }} 
                          >
                            View
                          </Anchor>
                         
                          )
                        </span>
                      ) : (
                        <span
                          style={{
                            color: "#faa21c",
                          }}
                        >
                          Requested
                        </span>
                      )}
                    </td>
                    <td>
                      {addOnOrder.Status === VoucherState.Purchased ||
                      addOnOrder.Status === VoucherState.Refunded ? (
                        moment(addOnOrder.AcceptedTime).format(
                          "DD/MM/YYYY hh:mm:ss A"
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                    <td>${addOnOrder.TotalAmount.toFixed(2)}</td>
                    <td>
                      {addOnOrder.RefundType !== RefundType.FullRefund ? (
                        addOnOrder?.AddOnPaymentReciepts?.[0] && (
                          <div className="addon-actions">
                            <Stack spacing={5}>
                              {addOnOrder.Status === VoucherState.Purchased ? (
                                <>
                                  <UnstyledButton
                                    color="red"
                                    size="xs"
                                    leftIcon={<IconReceiptRefund size={14} />}
                                    component={Link}
                                    to={`/order/${addOnOrder?.BidDealID}/refund`}
                                  >
                                    <Group spacing="xs">
                                      <IconReceiptRefund
                                        size={14}
                                        color="#F04A1C"
                                      />
                                      <Text size="sm" color="#F04A1C">
                                        Issue Refund
                                      </Text>
                                    </Group>
                                  </UnstyledButton>
                                </>
                              ) : (
                                <>
                                  <Group spacing="sm">
                                    <CopyButton
                                      value={`${paymentLink}/${addOnOrder?.AddOnPaymentReciepts?.[0].MappedPaymentID}`}
                                      timeout={5000}
                                    >
                                      {({ copied, copy }) => (
                                        <UnstyledButton onClick={copy}>
                                          <Group spacing="xs">
                                            {copied ? (
                                              <IconCheck
                                                size="1rem"
                                                color="green"
                                              />
                                            ) : (
                                              <IconCopy
                                                size="1rem"
                                                color="#F04A1C"
                                              />
                                            )}
                                            <Text
                                              size="sm"
                                              color={
                                                copied ? "green" : "#F04A1C"
                                              }
                                            >
                                              {copied
                                                ? "Copied"
                                                : "Copy Payment Link"}
                                            </Text>
                                          </Group>
                                        </UnstyledButton>
                                      )}
                                    </CopyButton>
                                  </Group>
                                  <Group spacing="sm">
                                    <IconCircleX color="#F04A1C" size={14} />
                                    <Anchor
                                      component="button"
                                      id="cancelAddon"
                                      onClick={() =>
                                        handleCancelAddonClick(
                                          addOnOrder?.AddOnPaymentReciepts?.[0]
                                            .PaymentRecieptID
                                        )
                                      }
                                    >
                                      Cancel Addon
                                    </Anchor>
                                  </Group>
                                </>
                              )}
                            </Stack>
                          </div>
                        )
                      ) : (
                        <span>-</span>
                      )}
                    </td>
                  </tr>
                  <tr
                    id="addonRefund_@addOnOrder.BidDealID"
                    className="addon-refund-row"
                  >
                    <td colSpan={6}>
                      <>
                        {() => {
                          var addOnRefundOrders =
                            addOnOrder.AddonRefunds?.RefundHistoryModels;

                          if (
                            addOnRefundOrders &&
                            addOnRefundOrders.length > 0
                          ) {
                            return (
                              <table className="table table-bordered table-hover">
                                <thead>
                                  <tr className="table-info">
                                    <th>Date</th>
                                    <th>Order No.</th>
                                    <th>Refund Amount</th>
                                    <th>Payment Method</th>
                                    <th>Summary</th>
                                    <th>Status</th>
                                  </tr>
                                </thead>
                                <tbody>
                                  {addOnRefundOrders.map(
                                    (addOnRefundOrder: any, index: number) => (
                                      <tr key={index}>
                                        <td>
                                          {addOnRefundOrder.TransactionDate}
                                        </td>
                                        <td>
                                          {addOnRefundOrder.RefundHistoryOrderItemModels.map(
                                            (bidDeal: any, b_index: number) => (
                                              <span key={b_index}>
                                                {bidDeal.BidDealId}

                                                <br />
                                              </span>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {addOnRefundOrder.RefundHistoryOrderItemModels.map(
                                            (bidDeal: any, b_index: number) => (
                                              <span key={b_index}>
                                                $
                                                {bidDeal.CustomerPrice.toFixed(
                                                  2
                                                )}
                                                <br />
                                              </span>
                                            )
                                          )}
                                        </td>
                                        <td>
                                          {addOnRefundOrder.PaymentMethod}
                                        </td>
                                        <td>
                                          <strong>
                                            {addOnRefundOrder.RefundReason}
                                          </strong>
                                          <div
                                            style={{
                                              wordBreak: "break-word",
                                            }}
                                          >
                                            {addOnRefundOrder.RefundNoteContent}
                                          </div>
                                        </td>
                                        <td>
                                          {addOnRefundOrder.Approved
                                            ? "Approved"
                                            : "Processing"}
                                        </td>
                                      </tr>
                                    )
                                  )}
                                </tbody>
                              </table>
                            );
                          }
                          return null;
                        }}
                      </>
                    </td>
                  </tr>
                </>
              ))}
            </tbody>
          </Table>

          <Modal
            opened={openAddonRefundModal}
            size="xl"
            onClose={() => setOpenAddonRefundModal(false)}
            closeOnClickOutside={false}
            title={
              <Text fw={700}>
                Refund details for {currentAddonOrder?.BidDealID}
              </Text>
            }
          >
            {currentAddonOrder && (
              <AddonRefundModal
                addonOrder={currentAddonOrder}             
                toggle={(reload) => {
                  toggleAddonRefundModal(reload);
                }}
              />
            )}
          </Modal>
        </>
      )}
    </>
  );
};
