import {
  Anchor,
  Burger,
  Container,
  createStyles,
  Group,
  Title,
  Header,
  MediaQuery,
  useMantineTheme,
  Text
} from "@mantine/core";
import { useRouterContext } from "@mydeal/core";
import logo from "../../assets/images/mydeal-mp-logo.png";
import { useSearchParams } from "@mydeal/react-router";
import { useLocalStorage } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  header: {
    paddingLeft: theme.spacing.md,
    paddingRight: theme.spacing.md,
  },

  inner: {
    height: 56,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  },

  links: {
    [theme.fn.smallerThan("sm")]: {
      display: "none",
    },
  },

  link: {
    display: "block",
    lineHeight: 1,
    padding: "8px 12px",
    borderRadius: theme.radius.sm,
    textDecoration: "none",
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    fontSize: theme.fontSizes.sm,
    fontWeight: 500,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[6]
          : theme.colors.gray[0],
    },
  },
}));

interface HeaderProps {
  toggleOpen: () => void;
  opened: boolean;
}

export const PublicHeader = ({ toggleOpen, opened }: HeaderProps) => {
  const theme = useMantineTheme();
  const { Link } = useRouterContext();
  const { classes } = useStyles();

  return (
    <Header
      height={{ base: 60, md: 100 }}
      p="md"
      data-testid="landingpage-header"
    >
      <Container size="xl" sx={{ height: "100%" }} px={0}>
        <Group position="apart" sx={{ alignItems: "center", height: "100%" }}>
          <Group>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
              <Burger
                opened={opened}
                onClick={toggleOpen}
                size="sm"
                color={theme.colors.gray[6]}
                mr="xl"
              />
            </MediaQuery>
            <Anchor href="/">
              <img src={logo} alt="MyDeal" width={130} />
            </Anchor>
          </Group>
          <Group ml={50} spacing={5} className={classes.links}>
            <Link to={`/login`} className={classes.link}>
                  <span>Log In</span>
            </Link>
            <Anchor
              href={`https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb`}
              className={classes.link}
              target="_blank"
            >
              Help
            </Anchor>
            <Link to={`/seller-application`} className={classes.link}>
              Contact
            </Link>
          </Group>
        </Group>
      </Container>
    </Header>
  );
};
