import { PageTitle } from "@components/shared";
import { InvitationsEditor } from "./InvitationsEditor";
import { Card } from "@mantine/core";

export const Invitations = () => {
  return (
    <>
      <PageTitle
        title="Invitations"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Administration" },
          { name: "Invitations" },
        ]}
      />

      <Card withBorder mih={300} p="xl">
        <InvitationsEditor />
      </Card>
    </>
  );
};
