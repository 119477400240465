import {
  RingProgress,
  Text,
  SimpleGrid,
  Paper,
  Center,
  Title,
  Stack,
} from "@mantine/core";

interface ImportShipmentSummaryProps {
  data: {
    label: string;
    stats: string;
    progress: number;
    color: string;
  }[];
}

export function ImportShipmentSummary({ data }: ImportShipmentSummaryProps) {
  const stats = data.map((stat) => {
    return (
      <Paper withBorder radius="md" p="xs" key={stat.label}>
        <Stack align="center">
          <RingProgress
            size={100}
            roundCaps
            thickness={8}
            sections={[{ value: stat.progress, color: stat.color }]}
          />
          <Text color="dimmed" size="xs" transform="uppercase" weight={700}>
            {stat.label}
          </Text>
          <Text weight={700} size="xl">
            {stat.stats}
          </Text>
        </Stack>
      </Paper>
    );
  });

  return (
    <>
      <Stack>
        <Title order={4} mt="sm">
          Order summary
        </Title>
        <SimpleGrid cols={3} breakpoints={[{ maxWidth: "sm", cols: 1 }]}>
          {stats}
        </SimpleGrid>
      </Stack>
    </>
  );
}
