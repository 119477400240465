import { useEffect, useState } from "react";
import { PageTitle } from "@components";
import { Paper, Divider, Text, Card } from "@mantine/core";
import { TicketStatus } from "./enquiryInbox.types";
import { TicketsDataTable } from "./ticketsDataTable";
import { SearchTicketsCard } from "./searchTicketsCard";

export const Escalations = () => {
  const [ticketsCount, setTicketsCount] = useState<number>(0);
  return (
    <>
      <PageTitle
        title="Escalation from MyDeal"
        description={<Text fz="lg">{`(${ticketsCount} tickets)`}</Text>}
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Escalation from MyDeal" },
        ]}
      />
      <SearchTicketsCard />
      <Card withBorder mih={300} p="xl">        
        <Paper
          sx={{ position: "relative" }}
          data-testid="enquiry-openescalation-table"
        >
          <TicketsDataTable
            ticketStatus={TicketStatus.Escalation}
            refreshTicketsCount={(i: number) => setTicketsCount(i)}
          />
        </Paper>
      </Card>
    </>
  );
};
