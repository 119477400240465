import { useMemo, useCallback } from "react";
import * as yup from "yup";
import { useDataProvider, useNotification } from "@mydeal/core";
import {
  ActionIcon,
  Anchor,
  Card,
  Checkbox,
  Divider,
  Grid,
  Group,
  SimpleGrid,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { accountService } from "@services";
import {
  IAccountingNotificationEmailModel,
  IContactDetailModel,
  IPurchaseNotificationEmailModel,
} from "@types";
import { Label } from "@components";
import { IconExternalLink, IconPlus, IconTrash } from "@tabler/icons-react";
import {
  AuthenticatedForm,
  FormSelect,
  FormTextField,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";

interface IContactDetailsEditorProps {
  onboarding: boolean;
}

const ContactDetailsSchema = yup.object<{
  [Property in keyof IContactDetailModel]: any;
}>({
  BillingAddressLine1: yup.string().nullable().required("Required"),
  BillingSuburb: yup.string().nullable().required("Required"),
  BillingState: yup.string().nullable().required("Required"),
  BillingPostcode: yup.string().nullable().required("Required"),
  BillingCountry: yup.string().nullable().required("Required"),

  ReturnAddressLine1: yup
    .string()
    .nullable()
    .when("IsReturnAddressSameBillingAddress", {
      is: false,
      then: (schema) => schema.required("Required"),
    }),
  ReturnSuburb: yup
    .string()
    .nullable()
    .when("IsReturnAddressSameBillingAddress", {
      is: false,
      then: (schema) => schema.required("Required"),
    }),
  ReturnState: yup
    .string()
    .nullable()
    .when("IsReturnAddressSameBillingAddress", {
      is: false,
      then: (schema) => schema.required("Required"),
    }),
  ReturnPostcode: yup.string().when("IsReturnAddressSameBillingAddress", {
    is: false,
    then: (schema) => schema.required("Required"),
  }),
  ReturnCountry: yup.string().when("IsReturnAddressSameBillingAddress", {
    is: false,
    then: (schema) => schema.required("Required"),
  }),

  FirstName: yup.string().nullable().required("Required"),
  LastName: yup.string().nullable().required("Required"),
  PhoneNumber: yup
    .string()
    .nullable()
    .required("Required")
    .min(
      8,
      "The Phone Number field must be a string with a minimum length of '8'"
    )
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      "The Phone Number field is not valid"
    ),
  MobileNumber: yup
    .string()
    .nullable()
    .required("Required")
    .min(
      8,
      "The Mobile Number field must be a string with a minimum length of '8'"
    )
    .matches(
      /^[+]*[(]{0,1}[0-9]{1,4}[)]{0,1}[-\s\./0-9]*$/,
      "The Mobile Number field is not valid"
    ),
  PrimaryContactEmail: yup
    .string()
    .nullable()
    .required("Required")
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      "Enter a valid e-mail address."
    ),
  SecondaryContactEmail: yup
    .string()
    .nullable()
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      "Enter a valid e-mail address."
    ).transform((_, value) => {
      return value === "" ? null :value;
    }),
  // CustomerServiceEmail: yup
  //   .string()
  //   .nullable()
  //   .required("Required")
  //   .email("Enter a valid e-mail address."),
  MarketingContactEmail: yup
    .string()
    .nullable()
    .required("Required")
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      "Enter a valid e-mail address."
    ),
  TechnicalContactEmail: yup
    .string()
    .nullable()
    .required("Required")
    .matches(
      /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
      "Enter a valid e-mail address."
    ),

  PurchaseNotificationEmails: yup.array(
    yup.object({
      EmailAddress: yup
        .string()
        .nullable()
        .required("Required")
        .matches(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          "Enter a valid e-mail address."
        ),
    })
  ),

  AccountingNotificationEmails: yup.array(
    yup.object({
      EmailAddress: yup
        .string()
        .nullable()
        .required("Required")
        .matches(
          /[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*@(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?/,
          "Enter a valid e-mail address."
        ),
    })
  ),
});

export const ContactDetailsEditor = ({
  onboarding = false,
}: IContactDetailsEditorProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );
  const refreshOnboardingProgress = useRefreshOnboardingProgress();
  const { open: notify } = useNotification();

  const form = useFocusableForm<IContactDetailModel>({
    schema: ContactDetailsSchema,
  });

  const onSubmit = useCallback(
    async (data: any) => {
      return new Promise((resolve, reject) => {
        const castedData = ContactDetailsSchema.cast(data);
        _accountService
          .updateContactDetails(castedData, onboarding)
          .then((result) => {
            resolve(result);
          })
          .catch((err: any) => {
            if (err.response.status === 400) {
              const {
                response: {
                  data: { errors },
                },
              } = err;

              form.setErrors(errors);
            }
            reject(err);
          });
      });
    },
    [_accountService, onboarding]
  );

  const afterSubmit = useCallback(() => {
    if (onboarding) {
      refreshOnboardingProgress();
    }
  }, [onboarding, refreshOnboardingProgress]);

  const formValues = form.getValues();

  return (
    <AuthenticatedForm
      dataProviderInstance={dataProviderInstance}
      form={form}
      fetchData={_accountService.loadContactDetails}
      submitLabel={onboarding ? "Save and Next" : "Update"}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      allowSubmitWithNoChanges={onboarding}
    >
      <Card mb="lg">
        {!onboarding && (
          <Card.Section py="sm">
            <Title order={5} mb="xs">
              Contact Details
            </Title>
            <Divider />
          </Card.Section>
        )}
        <Card.Section py="sm">
          <Stack>
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label>Company Name:</Label>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <Text fz="sm">{formValues.CompanyName}</Text>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Billing Address</Label>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <Stack spacing="sm">
                  <FormTextField
                    fieldName="BillingAddressLine1"
                    parentForm={form}
                    placeholder="Address Line 1"
                  />

                  <FormTextField
                    placeholder="Address Line 2"
                    fieldName="BillingAddressLine2"
                    parentForm={form}
                  />

                  <FormTextField
                    placeholder="Enter city"
                    fieldName="BillingSuburb"
                    parentForm={form}
                  />

                  <SimpleGrid cols={2}>
                    <FormTextField
                      placeholder="Enter state/region/province"
                      fieldName="BillingState"
                      parentForm={form}
                    />

                    <FormTextField
                      placeholder="Enter postal/ZIP code"
                      fieldName="BillingPostcode"
                      parentForm={form}
                    />
                  </SimpleGrid>
                  <FormSelect
                    data={(formValues.CountryList || []).map((c) => {
                      return { value: c.Value, label: c.Text };
                    })}
                    placeholder="-Select Country-"
                    fieldName="BillingCountry"
                    parentForm={form}
                    // onChange={(item) => {
                    //   setValue("BillingCountry", item?.key);
                    // }}
                  />
                </Stack>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Return Address</Label>
                <Text color="dimmed">
                  Please confirm a return adress for your items
                </Text>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <Stack spacing="sm">
                  <Checkbox
                    label="Return Address same as Billing Address"
                    {...form.getInputProps(
                      "IsReturnAddressSameBillingAddress",
                      { type: "checkbox" }
                    )}
                  />

                  {!formValues.IsReturnAddressSameBillingAddress && (
                    <>
                      <FormTextField
                        placeholder="Address Line 1"
                        fieldName="ReturnAddressLine1"
                        parentForm={form}
                      />

                      <FormTextField
                        placeholder="Address Line 2"
                        fieldName="ReturnAddressLine2"
                        parentForm={form}
                      />

                      <FormTextField
                        placeholder="Enter city"
                        fieldName="ReturnSuburb"
                        parentForm={form}
                      />

                      <SimpleGrid cols={2}>
                        <FormTextField
                          placeholder="Enter state/region/province"
                          fieldName="ReturnState"
                          parentForm={form}
                        />

                        <FormTextField
                          placeholder="Enter postal/ZIP code"
                          fieldName="ReturnPostcode"
                          parentForm={form}
                        />
                      </SimpleGrid>

                      <FormSelect
                        data={(formValues.CountryList || []).map((c) => {
                          return { value: c.Value, label: c.Text };
                        })}
                        placeholder="-Select Country-"
                        fieldName="ReturnCountry"
                        parentForm={form}
                        // onChange={(item) => {
                        //   setValue("ReturnCountry", item?.key);
                        // }}
                      />
                    </>
                  )}
                </Stack>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Primary Contact Name</Label>
              </Grid.Col>
              <Grid.Col md={6}>
                <Grid>
                  <Grid.Col md={6}>
                    <FormTextField
                      placeholder="First Name"
                      fieldName="FirstName"
                      parentForm={form}
                    />
                  </Grid.Col>
                  <Grid.Col md={6} sm={3}>
                    <FormTextField
                      placeholder="Last Name"
                      fieldName="LastName"
                      parentForm={form}
                    />
                  </Grid.Col>
                </Grid>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Phone Number</Label>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Phone Number"
                  fieldName="PhoneNumber"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Mobile Number</Label>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Mobile Number"
                  fieldName="MobileNumber"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Primary Contact Email</Label>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Email address"
                  fieldName="PrimaryContactEmail"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label>Secondary Contact Email</Label>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Email address"
                  fieldName="SecondaryContactEmail"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Customer Service Email</Label>
                <Text color="dimmed">
                  All enquiries for your products and orders will be sent to
                  this address. To update this address please{" "}
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
                    target="_blank"
                  >
                    submit an enquiry. <IconExternalLink size={14} />
                  </Anchor>
                </Text>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Email address"
                  disabled
                  fieldName="CustomerServiceEmail"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Marketing Contact Email</Label>
                <Text color="dimmed">
                  All marketing related information and promotional
                  opportunities will be sent to this address
                </Text>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Email address"
                  fieldName="MarketingContactEmail"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Technical Contact Email</Label>
                <Text color="dimmed">
                  All technical information and support will be sent to this
                  address
                </Text>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <FormTextField
                  placeholder="Email address"
                  fieldName="TechnicalContactEmail"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>
          </Stack>
        </Card.Section>
      </Card>

      <Card
        mb="sm"
        sx={(theme) => ({
          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[7]
              : theme.colors.gray[0],
        })}
      >
        <Card.Section p="sm">
          <Title order={5}>Notification Emails:</Title>
        </Card.Section>
        <Card.Section p="sm">
          <Stack spacing="md">
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Purchase Notification Emails</Label>
                <Text color="dimmed">
                  This email address will be notified when you receive an order
                  or when an order is put on hold
                </Text>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <>
                  <Stack spacing="sm">
                    {formValues.PurchaseNotificationEmails?.map(
                      (item, index) => {
                        return (
                          <FormTextField
                            key={index}
                            fieldName={`PurchaseNotificationEmails.${index}.EmailAddress`}
                            parentForm={form}
                          />
                        );
                      }
                    )}

                    <Group position="right" spacing="xs">
                      <ActionIcon
                        variant="default"
                        disabled={
                          (formValues.PurchaseNotificationEmails?.length || 0) <
                          2
                        }
                        onClick={() => {
                          form.removeListItem(
                            "PurchaseNotificationEmails",
                            (formValues.PurchaseNotificationEmails?.length ||
                              0) - 1
                          );
                        }}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                      <ActionIcon
                        variant="default"
                        onClick={() => {
                          if (
                            (formValues.PurchaseNotificationEmails?.length ||
                              0) >= 4
                          ) {
                            notify?.({
                              type: "error",
                              message:
                                "You have reached the maximum of 4 emails allowed.",
                              description: "",
                            });
                          } else {
                            const newItem: IPurchaseNotificationEmailModel = {
                              EmailAddress: "",
                            };
                            form.insertListItem(
                              "PurchaseNotificationEmails",
                              newItem
                            );
                          }
                        }}
                      >
                        <IconPlus size={16} />
                      </ActionIcon>
                    </Group>
                  </Stack>
                </>
              </Grid.Col>
            </Grid>

            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Accounting Notification Emails</Label>
                <Text color="dimmed">
                  This email address will receive remittance notices and other
                  accounting related information
                </Text>
              </Grid.Col>
              <Grid.Col md={6} sm={6}>
                <>
                  <Stack spacing="sm">
                    {formValues.AccountingNotificationEmails?.map(
                      (item, index) => {
                        return (
                          <FormTextField
                            key={index}
                            fieldName={`AccountingNotificationEmails.${index}.EmailAddress`}
                            parentForm={form}
                          />
                        );
                      }
                    )}

                    <Group position="right" spacing="xs">
                      <ActionIcon
                        variant="default"
                        disabled={
                          (formValues.AccountingNotificationEmails?.length ||
                            0) < 2
                        }
                        onClick={() => {
                          form.removeListItem(
                            "AccountingNotificationEmails",
                            (formValues.AccountingNotificationEmails?.length ||
                              0) - 1
                          );
                        }}
                      >
                        <IconTrash size={16} />
                      </ActionIcon>
                      <ActionIcon
                        variant="default"
                        onClick={() => {
                          if (
                            (formValues.AccountingNotificationEmails?.length ||
                              0) >= 4
                          ) {
                            notify?.({
                              type: "error",
                              message:
                                "You have reached the maximum of 4 emails allowed.",
                              description: "",
                            });
                          } else {
                            const newItem: IAccountingNotificationEmailModel = {
                              EmailAddress: "",
                            };
                            form.insertListItem(
                              "AccountingNotificationEmails",
                              newItem
                            );
                          }
                        }}
                      >
                        <IconPlus size={16} />
                      </ActionIcon>
                    </Group>
                  </Stack>
                </>
              </Grid.Col>
            </Grid>
          </Stack>
        </Card.Section>
      </Card>
    </AuthenticatedForm>
  );
};
