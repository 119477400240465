import React, { useMemo, useState } from "react";
import {
  Button,
  Title,
  Text,
  Paper,
  LoadingOverlay,
  Card,
} from "@mantine/core";
import { useAuthenticatedApi, useDataProvider } from "@mydeal/core";
import { paymentsService } from "@services";
import Moment from "react-moment";
import { PageTitle } from "@components";
import { IconDownload } from "@tabler/icons-react";
import { IDataTableRequestModel } from "../../types";
import { DynamicDataTable, IDynamicFetchParameters } from "@mydeal/ui-mantine";

export const Invoices = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _paymentsService = useMemo(
    () => paymentsService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setModel] = useState<any>();
  const [selectedInvoiceId, setSelectedInvoiceId] = useState<any>();
  const [fileDownloading, setFileDownloading] = useState<boolean>(false);
  const [fileformat, setFileformat] = useState<string>();

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sort }: IDynamicFetchParameters) => {
      var dataTableRequest: IDataTableRequestModel = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        Sort: sort.length > 0 ? sort[0].id : "",
        SortType: sort.length > 0 ? (sort[0].desc ? "desc" : "asc") : "",
      };
      const result = await _paymentsService.loadInvoices(dataTableRequest);
      setModel(result);
      return result;
    },
    [_paymentsService]
  );

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const viewInvoiceRemittance = (invoiceId: number, format: string) => {
    setSelectedInvoiceId(invoiceId);
    setFileDownloading(true);
    setFileformat(format);

    _paymentsService
      .viewInvoiceRemittance(invoiceId, format)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setFileDownloading(false);
      });
  };

  return (
    <>
      <PageTitle
        title="My Payments"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Payments" },
        ]}
      />
      <Card withBorder mih={300} p="xl">
        <Paper sx={{ position: "relative" }} data-testid="payment-table">
          <Title order={5} mt="md" mb="sm">
            Current Earnings: ${model && model.currentEarnings}
            <Text fz="xs" fw={500} component="span">
              {" "}
              (*includes all pending and un-dispatched orders that are not yet
              eligible for payment)
            </Text>
          </Title>
          <Title order={5} mt="lg" mb="sm">
            Earnings Paid(Past 15 months): $
            {model && model.totalPaidAmount.toFixed(2)}
          </Title>
          <LoadingOverlay visible={loading} />
          {!loading && (
            <>
              <DynamicDataTable<any, any>
                sortable={true}
                fetchData={fetchData}
                getData={(response) => response.invoiceList}
                getCount={(response) => response.totalCount}
                mantineTableBodyRowProps={{}}
                        columns={[
                  {
                    fieldName: "name",
                    header: "Invoice ID",
                    minSize: 100,
                    sortable: false,
                    cellTemplate: (value: any) => <Text>{value}</Text>,
                  },
                  {
                    fieldName: "bankpaymentid",
                    header: "Bank Payment ID",
                    minSize: 100,
                    sortable: false,
                    cellTemplate: (value: any) => <Text>{value}</Text>,
                  },
                  {
                    fieldName: "paidDate",
                    header: "Paid Date",
                    minSize: 100,
                    cellTemplate: (value: any) => (
                      <Moment format="DD/MM/YYYY">{value}</Moment>
                    ),
                  },
                  {
                    fieldName: "amount",
                    header: "Amount",
                    size: 100,
                    sortable: false,
                    cellTemplate: (value: any) => (
                      <Text>${value.toFixed(2)}</Text>
                    ),
                  },
                  {
                    fieldName: "invoiceID",
                    header: "Download",
                    minSize: 100,
                    sortable: false,
                    cellTemplate: (value: any, row: any) => {
                      if (row.isCompanyPaymentPart)
                        return (
                          <Button compact leftIcon={<IconDownload size={12} />}>
                            ViewRemittance{" "}
                          </Button> //TODO: looks like the companypart payment is hardcoded to 'false'
                        );
                      else
                        return (
                          <>
                            <Button
                              compact
                              loading={
                                fileDownloading &&
                                selectedInvoiceId == value &&
                                fileformat == "pdf"
                              }
                              styles={{
                                root: {
                                  marginRight: 20,
                                },
                              }}
                              leftIcon={<IconDownload size={12} />}
                              onClick={() =>
                                viewInvoiceRemittance(value, "pdf")
                              }
                            >
                              PDF{" "}
                            </Button>
                            <Button
                              compact
                              loading={
                                fileDownloading &&
                                selectedInvoiceId == value &&
                                fileformat == "csv"
                              }
                              leftIcon={<IconDownload size={12} />}
                              onClick={() =>
                                viewInvoiceRemittance(value, "csv")
                              }
                            >
                              CSV{" "}
                            </Button>
                          </>
                        );
                    },
                  },
                ]}
              />
            </>
          )}
        </Paper>
      </Card>
    </>
  );
};
