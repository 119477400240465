import {
  FormNumberInput,
  FormSelect,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import {
  ActionIcon,
  Alert,
  Anchor,
  Card,
  Group,
  SelectItem,
  Stack,
  Table,
  Text,
} from "@mantine/core";
import { useRouterContext } from "@mydeal/core";
import { IconPlus, IconTrash } from "@tabler/icons-react";
import { FreightType, IFreightAssignmentSettingModel } from "@types";

export interface IShippingAssignmentTableProps {
  parentForm: any; //useFocusableForm<any>;
  type: "Price" | "Weight";
  note: React.ReactNode;
  items?: IFreightAssignmentSettingModel[];
  itemsKey: string;
  freightSchemes: SelectItem[];
  freightTypes: SelectItem[];
}

export const ShippingAssignmentTable = ({
  parentForm,
  type,
  note,
  items,
  itemsKey,
  freightSchemes,
  freightTypes,
}: IShippingAssignmentTableProps) => {
  const { Link } = useRouterContext();

  const itemsLength = items?.length || 0;
  return (
    <Card withBorder p="md" pos="inherit">
      <Stack spacing="sm">
        <Alert title="Note">{note}</Alert>
        <Table highlightOnHover verticalSpacing="xs">
          <colgroup>
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
            <col width="25%" />
          </colgroup>
          <thead>
            <tr>
              <th>
                <div>{type} Range From</div>
              </th>
              <th>
                <div>{type} Range To</div>
              </th>
              <th>
                <div>Shipping option</div>
              </th>
              <th>
                <div>Value</div>
              </th>
            </tr>
          </thead>
          <tbody>
            {items?.map(
              (item: IFreightAssignmentSettingModel, index: number) => {
                const fieldName = `${itemsKey}.${index}`;
                return (
                  <tr key={index}>
                    <td className="col-md-3">
                      <FormNumberInput
                        parentForm={parentForm}
                        fieldName={`${fieldName}.From`}
                        precision={2}
                        placeholder={item.FromPlaceHolder}
                      />
                    </td>
                    <td className="col-md-3">
                      <FormNumberInput
                        parentForm={parentForm}
                        fieldName={`${fieldName}.To`}
                        placeholder={item.ToPlaceHolder}
                        precision={2}
                        onChange={(e) => {
                          if (typeof e === "number") {
                            if (index < items.length - 1) {
                              parentForm.setFieldValue(
                                `${itemsKey}.${index + 1}.From`,
                                e + 0.01
                              );
                            }
                            parentForm.setFieldValue(
                              `${fieldName}.To`,
                              Number(e)
                            );
                          }
                        }}
                      />
                    </td>
                    <td className="col-md-3">
                      <FormSelect
                        parentForm={parentForm}
                        fieldName={`${fieldName}.FreightType`}
                        data={freightTypes}
                        withAsterisk
                        placeholder="-Select-"
                        numericValue
                      />
                    </td>
                    <td className="col-md-3">
                      {(item.FreightType === FreightType.FlatRate ||
                        item.FreightType === FreightType.FlatAnyQty) && (
                        <div className="FreightFlatRateSection">
                          <FormNumberInput
                            parentForm={parentForm}
                            fieldName={`${fieldName}.FreightFlatRate`}
                          />
                        </div>
                      )}
                      {item.FreightType === FreightType.Custom && (
                        <div className="FreightSchemeIDSection">
                          <FormSelect
                            parentForm={parentForm}
                            fieldName={`${fieldName}.FreightSchemeID`}
                            data={freightSchemes}
                            withAsterisk
                            placeholder="-Select-"
                          />
                        </div>
                      )}
                    </td>
                  </tr>
                );
              }
            )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={4}>
                <Group spacing={5} pl="xs">
                  <ActionIcon
                    variant="default"
                    color="red"
                    disabled={itemsLength < 3}
                    onClick={() => {
                      if (items) {
                        parentForm.removeListItem(itemsKey, itemsLength - 1);
                      }
                    }}
                  >
                    <IconTrash size={16} />
                  </ActionIcon>
                  <ActionIcon
                    variant="default"
                    onClick={() => {
                      const lastItem = items && items[itemsLength - 1];

                      let previousFrom = Number(lastItem?.To);

                      let from =
                        !isNaN(previousFrom) && previousFrom > 0
                          ? previousFrom + 0.01
                          : undefined;
                      const newItem: Partial<IFreightAssignmentSettingModel> = {
                        ...lastItem,
                        From: from,
                        To: undefined,
                        FreightSchemeID: undefined,
                        FreightFlatRate: undefined,
                      };

                      parentForm.insertListItem(itemsKey, newItem);
                    }}
                  >
                    <IconPlus size={16} />
                  </ActionIcon>
                </Group>
              </td>
            </tr>
          </tfoot>
        </Table>
        {freightSchemes.length === 0 && (
          <Text fz="sm">
            No shipping calculator created.{" "}
            <Anchor component={Link} to="/shipping/manage-freight/create">
              Add Now
            </Anchor>
          </Text>
        )}
      </Stack>
    </Card>
  );
};
