import { Center } from "@mantine/core";
import { useRef, useState } from "react";

export const SellerApplicationForm = () => {
  const runtimeConfig = window?.__RUNTIME_CONFIG__ || {};
  const frameRef = useRef<HTMLIFrameElement>(null);

  return (
    <Center h="100%">
      <iframe
        style={{ border: "none" }}
        width="100%"
        height="100%"
        title="Seller Application Form"
        src={runtimeConfig.REACT_APP_SELLER_APPLICATION_FORM}
        ref={frameRef}
      ></iframe>
    </Center>
  );
};
