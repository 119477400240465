import { useCallback, useMemo, useState } from "react";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
  useRouterContext,
} from "@mydeal/core";
import { shippingService } from "@services";
import { IFreightScheme } from "./types";
import { StaticDataTable } from "@mydeal/ui-mantine";
import {
  Badge,
  Button,
  Group,
  LoadingOverlay,
  Paper,
  Stack,
  Text,
  Tooltip,
} from "@mantine/core";
import {
  IconCheck,
  IconEdit,
  IconPlus,
  IconHelpCircle,
  IconX,
} from "@tabler/icons-react";
import { LimitedFreeShippingEligibility } from "./FreightForm.types";

export interface IFreightList {
  onboarding: boolean;
}

export const FreightList = ({ onboarding }: IFreightList) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _shippingService = useMemo(
    () => shippingService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const { Link, useLocation, useHistory } = useRouterContext();
  const location = useLocation();
  const history = useHistory();

  const [freightSchemes, setFreightSchemes] = useState<IFreightScheme[]>([]);
  const [loading, setLoading] = useState<boolean>(false);

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(true);
    const fetchData = async () => {
      await _shippingService
        .freightList()
        .then((result: any) => {
          setFreightSchemes(result);
        })
        .finally(() => setLoading(false));
    };

    fetchData();
  });

  const renderRequiredFields = (data: IFreightScheme) => {
    return (
      <Stack spacing="xs">
        {data.RequiresSku && <Badge color="dark">SKU</Badge>}
        {data.RequiresExternalID && <Badge color="dark">External ID</Badge>}
        {data.RequiresExternalFreightID && (
          <Badge color="dark">External Freight ID</Badge>
        )}
        {data.RequiresWeight && <Badge color="dark">Weight</Badge>}
        {data.RequiresHeight && <Badge color="dark">Height</Badge>}
        {data.RequiresLength && <Badge color="dark">Length</Badge>}
        {data.RequiresWidth && <Badge color="dark">Width</Badge>}
      </Stack>
    );
  };

  const addFreightScheme = useCallback(() => {
    if (freightSchemes.length >= 100) {
      notify?.({
        type: "error",
        message: "You have reached the maximum of 10 freight schemes allowed.",
        description: "",
      });
    } else {
      history.push(`${location.pathname}/create`);
    }
  }, [freightSchemes.length, history, location.pathname, notify]);

  return (
    <Stack>
      <Group position="right">
        <Button
          onClick={addFreightScheme}
          leftIcon={<IconPlus size={16} />}
          data-testid="shipping-managefreightscheme-addbutton"
        >
          Add New
        </Button>
      </Group>
      <Paper
        sx={{ position: "relative" }}
        data-testid="shipping-managefreightscheme-table"
      >
        <LoadingOverlay visible={loading} />

        <StaticDataTable<any>
          data={freightSchemes}
          mantineTableBodyRowProps={{}}
            columns={[
            {
              fieldName: "FreightSchemeID",
              header: "Freight Scheme ID",
              size: 60,
              cellTemplate: (value: any) => <Text weight={600}>{value}</Text>,
            },
            {
              fieldName: "FreightSchemeTypeDescription",
              header: "Freight Scheme Type",
              maxSize: 50,
            },
            {
              fieldName: "FreightTypeDescription",
              header: "Option",
              maxSize: 50,
            },
            {
              fieldName: "Description",
              header: "Description",
              maxSize: 150,
            },
            {
              fieldName: "HasPostcodeExclusions",
              header: "Has Postcode Exclusions",
              maxSize: 50,
              cellTemplate: (value: any) => (value ? <IconCheck /> : <IconX />),
            },
            {
              fieldName: "SupportsQty",
              header: "Combined Shipping Cost",
              maxSize: 50,
              cellTemplate: (value: any) => (value ? <IconCheck /> : <IconX />),
            },
            {
              fieldName: "LimitedFreeShippingEligibility",
              header: "Limited Free Shipping",
              maxSize: 50,
              cellTemplate: (value: any) => {
                switch (value) {
                  case LimitedFreeShippingEligibility.Deal_Level:
                    return <Text weight={600}>Deal Level</Text>;
                  case LimitedFreeShippingEligibility.True:
                    return <Text weight={600}>True</Text>;
                  case LimitedFreeShippingEligibility.False:
                    return <Text weight={600}>False</Text>;
                }

                <Text weight={600}>{value}</Text>;
              },
            },
            {
              fieldName: "RequiresWidth",
              header: "Required Fields",
              maxSize: 80,
              cellTemplate: (value: any, row: any) => {
                return renderRequiredFields(row);
              },
            },
            {
              fieldName: "FreightSchemeCompanyID",
              header: "Action",
              size: 100,
              cellTemplate: (value: any, row: any) => {
                if (row.FreightTypeDescription === "Custom")
                  return (
                    <Tooltip label="Cannot edit this freight type.">
                      <IconHelpCircle size={16} />
                    </Tooltip>
                  );
                else
                  return (
                    <Button
                      component={Link}
                      to={`${location.pathname}/edit/${row.FreightSchemeID}?freightSchemeCompanyID=${value}`}
                    >
                      <IconEdit size={16} /> View/Edit
                    </Button>
                  );
              },
            },
          ]}
        />
      </Paper>
    </Stack>
  );
};
