import { ReactNode, useContext, useMemo } from "react";
import { Anchor, Breadcrumbs, Group, Stack, Text, Title } from "@mantine/core";
import { useAuthenticatedApi, useDataProvider, useRouterContext } from "@mydeal/core";
import { TicketSummaryContext } from "@pages/enquiryInbox";

export interface IPageTitleProps {
  title: string | ReactNode;
  description?: string | ReactNode;
  breadcrumbs?: IBreadcrumbItem[];
  actions?: ReactNode;
  children?: ReactNode;
}

export interface IBreadcrumbItem {
  name: string | ReactNode;
  route?: string;
}

export const PageTitle = ({
  title,
  description,
  breadcrumbs,
  actions,
  children,
}: IPageTitleProps) => {
  const { Link } = useRouterContext();
  const ticketSummaryContext = useContext(TicketSummaryContext);
  
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  
  useAuthenticatedApi(dataProviderInstance, () => {   
    if (ticketSummaryContext && ticketSummaryContext.ticketSummaryModel) {
      ticketSummaryContext.ticketSummaryModel?.GetSummary();
    }
  });
  
  return (
    <>
      <Stack spacing="sm" mb="xl">
        <Group grow>
          <Stack>
            <Title order={2}>{title}</Title>
            {description && <Text color="dimmed">{description}</Text>}
            {breadcrumbs && (
              <Breadcrumbs fz={14}>
                {breadcrumbs.map((item, index) =>
                  item.route ? (
                    <Anchor component={Link} to={item.route} key={index}>
                      {item.name}
                    </Anchor>
                  ) : (
                    <Text key={index}>{item.name}</Text>
                  )
                )}
              </Breadcrumbs>
            )}
          </Stack>
          <Group position="right">{actions}</Group>
        </Group>
        {children}
      </Stack>
    </>
  );
};
