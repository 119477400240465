import { IDataContextProvider } from "@mydeal/core";

export const clientsService = (dataProvider: IDataContextProvider) => ({
  list: async (searchModel: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/ClientList/Show`,
        payload: searchModel,
      })
      .then((result) => {
        return result?.data;
      });
  },

  downloadList: async (url: string) => {
    return dataProvider.download({ resource: url }).then((result) => {
      return result;
    });

    // const baseUrl = dataProvider.getApiUrl();
    // return await dataProvider
    //   .custom?.({
    //     method: "post",
    //     url: `${baseUrl}/${url}`,
    //     headers: {
    //       responseType: "blob",
    //     },
    //   })
    //   .then((result) => {
    //     debugger;
    //     // create temp download url
    //     const downloadUrl = window.URL.createObjectURL(
    //       new Blob([result.data as any], {
    //         type: "application/pdf;charset=utf-8;",
    //       })
    //     );

    //     const k = document.createElement("a");
    //     k.setAttribute("href", downloadUrl);
    //     k.setAttribute("target", "_blank");
    //     k.click();

    //     // // open pdf file on new tab
    //     // window.open(downloadUrl, "__blank");

    //     // // remove temp url
    //     // window.URL.revokeObjectURL(url);

    //     return result;
    //   });
  },

  importShipments: async (entity: any) => {
    return dataProvider
      .upload({ resource: "ClientList/ImportShipments", variables: entity })
      .then((result) => {
        return result?.data;
      });
  },

  importVouchers: async (entity: any) => {
    return dataProvider
      .upload({ resource: "ClientList/ImportVouchers", variables: entity })
      .then((result) => {
        return result?.data;
      });
  },
});
