import { Navigate } from "@mydeal/react-router";

export const redirects = [
  {
    path: "/Home/UserIndex",
    element: <Navigate to="/dashboard" />,
  },
  {
    path: "/MyAccount/PrivateContactDetails",
    element: <Navigate to="/myaccount/contact-details " />,
  },
  {
    path: "/MyAccount/PrivateBankDetails",
    element: <Navigate to="/myaccount/bank-details" />,
  },
  {
    path: "/MyAccount/PrivateChangePassword",
    element: <Navigate to="/myaccount/change-password" />,
  },
  {
    path: "/MyAccount/PublicProfilePage",
    element: <Navigate to="/myaccount/profile" />,
  },
  {
    path: "/MyAccount/ShippingSettings",
    element: <Navigate to="/shipping" />,
  },
  {
    path: "/ManageFreight",
    element: <Navigate to="/shipping/manage-freight" />,
  },
  {
    path: "/ManageFreight/ShippingOffer",
    element: <Navigate to="/shipping/shipping-offers" />,
  },
  {
    path: "/ManageFreight/CombinedShipping",
    element: <Navigate to="/shipping/combined-shipping" />,
  },

  {
    path: "/MyAccount/ShippingAssignment",
    element: <Navigate to="/shipping/shipping-assignment" />,
  },
  {
    path: "/MyAccount/IntegrationSettings",
    element: <Navigate to="/myaccount/integration" />,
  },
  {
    path: "/MyAccount/ProductSettings",
    element: <Navigate to="/myaccount/product-settings" />,
  },

  {
    path: "/Deal/DealList",
    element: <Navigate to="/mydeals" />,
  },
  {
    path: "/Payment/Invoices",
    element: <Navigate to="/payments" />,
  },
  {
    path: "/EnquiryInbox/OpenTickets",
    element: <Navigate to="/enquiryinbox/open-tickets" />,
  },
  {
    path: "/EnquiryInbox/PendingTickets",
    element: <Navigate to="/enquiryinbox/pending-tickets" />,
  },
  {
    path: "/EnquiryInbox/SolvedTickets",
    element: <Navigate to="/enquiryinbox/solved-tickets" />,
  },
  {
    path: "/EnquiryInbox/EscalationTickets",
    element: <Navigate to="/enquiryinbox/escalations" />,
  },
  {
    path: "/EnquiryInbox/SolvedEscalationTickets",
    element: <Navigate to="/enquiryinbox/solved-escalations" />,
  },
  {
    path: "/Coupon",
    element: <Navigate to="/coupon" />,
  },
  {
    path: "/ClientList/Show",
    element: <Navigate to="/mydeals/client-list" />,
  },
];
