import { Stack, Title } from "@mantine/core";
import { ShippingAssignmentEditor } from "@pages/shipping/ShippingAssignmentEditor";

export const OnboardingShippingAssignment = () => {
  return (
    <Stack spacing="lg">
      <Title order={2}>Shipping Assignment</Title>
      <ShippingAssignmentEditor onboarding={true} />
    </Stack>
  );
};
