export interface IFreightResponse {
  freightTypes: IFreightTypeModel[];
  model: IFreightSchemeModel;
}

export interface IFreightTypeModel {
  ID: number;
  Name: string;
  IsStandardTemplate: boolean;
  IsAPITemplate: boolean;
  IsCustom: boolean;
  Sort: number;
  Disabled: boolean;
}

export interface IFreightSchemeModel {
  FreightSchemeType?: FreightSchemaType;
  FreightType?: StandardTemplate | FreightAPITemplate;
  FreightSchemeID: number;
  CompanyID: number;
  SupportsQty: number;
  TemplateID: StandardTemplate;
  APITemplateID: FreightAPITemplate;
  RequiresExternalFreightID: number;
  RequiresExternalID: number;
  RequiresSku: string;
  RequiresHeight: number;
  RequiresLength: number;
  RequiresWidth: number;
  FreightSchemeName: string;

  FreightSchemeCompanyID?: number;
  Description?: string;
  PostcodeExclusionGroupID?: number;

  ApiUrl?: string;
  ApiUserName?: string;
  ApiPassword?: string;
  ContainerType?: string;
  ShippingMethodName?: string;
  FromLocation?: any;
  FromPostcode?: string;
  FromSuburb?: string;
  FromState?: string;

  PostcodeExclusionFile?: File | null;
  RatetableFile?: File | null;
  LimitedFreeShippingEligibility?: Number;
}

export enum FreightSchemaType {
  Freight_API = 1,
  Shipping_Rate_Table = 2,
}
export enum FreightAPITemplate {
  None = 0,
  AusPost = 1,
  Sendle = 2,
  HunterExpress = 3,
  Shippit = 4,
  Bespoke = 5,
  Neto = 6,
}

export enum StandardTemplate {
  None = 0,
  MyDealZones_Table = 1,
  MyDealZones_Weight_Table = 2,
  MyDealZones_Price_Table = 3,
  Postcode_Table = 4,
  PostcodeWeight_Table = 5,
}

export enum LimitedFreeShippingEligibility {
  Deal_Level = 0,
  True = 1,
  False = 2,
}

export enum FreightInstructionsUrl {
  AusPost = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10013259121167",
  Sendle = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10013309607311",
  Shippit = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10013325084303",
  HunterExpress = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10013313077263",
  Neto = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10013415385359",
}

export enum TemplateInstructionsUrl {
  MyDealZones = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10009567793807",
  MyDealZones_Weight = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10010654675983",
  MyDealZones_Price = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10011030213647",
  Postcode = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10011293426575",
  PostcodeWeight = "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10013181841935",
}