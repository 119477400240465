import { useNotification } from "@mydeal/core";
import { IAddonOrderViewModel } from "./types";
import { useState } from "react";
import { RefundHistoryTable } from "./RefundHistoryTable";

interface IAddonRefundModalProps {
  addonOrder: IAddonOrderViewModel;
  toggle: (reload?: boolean) => void;
}

export const AddonRefundModal = ({
  addonOrder,
}: IAddonRefundModalProps) => {

  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  
  return (
    <>
      <RefundHistoryTable
        refundHistoryModels={addonOrder?.AddonRefunds?.RefundHistoryModels}
      />
    </>
  );
};
