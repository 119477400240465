import {
    createStyles,
    Text,
    Card,
    SimpleGrid,
    Container,
} from '@mantine/core';
import { IconUsers, IconBusinessplan, IconSettings } from '@tabler/icons-react';

const mockdata = [
    {
        title: 'Promote Your Product Catalogue',
        description:
            'A MyDeal storefront exposes your products to millions of potential customers. Establish your online presence with the only Australian marketplace dedicated to the home. MyDeal currently serves 1 million users per month.',
        icon: IconUsers,
    },
    {
        title: 'Boost Sales Without Breaking Budget',
        description:
            'Increase your sales without the worry or stress about spending time and money. Our collective team of experts can provide marketing, strategy, technology and business development advice to help you grow your business.',
        icon: IconBusinessplan,
    },
    {
        title: 'Have the Tools for Success',
        description:
            'Our dedicated team of ecommerce professionals can help achieve your goals and create success. We are committed to shaping the way sellers approach online retail. We have everything you need to create an unbeatable online shopping experience for millions of customers.',
        icon: IconSettings,
    },
];

const useStyles = createStyles((theme) => ({
    title: {
        fontSize: 34,
        fontWeight: 900,
        [theme.fn.smallerThan('sm')]: {
            fontSize: 24,
        },
    },

    description: {
        maxWidth: 600,
        margin: 'auto',

        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: 45,
            height: 2,
            marginTop: theme.spacing.sm,
            marginLeft: 'auto',
            marginRight: 'auto',
        },
    },

    card: {
        border: `1px solid ${theme.colorScheme === 'dark' ? theme.colors.dark[5] : theme.colors.gray[1]
            }`,
    },

    cardTitle: {
        '&::after': {
            content: '""',
            display: 'block',
            backgroundColor: theme.fn.primaryColor(),
            width: 45,
            height: 2,
            marginTop: theme.spacing.sm,
        },
    },
}));

export function FeaturesCards() {
    const { classes, theme } = useStyles();

    const features = mockdata.map((feature) => (
        <Card key={feature.title} shadow="md" radius="md" className={classes.card} p="xl">
            <feature.icon size={50} stroke={2} color={theme.fn.primaryColor()} />
            <Text size="lg" weight={500} className={classes.cardTitle} mt="md">
                {feature.title}
            </Text>
            <Text size="sm" color="dimmed" mt="sm">
                {feature.description}
            </Text>
        </Card>
    ));

    return (
        <Container size="lg" py="xl" px={0} >
            <SimpleGrid cols={3} spacing="xl" mt={30} breakpoints={[{ maxWidth: 'md', cols: 1 }]}>
                {features}
            </SimpleGrid>
        </Container>
    );
}