import {
  Alert, createStyles,
  AlertProps
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  errorAlert: {
    maxWidth: "40rem",
    color: theme.colors.red,
    background: theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },
  errorText: {
    color: theme.colors.red,
  }
}));

export function ErrorAlert({
  className, children, ...rest
}: AlertProps &
  Omit<React.ComponentPropsWithoutRef<"div">, keyof AlertProps>) {
  const { classes, cx } = useStyles();

  return (
    <Alert icon={<IconAlertCircle size="1rem" />} className={cx(classes.errorAlert, className)}>
      <div className={cx(classes.errorText)}>
        {children}
      </div>
    </Alert>
  );
}
