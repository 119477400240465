import { useMemo, useState } from "react";
import { PageTitle } from "@components";
import {
  FormFileInput,
  FormSelect,
  FormTextArea,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import {
  Anchor,
  Card,
  Text,
  Title,
  LoadingOverlay,
  Stack,
  Group,
  Space,
  Paper,
  Alert,
  Button,
  Divider,
  ActionIcon,
} from "@mantine/core";
import {
  useDataProvider,
  useRouterContext,
  useNotification,
  useAuthenticatedApi,
} from "@mydeal/core";
import { infoService } from "@services";
import {
  IconMapPin,
  IconPhone,
  IconChevronDown,
  IconAlertCircle,
  IconPaperclip,
  IconFileText,
  IconPolaroid,
  IconTrash,
} from "@tabler/icons-react";
import { formatAddress } from "../../utils/formatAddress";
import * as yup from "yup";
import { IContactUsViewModel, IOrderViewItem } from "./info.types";
import { OrderViewItem } from "./orderViewItem";

const ContactUsValidateSchema = yup.object<{
  [Property in keyof IContactUsViewModel]: any;
}>({
  Enquiry: yup.string().nullable().required("Please select enquiry type"),
  Comments: yup.string().nullable().required("Message cannot be blank."),
  Attachments: yup
    .array()
    .nullable()
    .max(3, "No more than 3 files allowed to submit.")
    .test(
      "ValidateAttachmentsTotalSize",
      "Files cannot exceed 20MB",
      (files: any) => {
        let totalSize = 0;
        let validSize = true;
        if (files && files.length > 0) {
          files.map((item: any) => {
            totalSize = totalSize + Math.ceil(item.size / 1024 / 1024);
          });
          if (totalSize > 20) {
            validSize = false;
          } else {
            validSize = true;
          }
        }
        return validSize;
      }
    ),
});

interface IRouteParams {
  bigDealId: string;
}

export const OrderHelp = () => {
  const { useParams, Link } = useRouterContext();
  const { bigDealId } = useParams<IRouteParams>();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _infoService = useMemo(
    () => infoService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [contactUsViewModel, setContactUsViewModel] =
    useState<IContactUsViewModel>();
  const [orderViewItem, setOrderViewItem] = useState<IOrderViewItem | null>();
  const [enquiryTypes, setEnquiryTypes] = useState<any>([]);
  const [model, setModel] = useState<any>();
  const [helpDeskModel, setHelpDeskModel] = useState<any>();
  const { open: notify } = useNotification();
  const [hideFormEntry, setHideFormEntry] = useState<boolean>(false);

  const onSubmit = (data: any) => {
    setLoading(true);

    const toFormData = (object: any) =>
      Object.entries(object).reduce((formData, [key, val]: any) => {
        if (Array.isArray(val)) {
          val.forEach((v) => formData.append(key, v));
        } else {
          formData.append(key, val);
        }
        return formData;
      }, new FormData());

    _infoService
      .orderHelp(toFormData(data))
      .then((result: any) => {
        setHelpDeskModel(result);
        setHideFormEntry(result?.Success);
        notify?.({
          type: "success",
          message: result.SuccessMessage,
          description: "",
        });
      })
      .catch((err) => {
        const {
          response: {
            statusText,
            data: { ErrorMessage, title },
          },
        } = err;

        notify?.({
          type: "error",
          message: statusText,
          description: ErrorMessage ?? title,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const fetchData = async () => {
    setLoading(true);
    await _infoService
      .loadOrderHelp(BigInt(bigDealId))
      .then((result: any) => {
        setModel(result);
        setContactUsViewModel(result?.contactUsModel);
        form.setValues(result?.contactUsModel);
        setOrderViewItem(result?.orderLineItem);
      })
      .finally(() => setLoading(false));
  };

  const fetchEnquiryTypes = async () => {
    setLoading(true);
    await _infoService
      .loadEnquiryTypes()
      .then((result: any) => {
        setEnquiryTypes(result?.enquiryTypes);
        fetchData();
      })
      .finally();
  };

  const form = useFocusableForm<IContactUsViewModel>({
    schema: ContactUsValidateSchema,
    clearInputErrorOnChange: true,
  });

  useAuthenticatedApi(dataProviderInstance, () => {
    fetchEnquiryTypes();
  });

  const formValues = form.getValues();

  return (
    <>
      <PageTitle
        title={<Title> Contact Customer</Title>}
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Orders" },
          { name: "Order details", route: `/order/${bigDealId}` },
          { name: "Contact Customer" },
        ]}
      />
      <Paper>
        <Card mih={200}>
          <LoadingOverlay visible={loading} />
          <Stack spacing={"lg"}>
            {helpDeskModel && (
              <Card withBorder>
                <Card.Section p="sm">
                  <Text fw={500}>
                    Your message has been sent. A ticket has been created with
                    Ticket ID -
                    <Anchor
                      component={Link}
                      to={`/EnquiryInbox/ViewTicket/${helpDeskModel.helpDeskTicketID}`}
                    >
                      {" "}
                      {helpDeskModel.helpDeskTicketID}{" "}
                    </Anchor>{" "}
                    & same has been sent to the customer.
                  </Text>
                </Card.Section>
              </Card>
            )}
            {!hideFormEntry && (
              <Card withBorder>
                <Card.Section p="sm">
                  <Title order={4}>Order items</Title>
                </Card.Section>
                <Divider />
                <Card.Section p="sm">
                  <Space h="xs" />
                  {orderViewItem && (
                    <OrderViewItem OrderViewItem={orderViewItem} />
                  )}
                </Card.Section>
              </Card>
            )}
            {!hideFormEntry && contactUsViewModel && (
              <form onSubmit={form.onSubmit(onSubmit)}>
                <Card withBorder>
                  <Card.Section p="sm">
                    <Title order={4}>Submit Enquiry</Title>
                  </Card.Section>
                  <Divider />
                  <Card.Section p="sm">
                    <div style={{ width: "40%" }}>
                      <FormSelect
                        data={
                          enquiryTypes &&
                          enquiryTypes.map(
                            (item: { Value: any; Text: any }) => {
                              return {
                                value: item.Value,
                                label: item.Text,
                              };
                            }
                          )
                        }
                        rightSection={<IconChevronDown size={14} />}
                        rightSectionWidth={30}
                        styles={{ rightSection: { pointerEvents: "none" } }}
                        label="Enquiry Type :"
                        placeholder="-- SELECT --"
                        fieldName="Enquiry"
                        parentForm={form}
                      />
                    </div>
                  </Card.Section>
                  <Card.Section p="sm">
                    <Stack spacing={"lg"}>
                      <Stack spacing={"xs"}>
                        <Title order={5}>
                          Submit your message in below email template:
                        </Title>
                        <Text>
                          (A copy of the order details will be included)
                        </Text>
                      </Stack>
                      <Card withBorder>
                        <Card.Section p="sm">
                          <FormTextArea
                            label="Message:"
                            autosize
                            minRows={3}
                            fieldName="Comments"
                            parentForm={form}
                          />
                        </Card.Section>
                        <Card.Section p="sm">
                          <Stack spacing="xs">
                            <Text>
                              <Text span fw={700}>
                                Order Number:{" "}
                              </Text>
                              {orderViewItem?.BidDealId.toString()}
                            </Text>
                            <Text>
                              <Text span fw={700}>
                                Order Group Number:{" "}
                              </Text>
                              {orderViewItem?.BidDealGroupId?.toString()}
                              <Text>
                                {orderViewItem?.OrderQty} X{" "}
                                {orderViewItem?.MainDeal} ,{" "}
                                {orderViewItem?.SelectedOption}
                              </Text>
                            </Text>
                            <Text fw={700}>Delivery Address:</Text>
                            <Stack spacing="xs">
                              <Group>
                                <IconMapPin size={16} />
                                {formatAddress(orderViewItem?.ShippingAddress)}
                              </Group>
                              <Group>
                                <IconPhone size={16} />{" "}
                                {orderViewItem?.PhoneNo || "n/a"}
                              </Group>
                            </Stack>
                            <Text>
                              <Text span fw={700}>
                                Tracking No:
                              </Text>
                              {orderViewItem?.TrackingNo}
                            </Text>
                            <Text>
                              <Text span fw={700}>
                                Courier:
                              </Text>
                              {orderViewItem?.Courier}
                            </Text>
                            <Alert
                              title="NOTE:"
                              icon={<IconAlertCircle size={16} />}
                              color={"yellow"}
                            >
                              <Text fz="xs">
                                This message only refers to this item.
                              </Text>
                            </Alert>
                          </Stack>
                        </Card.Section>
                      </Card>
                    </Stack>
                  </Card.Section>
                  <Card.Section p="sm">
                    <Stack spacing={"xs"} style={{ width: "40%" }}>
                      <FormFileInput
                        label={
                          <Text>
                            Attachments:{" "}
                            <Text span fz="xs" fs="italic" c="dimmed">
                              Total Files Max size must be less than 20MB
                            </Text>
                          </Text>
                        }
                        placeholder="Select file(s)"
                        variant="filled"
                        icon={<IconPaperclip size={14} />}
                        multiple
                        size="sm"
                        style={{ backgroundColor: "gray.0" }}
                        fieldName="Attachments"
                        parentForm={form}
                      />

                      {formValues.Attachments &&
                        formValues.Attachments.map((file: any, index: any) => (
                          <>
                            <Paper
                              key={index}
                              p="xs"
                              withBorder
                              style={{
                                backgroundColor: "#f1f3f5",
                              }}
                            >
                              <Group
                                position="apart"
                                style={{
                                  margin: "-5px",
                                }}
                              >
                                <Group spacing="xs">
                                  {file.type.includes("image") ? (
                                    <IconPolaroid size={20} color="#F04A1C" />
                                  ) : (
                                    <IconFileText size={20} color="#F04A1C" />
                                  )}
                                  <Text fz="md">&nbsp;{file.name}</Text>
                                </Group>
                                <ActionIcon
                                  color="#F04A1C"
                                  style={{ float: "right" }}
                                  onClick={() => {
                                    formValues.Attachments?.splice(index, 1);
                                    form.setValues({
                                      //requires to rerender this component
                                      Attachments: formValues.Attachments
                                        ? formValues.Attachments
                                        : [],
                                    });
                                  }}
                                >
                                  <IconTrash size={20} />
                                </ActionIcon>
                              </Group>
                            </Paper>
                          </>
                        ))}
                    </Stack>
                  </Card.Section>
                  <Card.Section p="sm">
                    {model.enableReplyMode ? (
                      <Alert
                        title="NOTE:"
                        icon={<IconAlertCircle size={16} />}
                        color={"yellow"}
                      >
                        <Text fz="xs">
                          On clicking the submit button, an email will be
                          automatically sent to the customer and a ticket will
                          be created and appear in your Enquiry Inbox under the
                          'Pending Tickets' view. Once the customer has
                          responded to the email, the ticket status will
                          automatically change to 'Open' and appear in the 'Open
                          Tickets' view.
                        </Text>
                      </Alert>
                    ) : (
                      <Alert
                        title="NOTE:"
                        icon={<IconAlertCircle size={16} />}
                        color={"yellow"}
                      >
                        <Text fz="xs">
                          Sorry, the MyDeal Helpdesk is down for a bit of
                          maintenance right now. We will be right back. Thanks
                          for your patience.
                        </Text>
                      </Alert>
                    )}
                  </Card.Section>
                  <Card.Section p="sm">
                    <Button type="submit" color="primary" disabled={loading}>
                      Send Enquiry{" "}
                    </Button>
                  </Card.Section>
                </Card>
              </form>
            )}
          </Stack>
        </Card>
      </Paper>
    </>
  );
};
