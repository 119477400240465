import { useCallback, useMemo, useRef, useState } from "react";
import {
  Title,
  Text,
  Paper,
  LoadingOverlay,
  Card,
  Anchor,
  Space,
  Group,
  Badge,
  Table,
} from "@mantine/core";
import {
  useAuthenticatedApi,
  useDataProvider,
  useRouterContext,
} from "@mydeal/core";
import { IDataTableRequestModel } from "../../types";
import { DynamicDataTable, IDynamicFetchParameters } from "@mydeal/ui-mantine";
import { PageTitle } from "@components";
import { JobService } from "@services";
import Moment from "react-moment";
import { IconDownload } from "@tabler/icons-react";
import React from "react";
import { WorkItemProductStatus } from "./job.types";

interface IRouteParams {
  jobId: string;
}

export const ProductImportResult = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _jobService = useMemo(
    () => JobService(dataProviderInstance),
    [dataProviderInstance]
  );

  const { useParams } = useRouterContext();
  const { jobId } = useParams<IRouteParams>();

  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<any>();

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex }: IDynamicFetchParameters) => {
      const result = await _jobService.loadProductImportResult(jobId, {
        PageIndex: pageIndex,
        PageSize: pageSize,
        Sort: "",
        SortType: "",
      });
      setModel(result);
      return result;
    },
    [_jobService]
  );

  const downloadErrorProductResult = (productImportHistoryId: string) => {
    setLoading(true);
    _jobService
      .downloadErrorProductResult(productImportHistoryId)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const downloadNewProductResult = (productImportHistoryId: string) => {
    setLoading(true);
    _jobService
      .downloadNewProductResult(productImportHistoryId)
      .catch((err) => {
        console.log(err);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  return (
    <>
      <PageTitle
        title="Product Import Results"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Request Dashboard", route: "/job/requestDashboard" },
          { name: "Product Import Results" },
        ]}
      />

      <Paper p="xl" sx={{ position: "relative" }}>
        <Card mih={200}>
          <LoadingOverlay visible={loading} />
          <Card.Section>
            <Group>
              <Title order={3}>Import ID: </Title>
              <Text fw={500} fz="lg">
                {jobId}
              </Text>
            </Group>
            <Space h="sm" />
            <Group>
              <Title order={3}>Finished Processing: </Title>
              <Text fw={500} fz="lg">
                <Moment format="DD/MM/YYYY hh:mm A">
                  {model?.model?.JobModel?.FinishedDate}
                </Moment>
              </Text>
            </Group>
          </Card.Section>
          <Card.Section>
            <Space h="sm" />
            <Table withBorder withColumnBorders striped mb="md">
              <thead>
                <tr>
                  <th colSpan={3}>
                    <Title order={3}>Summary</Title>
                  </th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td width="250">
                    <strong>Product Updates:</strong>
                  </td>
                  <td width="200">
                    <Text fw={500} fz="lg" color="green">
                      {model?.model?.ProductUpdatedCount}
                    </Text>
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td>
                    <strong>New Products:</strong>
                  </td>
                  <td>
                    <Text fw={500} fz="lg" color="green">
                      {model?.model?.ProductCreatedCount}
                    </Text>
                  </td>
                  <td>
                    {model?.model?.ProductCreatedCount > 0 && (
                      <Anchor
                        component="button"
                        onClick={() =>
                          downloadNewProductResult(
                            model?.model?.ProductImportHistoryId
                          )
                        }
                      >
                        <IconDownload size={14} /> Download List
                      </Anchor>
                    )}
                  </td>
                </tr>
                {model?.model?.ProductSkippedCount > 0 && (
                  <tr>
                    <td>
                      <strong> Skipped Products:</strong>
                    </td>
                    <td>
                      <Text fw={500} fz="lg" color="orange">
                        {model?.model?.ProductSkippedCount}
                      </Text>
                    </td>
                    <td></td>
                  </tr>
                )}
                <tr>
                  <td>
                    <strong> Error:</strong>
                  </td>
                  <td>
                    <Text fw={500} fz="lg" color="red">
                      {model?.model?.ProductErrorCount}
                    </Text>
                  </td>
                  <td>
                    {model?.model?.ProductErrorCount > 0 && (
                      <Anchor
                        component="button"
                        onClick={() =>
                          downloadErrorProductResult(
                            model?.model?.ProductImportHistoryId
                          )
                        }
                      >
                        <IconDownload size={14} /> Download Error Report
                      </Anchor>
                    )}
                  </td>
                </tr>
                <tr>
                  <td>
                    <strong> Total:</strong>
                  </td>
                  <td>
                    <Text fw={500} fz="lg">
                      {model?.model?.ProductTotalCount}
                    </Text>
                  </td>
                  <td></td>
                </tr>
              </tbody>
            </Table>
          </Card.Section>
          <Card.Section>
            <Card withBorder>
              <Card.Section p="sm">
                <Title order={4}>Import Results</Title>
              </Card.Section>
              <Card.Section p="sm">
                {!loading && (
                  <>
                    <DynamicDataTable<any, any>
                      sortable={false}
                      fetchData={fetchData}
                      mantineTableBodyRowProps={{}}
                                    getData={(response) =>
                        response?.model?.ProductImportResultModels
                      }
                      getCount={(response) =>
                        response?.model?.ProductTotalCount
                      }
                      columns={[
                        {
                          fieldName: "ParentSku",
                          header: "Parent Sku",
                          size: 200,
                          cellTemplate: (value: any) => (
                            <>
                              <Text>{value}</Text>
                            </>
                          ),
                        },
                        {
                          fieldName: "DealTitle",
                          header: "Deal Title",
                          size: 200,
                          cellTemplate: (value: any) => (
                            <>
                              <Text>{value}</Text>
                            </>
                          ),
                        },
                        {
                          fieldName: "Status",
                          header: "Status",
                          size: 200,
                          cellTemplate: (value: any) => (
                            <>
                              {value == WorkItemProductStatus.Skipped && (
                                <Badge
                                  size="sm"
                                  variant="filled"
                                  color="#F04A1C"
                                >
                                  {value}
                                </Badge>
                              )}
                              {value == WorkItemProductStatus.Imported && (
                                <Badge size="sm" variant="filled" color="green">
                                  {value}
                                </Badge>
                              )}
                              {value == WorkItemProductStatus.Failed && (
                                <Badge size="sm" variant="filled" color="red">
                                  {value}
                                </Badge>
                              )}
                            </>
                          ),
                        },
                        {
                          fieldName: "ErrorMessage",
                          header: "Error Message",
                          size: 200,
                          cellTemplate: (value: any) => (
                            <>
                              <Text>{value}</Text>
                            </>
                          ),
                        },
                      ]}
                    />
                  </>
                )}
              </Card.Section>
            </Card>
          </Card.Section>
        </Card>
      </Paper>
    </>
  );
};
