export enum OnboardingState {
  SetPassword = 0,
  WelcomePage = 1,
  ContactDetail = 2,
  SellerProfile = 3,
  IntegrationConfiguration = 4,
  ProductSetting = 5,
  ShippingOption = 6,
  ShippingAssignment = 7,
  FinalStep = 8,
  FinalStep_ImportRequested = 9,
  Completed = 10,
}

export interface IOnboardingStatus {
  Loaded: boolean;
  OnboardingState?: OnboardingState;
  Bypass: boolean;
  HideShippingAssignment: boolean;
}
