import { useEffect, useMemo, useState } from "react";
import { PageTitle } from "@components";
import { Text, Paper, Divider, Card } from "@mantine/core";

import { useDataProvider } from "@mydeal/core";
import { enquiryInboxService } from "@services";
import { TicketStatus, SearchKeywordList } from "./enquiryInbox.types";
import { TicketsDataTable } from "./ticketsDataTable";
import { SearchTicketsCard } from "./searchTicketsCard";

export const OpenTickets = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [searchType, setSearchType] = useState<string>();
  const [ticketsCount, setTicketsCount] = useState<number>(0);

  const [ticketTypes, setTicketTypes] = useState([
    {
      value: SearchKeywordList.CustomerTickets,
      label: SearchKeywordList.CustomerTickets.toString(),
    },
    {
      value: SearchKeywordList.EscalatedTickets,
      label: SearchKeywordList.EscalatedTickets.toString(),
    },
  ]);

  useEffect(() => {}, []);

  return (
    <>
      <PageTitle
        title="Open Tickets"
        description={<Text fz="lg">{`(${ticketsCount} tickets)`}</Text>}
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Open Tickets" },
        ]}
      />
      <SearchTicketsCard />
      <Card withBorder mih={300} p="xl">
        <Paper
          sx={{ position: "relative" }}
          data-testid="enquiry-openticket-table"
        >
          <TicketsDataTable
            ticketStatus={TicketStatus.Open}
            refreshTicketsCount={(i: number) => setTicketsCount(i)}
          />
        </Paper>
      </Card>
    </>
  );
};
