import { IDataContextProvider } from "@mydeal/core";
import {
  ITicketsTableRequestModel,
  TicketStatus,
} from "../pages/enquiryInbox/enquiryInbox.types";

export const enquiryInboxService = (dataProvider: IDataContextProvider) => ({
  loadTickets: async (
    ticketStatus: TicketStatus,
    dataTableRequest: ITicketsTableRequestModel
  ) => {
    
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/enquiries/tickets/${ticketStatus}`,
        payload: dataTableRequest,
        headers: {
          "Content-Type": "application/json"
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  searchTickets: async (
    isEscalation: Boolean,
    searchKeyword: String,
    dataTableRequest: ITicketsTableRequestModel
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/enquiries/search-tickets/${isEscalation}/${searchKeyword}`,
        payload: dataTableRequest,
      })
      .then((result) => {
        return result?.data;
      });
  },
  viewTicket: async (ticketId: BigInt) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/enquiries/${ticketId}`,
      })
      .then((result) => {
        return result?.data;
      });
  },
  updateTicket: async (ticketId: number, model: any) => {
    const baseUrl = dataProvider.getApiUrl();

    var url = `${baseUrl}/enquiries/${ticketId}/update`;

    return dataProvider
      .custom?.({
        method: "post",
        url: url,
        payload: model,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      })
      .then((result) => {
        return result?.data;
      });
  },
  loadSummary: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/enquiries/summary`,
      })
      .then((result) => {
        return result?.data;
      });
  },
});
