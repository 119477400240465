import { Code, createStyles, Divider, Group, MediaQuery, Navbar, ScrollArea } from "@mantine/core";
import { useRouterContext } from "@mydeal/core";
import { IconHelp, IconHome, IconLogin, IconPhone } from "@tabler/icons-react";
import { ILinksGroupProps, LinksGroup } from "../layout/sidenav/NavbarLinks";
const useStyles = createStyles((theme) => ({
    link: {}

}));

const data: ILinksGroupProps[] = [
    {
        icon: IconLogin,
        label: "Log In",
        route: "/login",
    },
    {
        icon: IconHelp,
        label: "Help",
        route: "https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb",
    },
    {
        icon: IconPhone,
        label: "Contact",
        route: "/start",
    },
];

interface PublicNavProps {
    opened: boolean;
}



export const PublicNav = ({ opened }: PublicNavProps) => {
    const { classes } = useStyles();
    const { Link } = useRouterContext();
    const links = data.map((item) => <LinksGroup {...item} key={item.label} />);

    return (
        <>
            <MediaQuery largerThan="sm" styles={{ display: "none" }}>
                <Navbar
                    width={{ base: 300 }}
                    p="md"
                    hidden={!opened}
                >
                    <Navbar.Section grow mt="xs" component={ScrollArea}>
                        {links}
                    </Navbar.Section>
                </Navbar>
            </MediaQuery>
        </>
    );
};
