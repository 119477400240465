import { useCallback, useMemo, useRef, useState } from "react";
import {
  Alert,
  Anchor,
  Button,
  Card,
  Divider,
  List,
  LoadingOverlay,
  Tabs,
  Text,
  Title,
} from "@mantine/core";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNavigation,
  useRouterContext,
} from "@mydeal/core";
import { dealsService } from "@services";
import {
  IconAlertCircle,
  IconExclamationCircle,
  IconUsers,
} from "@tabler/icons-react";
import { PageTitle } from "@components/shared";
import { DynamicDataTable, IDynamicFetchParameters } from "@mydeal/ui-mantine";
import { ExportImportCard } from "./ExportImportCard";

export const DealsList = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _dealsService = useMemo(
    () => dealsService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [model, setModel] = useState<any>({ UnfulfilledBidDeals: [] });
  const [loading, setLoading] = useState<boolean>(true);
  const [uncategorisedProductCount, setUncategorisedProductCount] =
    useState<number>(0);
  const { Link, useLocation } = useRouterContext();
  const location = useLocation();
  const [activeTab, setActiveTab] = useState<string>("products");
  const { push } = useNavigation();

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }: IDynamicFetchParameters) => {
      const result = await _dealsService.list({
        start: pageSize * pageIndex,
        length: pageSize,
      });
      setModel(result);
      return result;
    },
    [_dealsService]
  );

  const getUncategorisedProductCount = async () => {
    const result = await _dealsService.getUncategorisedProductCount();
    setUncategorisedProductCount(result?.count || 0);
  };

  useAuthenticatedApi(
    dataProviderInstance,
    () => {
      getUncategorisedProductCount().then(() => setLoading(false));
      if (location.search) {
        var val = new URLSearchParams(location.search).get("show") || "";
        setActiveTab(val);
      }
    },
    location
  );

  const dealColumns = useMemo(
    () => [
      {
        fieldName: "MainDeal",
        header: "Deal Name",
        size: 250,
        cellTemplate: (value: any, row: any) => (
          <>
            <Anchor href={row.Url} target="_blank">
              <strong>{value}</strong>             
            </Anchor>
          </>
        ),
      },
      {
        fieldName: "UnfulfilledOrderItemCount",
        header: "Pending Orders",
        size: 50,
        cellTemplate: (value: any) => <Text color="red">{value}</Text>,
        footer: () => <Text>Total: {model.TotalPending}</Text>,
      },
      {
        fieldName: "DealID",
        header: "Client List",
        size: 100,
        cellTemplate: (value: any) => (
          <Link to={`client-list/${value}`}>
            <Button leftIcon={<IconUsers size={16} />}>View customers</Button>
          </Link>
        ),
        footer: () => (
          <>
            <Anchor component={Link} to="client-list">
              View All Customers
            </Anchor>
          </>
        ),
      },
    ],
    [Link, model.TotalPending]
  );

  const runtimeConfig = window?.__RUNTIME_CONFIG__ || {};

  return (
    <>
      <PageTitle
        title="My Deals"
        description={
          <Text fz="lg">Review and update all of your products here.</Text>
        }
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Deals" },
        ]}
      />

      <Card p="xl" withBorder mih={300}>
        <Tabs
          value={activeTab}
          onTabChange={(value) => push(`/Mydeals?show=${value}`)}
        >
          <Tabs.List>
            <Tabs.Tab value="products">Manage Products</Tabs.Tab>
            <Tabs.Tab value="orders">Manage Orders</Tabs.Tab>
          </Tabs.List>

          <Tabs.Panel value="products" data-testid="mydeals-updatetask">
            <LoadingOverlay visible={loading} />
            {model && (
              <>
                <Alert
                  color="yellow"
                  title="Please note:"
                  icon={<IconAlertCircle size={16} />}
                  mb="sm"
                  mt="sm"
                >
                  <List>
                    <List.Item>
                      The exported Prices & RRPs from this portal are the final
                      MyDeal listed prices inclusive of GST.
                    </List.Item>
                    <List.Item>
                      All Prices & RRPs imported via this portal must include
                      GST (including direct import products {`>`} $1000).
                    </List.Item>
                  </List>
                </Alert>

                {model.ShowStockUpdate && (
                  <ExportImportCard
                    exportTitle="Export Products"
                    importTitle="Import CSV File to Update Stock/Status"
                    instructionUrl={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/Stock-Update-Instructions.pdf`}
                    exportUrl={`MyDeals/ExportStock`}
                    importUrl={`MyDeals/StockImport`}
                    data-testid="mydeals-updatetask-stockstatus"
                  >
                    <Divider
                      label={
                        <Title order={4} color="brand">
                          Update Stock/Status
                        </Title>
                      }
                      mt="xl"
                    />
                    {model.AutoStockUpdate && (
                      <Alert
                        color="blue"
                        icon={<IconExclamationCircle size={16} />}
                        title="Automated stock updates are ON."
                      >
                        <Text>
                          Please ensure your feed is up to date or your changes
                          will be overwritten by your feed data when the next
                          update runs.
                        </Text>
                      </Alert>
                    )}
                  </ExportImportCard>
                )}

                {model.ShowPriceUpdate && (
                  <ExportImportCard
                    exportTitle="Export Products"
                    importTitle="Import CSV File to Update Price/RRP"
                    instructionUrl={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/Price-Update-Instructions.pdf`}
                    exportUrl={`MyDeals/ExportPrice`}
                    importUrl={`MyDeals/PriceImport`}
                  >
                    <Divider
                      label={
                        <Title order={4} color="brand">
                          Update Price/RRP
                        </Title>
                      }
                      mt="xl"
                    />
                    {model.AutoPriceUpdate && (
                      <Alert
                        color="blue"
                        icon={<IconExclamationCircle size={16} />}
                        title="Automated price updates are ON."
                      >
                        <Text>
                          Please ensure your feed is up to date or your changes
                          will be overwritten by your feed data when the next
                          update runs.
                        </Text>
                      </Alert>
                    )}
                  </ExportImportCard>
                )}

                {model.ShowImportExportProductSection && (
                  <ExportImportCard
                    exportTitle="Export Product List"
                    importTitle="Import CSV File to Update or Create New Products"
                    instructionUrl={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/MyDealProductImport_Instructions.pdf`}
                    templateUrl={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/MyDeal_Product_Template.csv`}
                    exportUrl={`MyDeals/ProductExport`}
                    importUrl={`MyDeals/ProductImport`}
                    isProduct={true}
                    showResult={false}
                    redirectUrl={`/job/requestDashboard`}
                  >
                    <Divider
                      label={
                        <Title order={4} color="brand">
                          Update or Create New Products
                        </Title>
                      }
                      mt="xl"
                    />
                    <Anchor component={Link} to={`/job/requestDashboard`}>
                      Request Dashboard
                    </Anchor>
                  </ExportImportCard>
                )}

                <ExportImportCard
                  exportTitle="Export Products"
                  importTitle="Import CSV File to Update PO BOX Delivery"
                  instructionUrl={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/POBoxDelivery-Update-Instructions.pdf`}
                  exportUrl={`MyDeals/ExportPOBoxDelivery`}
                  importUrl={`MyDeals/POBoxDeliveryImport`}
                >
                  <Divider
                    label={
                      <Title order={4} color="brand">
                        Update PO BOX Delivery Status
                      </Title>
                    }
                    mt="xl"
                  />
                </ExportImportCard>

                <ExportImportCard
                  exportTitle="Export Products"
                  importTitle="Import CSV File to Update Categories"
                  instructionUrl={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/Category-Update-Instructions.pdf`}
                  exportUrl={`MyDeals/ExportUncategorisedProducts`}
                  importUrl={`MyDeals/UncategorisedProductsImport`}
                  isProduct={true}
                  isUncategorised={true}
                  enabled={uncategorisedProductCount > 0}
                >
                  <Divider
                    label={
                      <Title order={4} color="brand">
                        Update Uncategorised Products
                      </Title>
                    }
                    mt="xl"
                  />
                  {uncategorisedProductCount > 0 ? (
                    <Alert
                      color="yellow"
                      mt="lg"
                      icon={<IconExclamationCircle size={16} />}
                    >
                      <Text>
                        You have {uncategorisedProductCount} uncategorised
                        products that require category mapping. These products
                        have minimal exposure until they are updated.
                      </Text>
                    </Alert>
                  ) : (
                    <Alert color="gray" mt="lg">
                      <Text>
                        All products are categorised. No action is required.
                      </Text>
                    </Alert>
                  )}
                </ExportImportCard>
              </>
            )}
          </Tabs.Panel>
          <Tabs.Panel value="orders" data-testid="mydeals-dealwithpendingorder">
            <LoadingOverlay visible={loading} />
            {!loading && model && (
              <>
                <Title order={4} mt="lg" mb="sm">
                  Deals with Pending Orders:
                </Title>
                <DynamicDataTable<any, any>
                  fetchData={fetchData}
                  getData={(response) => response?.UnfulfilledBidDeals}
                  getCount={(response) => response?.TotalCount || 0}
                  showFooter={true}
                  columns={dealColumns}
                  mantineTableBodyRowProps={{}}
                          />
              </>
            )}
          </Tabs.Panel>
        </Tabs>
      </Card>
    </>
  );
};
