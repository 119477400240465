import { AppState, Auth0Provider } from "@auth0/auth0-react";
import { useRouterContext } from "@mydeal/core";
import { useRollbar } from "@rollbar/react";
import { useCallback } from "react";
import { IndividualLoginAuth0Context } from "./IndividualLoginAuth0Context";
import { v4 as uuidv4 } from "uuid";

export const MyDealAuth0Provider = ({ children }: any) => {
  const { useHistory } = useRouterContext();
  const history = useHistory();
  const runtimeConfig = window?.__RUNTIME_CONFIG__ || {};
  const rollbar = useRollbar();

  const onRedirectCallback = useCallback(
    (appState?: AppState) => {
      rollbar.info("Successfull login.");
      history.push(appState?.returnTo || "/dashboard");
    },
    [history, rollbar]
  );

  return (
    <Auth0Provider
      domain={runtimeConfig.REACT_APP_AUTH0_DOMAIN}
      context={IndividualLoginAuth0Context}
      clientId={runtimeConfig.REACT_APP_AUTH0_ORG_CLIENT_ID}
      authorizationParams={{
        redirect_uri: window.location.origin + "/individual-callback",
        audience: `https://${runtimeConfig.REACT_APP_AUTH0_INTERNAL_DOMAIN}/mfa/`,
        scope:
          "openid profile offline_access email enroll read:authenticators remove:authenticators",
        "ext-client-base-url": window.location.origin,
        "ext-client-type": "marketplace",
        "ext-sid": uuidv4(),
        connection: "Seller-Email-Connection",
        prompt: "login",
      }}
      useRefreshTokens={true}
      onRedirectCallback={onRedirectCallback}
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};
