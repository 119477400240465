import { useAuth0 } from "@auth0/auth0-react";
import { IAuthProvider } from "@mydeal/core";
import { IndividualLoginAuth0Context } from "@providers";

export const useAuth0Provider = (): IAuthProvider => {
  const {
    isLoading,
    isAuthenticated,
    user,
    logout,
    getIdTokenClaims,
    getAccessTokenSilently,
  } = useAuth0(IndividualLoginAuth0Context);

  return {
    isLoading: () => {
      return isLoading;
    },
    getIdentityToken: () => {
      if (isLoading) {
        return Promise.reject();
      }

      return getIdTokenClaims().then((token) => token?.__raw);
    },
    getApiToken: () => {
      if (isLoading) {
        return Promise.reject();
      }

      return getAccessTokenSilently();
    },
    login: () => {
      return Promise.resolve();
    },
    logout: () => {
      logout();
      return Promise.resolve("/");
    },
    checkError: () => Promise.resolve(),
    checkAuth: () => {
      if (isAuthenticated) {
        return Promise.resolve();
      }

      return Promise.reject();
    },
    getPermissions: () => Promise.resolve(),
    getUserIdentity: () => {
      if (user) {
        return Promise.resolve({
          ...user,
          avatar: user.picture,
        });
      } else {
        return Promise.reject();
      }
    },
  };
};
