import { IDataContextProvider } from "@mydeal/core";
import { IDataTableRequestModel } from "../types";

export const paymentsService = (dataProvider: IDataContextProvider) => ({
  loadInvoices: async (dataTableRequest: IDataTableRequestModel) => {
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Payments/Invoices`,
        payload: dataTableRequest,
      })
      .then((result) => {
        return result?.data;
      });
  },

  viewInvoiceRemittance: async (invoiceId: number, format: string) => {
    const baseUrl = dataProvider.getApiUrl();
    var resource = `Payments/Invoices/${invoiceId}/ViewInvoiceRemittance/${format}`;
    return dataProvider.download({ resource: resource }).then((result) => {
      return result;
    });
  },
});
