import {
  ComponentType,
  useCallback,
  useContext,
  useMemo,
  useState,
} from "react";
import {
  Button,
  Code,
  Divider,
  Navbar,
  ScrollArea,
  Timeline,
  Anchor,
} from "@mantine/core";
import OnboardingStatusContext from "@pages/onboarding/onboardingStatusContext";
import {
  IconBuildingStore,
  IconConfetti,
  IconHome,
  IconKey,
  IconMailbox,
  IconSettings,
  IconStar,
  IconTruckDelivery,
  IconUserCircle,
  TablerIconsProps,
} from "@tabler/icons-react";
import { OnboardingState } from "@pages/onboarding/types";
import { useAuthenticatedApi, useDataProvider } from "@mydeal/core";
import { onboardingService } from "@services/onboarding.service";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import TestingConfigContext from "@utils/testingConfigContext";

interface OnboardingNavbarProps {
  opened: boolean;
}

const states: {
  state: OnboardingState;
  icon: ComponentType<TablerIconsProps>;
  label: string;
}[] = [
  {
    state: OnboardingState.SetPassword,
    icon: IconKey,
    label: "Set Password",
  },
  {
    state: OnboardingState.WelcomePage,
    icon: IconStar,
    label: "Welcome",
  },
  {
    state: OnboardingState.ContactDetail,
    icon: IconUserCircle,
    label: "Contact Detail",
  },
  {
    state: OnboardingState.SellerProfile,
    icon: IconBuildingStore,
    label: "Seller Profile",
  },
  {
    state: OnboardingState.IntegrationConfiguration,
    icon: IconSettings,
    label: "Integration Configuration",
  },
  {
    state: OnboardingState.ProductSetting,
    icon: IconHome,
    label: "Product Setting",
  },
  {
    state: OnboardingState.ShippingOption,
    icon: IconMailbox,
    label: "Shipping Option",
  },
  {
    state: OnboardingState.ShippingAssignment,
    icon: IconTruckDelivery,
    label: "Shipping Assignment",
  },
  {
    state: OnboardingState.FinalStep,
    icon: IconConfetti,
    label: "Final Step",
  },
];

export const OnboardingNavbar = ({ opened }: OnboardingNavbarProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _onboardingService = useMemo(
    () => onboardingService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(false);

  const onboardingStatusContext = useContext(OnboardingStatusContext);
  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const testingConfigContext = useContext(TestingConfigContext);
  const testingMode = testingConfigContext.testingConfigModel.TestingMode;

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const changeState = useCallback(
    (onboardingState: OnboardingState) => {
      _onboardingService
        .changeStatus(onboardingState)
        .then((result: any) => {
          refreshOnboardingProgress();
        })
        .finally(() => {
          setLoading(false);
        });
    },
    [_onboardingService, refreshOnboardingProgress]
  );

  const currentState = Math.min(
    onboardingStatusContext.onboardingStatusModel?.OnboardingState || 0,
    OnboardingState.FinalStep
  );

  const activeItem = states.findIndex((s) => s.state === currentState);

  return (
    <Navbar
      width={{ base: 270 }}
      p="md"
      hiddenBreakpoint="sm"
      hidden={!opened}
      data-testid="sidenav"
    >
      <Navbar.Section grow mt="xs" component={ScrollArea}>
        <Timeline active={activeItem} bulletSize={28} lineWidth={2} fz="md">
          {states.map((s) => {
            const Component = s.icon;
            return onboardingStatusContext.onboardingStatusModel
              .HideShippingAssignment &&
              s.state === OnboardingState.ShippingAssignment ? null : (
              <Timeline.Item
                key={s.state}
                bullet={<Component size={16} />}
                title={
                  currentState >= s.state ? (
                    <Anchor
                      component="button"
                      onClick={() => changeState(s.state)}
                    >
                      {s.label}
                    </Anchor>
                  ) : (
                    s.label
                  )
                }
                pt={5}
              >
                {testingMode && !loading && (
                  <Button onClick={() => changeState(s.state)}>Go Here</Button>
                )}
              </Timeline.Item>
            );
          })}
        </Timeline>
      </Navbar.Section>
      <Navbar.Section>
        <Divider my="sm" />
        <Code sx={{ fontWeight: 80 }}>v2.0</Code>
      </Navbar.Section>
    </Navbar>
  );
};
