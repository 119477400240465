import { Anchor, Text, Title } from "@mantine/core";
import { Link } from "@mydeal/react-router";
import { IOrderTable, OrderTable } from "@components/shared/OrderTable";

import { DealModel } from "@types";
import {
  IDynamicFetchParameters,
  IDataTableColumnProps,
} from "@mydeal/ui-mantine";
import { Ref, useCallback, useImperativeHandle, useMemo, useRef } from "react";

export interface IOrderOrDealTableProps {
  fetchData: (parameters: IDynamicFetchParameters) => Promise<any>;
}

export interface ISearchResultTable {
  refresh(): void;
}

const SearchResultTable = ({
  fetchData,
  controlRef,
}: IOrderOrDealTableProps & {
  controlRef?: Ref<ISearchResultTable>;
}) => {
  const orderTableRef = useRef<IOrderTable>(null);

  const refreshTable = useCallback(() => {
    if (orderTableRef.current) {
      orderTableRef.current.refresh();
    }
  }, []);

  useImperativeHandle(controlRef, () => ({
    refresh() {
      refreshTable();
    },
  }));

  const dealColumns: IDataTableColumnProps<any>[] = useMemo(
    () => [
      {
        fieldName: "MainDeal",
        header: "Deal Name",
        size: 850,
        cellTemplate: (value: any, _row: DealModel) => {
          return (
            <>
              {value}

              <Anchor
                component={Link}
                to={`/mydeals/client-list/${_row.DealID}`}
              >
                <Text fw="bold">[Client List]</Text>
              </Anchor>
            </>
          );
        },
      },
      {
        fieldName: "Url",
        header: "Preview",
        size: 250,
        cellTemplate: (value: any) => {
          return (
            <>
              <Anchor href={value} target="_blank">
                <Text fw="bold">[Preview]</Text>
              </Anchor>
            </>
          );
        },
      },
    ],
    []
  );

  return (
    <OrderTable
      fetchData={fetchData}
      columnSelector={(response) =>
        response?.OrderViewItems?.length > 0 ? undefined : dealColumns
      }
      controlRef={orderTableRef}
      emptyMessage={
        <Title order={4} mt="lg" mb="sm">
          No Match Found!
        </Title>
      }
      getData={(response) =>
        (response?.OrderViewItems?.length > 0
          ? response.OrderViewItems
          : response?.DealModels) || []
      }
      getCount={(response) => response?.TotalCount || 0}
      showClientListLink={true}
    />
  );
};

export default SearchResultTable;
