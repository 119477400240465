import { Card, Stack, Title } from "@mantine/core";
import { ContactDetailsEditor } from "@pages/myaccount/ContactDetailsEditor";

export const OnboardingContactDetails = () => {
  return (
    <Stack spacing="lg">
      <Title order={2}>Contact Details</Title>
      <Card withBorder mih={300} p="xl">
        <ContactDetailsEditor onboarding={true} />
      </Card>
    </Stack>
  );
};
