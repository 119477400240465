import { Card, Grid, Image, Stack, Text, Title } from "@mantine/core";
import { ChangePasswordEditor } from "@pages/user/ChangePasswordEditor";
import svgUpdatePasswod from "../../assets/images/onboarding-updatepassword-background.svg";

export const OnboardingSetPassword = () => {
  return (
    <Grid mt="xl">
      <Grid.Col md={5}>
        <Image src={svgUpdatePasswod} />
      </Grid.Col>
      <Grid.Col md={6} offsetMd={1}>
        <Stack spacing="lg">
          <Title order={2}>Update Your Password</Title>
          <Card withBorder p="xl">
            <Text fz="lg">Set a new password for your marketplace account</Text>
            <ChangePasswordEditor onboarding={true} />
          </Card>
        </Stack>
      </Grid.Col>
    </Grid>
  );
};
