import { useCallback, useContext, useMemo, useState, useEffect } from "react";
import {
  ActionIcon,
  Avatar,
  Anchor,
  Burger,
  createStyles,
  Group,
  Header as MantineHeader,
  Image,
  MediaQuery,
  Menu,
  Text,
  UnstyledButton,
  useMantineColorScheme,
} from "@mantine/core";
import logo from "../../../assets/images/mydeal-mp-logo.png";
import {
  IconLogout,
  IconMoonStars,
  IconSun,
  IconHelp,
  IconMail,
  IconPassword,
  IconBuildingStore,
} from "@tabler/icons-react";
import {
  useLogout,
  useGetIdentity,
  useRouterContext,
  useDataProvider,
  useAuthenticatedApi,
} from "@mydeal/core";
import { NotificationsHeader } from "./notificationsHeader";
import { onboardingService } from "@services/onboarding.service";
import OnboardingStatusContext from "@pages/onboarding/onboardingStatusContext";
import { OnboardingState } from "@pages/onboarding/types";
import TestingConfigContext from "@utils/testingConfigContext";
import { useRollbar } from "@rollbar/react";
import { LogoutOptions } from "@auth0/auth0-react";

const useStyles = createStyles((theme) => ({
  header: {
    justifyContent: "space-around",
  },
  logoContainer: {
    width: 150,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 100,
    },
  },
  notificationBadge: {
    marginBottom: "20px",
    marginLeft: "-12px",
    padding: 2,
    minWidth: 20,
  },
  headerLinks: {
    padding: "6px 10px",
    borderRadius: "5px",
    "&:hover": {
      backgroundColor: "#F8F9FA",
    },
  },
}));

interface HeaderProps {
  toggleOpen: () => void;
  opened: boolean;
}

export const Header = ({ toggleOpen, opened }: HeaderProps) => {
  const HEADER_HEIGHT = 67;
  const { colorScheme, toggleColorScheme } = useMantineColorScheme();
  const [userMenuOpened, setUserMenuOpened] = useState(false);
  const { Link } = useRouterContext();

  const { mutate: logout } = useLogout<LogoutOptions>();
  const { data: user } = useGetIdentity();
  const { classes } = useStyles();
  const rollbar = useRollbar();

  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _onboardingService = useMemo(
    () => onboardingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const onboardingStatusContext = useContext(OnboardingStatusContext);
  const testingConfigContext = useContext(TestingConfigContext);

  useAuthenticatedApi(
    dataProviderInstance,
    () => {
      if (!onboardingStatusContext.onboardingStatusModel.Loaded) {
        _onboardingService.getStatus().then((result) => {
          onboardingStatusContext.setOnboardingStatusModel({
            ...onboardingStatusContext.onboardingStatusModel,
            ...result,
            Loaded: true,
          });
        });
      }
    },
    [onboardingStatusContext.onboardingStatusModel.Loaded]
  );

  const allowTesting = !(window?.location?.href || "").includes(
    "portal.mydeal.com.au"
  );

  const testingMode =
    allowTesting && testingConfigContext.testingConfigModel.TestingMode;

  const onboardingCompleted =
    onboardingStatusContext &&
    onboardingStatusContext.onboardingStatusModel.Loaded &&
    (onboardingStatusContext.onboardingStatusModel.OnboardingState ===
      OnboardingState.Completed ||
      (testingMode && onboardingStatusContext.onboardingStatusModel.Bypass));

  const onTestingModeChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      testingConfigContext.setTestingConfigModel({
        ...testingConfigContext.testingConfigModel,
        TestingMode: event.currentTarget.checked,
      });
    },
    [testingConfigContext]
  );

  const onBypassOnboardingChange = useCallback<
    React.ChangeEventHandler<HTMLInputElement>
  >(
    (event) => {
      onboardingStatusContext.setOnboardingStatusModel({
        ...onboardingStatusContext.onboardingStatusModel,
        Bypass: event.currentTarget.checked,
      });
    },
    [onboardingStatusContext]
  );

  useEffect(() => {
    if (user) {
      rollbar.configure({
        payload: {
          person: { id: user["https://mydeal.com.au/company-id"] },
          custom: {
            seller_name: user.name,
            seller_id: user["https://mydeal.com.au/company-id"],
          },
        },
      });
    }
  }, [user]);

  return (
    <MantineHeader
      height={HEADER_HEIGHT}
      className={classes.header}
      data-testid="header"
    >
      <Group sx={{ height: "100%" }} px={20} position="apart">
        <Group align="center">
          <MediaQuery largerThan="sm" styles={{ display: "none" }}>
            <Group position="apart">
              <Burger opened={opened} onClick={toggleOpen} size="sm" />
            </Group>
          </MediaQuery>
          <div className={classes.logoContainer}>
            <Image src={logo} alt="MyDeal" />
          </div>
        </Group>
        <Group spacing="xs">
          {onboardingCompleted && <NotificationsHeader />}
          {/* {allowTesting && (
            <Switch
              onLabel="Enabled"
              offLabel="Disabled"
              label="Testing mode"
              size="lg"
              checked={testingConfigContext.testingConfigModel.TestingMode}
              onChange={onTestingModeChange}
              color="#F04A1C"
            />
          )}
          {testingMode && (
            <Switch
              onLabel="Enabled"
              offLabel="Disabled"
              label="Bypass onboarding"
              size="lg"
              checked={onboardingStatusContext.onboardingStatusModel.Bypass}
              onChange={onBypassOnboardingChange}
              color="#F04A1C"
            />
          )} */}
          {/* <TextInput
            placeholder="Search"
            size="sm"
            icon={<IconSearch size={16} stroke={1.5} />}
            styles={{ rightSection: { pointerEvents: "none" } }}
          /> */}
          <Menu position="bottom-end">
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb"
              target="_blank"
              size="sm"
              weight={500}
              variant="text"
              className={classes.headerLinks}
            >
              <Group spacing={7}>
                <IconHelp size={14} />
                Help
              </Group>
            </Anchor>
          </Menu>
          <Menu position="bottom-end">
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
              target="_blank"
              variant="text"
              size="sm"
              className={classes.headerLinks}
              weight={500}
            >
              <Group spacing={7} styles={{ display: "none" }}>
                <IconMail size={14} />
                <Text mr={3}>Contact us</Text>
              </Group>
            </Anchor>
          </Menu>
          <Menu position="bottom-end">
            <Group spacing={7}>
              <IconBuildingStore size={14} />
              <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                {user != null && user["https://mydeal.com.au/display-name"]
                  ? user["https://mydeal.com.au/display-name"]
                  : user?.name}
              </Text>
            </Group>
          </Menu>

          <Menu
            width={260}
            position="bottom-end"
            transitionProps={{ transition: "pop-top-right" }}
            onClose={() => setUserMenuOpened(false)}
            onOpen={() => setUserMenuOpened(true)}
          >
            <Menu.Target>
              <UnstyledButton className={classes.headerLinks}>
                <Avatar alt={user?.name} radius="xl" size={28} />
              </UnstyledButton>
            </Menu.Target>
            <Menu.Dropdown>
              <Menu.Item icon={<IconPassword size={14} stroke={1.5} />}>
                <Anchor component={Link} to={`/myaccount/change-password`}>
                  Change Password
                </Anchor>
              </Menu.Item>
              <Menu.Item
                onClick={() => logout({ logoutParams: { returnTo: window.location.origin } })}
                icon={<IconLogout size={14} stroke={1.5} />}
              >
                Logout
              </Menu.Item>
            </Menu.Dropdown>
          </Menu>
          <ActionIcon
            variant="outline"
            onClick={() => toggleColorScheme()}
            size="sm"
            radius="xl"
          >
            {colorScheme === "dark" ? (
              <IconSun size={16} />
            ) : (
              <IconMoonStars size={16} />
            )}
          </ActionIcon>
        </Group>
      </Group>
    </MantineHeader>
  );
};
