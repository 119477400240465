import { Layout, Login } from "@components";
import { PublicLayout } from "@components/publicLayout";
import { SimpleLayout } from "@components/simpleLayout";
import { Home } from "@pages";
import { SellerApplicationForm } from "@pages/home/SellerApplicationForm";
import { authenticatedRoutes } from "./authenticatedRoutes";

export const layouts = [
  {
    element: <PublicLayout />,
    children: [
      {
        path: "/",
        element: <Home />,
      },
      {
        path: "/individual-callback",
        element: <Home />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },

  {
    element: <SimpleLayout />,
    children: [
      {
        path: "/seller-application",
        element: <SellerApplicationForm />,
      },
    ],
  },

  {
    element: <Layout />,
    children: authenticatedRoutes,
  },
];
