import { LoadingOverlay } from "@mantine/core";
import { useRouterContext } from "@mydeal/core";
import OnboardingStatusContext from "@pages/onboarding/onboardingStatusContext";
import { OnboardingState } from "@pages/onboarding/types";
import { ComponentType, useEffect, FC, useContext, useState } from "react";

const defaultOnRedirecting = (): JSX.Element => (
  <LoadingOverlay visible={true} />
);

export interface WithOnboardingRequiredOptions {
  onRedirecting?: () => JSX.Element;
}

const getOnboardingPage = (onboardingState?: OnboardingState) => {
  let route = "";
  if (typeof onboardingState !== "undefined") {
    switch (onboardingState) {
      case OnboardingState.SetPassword:
        route = "/onboarding/set-password";
        break;
      case OnboardingState.WelcomePage:
        route = "/onboarding/welcome";
        break;
      case OnboardingState.ContactDetail:
        route = "/onboarding/contact-detail";
        break;
      case OnboardingState.SellerProfile:
        route = "/onboarding/seller-profile";
        break;
      case OnboardingState.IntegrationConfiguration:
        route = "/onboarding/integration-configuration";
        break;
      case OnboardingState.ProductSetting:
        route = "/onboarding/product-setting";
        break;
      case OnboardingState.ShippingOption:
        route = "/onboarding/shipping-option";
        break;
      case OnboardingState.ShippingAssignment:
        route = "/onboarding/shipping-assignment";
        break;
      case OnboardingState.FinalStep:
        route = "/onboarding/final-step";
        break;
    }
  }
  return route;
};

const withOnboardingRequired = <P extends object>(
  Component: ComponentType<P>,
  { onRedirecting }: WithOnboardingRequiredOptions = {}
): FC<P> => {
  return function WithOnboardingRequired(props: P): JSX.Element {
    const { onboardingStatusModel } = useContext(OnboardingStatusContext);
    const { useHistory, useLocation } = useRouterContext();
    const [redirectRequired, setRedirectRequired] = useState(true);
    const history = useHistory();
    const location = useLocation();

    useEffect(() => {
      if (onboardingStatusModel && onboardingStatusModel.Loaded) {
        let shouldRedirect = false;
        if (
          !onboardingStatusModel.Bypass &&
          onboardingStatusModel.OnboardingState !== OnboardingState.Completed
        ) {
          const route = getOnboardingPage(
            onboardingStatusModel.OnboardingState
          );
          if (route && !location.pathname.startsWith(route)) {
            history.push(route);
            shouldRedirect = true;
          }
        }
        setRedirectRequired(shouldRedirect);
      }
    }, [
      history,
      location.pathname,
      onboardingStatusModel,
      onboardingStatusModel.Loaded,
    ]);

    return !redirectRequired ? (
      <Component {...props} />
    ) : (
      (onRedirecting || defaultOnRedirecting)()
    );
  };
};

export default withOnboardingRequired;
