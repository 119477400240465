import { useCallback, useMemo, useState } from "react";
import { PageTitle } from "@components";
import { DynamicDataTable, IDynamicFetchParameters } from "@mydeal/ui-mantine";
import {
  createStyles,
  Text,
  Paper,
  LoadingOverlay,
  Divider,
  Anchor,
  Card,
} from "@mantine/core";

import {
  useAuthenticatedApi,
  useDataProvider,
  useRouterContext,
} from "@mydeal/core";
import { enquiryInboxService } from "@services";
import { SearchTicketsCard } from "./searchTicketsCard";
import { TicketPriority } from "./enquiryInbox.types";
import { IconAlertTriangle } from "@tabler/icons-react";
import { TicketStatusCard } from "./ticketStatusCard";

const useStyles = createStyles((theme) => ({
  ticketStatusBadge: {
    minWidth: 20,
    width: "auto",
    height: 20,
    padding: 2,
    marginLeft: 5,
  },
}));

interface IRouteParams {
  isEscalation: string;
  searchKeyword: string;
}

export const SearchResults = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const { Link } = useRouterContext();
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [isEscalationBool, setisEscalationBool] = useState<boolean>(false);
  const { useParams } = useRouterContext();
  const { isEscalation, searchKeyword } = useParams<IRouteParams>();

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sort }: IDynamicFetchParameters) => {
      return await _enquiryInboxService.searchTickets(
        isEscalationBool,
        searchKeyword,
        {
          PageIndex: pageIndex,
          PageSize: pageSize,
          Sort: sort.length > 0 ? sort[0].id : "",
          SortType: sort.length > 0 ? (sort[0].desc ? "desc" : "asc") : "",
        }
      );
    },
    [_enquiryInboxService, isEscalationBool, searchKeyword]
  );

  useAuthenticatedApi(dataProviderInstance, () => {
    const boolIsEscalation = JSON.parse(isEscalation);
    setisEscalationBool(boolIsEscalation);
    setLoading(false);
  });

  return (
    <>
      <PageTitle
        title="Search Result"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          { name: "Search Result" },
        ]}
      />
      <SearchTicketsCard
        isEscalationProp={isEscalation}
        searchKeywordProp={searchKeyword}
      />
      <Card withBorder mih={300} p="xl">
        <Paper p="xl" sx={{ position: "relative" }}>
          <LoadingOverlay visible={loading} />
          {!loading && (
            <>
              <DynamicDataTable<any, any>
                sortable={true}
                fetchData={fetchData}
                getData={(response) => response?.ticketModel?.TicketModels}
                getCount={(response) => response?.ticketModel?.TotalTicket || 0}
                mantineTableBodyRowProps={{}}
                        columns={[
                  {
                    fieldName: "TicketId",
                    header: "Ticket ID",
                    size: 100,
                    sortable: true,
                    cellTemplate: (value: any, row: any) => (
                      <>
                        <Anchor
                          component={Link}
                          to={`/EnquiryInbox/ViewTicket/${value}`}
                        >
                          <span>#</span>
                          {value} <span> </span>
                        </Anchor>
                        {row.Priority === TicketPriority.High && (
                          <IconAlertTriangle color="red" size={14} />
                        )}
                      </>
                    ),
                  },
                  {
                    fieldName: "Priority",
                    header: "Priority",
                    size: 100,
                    sortable: true,
                    cellTemplate: (value: any) => <Text>{value}</Text>,
                  },
                  {
                    fieldName: "Subject",
                    header: "Subject",
                    size: 250,
                    sortable: true,
                    cellTemplate: (value: any, row: any) => (
                      <>
                        <TicketStatusCard
                          hasFreightProtection={false}
                          isEscalation={row.IsEscalation}
                          isSellerEscalation={false}
                          ticketStatus={row.Status}
                        />
                        <Text>{value}</Text>
                      </>
                    ),
                  },
                  {
                    fieldName: "RequesterName",
                    header: "From",
                    size: 100,
                    sortable: true,
                    cellTemplate: (value: any, row: any) => {
                      var currentRow = row.Status;
                      return (
                        <>
                          <Text>
                            {currentRow.IsEscalation ? value : "MyDeal"}
                          </Text>
                        </>
                      );
                    },
                  },
                  ...(!isEscalationBool
                    ? [
                        {
                          fieldName: "OrderGroupId",
                          header: "Order Group No",
                          size: 90,
                          sortable: false,
                          cellTemplate: (value: any) => <Text>{value}</Text>,
                        },
                        {
                          fieldName: "OrderId",
                          header: "Order No",
                          size: 90,
                          sortable: false,
                          cellTemplate: (value: any) => <Text>{value}</Text>,
                        },
                      ]
                    : []),
                  {
                    fieldName: "CreatedDatePretty",
                    header: "Created Date",
                    size: 150,
                    sortable: true,
                    cellTemplate: (value: any) => <Text>{value}</Text>,
                  },
                ]}
              />
            </>
          )}
        </Paper>
      </Card>
    </>
  );
};
