import {
  createStyles,
  Text,
  Title,
  Image,
  TypographyStylesProvider,
} from "@mantine/core";
import ImageBenifit01 from "../../assets/images/landingTabs/mydeal-furniture.jpg";
import ImageBenifit02 from "../../assets/images/landingTabs/section-save.jpg";
import ImageBenifit03 from "../../assets/images/landingTabs/section-attract.jpg";

const useStyles = createStyles((theme) => ({
  wrapper: {
    display: "flex",
    alignItems: "center",
    padding: `calc(${theme.spacing.xl} * 2)`,

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      flexDirection: "column-reverse",
      padding: theme.spacing.xs,
    },
  },

  wrapperRightImageBody: {
    paddingRight: `calc(${theme.spacing.xl} * 1)`,
    paddingLeft: 0,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      paddingRight: 0,
    },
  },

  image: {
    maxWidth: "40%",

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      maxWidth: "100%",
    },
  },

  body: {
    maxWidth: "60%",
    paddingLeft: `calc(${theme.spacing.xl} * 2)`,

    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      paddingLeft: 0,
      marginTop: theme.spacing.xl,
      maxWidth: "100%",
    },
  },

  title: {
    color: theme.colorScheme === "dark" ? theme.white : theme.black,
    fontFamily: `Greycliff CF, ${theme.fontFamily}`,
    lineHeight: 1,
    marginBottom: theme.spacing.md,
  },
  centerContent: {
    display: "flex",
    justifyContent: "center",
  },
}));

export function TabBenefits() {
  const { classes } = useStyles();
  return (
    <>
      <div className={classes.wrapper}>
        <Image src={ImageBenifit01} className={classes.image} />
        <div className={classes.body}>
          <Title className={classes.title} order={4}>
            MyDeal is One of Australia’s Fastest Growing Marketplaces
          </Title>
          <Text size="md" mb={5} color="dimmed">
            Founded in 2011, MyDeal.com.au is one of Australia’s leading online
            marketplaces dedicated to the home and garden. MyDeal.com.au
            provides Australian consumers with over 1 million lifestyle products
            across 3,500 departments.
          </Text>
          <Text size="md" color="dimmed">
            An award winning ecommerce group, MyDeal is a leader in retail
            innovation and business growth. It is Australia’s premier online
            destination for the home where millions of consumers come to shop
            online.
          </Text>
        </div>
      </div>
      <div className={`${classes.wrapper}`}>
        <div className={`${classes.wrapperRightImageBody} ${classes.body}`}>
          <Title className={classes.title} order={4}>
            Best-In-Class Support From Our Expert Team
          </Title>
          <Text size="md" color="dimmed">
            We understand that your time and money is precious and limited. With
            over 100 years of collective ecommerce experience, our experts will
            guide you through the onboarding process. Once you begin selling, we
            will continue to be dedicated to your growth. With MyDeal
            Marketplace, you have full control and flexibility over your
            shopfront
          </Text>
        </div>
        <Image src={ImageBenifit02} className={classes.image} />
      </div>
      <div className={classes.wrapper}>
        <Image src={ImageBenifit03} className={classes.image} />
        <div className={classes.body}>
          <Title className={classes.title} order={4}>
            Expand Your Business
          </Title>
          <Text size="md" color="dimmed">
            MyDeal opens up a new channel for you to increase your sales volume.
            Our platform has millions of highly engaged customers looking for
            the best value for money. By becoming a seller on MyDeal, you can
            expand your reach and expose your products to the masses.
          </Text>
        </div>
      </div>
    </>
  );
}
