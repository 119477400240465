import {
  Badge,
  Card,
  Flex,
  Group,
  Space,
  Title,
  Text,
  Button,
  createStyles,
  Stack,
} from "@mantine/core";
import { openConfirmModal } from "@mantine/modals";
import { useDataProvider, useNotification } from "@mydeal/core";
import {
  AuthenticatedForm,
  FormSubmitButton,
  FormTextField,
  IFocusableForm,
  IRefreshableForm,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import { securityService } from "@services";
import { IconCircleCheck, TablerIconsProps } from "@tabler/icons-react";
import {
  IMfaEnrollmentModel,
  MfaEnrollmentState,
  MfaSecurityLevel,
} from "@types";
import { ReactNode, useCallback, useMemo, useRef, useState } from "react";
import * as yup from "yup";

export interface IMfaMethodSetUpProps {
  displayName: string;
  shortName: string;
  type: string;
  description: string;
  icon: (props: TablerIconsProps) => JSX.Element;
  formSchema: yup.ObjectSchema<
    {},
    { [Property in keyof IMfaEnrollmentModel]: any }
  >;
  securityLevel: MfaSecurityLevel;
  inputControls?: (
    form: IFocusableForm<Partial<IMfaEnrollmentModel>>,
    disabled: boolean
  ) => ReactNode;
  confirmationControls?: ReactNode;
  submitInput: (
    data: IMfaEnrollmentModel,
    service: ReturnType<typeof securityService>
  ) => Promise<void>;
  submitConfirmation: (
    data: IMfaEnrollmentModel,
    service: ReturnType<typeof securityService>
  ) => Promise<void>;
}

const useStyles = createStyles((theme) => ({
  enabledMethodHeader: {
    backgroundColor: theme.colors.green[2],
  },
}));

export const MfaMethodSetUp = ({
  icon,
  displayName,
  shortName,
  type,
  description,
  formSchema,
  securityLevel,
  inputControls,
  confirmationControls,
  submitInput,
  submitConfirmation,
}: IMfaMethodSetUpProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _securityService = useMemo(
    () => securityService(dataProviderInstance),
    [dataProviderInstance]
  );

  const [enrollmentState, setEnrollmentState] = useState(
    MfaEnrollmentState.None
  );
  const validationContext = useRef({ enrollmentState });
  validationContext.current.enrollmentState = enrollmentState;

  const form = useFocusableForm<IMfaEnrollmentModel>({
    schema: formSchema,
    context: { enrollmentState },
    initialValues: {
      AuthenticatorName: "",
      AuthenticatorActive: false,
      ConfirmationCode: "",
    },
  });
  const formRef = useRef<IRefreshableForm>(null);
  const formValues = useMemo(() => form.getValues(), [form]);

  const fetchData = useCallback(async () => {
    var result = await _securityService.getAuthenticationFactor(type);
    if (result?.AuthenticatorActive === false) {
      setEnrollmentState(MfaEnrollmentState.ProvidingConfirmationCode);
    }
    return result;
  }, [_securityService, setEnrollmentState, type]);

  const refreshMfa = useCallback(() => {
    if (formRef.current) {
      formRef.current.refresh();
    }
  }, []);

  const { open: notify } = useNotification();
  const removeMfa = useCallback(async () => {
    openConfirmModal({
      title: (
        <Text fw="bold" fz="sm">
          Please Confirm
        </Text>
      ),
      children: (
        <Text size="sm">Are you sure you want to remove {shortName} MFA?</Text>
      ),
      labels: { confirm: "Yes", cancel: "No" },
      onConfirm: () => {
        _securityService
          .removeMfa(formValues.AuthenticatorId || "")
          .then((result: any) => {
            notify?.({
              type: "success",
              message: `${shortName} MFA removed.`,
              description: "",
            });
            setEnrollmentState(MfaEnrollmentState.None);
            refreshMfa();
          })
          .catch((err: any) => {
            const {
              response: {
                data: { ErrorMessage, title },
              },
            } = err;
            notify?.({
              type: "error",
              message:
                ErrorMessage ??
                "Unable to process the request. Please try again later.",
              description: ErrorMessage ?? title,
            });
          });
      },
    });
  }, [
    shortName,
    _securityService,
    formValues.AuthenticatorId,
    notify,
    setEnrollmentState,
    refreshMfa,
  ]);

  const submit = useCallback(
    async (data: IMfaEnrollmentModel) => {
      const previousState = enrollmentState;
      try {
        if (enrollmentState === MfaEnrollmentState.None && inputControls) {
          setEnrollmentState(MfaEnrollmentState.ProvidingInput);
        } else if (
          (enrollmentState === MfaEnrollmentState.None && !inputControls) ||
          enrollmentState === MfaEnrollmentState.ProvidingInput
        ) {
          setEnrollmentState(MfaEnrollmentState.SubmittingInput);
          await submitInput(data, _securityService);
          form.setFieldValue("AuthenticatorActive", false);
          setEnrollmentState(MfaEnrollmentState.ProvidingConfirmationCode);
          form.setFieldValue("ConfirmationCode", undefined);
        } else if (
          enrollmentState === MfaEnrollmentState.ProvidingConfirmationCode
        ) {
          setEnrollmentState(MfaEnrollmentState.SubmittingConfirmationCode);
          await submitConfirmation(data, _securityService);
          form.setFieldValue("AuthenticatorActive", false);
          setEnrollmentState(MfaEnrollmentState.None);
          refreshMfa();
        }
      } catch (e) {
        setEnrollmentState(previousState);
        throw e;
      }
    },
    [
      _securityService,
      enrollmentState,
      form,
      inputControls,
      refreshMfa,
      setEnrollmentState,
      submitConfirmation,
      submitInput,
    ]
  );

  const methodEnabled = formValues.AuthenticatorActive === true;

  const IconComponent = icon;
  return (
    <Card w="26rem" withBorder shadow="sm" mih="20rem">
      <Card.Section
        withBorder
        p="xs"
      >
        <Flex align="center" justify="space-between">
          <IconComponent size="2rem" />
          <Title order={6}>{displayName}</Title>
          {methodEnabled && <IconCircleCheck size="2rem" color="green" />}
          {!methodEnabled && <Space mx="1rem" />}
        </Flex>
      </Card.Section>
      <AuthenticatedForm
        dataProviderInstance={dataProviderInstance}
        form={form}
        fetchData={fetchData}
        onSubmit={submit}
        controlRef={formRef}
        readOnly
        justify="space-between"
        spacing="sm"
        mih="15rem"
      >
        {enrollmentState === MfaEnrollmentState.None && (
        <>
            <Group position="apart" mt="md" mb="xs">
              <Text size="sm">
                {description}
              </Text>
              <Badge
                color={securityLevel === MfaSecurityLevel.Best ? "green" : "yellow"}
                variant="light"
              >
                {MfaSecurityLevel[securityLevel].toString()}
              </Badge>
            </Group>
            {formValues.AuthenticatorActive !== true && (
              <FormSubmitButton fullWidth mt="md">
                Enable
              </FormSubmitButton>
              )}
            
            {methodEnabled && (
              <Button onClick={removeMfa} fullWidth mt="md">
                Remove
              </Button>
            )}
          </>
        )}
        {enrollmentState !== MfaEnrollmentState.None && inputControls && (
          <Stack>
            <Group grow={true} noWrap={true}>
              {inputControls(
                form,
                enrollmentState !== MfaEnrollmentState.ProvidingInput
              )}
              {enrollmentState !== MfaEnrollmentState.ProvidingInput && (
                <Button
                  mt="1.35rem"
                  sx={{ alignSelf: "start" }}
                  onClick={() => {
                    setEnrollmentState(MfaEnrollmentState.ProvidingInput);
                    form.setFieldValue("AuthenticatorName", "");
                    form.setFieldValue("AuthenticatorActive", undefined);
                    form.setFieldValue("ConfirmationCode", undefined);
                  }}
                >
                  Edit
                </Button>
              )}
            </Group>
            {(enrollmentState === MfaEnrollmentState.ProvidingInput ||
              enrollmentState === MfaEnrollmentState.SubmittingInput) && (
              <Group position="right">
                <Button
                  onClick={() => {
                    setEnrollmentState(MfaEnrollmentState.None);
                    form.setFieldValue("AuthenticatorName", "");
                    form.setFieldValue("AuthenticatorActive", undefined);
                    form.setFieldValue("ConfirmationCode", undefined);
                  }}
                >
                  Cancel
                </Button>
                <FormSubmitButton
                  loading={
                    enrollmentState === MfaEnrollmentState.SubmittingInput
                  }
                >
                  Send Code
                </FormSubmitButton>
              </Group>
            )}
          </Stack>
        )}
        {(enrollmentState === MfaEnrollmentState.ProvidingConfirmationCode ||
          enrollmentState ===
            MfaEnrollmentState.SubmittingConfirmationCode) && (
          <>
            {confirmationControls}
            <Group grow={true} noWrap={true}>
              <FormTextField
                label="6-digit Confirmation Code"
                fieldName="ConfirmationCode"
                parentForm={form}
                disabled={
                  enrollmentState !==
                  MfaEnrollmentState.ProvidingConfirmationCode
                }
              />
              <FormSubmitButton
                mt="1.35rem"
                sx={{ alignSelf: "start" }}
                loading={
                  enrollmentState ===
                  MfaEnrollmentState.SubmittingConfirmationCode
                }
              >
                Confirm
              </FormSubmitButton>
            </Group>
            <Button
              fullWidth
              onClick={() => {
                setEnrollmentState(MfaEnrollmentState.None);
                form.setFieldValue("AuthenticatorName", "");
                form.setFieldValue("AuthenticatorActive", undefined);
                form.setFieldValue("ConfirmationCode", undefined);
              }}
            >
              Cancel
            </Button>
          </>
        )}
      </AuthenticatedForm>
    </Card>
  );
};
