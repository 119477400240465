import { OnboardingStatusContext } from "@pages/onboarding";
import { useCallback, useContext } from "react";

export const useRefreshOnboardingProgress = () => {
  const onboardingStatusContext = useContext(OnboardingStatusContext);

  const refreshOnboardingProgress = useCallback(() => {
    onboardingStatusContext.setOnboardingStatusModel({
      ...onboardingStatusContext.onboardingStatusModel,
      Loaded: false,
    });
  }, [onboardingStatusContext]);

  return refreshOnboardingProgress;
};
