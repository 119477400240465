import { useMemo, useState } from "react";
import { Button, Group, Space, Text, LoadingOverlay, Alert} from "@mantine/core";
import { IconAlertCircle, IconCheck} from "@tabler/icons-react";
import { useDataProvider , useNotification, } from "@mydeal/core";
import { couponService } from "@services";

interface CancelCouponPromoModelProps {
  couponId: number;
  promotionalEventId : number;
  onClose: (reload?: boolean) => void;
}

export const CancleCouponPromoModel = ({
  onClose,
  couponId,
  promotionalEventId,
}: CancelCouponPromoModelProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _couponService = useMemo(
    () => couponService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(false);
  const { open: notify } = useNotification();

  const deleteCoupon = () => {
    setLoading(true);
    _couponService
      .removeCoupon(couponId, promotionalEventId)
      .then((result) => {
        notify?.({
            type: "success",
            message: "Coupon removed successfully.",
            description: "",
          });
      })
      .finally(() => {
        setLoading(false);
        onClose?.(true);
      });
  };


  return (
    <>
    <LoadingOverlay visible={loading} />
      <Space h="sm" />
      <Alert icon={<IconAlertCircle size="1rem" />} color="yellow">
      Are you sure you want to cancel this coupon promotion ?
    </Alert>
      <Space h="xl" />
      <Group>
        <Button
          onClick={deleteCoupon}
          size="md"
          variant="outline"
          leftIcon={<IconCheck size={16} />}
        >
          Yes
        </Button>
        <Button
          onClick={() => {
            onClose?.();
          }}
          size="md"
        >
          No
        </Button>
      </Group>
    </>
  );
};
