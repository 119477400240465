import { Badge, createStyles } from "@mantine/core";

type MarketplaceBadgeProps = {
    text: string,
    applyLeftMargin?: boolean
}

const useStyles = createStyles((theme, { applyLeftMargin }: MarketplaceBadgeProps) => ({
    badgeWrapper: {
        maxWidth: "5rem",
        display: "inline",
        marginLeft: applyLeftMargin ? '.25rem' : '',
    },
}));

const externalMarketplacesMap: Record<string, string> = {
    "bigw": "Big W",
    "bigwgreen": "Big W Green",
    "bigwblue": "Big W Blue"
};
const showMarketplaceBadge = (marketplace: string) => { return marketplace && marketplace.toLowerCase() in externalMarketplacesMap; };
const parseMarketplaceName = (marketplace: string) => { return showMarketplaceBadge(marketplace) ? externalMarketplacesMap[marketplace.toLowerCase()] : null; };

export const MarketplaceBadge = ({ text, applyLeftMargin }: MarketplaceBadgeProps) => {
    const { classes } = useStyles({ text, applyLeftMargin });
    return (showMarketplaceBadge(text) &&
        <div className={classes.badgeWrapper}>
            <Badge size="xs" variant="filled" color="indigo.9"> {parseMarketplaceName(text)}</Badge>
        </div>);
};