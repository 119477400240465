import React, { createContext, useCallback, useState } from "react";
import { IOnboardingStatus } from "./types";
import { useLocalStorage } from "@mantine/hooks";

export type OnboardingStatusContextType = {
  onboardingStatusModel: IOnboardingStatus;
  setOnboardingStatusModel: React.Dispatch<
    React.SetStateAction<IOnboardingStatus>
  >;
};

export type OnboardingStatusContextProps = {
  children: React.ReactNode;
};

export const OnboardingStatusContext =
  createContext<OnboardingStatusContextType>({} as OnboardingStatusContextType);

export const OnboardingStatusContextProvider = ({
  children,
}: OnboardingStatusContextProps) => {
  const [bypassOnboarding, setBypassOnboarding] = useLocalStorage({
    key: "bypass-onboarding",
    defaultValue: "false",
  });

  const [onboardingStatusModel, setOnboardingStatusModel] =
    useState<IOnboardingStatus>({
      Loaded: false,
      Bypass: bypassOnboarding === "true",
      HideShippingAssignment: false,
    });

  const onOnboardingStatusModelChange = useCallback<
    React.Dispatch<React.SetStateAction<IOnboardingStatus>>
  >(
    (value) => {
      setOnboardingStatusModel(value);
      const newValue =
        typeof value === "function" ? value(onboardingStatusModel) : value;
      setBypassOnboarding(newValue.Bypass ? "true" : "false");
    },
    [onboardingStatusModel, setBypassOnboarding]
  );

  return (
    <OnboardingStatusContext.Provider
      value={{
        onboardingStatusModel,
        setOnboardingStatusModel: onOnboardingStatusModelChange,
      }}
    >
      {children}
    </OnboardingStatusContext.Provider>
  );
};

export default OnboardingStatusContext;
