import { IShippingSettingsModel } from "@types";
import React, { createContext, useState } from "react";

export type ShippingSettingsContextType = {
  shippingSettingsModel: IShippingSettingsModel | null;
  setShippingSettingsModel: React.Dispatch<
    React.SetStateAction<IShippingSettingsModel | null>
  >;
};

export type ShippingSettingsContextProps = {
  children: React.ReactNode;
};

export const ShippingSettingsContext =
  createContext<ShippingSettingsContextType>({} as ShippingSettingsContextType);

export const ShippingSettingsContextProvider = ({
  children,
}: ShippingSettingsContextProps) => {
  const [shippingSettingsModel, setShippingSettingsModel] =
    useState<IShippingSettingsModel | null>(null);

  return (
    <ShippingSettingsContext.Provider
      value={{ shippingSettingsModel, setShippingSettingsModel }}
    >
      {children}
    </ShippingSettingsContext.Provider>
  );
};

export default ShippingSettingsContext;
