import React, { useCallback, useEffect, useMemo, useState } from "react";
import * as yup from "yup";
import {
  useDataProvider,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@mydeal/core";
import {
  FreightAPITemplate,
  FreightSchemaType,
  IFreightSchemeModel,
  StandardTemplate,
  FreightInstructionsUrl,
  TemplateInstructionsUrl,
  LimitedFreeShippingEligibility,
} from "./FreightForm.types";
import { shippingService } from "@services/shipping.service";
import {
  useFocusableForm,
  FormFileInput,
  FormSelect,
  FormTextArea,
  AuthenticatedForm,
} from "@mydeal/ui-mantine";
import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Divider,
  Group,
  SelectItem,
  Title,
  Text,
  Tooltip,
  Alert,
  List,
  Table,
  TextInput,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconArrowBackUp,
  IconDownload,
  IconExternalLink,
  IconInfoCircle,
  IconPaperclip,
  IconX,
} from "@tabler/icons-react";
import { ConfigAusPost } from "./ConfigAusPost";
import { ConfigHunterExpress } from "./ConfigHunterExpress";
import { ConfigNeto } from "./ConfigNeto";
import { ConfigSendle } from "./ConfigSendle";
import { ConfigShippit } from "./ConfigShippit";
import { useParams, useSearchParams } from "@mydeal/react-router";
import { Label, PageTitle } from "@components/shared";

const FreightFormSchema = yup.object<{
  [Property in keyof IFreightSchemeModel]: any;
}>({
  FreightSchemeType: yup.number().nullable().required("Required"),
  FreightType: yup.number().nullable().required("Required"),
  Description: yup.string().nullable().required("Required").max(30),
  ApiUrl: yup
    .string()
    .nullable()
    .when("FreightSchemeType", {
      is: FreightSchemaType.Freight_API,
      then: (schema) => schema.required("Required"),
    }),
  ApiUserName: yup
    .string()
    .nullable()
    .when(["APITemplateID"], {
      is: (apiTemplateID: FreightAPITemplate) => {
        return (
          apiTemplateID === FreightAPITemplate.HunterExpress ||
          apiTemplateID === FreightAPITemplate.Neto ||
          apiTemplateID === FreightAPITemplate.Sendle
        );
      },
      then: (schema) => schema.required("Required"),
    }),
  ApiPassword: yup
    .string()
    .nullable()
    .when("FreightSchemeType", {
      is: FreightSchemaType.Freight_API,
      then: (schema) => schema.required("Required"),
    }),
  RatetableFile: yup.mixed().when("FreightSchemeType", {
    is: FreightSchemaType.Shipping_Rate_Table,
    then: (schema) => schema.required("Required"),
  }),
  ShippingMethodName: yup
    .string()
    .nullable()
    .when("APITemplateID", {
      is: (apiTemplateID: FreightAPITemplate) => {
        return apiTemplateID === FreightAPITemplate.AusPost;
      },
      then: (schema) => schema.required("Required"),
    }),
  FromLocation: yup
    .string()
    .nullable()
    .when(["APITemplateID"], {
      is: (apiTemplateID: FreightAPITemplate) => {
        return (
          apiTemplateID === FreightAPITemplate.AusPost ||
          apiTemplateID === FreightAPITemplate.HunterExpress ||
          apiTemplateID === FreightAPITemplate.Sendle
        );
      },
      then: (schema) => schema.required("Required"),
    }),
  ContainerType: yup
    .string()
    .nullable()
    .when(["APITemplateID"], {
      is: (apiTemplateID: FreightAPITemplate) => {
        return apiTemplateID === FreightAPITemplate.HunterExpress;
      },
      then: (schema) => schema.required("Required"),
    }),
  LimitedFreeShippingEligibility: yup.string().nullable(),
});

export interface IFreightFormProps {
  onboarding: boolean;
}

export const FreightForm: React.FC<IFreightFormProps> = ({ onboarding }) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _shippingService = useMemo(
    () => shippingService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { push } = useNavigation();

  const [apiFreightTypeOptions, setApiFreightTypeOptions] = useState<any[]>([]);
  const [standardFreightTypeOptions, setStandardFreightTypeOptions] = useState<
    any[]
  >([]);
  const [viewInstructionUrl, setViewInstructionUrl] = useState<any>();
  const [standardTemplateUrl, setStandardTemplateUrl] = useState<any>();
  const [rateTableTemplateUrl, setRateTableTemplateUrl] = useState<any>();
  const [postcodeExclusionFileErrors, setPostcodeExclusionFileErrors] =
    useState<any>([]);
  const [postcodeExclusionsErrors, setPostcodeExclusionsErrors] = useState<any>(
    []
  );
  const [ratetableFileErrors, setRatetableFileErrors] = useState<any>([]);
  const [ratetableErrors, setRatetableErrors] = useState<any>([]);
  const [limitedFreeShippingLabel, setLimitedFreeShippingLabel] =
    useState<string>("-Automatically Determined-");

  const { Link, useLocation } = useRouterContext();
  const location = useLocation();
  const params = useParams();
  const [searchParams] = useSearchParams();
  const freightSchemeCompanyId =
    searchParams.get("freightSchemeCompanyID") || undefined;
  const { open: notify } = useNotification();

  const form = useFocusableForm<IFreightSchemeModel>({
    schema: FreightFormSchema,
  });

  useEffect(() => {
    // To prevent running the effect at first render to show existing data
    if (form.isDirty()) {
      setLimitedFreeShippingLabel("-Automatically Determined-");
    }
  }, [form.isDirty()]);

  const fetchData = useCallback(async () => {
    const result = await _shippingService.getFreightById(
      parseInt(params?.id ?? "0"),
      freightSchemeCompanyId ? parseInt(freightSchemeCompanyId) : undefined
    );
    if (result) {
      // populates api freight type options
      const apiOptions = result.freightTypes
        .filter((freightType) => freightType.IsAPITemplate)
        .map((freightType) => {
          return {
            value: freightType.ID?.toString(),
            label: freightType.Name,
            disabled: freightType.Disabled,
          } as SelectItem;
        });
      setApiFreightTypeOptions(apiOptions);

      // populates standard freight type options
      const standardOptions = result.freightTypes
        .filter((freightType) => freightType.IsStandardTemplate)
        .map((freightType) => {
          return {
            value: freightType.ID?.toString(),
            label: freightType.Name,
            disabled: freightType.Disabled,
          } as SelectItem;
        });
      setStandardFreightTypeOptions(standardOptions);

      if (
        result.model.FromSuburb &&
        result.model.FromState &&
        result.model.FromPostcode
      ) {
        result.model.FromLocation = `${result.model.FromSuburb} ${result.model.FromState} ${result.model.FromPostcode}`;
      }
      if (result.model.FreightSchemeType === FreightSchemaType.Freight_API) {
        handleInstructionUrl(result.model.FreightType);
      } else if (
        result.model.FreightSchemeType === FreightSchemaType.Shipping_Rate_Table
      ) {
        handleStandardTemplateUrl(result.model.FreightType);
      }

      handleLimitedFreeShippingLabel(
        result.model.LimitedFreeShippingEligibility
      );

      return result?.model;
    } else {
      //If result not found navigate to FreightListPage
      notify?.({
        type: "error",
        message: "Freight Scheme not found",
        description: "",
      });
      push(`${baseRoute}`);
    }
  }, [_shippingService, freightSchemeCompanyId, params?.id]);

  const runtimeConfig = window?.__RUNTIME_CONFIG__ || {};

  //TODO: export interface IDropdownOption in @mydeal/ui
  const freightSchemeTypes: SelectItem[] = [
    { value: FreightSchemaType.Freight_API.toString(), label: "Freight API" },
    {
      value: FreightSchemaType.Shipping_Rate_Table.toString(),
      label: "Shipping Rate Table",
    },
  ];

  const handleInstructionUrl = (value: any) => {
    switch (value) {
      case FreightAPITemplate.AusPost: {
        setViewInstructionUrl(FreightInstructionsUrl.AusPost);
        break;
      }
      case FreightAPITemplate.HunterExpress: {
        setViewInstructionUrl(FreightInstructionsUrl.HunterExpress);
        break;
      }
      case FreightAPITemplate.Sendle: {
        setViewInstructionUrl(FreightInstructionsUrl.Sendle);
        break;
      }
      case FreightAPITemplate.Shippit: {
        setViewInstructionUrl(FreightInstructionsUrl.Shippit);
        break;
      }
      case FreightAPITemplate.Neto: {
        setViewInstructionUrl(FreightInstructionsUrl.Neto);
        break;
      }
      default: {
        setViewInstructionUrl("");
        break;
      }
    }
  };

  const handleStandardTemplateUrl = (value: any) => {
    switch (value) {
      case StandardTemplate.MyDealZones_Table: {
        setStandardTemplateUrl(TemplateInstructionsUrl.MyDealZones);
        setRateTableTemplateUrl(
          `https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/ratetables/MyDealZonesShippingTable_Template.csv`
        );
        break;
      }
      case StandardTemplate.MyDealZones_Weight_Table: {
        setStandardTemplateUrl(TemplateInstructionsUrl.MyDealZones_Weight);
        setRateTableTemplateUrl(
          `https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/ratetables/MyDealZoneWeightBasedShipping_Template.csv`
        );
        break;
      }
      case StandardTemplate.MyDealZones_Price_Table: {
        setStandardTemplateUrl(TemplateInstructionsUrl.MyDealZones_Price);
        setRateTableTemplateUrl(
          `https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/ratetables/MyDealZonePriceShippingTable_Template.csv`
        );
        break;
      }
      case StandardTemplate.Postcode_Table: {
        setStandardTemplateUrl(TemplateInstructionsUrl.Postcode);
        setRateTableTemplateUrl(
          `https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/ratetables/MyDealPostcodeShippingTable_Template.csv`
        );
        break;
      }
      case StandardTemplate.PostcodeWeight_Table: {
        setStandardTemplateUrl(TemplateInstructionsUrl.PostcodeWeight);
        setRateTableTemplateUrl(
          `https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/ratetables/PostcodeWeightShippingTable_Template.csv`
        );
        break;
      }
      default: {
        setStandardTemplateUrl("");
        break;
      }
    }
  };

  const handleLimitedFreeShippingLabel = (value: any) => {
    switch (value) {
      case LimitedFreeShippingEligibility.Deal_Level:
        setLimitedFreeShippingLabel("Deal Level");
        break;
      case LimitedFreeShippingEligibility.True:
        setLimitedFreeShippingLabel("True");
        break;
      case LimitedFreeShippingEligibility.False:
        setLimitedFreeShippingLabel("False");
        break;
      default:
        setLimitedFreeShippingLabel("-Automatically Determined-");
    }
  };

  const segments = location.pathname.split("/");
  const rootRoute = segments.slice(0, 2).join("/");
  const baseRoute = segments.slice(0, 3).join("/");

  const onSubmit = useCallback(
    async (data: any) => {
      return await new Promise((resolve, reject) => {
        var result = _shippingService
          .addFreight(data)
          .then(resolve)
          .catch((err) => {
            var errorMessage = "One or more validation errors occurred.";
            if (err.response) {
              var data = err.response.data;
              if (data) {
                if (
                  data.ratetableFileErrors &&
                  data.ratetableFileErrors.length > 0
                ) {
                  setRatetableFileErrors(data.ratetableFileErrors);
                  errorMessage = "Please upload a valid file.";
                  form.setFieldValue("RatetableFile", null);
                }
                if (data.RatetableErrors) {
                  setRatetableErrors(data.RatetableErrors);
                }
                if (data.postcodeExclusionFileErrors) {
                  setPostcodeExclusionFileErrors(
                    data.postcodeExclusionFileErrors
                  );
                  errorMessage = "Please upload a valid file.";
                  form.setFieldValue("PostcodeExclusionFile", null);
                }
                if (data.PostcodeExclusionsErrors) {
                  setPostcodeExclusionsErrors(data.PostcodeExclusionsErrors);
                }
              }
            }
            reject({
              statusCode: 400,
              response: {
                data: { errors: [], title: errorMessage },
              },
            });
          });
      });
    },
    [_shippingService]
  );

  const afterSubmit = useCallback(() => {
    push(baseRoute);
  }, [baseRoute, push]);

  const formValues = form.getValues();
  const isNew = (formValues.FreightSchemeID || 0) === 0;

  return (
    <>
      <>
        <PageTitle
          title="Manage Freight Schemes"
          description={
            <>
              <Anchor
                href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10009659355023"
                target="_blank"
                className="font-weight-bold"
              >
                Learn more about setting up freight schemes
                <IconExternalLink size={16} />
              </Anchor>
            </>
          }
          breadcrumbs={
            !onboarding
              ? [
                  { name: "Home", route: "/dashboard" },
                  { name: "Shipping Settings", route: rootRoute },
                  {
                    name: "Manage Freight Schemes",
                    route: baseRoute,
                  },
                  {
                    name: isNew ? "Add Freight Scheme" : "Edit Freight Scheme",
                  },
                ]
              : undefined
          }
          actions={
            <Anchor component={Link} to={baseRoute}>
              <IconArrowBackUp /> Back to Freight Scheme list
            </Anchor>
          }
        />
        <Card withBorder mih={300}>
          <Card.Section py="sm" px="xl">
            <Title order={5} mb="xs">
              {isNew ? "Add a new Freight Scheme" : "Edit your Freight Scheme"}
            </Title>
            <Divider />
          </Card.Section>
          <Card.Section py="sm" px="xl">
            <AuthenticatedForm
              dataProviderInstance={dataProviderInstance}
              form={form}
              fetchData={fetchData}
              onSubmit={onSubmit}
              afterSubmit={afterSubmit}
            >
              <FormSelect
                label="Freight Scheme:"
                withAsterisk
                data={freightSchemeTypes}
                placeholder="-Select-"
                numericValue
                parentForm={form}
                fieldName="FreightSchemeType"
                disabled={!isNew}
                onChange={(value: any) => {
                  if (value === FreightSchemaType.Freight_API) {
                    form.setFieldValue("TemplateID", StandardTemplate.None);
                  } else {
                    form.setFieldValue(
                      "APITemplateID",
                      FreightAPITemplate.None
                    );
                  }
                  form.setFieldValue("FreightType", undefined);
                }}
              />

              {formValues.FreightSchemeType ===
                FreightSchemaType.Freight_API && (
                <>
                  <FormSelect
                    label="Option:"
                    data={apiFreightTypeOptions}
                    withAsterisk
                    placeholder="-Select-"
                    numericValue
                    parentForm={form}
                    fieldName="FreightType"
                    disabled={!isNew}
                    onChange={(value: any) => {
                      handleInstructionUrl(value);
                      if (value) {
                        form.setFieldValue("APITemplateID", value);
                        form.setFieldValue("TemplateID", StandardTemplate.None);
                      } else {
                        form.setFieldValue("TemplateID", StandardTemplate.None);
                        form.setFieldValue(
                          "APITemplateID",
                          FreightAPITemplate.None
                        );
                        form.setFieldValue("FreightSchemeID", 0);
                      }
                    }}
                  />
                  <Anchor href={viewInstructionUrl} target="_blank">
                    View Instructions
                    <IconExternalLink size={16} />
                  </Anchor>
                </>
              )}

              {formValues.FreightSchemeType ===
                FreightSchemaType.Shipping_Rate_Table && (
                <>
                  <FormSelect
                    label="Option:"
                    data={standardFreightTypeOptions}
                    withAsterisk
                    placeholder="-Select-"
                    numericValue
                    disabled={!isNew}
                    parentForm={form}
                    fieldName="FreightType"
                    onChange={(value: any) => {
                      handleStandardTemplateUrl(value);
                      if (value) {
                        form.setFieldValue("TemplateID", value);
                        form.setFieldValue(
                          "APITemplateID",
                          FreightAPITemplate.None
                        );
                      } else {
                        form.setFieldValue("TemplateID", StandardTemplate.None);
                        form.setFieldValue(
                          "APITemplateID",
                          FreightAPITemplate.None
                        );
                        form.setFieldValue("FreightSchemeID", 0);
                      }
                    }}
                  />
                  <Anchor href={standardTemplateUrl} target="_blank">
                    View Instructions
                    <IconExternalLink size={16} />
                  </Anchor>
                </>
              )}
              <FormTextArea
                label={
                  <Group spacing="xs">
                    <Text span>
                      Description:{" "}
                      <Text span color="red">
                        *
                      </Text>
                    </Text>
                    <Tooltip
                      multiline
                      width={250}
                      withArrow
                      label="Enter a short description to help you identify the freight scheme"
                    >
                      <div>
                        <IconInfoCircle size={14} />
                      </div>
                    </Tooltip>
                  </Group>
                }
                placeholder="_30 character max_"
                fieldName="Description"
                parentForm={form}
              />

              {formValues.FreightSchemeType ===
                FreightSchemaType.Shipping_Rate_Table &&
                (formValues.TemplateID ===
                  StandardTemplate.MyDealZones_Weight_Table ||
                  formValues.TemplateID ===
                    StandardTemplate.PostcodeWeight_Table) && (
                  <Checkbox
                    label={
                      <Group spacing="xs">
                        <Text span>Combined Shipping Cost:</Text>
                        <Tooltip
                          multiline
                          width={250}
                          withArrow
                          label="Tick this option when shipping cost can be combined for multiple quantity purchases of the same product"
                        >
                          <div>
                            <IconInfoCircle size={14} />
                          </div>
                        </Tooltip>
                      </Group>
                    }
                    {...form.getInputProps("SupportsQty", {
                      type: "checkbox",
                    })}
                  />
                )}

              {formValues.FreightSchemeType === FreightSchemaType.Freight_API &&
                formValues.FreightType === FreightAPITemplate.AusPost && (
                  <ConfigAusPost parentForm={form} />
                )}

              {formValues.FreightSchemeType === FreightSchemaType.Freight_API &&
                formValues.FreightType === FreightAPITemplate.HunterExpress && (
                  <ConfigHunterExpress parentForm={form} />
                )}

              {formValues.FreightSchemeType === FreightSchemaType.Freight_API &&
                formValues.FreightType === FreightAPITemplate.Neto && (
                  <ConfigNeto parentForm={form} />
                )}

              {formValues.FreightSchemeType === FreightSchemaType.Freight_API &&
                formValues.FreightType === FreightAPITemplate.Sendle && (
                  <ConfigSendle parentForm={form} />
                )}

              {formValues.FreightSchemeType === FreightSchemaType.Freight_API &&
                formValues.FreightType === FreightAPITemplate.Shippit && (
                  <ConfigShippit parentForm={form} />
                )}

              {formValues.FreightSchemeType ===
                FreightSchemaType.Shipping_Rate_Table && (
                <Card withBorder>
                  <Card.Section
                    p="sm"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[7]
                          : theme.colors.gray[0],
                    })}
                  >
                    <Group position="apart">
                      <div className="flex-grow-1">
                        <Label required>Rate Table:</Label>
                      </div>
                      <div>
                        <Anchor href={rateTableTemplateUrl}>
                          <IconDownload size={16} /> Sample template
                        </Anchor>
                        {!isNew && (
                          <Button
                            ml="xs"
                            onClick={() =>
                              _shippingService.downloadRateTableFile(
                                formValues.FreightSchemeID || 0,
                                formValues.TemplateID || 0
                              )
                            }
                          >
                            <IconDownload size={16} />
                            Download
                          </Button>
                        )}
                      </div>
                    </Group>
                  </Card.Section>
                  <Card.Section>
                    <FormFileInput
                      placeholder="Choose file"
                      clearable
                      icon={<IconPaperclip size={14} />}
                      multiple
                      size="sm"
                      accept=".csv"
                      style={{ backgroundColor: "gray.0" }}
                      fieldName="RatetableFile"
                      parentForm={form}
                    />
                  </Card.Section>
                </Card>
              )}

              <Card withBorder>
                <Card.Section
                  p="sm"
                  sx={(theme) => ({
                    backgroundColor:
                      theme.colorScheme === "dark"
                        ? theme.colors.dark[7]
                        : theme.colors.gray[0],
                  })}
                >
                  <Group position="apart">
                    <div className="flex-grow-1">
                      <Label>Postcode Exclusions:</Label>
                    </div>
                    <div>
                      <Anchor
                        href={`https://${runtimeConfig.REACT_APP_CDN_HOSTNAME}/content/marketplace/ratetables/PostcodeExclusion_Template.csv`}
                      >
                        <IconDownload size={16} /> Sample template
                      </Anchor>
                      {!isNew &&
                        (formValues.PostcodeExclusionGroupID || 0) > 0 && (
                          <>
                            <Button
                              ml="xs"
                              onClick={() =>
                                _shippingService.downloadPostcodeFile(
                                  formValues.PostcodeExclusionGroupID || 0
                                )
                              }
                            >
                              <IconDownload size={16} />
                              Download
                            </Button>
                            <Button
                              ml="xs"
                              onClick={() => {
                                _shippingService.deletePostcodeFile(
                                  formValues.FreightSchemeID || 0,
                                  formValues.PostcodeExclusionGroupID || 0
                                );
                                form.setFieldValue(
                                  "PostcodeExclusionGroupID",
                                  undefined
                                );
                              }}
                            >
                              <IconX size={16} />
                              Delete
                            </Button>
                          </>
                        )}
                    </div>
                  </Group>
                </Card.Section>
                <Card.Section>
                  <FormFileInput
                    placeholder="Choose file"
                    clearable
                    icon={<IconPaperclip size={14} />}
                    multiple
                    size="sm"
                    accept=".csv"
                    fieldName="PostcodeExclusionFile"
                    parentForm={form}
                  />
                </Card.Section>
              </Card>
              <TextInput
                label={
                  <Group spacing="xs">
                    <Text span>Limited Free Shipping Eligibility: </Text>
                    <Tooltip
                      multiline
                      width={250}
                      withArrow
                      label="Determined automatically for supported freight calculator"
                    >
                      <div>
                        <IconInfoCircle size={14} />
                      </div>
                    </Tooltip>
                  </Group>
                }
                disabled={true}
                value={limitedFreeShippingLabel}
              />
              {ratetableFileErrors && ratetableFileErrors.length > 0 && (
                <Card>
                  <Card.Section>
                    <Label>Rate Table Errors:</Label>
                  </Card.Section>
                  <Card.Section p="sm">
                    <List>
                      {ratetableFileErrors &&
                        ratetableFileErrors.map((item: any, index: number) => (
                          <List.Item key={index} style={{ color: "red" }}>
                            {item}
                          </List.Item>
                        ))}
                    </List>
                  </Card.Section>
                  <Card.Section p="sm">
                    {ratetableErrors && ratetableErrors.length > 0 && (
                      <Table withBorder withColumnBorders striped>
                        <thead>
                          <tr className="bg-light">
                            <th>Row</th>
                            <th>Error message</th>
                          </tr>
                        </thead>
                        <tbody>
                          {ratetableErrors.map(
                            (fileError: any, index: number) => (
                              <tr key={index}>
                                <td>{fileError.RowNo}</td>
                                <td>{fileError.Message}</td>
                              </tr>
                            )
                          )}
                        </tbody>
                      </Table>
                    )}
                  </Card.Section>
                </Card>
              )}
              {postcodeExclusionFileErrors &&
                postcodeExclusionFileErrors.length > 0 && (
                  <Card>
                    <Card.Section>
                      <Label>Postcode Exclusion Errors:</Label>
                    </Card.Section>
                    <Card.Section p="sm">
                      <List>
                        {postcodeExclusionFileErrors &&
                          postcodeExclusionFileErrors.map(
                            (item: any, index: number) => (
                              <List.Item key={index} style={{ color: "red" }}>
                                {item}
                              </List.Item>
                            )
                          )}
                      </List>
                    </Card.Section>
                    <Card.Section p="sm">
                      {postcodeExclusionsErrors &&
                        postcodeExclusionsErrors.length > 0 && (
                          <Table withBorder withColumnBorders striped>
                            <thead>
                              <tr className="bg-light">
                                <th>Row</th>
                                <th>Error message</th>
                              </tr>
                            </thead>
                            <tbody>
                              {postcodeExclusionsErrors.map(
                                (fileError: any, index: number) => (
                                  <tr key={index}>
                                    <td>{fileError.RowNo}</td>
                                    <td>{fileError.Message}</td>
                                  </tr>
                                )
                              )}
                            </tbody>
                          </Table>
                        )}
                    </Card.Section>
                  </Card>
                )}
              <Card>
                <Alert
                  color="yellow"
                  title="NOTE:"
                  icon={<IconAlertCircle size={16} />}
                >
                  Updating this freight scheme will immediately affect all
                  products assigned to this freight scheme.
                </Alert>
              </Card>
            </AuthenticatedForm>
          </Card.Section>
        </Card>
      </>
    </>
  );
};
