import { AppShell, Container } from "@mantine/core";
import { useContext, useState } from "react";
import { Footer } from "./footer";
import { Header } from "./header";

import { SideNav } from "./sidenav";
import OnboardingStatusContext from "@pages/onboarding/onboardingStatusContext";
import { OnboardingState } from "@pages/onboarding/types";
import { OnboardingNavbar } from "./sidenav/OnboardingNavbar";
import { Outlet } from "@mydeal/react-router";

export const Layout = ({ children }: any) => {
  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  const onboardingStatusContext = useContext(OnboardingStatusContext);

  const loading = !onboardingStatusContext.onboardingStatusModel.Loaded;

  const onboardingCompleted =
    onboardingStatusContext.onboardingStatusModel?.OnboardingState ===
      OnboardingState.Completed ||
    onboardingStatusContext.onboardingStatusModel.Bypass;

  return (
    <>
      <AppShell
        navbarOffsetBreakpoint="sm"
        padding="md"
        header={<Header toggleOpen={toggleOpen} opened={opened} />}
        navbar={
          loading ? undefined : onboardingCompleted ? (
            <SideNav opened={opened} />
          ) : (
            <OnboardingNavbar opened={opened} />
          )
        }
        footer={<Footer></Footer>}
        styles={(theme) => ({
          main: {
            minHeight: "calc(100vh - 80px)",
            backgroundColor:
              theme.colorScheme === "dark"
                ? theme.colors.dark[8]
                : theme.colors.gray[0],
          },
        })}
      >
        <Container size="xl">
          <Outlet />
        </Container>
      </AppShell>
    </>
  );
};
