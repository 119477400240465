import { useState, useRef, useMemo, useCallback } from "react";

import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
  useRouterContext,
} from "@mydeal/core";
import {
  Alert,
  Anchor,
  Grid,
  Stack,
  Title,
  Image,
  LoadingOverlay,
  Text,
  Button,
  Group,
  Flex,
  createStyles,
  ActionIcon,
  Modal,
  Divider,
  HoverCard,
  Card,
  Space,
} from "@mantine/core";
import { Dropzone, MIME_TYPES } from "@mantine/dropzone";
import {
  IconAlertCircle,
  IconCloudUpload,
  IconDownload,
  IconInfoCircle,
  IconX,
} from "@tabler/icons-react";
import { clientsService, dashboardService } from "@services";
import { IDashboardModel } from "@types";
import { DashboardCard, ActionCard } from "@components/shared";
import { DashboardWidget } from "./DashboardWidget";
import { Search } from "./Search";
import svgBronzeSeller from "../../assets/icons/seller-score/icon-bronze-seller.svg";
import svgSilverSeller from "../../assets/icons/seller-score/icon-silver-seller.svg";
import svgGoldSeller from "../../assets/icons/seller-score/icon-gold-seller.svg";
import svgPlatinumSeller from "../../assets/icons/seller-score/icon-platinum-seller.svg";
import { ImportShipmentModel } from "./ImportShipmentModel";
import moment from "moment";
import { useApiErrorHandler } from "@mydeal/ui-mantine";
import { useRollbar } from "@rollbar/react";

const useStyles = createStyles((theme) => ({
  dropzone: {
    paddingTop: 15,
    padingRight: 15,
    paddingBottom: 30,
    paddingLeft: 15,
    marginBottom: 10,
  },
  underlineText: {
    color: "black",
    textDecoration: "underline",
  },
  alertBulletsRoot: {
    margin: "0",
  },
}));

export const Dashboard = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _dashboardService = useMemo(
    () => dashboardService(dataProviderInstance),
    [dataProviderInstance]
  );
  const _clientsService = useMemo(
    () => clientsService(dataProviderInstance),
    [dataProviderInstance]
  );

  const { open: notify } = useNotification();
  const [homeModel, setHomeModel] = useState<IDashboardModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const { Link } = useRouterContext();
  const openRef = useRef<() => void>(null);
  const [importSourceFile, setImportSourceFile] = useState<any>(null);
  const [openImportShipmentModel, setOpenImportShipmentModel] =
    useState<boolean>(false);

  const apiErrorHandler = useApiErrorHandler(notify);

  useAuthenticatedApi(dataProviderInstance, () => {
    const fetchData = async () => {
      setLoading(true);
      await _dashboardService
        .loadDashboard()
        .then((result: any) => setHomeModel(result))
        .finally(() => {
          setLoading(false);
        });
    };

    fetchData();
  });

  const { classes } = useStyles();

  var sellerScore = useMemo(() => {
    var sellerScoreType = "";
    var sellerScoreImage = "";
    if (homeModel?.SellerScore) {
      if (homeModel.SellerScore >= 96) {
        sellerScoreType = "Platinum";
        sellerScoreImage = svgPlatinumSeller;
      } else if (homeModel.SellerScore >= 93) {
        sellerScoreType = "Gold";
        sellerScoreImage = svgGoldSeller;
      } else if (homeModel.SellerScore >= 90) {
        sellerScoreType = "Silver";
        sellerScoreImage = svgSilverSeller;
      } else if (homeModel.SellerScore >= 87) {
        sellerScoreType = "Bronze";
        sellerScoreImage = svgBronzeSeller;
      }
    }
    return {
      value: homeModel?.SellerScore,
      type: sellerScoreType,
      image: sellerScoreImage,
    };
  }, [homeModel?.SellerScore]);

  const downloadFile = useCallback(
    (IsPosted: string) => {
      var DealID = -1111;
      var onlyFulfilled = "False";

      var url = "ClientList/CsvDownload?";

      url += "DealID=" + DealID;
      url += "&";
      url += "IsPosted=" + IsPosted;
      url += "&";
      url += "onlyFulfilled=" + onlyFulfilled;

      setLoading(true);
      _clientsService
        .downloadList(url)
        .then((result) => {
          notify?.({
            type: "success",
            message:
              result?.data?.SuccessMessage ||
              result?.data?.Message ||
              "Export successful",
            description: "",
          });
        })
        .catch(apiErrorHandler)
        .finally(() => setLoading(false));
    },
    [_clientsService, apiErrorHandler, notify]
  );

  const onImportShipments = useCallback(
    (file: any) => {
      setOpenImportShipmentModel(true);
      setImportSourceFile(file);
    },
    [setImportSourceFile, setOpenImportShipmentModel]
  );

  const toggleImportShipmentModel = useCallback(
    (reload?: boolean) => {
      setOpenImportShipmentModel(!setOpenImportShipmentModel);
    },
    [setOpenImportShipmentModel]
  );

  const onCloseImportModal = useCallback(() => {
    setOpenImportShipmentModel(false);
    setImportSourceFile(null);
  }, [setOpenImportShipmentModel]);

  const rollbar = useRollbar();

  return (
    <>
      <Stack spacing="xl" mb="xl">
        <LoadingOverlay visible={loading} />
        <Title order={1}>Welcome to your MyDeal Shop</Title>
        {/* <Breadcrumbs fz={14}>
            <Anchor>Home</Anchor>
            <Anchor>Dashboard</Anchor>
          </Breadcrumbs> */}

        {homeModel && (
          <>
            {homeModel.LastProductSyncAt != null &&
              homeModel.ProductSyncFrequency != null && (
                <Grid columns={12}>
                  <Grid.Col span={12}>
                    <Alert
                      icon={<IconAlertCircle size={16} />}
                      title=""
                      color="yellow"
                    >
                      Your next product sync is scheduled in{" "}
                      {homeModel.ProductSyncFrequency} days. (Last synced:{" "}
                      {moment(homeModel.LastProductSyncAt).format("DD/MM/YYYY")}
                      )
                    </Alert>
                  </Grid.Col>
                </Grid>
              )}

            <Grid data-testid="home grid">
              <Grid.Col md={6} lg={4}>
                <ActionCard
                  header="My Seller Profile"
                  title={homeModel.CompanyName}
                >
                  {homeModel?.CompanyCategoryImage && (
                    <img
                      src={homeModel.CompanyCategoryImage}
                      alt=""
                      height="fit-content"
                      width={30}
                    />
                  )}
                  <Group spacing="sm">
                    {sellerScore.type && sellerScore.image && (
                      <Image
                        src={sellerScore.image}
                        alt={sellerScore.type}
                        width={25}
                      />
                    )}
                    <Stack spacing={0} mb="xs">
                      <Group h={22} spacing={0}>
                        {sellerScore.type && (
                          <span className="seller-title">
                            {sellerScore.type} Seller
                          </span>
                        )}
                        {!sellerScore.type && (
                          <span className="seller-title">Seller Rating</span>
                        )}
                        <HoverCard
                          position="top"
                          width={230}
                          withArrow
                          transitionProps={{
                            transition: "fade",
                            duration: 200,
                          }}
                          withinPortal
                        >
                          <HoverCard.Target>
                            <ActionIcon>
                              <IconInfoCircle size={14} />
                            </ActionIcon>
                          </HoverCard.Target>
                          <HoverCard.Dropdown>
                            {!sellerScore.type ? (
                              <Text>
                                Seller has done less than 20 sales in last 2
                                months or could be a new seller
                              </Text>
                            ) : (
                              <Text>
                                A seller's rating is an overall score calculated
                                from performance metrics.{"  "}
                                <a
                                  target="_blank"
                                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/9969592707727"
                                  rel="noreferrer"
                                >
                                  Learn More
                                </a>
                              </Text>
                            )}
                          </HoverCard.Dropdown>
                        </HoverCard>
                      </Group>

                      <Group>
                        {!sellerScore.value && (
                          <Text
                            fz={18}
                            weight={600}
                            className="seller-score-value"
                          >
                            N/A
                          </Text>
                        )}
                        {sellerScore.value && (
                          <Text
                            fz={18}
                            weight={600}
                            className="seller-score-value"
                          >
                            {sellerScore.value?.toFixed(2)}%
                          </Text>
                        )}
                      </Group>
                    </Stack>
                  </Group>
                  <Anchor
                    href={homeModel.CompanyProfileUrl}
                    target="_blank"
                    size={14}
                  >
                    View Your Profile Page
                  </Anchor>
                </ActionCard>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <ActionCard
                  header="Monthly Sales"
                  title="Earnings last 30 days: "
                  description={"$" + homeModel.EarningsLast30Days?.toFixed(2)}
                ></ActionCard>
              </Grid.Col>

              <Grid.Col md={6} lg={4}>
                <ActionCard header="Coupon Promotions">
                  <Text size="sm" color="dimmed">
                    Click{" "}
                    <Anchor component={Link} to="/coupon" size={14}>
                      here
                    </Anchor>{" "}
                    to create or manage your coupon promotion
                  </Text>
                </ActionCard>
              </Grid.Col>
            </Grid>

            <Search />

            <Stack>
              <Title order={3} mb="xs">
                Deals Quick View
              </Title>
              {homeModel.HasProduct &&
              homeModel.TotalPendingOrderCount === 0 &&
              homeModel.TotalUnusedVoucherCount === 0 ? (
                <div>
                  <Alert
                    icon={<IconAlertCircle size={16} />}
                    title=""
                    color="yellow"
                  >
                    <strong>Note:</strong> No deals created yet.
                  </Alert>
                </div>
              ) : (
                <Card withBorder mih={300} p="md">
                  <DashboardWidget title="Overview">
                    <Grid grow data-testid="home deals-quick-view overview">
                      <Grid.Col xs={4} md={3}>
                        <DashboardCard title="Total Pending Orders">
                          <Text size="30px" weight={500}>
                            {homeModel?.TotalPendingOrderCount}
                          </Text>
                        </DashboardCard>
                      </Grid.Col>
                      <Grid.Col xs={4} md={3}>
                        <DashboardCard title="Export All Unfulfilled">
                          <Stack align="center">
                            <Button
                              radius="xl"
                              size="xs"
                              onClick={() => {
                                downloadFile("True");
                              }}
                            >
                              Export
                            </Button>
                          </Stack>
                        </DashboardCard>
                      </Grid.Col>
                      <Grid.Col xs={4} md={6}>
                        <DashboardCard title="Import Tracking CSV File">
                          <Stack>
                            <div>
                              <Dropzone
                                openRef={openRef}
                                onDrop={(files) => {
                                  onImportShipments({ importFile: files[0] });
                                }}
                                radius="md"
                                accept={[MIME_TYPES.csv]}
                                maxSize={30 * 1024 ** 2}
                                className={classes.dropzone}
                                multiple={false}
                              >
                                <div style={{ pointerEvents: "none" }}>
                                  <Group position="center">
                                    <Dropzone.Accept>
                                      <IconDownload size={20} stroke={1} />
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                      <IconX size={20} stroke={1} />
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                      <IconCloudUpload size={20} stroke={1} />
                                    </Dropzone.Idle>
                                  </Group>

                                  <Text
                                    align="center"
                                    weight={600}
                                    size="sm"
                                    mt="l"
                                  >
                                    <Dropzone.Accept>
                                      Drop files here
                                    </Dropzone.Accept>
                                    <Dropzone.Reject>
                                      CSV file less than 30mb
                                    </Dropzone.Reject>
                                    <Dropzone.Idle>
                                      Upload Tracking CSV
                                    </Dropzone.Idle>
                                  </Text>

                                  <Text
                                    align="center"
                                    size="xs"
                                    mt="xs"
                                    color="dimmed"
                                  >
                                    Drag&apos;n&apos;drop files here to upload.
                                    We can accept only <i>.csv</i> files that
                                    are less than 30mb in size.
                                  </Text>
                                  <Flex
                                    mih={40}
                                    gap="md"
                                    justify="center"
                                    align="flex-end"
                                    mb={-10}
                                  >
                                    <Button
                                      size="xs"
                                      radius="xl"
                                      onClick={() => openRef.current?.()}
                                    >
                                      Select files
                                    </Button>
                                  </Flex>
                                </div>
                              </Dropzone>
                            </div>
                            <Alert
                              icon={<IconAlertCircle size={12} />}
                              title=""
                              color="gray"
                            >
                              <strong> Note:</strong> Tracking/Courier details
                              are visible to customers. Supplier notes are
                              private.
                            </Alert>
                          </Stack>
                        </DashboardCard>
                      </Grid.Col>
                    </Grid>
                  </DashboardWidget>
                  <Space h="sm" />
                  <Text size="sm" color="dimmed">
                    <Anchor href="/Mydeals?show=orders" size={14}>
                      Click here{" "}
                    </Anchor>
                    to view a list of your Active Deals with Pending Orders
                  </Text>
                </Card>
              )}
            </Stack>
          </>
        )}

        <Alert color="blue" p="lg">
          <Title order={3} size="sm" color="dimmed">
            Need some assistance? Please{" "}
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
              target="_blank"
            >
              contact us{" "}
            </Anchor>{" "}
            and we'll get back to you asap!
          </Title>
        </Alert>
        {/* {<div>{importFile}</div>} */}
        {/* Import result model */}
        <Modal
          keepMounted
          withinPortal
          opened={openImportShipmentModel}
          size="80%"
          padding="lg"
          onClose={onCloseImportModal}
          closeOnClickOutside={false}
          title={
            <Title order={3} fw={700}>
              Import Shipments
            </Title>
          }
        >
          <Divider my="sm" />
          <ImportShipmentModel
            importSourceFile={importSourceFile}
            onClose={toggleImportShipmentModel}
          />
        </Modal>
      </Stack>
    </>
  );
};
