import { useMemo } from "react";
import * as yup from "yup";

import {
  Card,
  Title,
  UnstyledButton,
  Group,
  Text,
  Divider,
  createStyles,
  Switch,
} from "@mantine/core";
import { PageTitle } from "@components";
import { useDataProvider, useRouterContext } from "@mydeal/core";
import { accountService } from "@services";
import { IShippingSettingsModel } from "@types";
import {
  AuthenticatedForm,
  FormSwitch,
  useFocusableForm,
} from "@mydeal/ui-mantine";

const useStyles = createStyles((theme) => ({
  link: {
    "&:hover": {
      ".item-text *": { color: theme.colors.brand[6] },
    },
  },
}));

export const ShippingSettings = () => {
  const { classes } = useStyles();
  const { Link } = useRouterContext();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<IShippingSettingsModel>({
    schema: yup.object(),
  });

  const formValues = form.getValues();

  return (
    <>
      <PageTitle
        title="Shipping Settings"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Shipping Settings" },
        ]}
      />
      <Card withBorder mih={300}>
        <Card.Section py="sm" px="xl" inheritPadding>
          <AuthenticatedForm
            dataProviderInstance={dataProviderInstance}
            form={form}
            fetchData={_accountService.loadShippingSettings}
            readOnly
          >
            <UnstyledButton
              component={Link}
              to="/shipping/manage-freight"
              className={classes.link}
            >
              <Group>
                <div className="item-text">
                  <Title order={3}>Manage Freight Schemes</Title>
                  <Text size="xs" color="dimmed">
                    Create, view and update the shipping calculator(s) for your
                    products
                  </Text>
                </div>
              </Group>
            </UnstyledButton>
            {formValues.IsEligibleForCombinedFreight && (
              <>
                <Divider />
                <UnstyledButton
                  component={Link}
                  to="/shipping/shipping-offers"
                  className={classes.link}
                >
                  <Group position="apart">
                    <div className="item-text">
                      <Title order={3}>Shipping Offers</Title>
                      <Text size="xs" color="dimmed">
                        Opt in to offer shipping discounts when customers spends
                        over a certain amount
                      </Text>
                    </div>
                    <FormSwitch
                      onLabel="Enabled"
                      offLabel="Disabled"
                      size="lg"
                      parentForm={form}
                      //readOnly
                      fieldName="HasShippingOffers"
                      color="brand.6"
                    />
                  </Group>
                </UnstyledButton>
                <Divider />
                <UnstyledButton
                  component={Link}
                  to="/shipping/combined-shipping"
                  className={classes.link}
                >
                  <Group position="apart">
                    <div className="item-text">
                      <Title order={3}>Combined Shipping</Title>
                      <Text size="xs" color="dimmed">
                        Opt in to provide combined shipping rates on products,
                        specify pricing rules and exclusions
                      </Text>
                    </div>
                    <FormSwitch
                      onLabel="Enabled"
                      offLabel="Disabled"
                      size="lg"
                      parentForm={form}
                      //readOnly
                      fieldName="HasCombinedShipping"
                      color="brand.6"
                    />
                  </Group>
                </UnstyledButton>
              </>
            )}
            {formValues.IsPullSeller && (
              <>
                <Divider />
                <UnstyledButton
                  component={Link}
                  to="/shipping/shipping-assignment"
                  className={classes.link}
                >
                  <Group position="apart">
                    <div className="item-text">
                      <Title order={3}>Shipping Assignment</Title>
                      <Text size="xs" color="dimmed">
                        Manage how shipping options are assigned to your
                        products
                      </Text>
                    </div>
                    <FormSwitch
                      onLabel="Enabled"
                      offLabel="Disabled"
                      size="lg"
                      parentForm={form}
                      //readOnly
                      fieldName="HasShippingAssignment"
                      color="brand.6"
                    />
                  </Group>
                </UnstyledButton>
              </>
            )}
          </AuthenticatedForm>
        </Card.Section>
      </Card>
    </>
  );
};
