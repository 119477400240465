import { useMemo, useState } from "react";
import * as yup from "yup";
import {
  Anchor,
  Card,
  Paper,
  createStyles,
  Text,
  LoadingOverlay,
  Stack,
  Space,
  Group,
  TypographyStylesProvider,
  Button,
  Menu,
  ActionIcon,
} from "@mantine/core";
import {
  TicketReplyModel,
  TicketDetailViewModel,
  TicketStatus,
  TicketUserType,
  TicketCommentModel,
} from "./enquiryInbox.types";
import { useDataProvider, useNavigation, useNotification } from "@mydeal/core";
import { enquiryInboxService } from "@services";
import Moment from "react-moment";
import mydeallogo from "../../assets/images/mydeal-icon.png";
import {
  IconBuildingBank,
  IconUser,
  IconChevronDown,
  IconPaperclip,
  IconTrash,
  IconFileText,
  IconPolaroid,
} from "@tabler/icons-react";
import {
  FormFileInput,
  FormTextArea,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import { useRollbar } from "@rollbar/react";

const useStyles = createStyles((theme) => ({
  submitButton: {    
    height: "42px",
  },
  submitActionIcon: {
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    border: 0,
    height: "42px",
  },
}));

interface TicketDetailsProps {
  currentTicket: string;
  ticketDetailsModel: TicketDetailViewModel;
  enableReplyMode: boolean;
  firstComment: TicketCommentModel;
  fetchData: any;
}

export const TicketDetails = ({
  currentTicket,
  ticketDetailsModel,
  enableReplyMode,
  firstComment,
  fetchData,
}: TicketDetailsProps) => {
  const { classes } = useStyles();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const { open: notify } = useNotification();
  const { push } = useNavigation();
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const rollbar = useRollbar();
  const [loading, setLoading] = useState<boolean>(false);
  const [files, setFiles] = useState<File[]>([]);

  const ValidFileTypes = [
    "image/png",
    "image/gif",
    "image/jpeg",
    "text/plain",
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
  ];
  const TicketDetailsSchema = yup.object<{
    [Property in keyof TicketReplyModel]: any;
  }>({
    EscalationBody: yup
      .string()
      .nullable()
      .when("IsSellerEscalation", {
        is: true,
        then: (schema) =>
          schema.required("Please add message before submitting"),
      }),
    Files: yup
      .mixed()
      .nullable()
      .test("ValidateFileSize", "Files cannot exceed 20MB", (files: any) => {
        let totalSize = 0;
        let validSize = true;
        if (files && files.length > 0) {
          files.map((item: any) => {
            totalSize = totalSize + Math.ceil(item.size / 1024 / 1024);
          });
          if (totalSize > 20) {
            validSize = false;
          } else {
            validSize = true;
          }
        }
        return validSize;
      }),
  });

  const form = useFocusableForm<TicketReplyModel>({
    clearInputErrorOnChange: true,
    schema: TicketDetailsSchema,
    initialValues: {
      IsSellerEscalation:
        ticketDetailsModel &&
        ticketDetailsModel.TicketModel &&
        ticketDetailsModel.TicketModel.IsSellerEscalation,
      Body: "",
      EscalationBody: "",
      Files: [],
      TicketSubmitStatus: null,
    },
  });

  const reloadData = () => {
    var ticketId = JSON.parse(currentTicket);
    fetchData(ticketId);
  };

  const onSubmit = (data: any) => {
    setLoading(true);

    data.IsSellerEscalation =
      ticketDetailsModel.TicketReplyModel.IsSellerEscalation;
    if (data && !ticketDetailsModel.TicketReplyModel.IsSellerEscalation) {
      data.EscalationBody = data.EscalationBody
        ? data.EscalationBody
        : data.Body;
    }
    data.CustomerMarketplace = ticketDetailsModel.OrderModel.CustomerMarketplace;

    const toFormData = (object: any) =>
      Object.entries(object).reduce((formData, [key, val]: any) => {
        if (Array.isArray(val)) {
          val.forEach((v) => formData.append(key, v));
        } else {
          formData.append(key, val);
        }
        return formData;
      }, new FormData());

    _enquiryInboxService
      .updateTicket(ticketDetailsModel.TicketModel.TicketId, toFormData(data))
      .then((result: any) => {
        notify?.({
          type: "success",
          message: result.SuccessMessage,
          description: "",
        });
      })
      .catch((err) => {
        rollbar.error(err);
        const {
          response: {
            statusText,
            data: { ErrorMessage, title },
          },
        } = err;

        notify?.({
          type: "error",
          message: ErrorMessage ?? statusText,
          description: ErrorMessage ?? title,
        });
        push(`/enquiryinbox/open-tickets`);
      })
      .finally(() => {
        form.reset();
        setFiles([]);
        reloadData();
        setLoading(false);
      });
  };

  const getTicketStatus = (data: string) => {
    var status = TicketStatus.Open;
    switch (ticketDetailsModel.TicketModel.Status) {
      case TicketStatus[TicketStatus.Pending].toLowerCase(): {
        status = TicketStatus.Pending;
        break;
      }
      case TicketStatus[TicketStatus.Solved].toLowerCase(): {
        status = TicketStatus.Solved;
        break;
      }
      case TicketStatus[TicketStatus.Escalation].toLowerCase(): {
        status = TicketStatus.Escalation;
        break;
      }
      case TicketStatus[TicketStatus.SolvedEscalation].toLowerCase(): {
        status = TicketStatus.SolvedEscalation;
        break;
      }
      default: {
        status = TicketStatus.Open;
        break;
      }
    }
    return status;
  };

  return (
    <>
      {ticketDetailsModel && (
        <Stack>
          <LoadingOverlay visible={loading} />
          {ticketDetailsModel.CommentModels &&
            ticketDetailsModel.CommentModels.map((commentmodel, index) => (
              <>
                <Card key={index} withBorder>
                  <Card.Section
                    withBorder
                    inheritPadding
                    py="xs"
                    sx={(theme) => ({
                      backgroundColor:
                        theme.colorScheme === "dark"
                          ? theme.colors.dark[7]
                          : theme.colors.gray[0],
                    })}
                  >
                    <Group position="apart">
                      <Group spacing="xs">
                        {commentmodel.AuthorType == TicketUserType.MyDeal && (
                          <img
                            src={mydeallogo}
                            alt="MyDeal"
                            width={20}
                            height={20}
                          />
                        )}
                        {commentmodel.AuthorType == TicketUserType.Seller && (
                          <IconBuildingBank color="Red" size={16} />
                        )}
                        {commentmodel.AuthorType == TicketUserType.Customer && (
                          <IconUser color="Red" size={16} />
                        )}
                        <Text fz="lg" fw={700}>
                          {commentmodel.AuthorName}{" "}
                        </Text>
                      </Group>
                      <Text fw={700}>
                        <Moment format="DD/MM/YYYY hh:mm A">
                          {commentmodel.CreatedDate}
                        </Moment>
                      </Text>
                    </Group>
                  </Card.Section>
                  <Card.Section inheritPadding py="xs">
                    <TypographyStylesProvider>
                      <div
                        dangerouslySetInnerHTML={{
                          __html: commentmodel.HtmlBody,
                        }}
                      />
                    </TypographyStylesProvider>
                  </Card.Section>
                  <Card.Section inheritPadding py="xs">
                    <Group>
                      {commentmodel.AttachmentModels &&
                        commentmodel.AttachmentModels.map((attachment) => (
                          <>
                            <Anchor
                              href={attachment.ContentUrl}
                              target="_blank"
                            >
                              {attachment.ContentType.startsWith("image") ? (
                                <>
                                  <Card withBorder>
                                    <Card.Section inheritPadding>
                                      {" "}
                                      <img
                                        src={attachment.ThumbnailUrl}
                                        alt={attachment.FileName}
                                        width={80}
                                        height={80}
                                      />{" "}
                                    </Card.Section>
                                    <Card.Section
                                      inheritPadding
                                      sx={(theme) => ({
                                        backgroundColor:
                                          theme.colorScheme === "dark"
                                            ? theme.colors.dark[7]
                                            : theme.colors.gray[0],
                                      })}
                                    >
                                      <Text>{attachment.FileName}</Text>
                                    </Card.Section>
                                  </Card>
                                </>
                              ) : (
                                <Text>{attachment.FileName}</Text>
                              )}
                            </Anchor>
                          </>
                        ))}
                    </Group>
                  </Card.Section>
                </Card>
              </>
            ))}
          {enableReplyMode && (
            <>
              {ticketDetailsModel.TicketModel &&
                ticketDetailsModel.TicketModel.Status !==
                  TicketStatus[TicketStatus.Closed].toLowerCase() && (
                  <form id="Reply" onSubmit={form.onSubmit(onSubmit)}>
                    <Card withBorder style={{ marginBottom: "50px" }}>
                      <Card.Section
                        withBorder
                        inheritPadding
                        py="xs"
                        sx={(theme) => ({
                          backgroundColor:
                            theme.colorScheme === "dark"
                              ? theme.colors.dark[7]
                              : theme.colors.gray[0],
                        })}
                      >
                        <Group position="apart">
                          <Group spacing="xs">
                            <Text fz="lg" fw={700}>
                              TO:{" "}
                              {ticketDetailsModel.TicketModel.IsSellerEscalation
                                ? firstComment.AuthorName
                                : ticketDetailsModel.TicketModel
                                    .RequesterName}{" "}
                            </Text>
                          </Group>
                        </Group>
                      </Card.Section>
                      <Card.Section withBorder inheritPadding py="xs">
                        {ticketDetailsModel.TicketModel.IsSellerEscalation ? (
                          <TypographyStylesProvider>
                            <FormTextArea
                              autosize
                              minRows={5}
                              variant="unstyled"
                              fieldName="EscalationBody"
                              parentForm={form}
                            />
                          </TypographyStylesProvider>
                        ) : (
                          <TypographyStylesProvider>
                            <FormTextArea
                              autosize
                              minRows={5}
                              variant="unstyled"
                              fieldName="Body"
                              parentForm={form}
                            />
                          </TypographyStylesProvider>
                        )}
                      </Card.Section>
                      <Card.Section inheritPadding py="xs">
                        <Group position="right" spacing="xl" grow>
                          <div style={{ minWidth: "60%" }}>
                            <FormFileInput
                              placeholder="Select file(s)"
                              variant="filled"
                              icon={<IconPaperclip size={14} />}
                              multiple
                              size="sm"
                              accept={ValidFileTypes.toString()}
                              style={{ backgroundColor: "gray.0" }}
                              fieldName="Files"
                              parentForm={form}
                              onChange={(data:any) => {
                                data && data.map((d:any) => files.push(d));
                                form.setValues({
                                  Files: files,
                                });
                              }}
                            />
                            {files &&
                              files.map((file, index) => (
                                <>
                                  <Space h="xs" />
                                  <Paper
                                    key={index}
                                    p="xs"
                                    withBorder
                                    style={{
                                      backgroundColor: "#f1f3f5",
                                    }}
                                  >
                                    <Group
                                      position="apart"
                                      style={{
                                        margin: "-5px",
                                      }}
                                    >
                                      <Group spacing="xs">
                                        {file.type.includes("image") ? (
                                          <IconPolaroid
                                            size={20}
                                            color="#F04A1C"
                                          />
                                        ) : (
                                          <IconFileText
                                            size={20}
                                            color="#F04A1C"
                                          />
                                        )}
                                        <Text fz="md">&nbsp;{file.name}</Text>
                                      </Group>
                                      <ActionIcon
                                        color="#F04A1C"
                                        style={{ float: "right" }}
                                        onClick={() => {
                                          if (files.length > 1) {
                                            files.splice(index, 1);
                                            form.setValues({
                                              Files: files,
                                            });
                                          } else {
                                            setFiles([]);
                                            form.setValues({
                                              Files: [],
                                            });
                                          }
                                        }}
                                      >
                                        <IconTrash size={20} />
                                      </ActionIcon>
                                    </Group>
                                  </Paper>
                                </>
                              ))}
                          </div>
                          </Group>
                      </Card.Section>
                      <Card.Section inheritPadding py="xs">
                        <Group position="right" spacing="xl" grow>
                          <div style={{ alignSelf: "baseline" }}>
                            {ticketDetailsModel.TicketModel
                              .IsSellerEscalation ? (
                              <Button
                                type="submit"
                                onClick={() => {
                                  form.setValues({
                                    TicketSubmitStatus: Number(
                                      getTicketStatus(
                                        ticketDetailsModel.TicketModel.Status
                                      )
                                    ),
                                  });
                                }}
                              >
                                Submit
                              </Button>
                            ) : (
                              <Group noWrap spacing={0}>
                                {/* <Button
                                  pr="lg"
                                  mr="lg"
                                  size="md"
                                  radius="md"
                                  className={classes.submitButton}
                                  type="submit"
                                  onClick={() => {
                                    form.setValues({
                                      TicketSubmitStatus: Number(
                                        getTicketStatus(
                                          ticketDetailsModel.TicketModel.Status
                                        )
                                      ),
                                    });
                                  }}
                                >
                                  {`Submit as ${ticketDetailsModel.TicketModel.Status}`}
                                </Button> */}
                                {ticketDetailsModel.TicketSubmitStatusList &&
                                  ticketDetailsModel.TicketSubmitStatusList.map(
                                    (submitStatus, index) =>                                      
                                        (
                                        <Button
                                          pr="lg"
                                          mr="lg"
                                          radius="md"
                                          variant={ submitStatus.Text.toLowerCase() !==
                                            ticketDetailsModel.TicketModel.Status.toLocaleLowerCase() ? "outline" :"filled"}
                                          key={index}
                                          size="md"
                                          className={classes.submitButton}
                                          type="submit"
                                          onClick={() => {
                                            form.setValues({
                                              TicketSubmitStatus: Number(
                                                submitStatus.Value
                                              ),
                                            });
                                          }}
                                        >
                                          {`Submit as ${submitStatus.Text}`}
                                        </Button>
                                      )
                                  )}
                                <Space w={"xl"} />                                
                              </Group>
                            )}
                          </div>
                        </Group>
                      </Card.Section>
                    </Card>
                  </form>
                )}
            </>
          )}
        </Stack>
      )}
    </>
  );
};
