import { useCallback, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { useFocusableForm, FormTextField } from "@mydeal/ui-mantine";
import { IDynamicFetchParameters } from "@mydeal/ui-mantine";
import {
  Card,
  LoadingOverlay,
  ActionIcon,
  Group,
  createStyles,
  Text,
  Space,
  Alert,
} from "@mantine/core";
import {
  useDataProvider,
  useRouterContext,
  useAuthenticatedApi,
  useNavigation,
} from "@mydeal/core";

import { IconAlertCircle, IconSearch } from "@tabler/icons-react";
import { searchService } from "@services/search.service";
import SearchResultTable, { ISearchResultTable } from "./SearchResultTable";
import { PageTitle } from "@components/shared";

const useStyles = createStyles((theme) => ({
  searchContainer: {
    gap: 0,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    borderRadius: 10,
    minWidth: 450,
    padding: 3,
  },
}));

export const SearchResult = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _searchService = useMemo(
    () => searchService(dataProviderInstance),
    [dataProviderInstance]
  );

  const [loading, setLoading] = useState<boolean>(true);
  const { useLocation } = useRouterContext();
  const searchTermRef = useRef("");
  const { classes } = useStyles();
  const location = useLocation();
  const { push } = useNavigation();
  const [model, setModel] = useState<any>();
  const [isPosted, setIsPosted] = useState<boolean>(false);

  interface ISearchForm {
    searchTerm: string;
  }
  const form = useFocusableForm<ISearchForm>({
    schema: yup.object(),
  });

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }: IDynamicFetchParameters) => {
      const result = await _searchService.search({
        query: searchTermRef.current,
        start: pageSize * pageIndex,
        length: pageSize,
      });
      // used to change the URL query parameter to be in synced with the search
      push(`/Search?query=${searchTermRef.current}`);
      setModel(result);    
      setIsPosted(
        result?.OrderViewItems?.some(function (i: any) {
          if (i.IsPosted) {
            return true;
          } else {
            return false;
          }
        })
      );
      return result;
    },
    [_searchService, push]
  );

  const searchResultTableRef = useRef<ISearchResultTable>(null);

  const refreshTable = useCallback(() => {
    if (searchResultTableRef.current) {
      searchResultTableRef.current.refresh();
    }
  }, []);

  useAuthenticatedApi(
    dataProviderInstance,
    () => {
      if (location.search) {
        var q = new URLSearchParams(location.search).get("query") || "";
        form.setValues({
          searchTerm: q,
        });
        searchTermRef.current = q;
      }
      setLoading(false);
    },
    location
  );

  return (
    <>
      <PageTitle
        title="Search"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Search Result" },
        ]}
      />

      <Card p="xl" withBorder>
        <Group position="apart">
          <Text>
            Search By Order Group No/Order No/Customer Name/Product SKU
          </Text>
          {/* values only set on submit because to prevent re-fetching if Onchange in TextInput is used instead */}
          <form
            onSubmit={form.onSubmit((values) => {
              searchTermRef.current = values.searchTerm || "";
              refreshTable();
            })}
          >
            <Group
              className={classes.searchContainer}
              position="right"
              noWrap={true}
            >
              <FormTextField
                radius="md"
                placeholder="Search"
                size="sm"
                icon={<IconSearch size={16} stroke={1.5} />}
                styles={{ rightSection: { pointerEvents: "none" } }}
                w="100%"
                variant="unstyled"
                fieldName="searchTerm"
                parentForm={form}
              />
              <ActionIcon
                component="button"
                type="submit"
                color="brand"
                size="lg"
                radius="md"
                variant="filled"
              >
                <IconSearch size="16" />
              </ActionIcon>
            </Group>
          </form>
        </Group>
        <Space h="xl" />
        {model?.OrderViewItems?.length > 0 && isPosted && (
          <Alert
            color="yellow"
            title="NOTE:"
            icon={<IconAlertCircle size={16} />}
          >
            Shipping Status, tracking and courier details are visible to
            customers. Notes field is private.
          </Alert>
        )}
        <Space h="xl" />
        <LoadingOverlay visible={loading} />
        {!loading && (
          <SearchResultTable
            fetchData={fetchData}
            controlRef={searchResultTableRef}
          />
        )}
      </Card>
    </>
  );
};
