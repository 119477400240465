import * as yup from "yup";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
} from "@mydeal/core";
import { IOrderViewModel, ISellerNoteModel } from "./types";
import { useMemo, useState } from "react";
import { orderService } from "@services/order.service";
import {
  Alert,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Stack,
} from "@mantine/core";
import { useFocusableForm, FormTextArea } from "@mydeal/ui-mantine";

const UpdateShippingSchema = yup.object({
  note: yup.string().required("Required"),
});

interface ISellerNotesModalProps {
  order?: IOrderViewModel;
  bidDealId?: number;
  toggle: (reload?: boolean) => void;
}
/**
 * accept param of either order or bidDealID(order number) */
export const SellerNotesModal = ({
  order,
  bidDealId,
  toggle,
}: ISellerNotesModalProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<ISellerNoteModel>({
    initialValues: {
      bidDealID: order ? order?.BidDealID : bidDealId,
    },
    schema: UpdateShippingSchema,
  });
  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);

  // different data structure from Order<IOrderViewModel>
  // because available API .getAllOrderNotes(id) returns list of string instead
  const [notesData, setNotesData] = useState<string[]>();

  useAuthenticatedApi(dataProviderInstance, () => {
    if (bidDealId) {
      fetchNotes(bidDealId);
    }
  });

  const fetchNotes = (id: number) => {
    setLoading(true);
    _orderService
      .getAllOrderNotes(id)
      .then((result) => {
        setNotesData(result?.notes);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onSubmit = (data: any) => {
    setLoading(true);

    _orderService
      .addSellerNote(data)
      .then((result) => {
        if (result.Success) {
          notify?.({
            type: "success",
            message: "Note has been added successfully.",
            description: "",
          });
          toggle(true);
        } else {
          notify?.({
            type: "error",
            message: "Something went wrong. Please try again.",
            description: "",
          });
        }
      })
      .catch((err) => {
        const {
          response: {
            statusText,
            data: { ErrorMessage, title },
          },
        } = err;

        notify?.({
          type: "error",
          message: statusText,
          description: ErrorMessage ?? title,
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  return (
    <>
      <Stack>
        <LoadingOverlay visible={loading} />
        <>
          {order
            ? order?.BidDealNotes?.slice()
            .reverse().map((bidDealNote, index) => (
                <Alert color="yellow" key={index}>
                  {bidDealNote.NoteContent}
                </Alert>
              ))
            : notesData?.map((note, index) => (
                <Alert color="yellow" key={index}>
                  {note}
                </Alert>
              ))}
        </>
        <Divider />
        <Stack>
          <form onSubmit={form.onSubmit(onSubmit)}>
            <FormTextArea
              withAsterisk
              label="Notes:"
              fieldName="note"
              parentForm={form}
            />

            <Group position="right" mt="sm">
              <Button
                type="button"
                variant="light"
                onClick={() => {
                  toggle();
                }}
                disabled={loading}
              >
                Cancel
              </Button>
              <Button type="submit" loading={loading}>
                Save Note
              </Button>
            </Group>
          </form>
        </Stack>
      </Stack>
    </>
  );
};
