import { Anchor, Container, Group, Header } from "@mantine/core";
import logo from "../../assets/images/mydeal-mp-logo.png";

export const SimpleHeader = () => {
  return (
    <Header
      height={{ base: 60, md: 100 }}
      p="md"
      data-testid="landingpage-header"
    >
      <Container size="xl" sx={{ height: "100%" }} px={0}>
        <Group position="apart" sx={{ alignItems: "center", height: "100%" }}>
          <Group>
            <Anchor href="/">
              <img src={logo} alt="MyDeal" width={194} />
            </Anchor>
          </Group>
        </Group>
      </Container>
    </Header>
  );
};
