import { Text, Stack, Title } from "@mantine/core";
import { PublicProfileEditor } from "@pages/myaccount/PublicProfileEditor";

export const OnboardingSellerProfile = () => {
  return (
    <Stack spacing="lg">
      <Title order={2}>Seller Profile</Title>
      <Text fz="lg">Let's set up your seller profile</Text>
      <PublicProfileEditor onboarding={true} />
    </Stack>
  );
};
