import { PageTitle } from "@components/shared";
import { IntegrationSettingsEditor } from "./IntegrationSettingsEditor";

export const IntegrationSettings = () => {
  return (
    <>
      <PageTitle
        title="Integration Settings"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Company" },
          { name: "Integration Settings" },
        ]}
      />

      <IntegrationSettingsEditor onboarding={false} />
    </>
  );
};
