import { PageTitle } from "@components/shared";
import { PublicProfileEditor } from "./PublicProfileEditor";

export const PublicProfile = () => {
  return (
    <>
      <PageTitle
        title="Public Profile"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Company" },
          { name: "Public Profile" },
        ]}
      />

      <PublicProfileEditor onboarding={false} />
    </>
  );
};
