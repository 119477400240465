import { FormSwitch, FormTextField, IFocusableForm } from "@mydeal/ui-mantine";
import {
  Anchor,
  Card,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { IIntegrationConfigModel } from "@types";
import { Label } from "@components/shared";

export const WooCommerceSettings = ({
  form,
  onboarding,
}: {
  form: IFocusableForm<Partial<IIntegrationConfigModel>>;
  onboarding: boolean;
}) => {
  const { AutoPushOrder, AutoPriceUpdate, AutoStockUpdate, AutoFetchTracking } =
    form.getValues();

  return (
    <>
      <Card withBorder mt="xl">
        <Card.Section p="sm">
          <Group position="apart" align="center">
            <Title order={5} mb="xs">
              WooCommerce Configuration Settings
            </Title>
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10157361859215"
              target="_blank"
              rel="noreferrer"
            >
              Click here to learn about setting up an integration with
              WooCommerce <IconExternalLink size={14} />
            </Anchor>
          </Group>
          <Divider variant="dashed" />
        </Card.Section>
        <Stack spacing="md">
          <FormTextField
            label="WooCommerce Store URL"
            readOnly={!onboarding}
            parentForm={form}
            fieldName="WooCommerceStoreUrl"
          />
          <FormTextField
            label="MyDeal API User Consumer Key"
            readOnly={!onboarding}
            parentForm={form}
            fieldName="WooCommerceConsumerKey"
          />
          <FormTextField
            label="MyDeal API User Consumer Secret"
            readOnly={!onboarding}
            parentForm={form}
            fieldName="WooCommerceConsumerSecret"
          />

          <Divider />

          <FormSwitch
            size="lg"
            readOnly={!onboarding}
            label="Auto push orders"
            description="How your orders will be managed on MyDeal"
            onLabel="Yes"
            offLabel="No"
            parentForm={form}
            fieldName="AutoPushOrder"
          />
          {AutoPushOrder && (
            <Text>MyDeal pushes orders to your WooCommerce store"</Text>
          )}
          {typeof AutoPushOrder !== "undefined" && !AutoPushOrder && (
            <Text>
              You will manually fulfill orders via the MyDeal Seller Portal
            </Text>
          )}

          <Divider />

          <FormSwitch
            size="lg"
            readOnly={!onboarding}
            disabled={AutoPushOrder}
            label="Automated Price Update"
            onLabel="Yes"
            description={
              AutoPushOrder
                ? "Option not available when orders are pushed to your WooCommerce Store"
                : "Your product prices on MyDeal will sync twice a day from your Magento store"
            }
            offLabel="No"
            parentForm={form}
            fieldName="AutoPriceUpdate"
          />
          {!AutoPushOrder && AutoPriceUpdate && (
            <Text>
              Your product prices on MyDeal will be the same as on your
              WooCommerce store
            </Text>
          )}
          {(AutoPushOrder ||
            (typeof AutoPriceUpdate !== "undefined" && !AutoPriceUpdate)) && (
            <Text>
              Your products will be imported to MyDeal without any price and
              they will not go live until price values are assigned manually via
              the MyDeal Seller Portal
            </Text>
          )}

          <Divider />

          <FormSwitch
            size="lg"
            readOnly={!onboarding}
            disabled={AutoPushOrder}
            label="Automated Stock Update"
            onLabel="Yes"
            description={
              AutoPushOrder
                ? "Option not available when orders are pushed to your WooCommerce Store"
                : "Your product stocks on MyDeal will sync twice a day from your WooCommerce store"
            }
            offLabel="No"
            parentForm={form}
            fieldName="AutoStockUpdate"
          />
          {!AutoPushOrder && AutoStockUpdate && (
            <Text>
              Your stock value on MyDeal will be the same as your WooCommerce
              store (unless global stock buffer added)
            </Text>
          )}
          {(AutoPushOrder ||
            (typeof AutoStockUpdate !== "undefined" && !AutoStockUpdate)) && (
            <Text>
              Your products will be imported to MyDeal without any stock and
              they will not go live until stock values are assigned manually via
              the MyDeal Seller Portal
            </Text>
          )}

          <Divider />

          <FormSwitch
            size="lg"
            readOnly={!onboarding}
            label="Automated Tracking"
            onLabel="Yes"
            offLabel="No"
            parentForm={form}
            fieldName="AutoFetchTracking"
          />
          {AutoFetchTracking && (
            <Text>
              Tracking details will be fetched automatically from your
              WooCommerce store
            </Text>
          )}
          {typeof AutoFetchTracking !== "undefined" && !AutoFetchTracking && (
            <Text>
              You will manually add or upload tracking via the MyDeal Seller
              Portal
            </Text>
          )}

          <Divider />

          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label>Price Customisation</Label>
              <div>
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10133153927695"
                  target="_blank"
                  color="dimmed"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </div>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextField
                readOnly={!onboarding}
                parentForm={form}
                fieldName="PriceCustomField"
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label>WooCommerce Shipping Method</Label>
              <div>
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10157390966415"
                  target="_blank"
                  color="dimmed"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </div>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextField
                readOnly={!onboarding}
                parentForm={form}
                fieldName="WooCommerceShippingMethod"
              />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label>WooCommerce Payment Method</Label>
              <div>
                <Anchor
                  href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10157319213839"
                  target="_blank"
                  color="dimmed"
                >
                  Learn more <IconExternalLink size={14} />
                </Anchor>
              </div>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextField
                readOnly={!onboarding}
                parentForm={form}
                fieldName="WooCommercePaymentMethod"
              />
            </Grid.Col>
          </Grid>
        </Stack>
      </Card>
    </>
  );
};
