import * as yup from "yup";
import {
  Alert,
  Anchor,
  Card,
  Divider,
  Grid,
  SelectItem,
  TextInput,
  Title,
} from "@mantine/core";
import { useDataProvider } from "@mydeal/core";
import { accountService } from "@services";
import { IconAlertCircle, IconExternalLink } from "@tabler/icons-react";
import {
  IChannelType,
  IIntegrationConfigModel,
  SourceChannel,
} from "../../types";
import { useCallback, useContext, useMemo, useState } from "react";
import { WooCommerceSettings } from "./WooCommerceSettings";
import { MagentoIntegrationSettings } from "./MagentoIntegrationSettings";
import { CustomIntegrationSettings } from "./CustomIntegrationSettings";
import {
  useFocusableForm,
  FormSelect,
  AuthenticatedForm,
} from "@mydeal/ui-mantine";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import TestingConfigContext from "@utils/testingConfigContext";
import { Label } from "@components/shared";

const IntegrationSettingsSchema = yup.object<{
  [Property in keyof IIntegrationConfigModel]: any;
}>({
  SourceChannel: yup
    .number()
    .label("Integration Choice")
    .nullable()
    .required()
    .oneOf(
      Object.values(SourceChannel) as number[],
      "Must be a valid integration type"
    ),
  SourceChannelName: yup.string().nullable(), // Not used
  IsExternalSourceChannel: yup.boolean().nullable(), // Not used
  AutoPushOrder: yup
    .boolean()
    .label("Auto Push Orders")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2 ||
        sourceChannel === SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
  AutoFetchTracking: yup
    .boolean()
    .label("Auto Fetch Tracking")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2 ||
        sourceChannel === SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
  AutoPriceUpdate: yup
    .boolean()
    .label("Auto Price Update")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel !== SourceChannel.AcosEcommerce &&
        sourceChannel !== SourceChannel.Booktopia &&
        sourceChannel !== SourceChannel.Cin7 &&
        sourceChannel !== SourceChannel.CustomFile,
      then: (schema) => schema.required(),
    }),
  AutoStockUpdate: yup
    .boolean()
    .label("Auto Stock Update")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2 ||
        sourceChannel === SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
  ShopifyAppStoreUrl: yup
    .string()
    .label("Shopify Store URL")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Shopify ||
        sourceChannel === SourceChannel.ShopifyApp,
      then: (schema) => schema.url().required(),
    }),
  NetoAppStoreUrl: yup
    .string()
    .label("Neto Store URL")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Neto ||
        sourceChannel === SourceChannel.NetoApp,
      then: (schema) => schema.url().required(),
    }),
  AutoPushOrderOptions: yup.array().nullable(), // Not used
  PlatformChoiceOptions: yup.array().nullable(), // Not used
  SellerId: yup.number().nullable(), // Not editable
  SellerToken: yup.string().nullable(), // Not editable
  IsFormDirty: yup.boolean().nullable(), // Not used
  MagentoStoreUrl: yup
    .string()
    .label("Magento  Store URL")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2,
      then: (schema) => schema.url().required(),
    }),
  MagentoUsername: yup
    .string()
    .label("Magento API Username")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.Magento,
      then: (schema) => schema.required(),
    }),
  MagentoPassword: yup
    .string()
    .label("Magento API Key")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.Magento,
      then: (schema) => schema.required(),
    }),
  MagentoToken: yup
    .string()
    .label("Magento API Access Token")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.Magento2,
      then: (schema) => schema.required(),
    }),
  Magento1StoreID: yup
    .string()
    .label("Magento Store ID")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.Magento,
      then: (schema) => schema.matches(/^[\d.]+$/).required(),
    }),
  Magento2StoreID: yup.string().label("Magento Store ID").nullable(),
  CreateInvoiceForOrder: yup
    .boolean()
    .label("Create Invoice for Orders")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2,
      then: (schema) => schema.required(),
    }),
  MagentoPaymentMethod: yup
    .string()
    .label("Magento Payment Method")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2,
      then: (schema) => schema.required(),
    }),

  MagentoBrandAttributeCode: yup
    .string()
    .label("Brand Attribute Code")
    .nullable(),
  ImageBaseMediaUrl: yup
    .string()
    .label("Product Image Media Path")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.Magento2,
      then: (schema) => schema.required(),
    }),
  DescriptionBaseMediaUrl: yup
    .string()
    .label("Product Description Media Path")
    .nullable()
    .when("SourceChannel", {
      is: (sourceChannel: SourceChannel) =>
        sourceChannel === SourceChannel.Magento ||
        sourceChannel === SourceChannel.Magento2,
      then: (schema) => schema.required(),
    }),
  MagentoPaymentMethodOptions: yup.array().nullable(), // Not used

  WooCommerceStoreUrl: yup
    .string()
    .label("WooCommerce Store URL")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.WooCommerce,
      then: (schema) => schema.url().required(),
    }),
  WooCommerceConsumerKey: yup
    .string()
    .label("MyDeal API User Consumer Key")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
  WooCommerceConsumerSecret: yup
    .string()
    .label("MyDeal API User Consumer Secret")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
  PriceCustomField: yup.string().label("Price Customisation").nullable(),
  WooCommerceShippingMethod: yup
    .string()
    .label("WooCommerce Shipping Method")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
  WooCommercePaymentMethod: yup
    .string()
    .label("WooCommerce Payment Method")
    .nullable()
    .when("SourceChannel", {
      is: SourceChannel.WooCommerce,
      then: (schema) => schema.required(),
    }),
});

export interface IIntegrationSettingsEditor {
  onboarding: boolean;
}

const selectableChannelTypes: SourceChannel[] = [
  SourceChannel.NetoApp,
  SourceChannel.IntelligentReach,
  SourceChannel.Omnivore,
  SourceChannel.CommerceConnect,
  SourceChannel.ChannelAdvisor,
  SourceChannel.ChannelEngine,
  SourceChannel.StandardApiIntegration_SKU,
  SourceChannel.CustomFile,
  SourceChannel.MagentoApp,
  SourceChannel.WooCommerceApp,
];

export const IntegrationSettingsEditor = ({
  onboarding,
}: IIntegrationSettingsEditor) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<Partial<IIntegrationConfigModel>>({
    schema: IntegrationSettingsSchema,
  });

  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const [allChannelTypes, setAllChannelTypes] = useState<IChannelType[]>([]);
  const [testingDropdownChannelTypes, setTestingDropdownChannelTypes] =
    useState<SelectItem[]>([]);
  const [dropdownChannelTypes, setDropdownChannelTypes] = useState<
    SelectItem[]
  >([]);

  const testingConfigContext = useContext(TestingConfigContext);
  const testingMode = testingConfigContext.testingConfigModel.TestingMode;

  const fetchData = useCallback(async () => {
    const result = await _accountService.loadIntegrationSettings();
    setAllChannelTypes(result?.channelTypes || []);
    setTestingDropdownChannelTypes(
      [{ label: "-Select-", value: "" } as SelectItem].concat(
        (result?.channelTypes || []).map(
          (c) =>
            ({
              label: c.name,
              value: c.id.toString(),
            } as SelectItem)
        )
      )
    );
    setDropdownChannelTypes(
      [{ label: "-Select-", value: "" } as SelectItem].concat(
        (result?.channelTypes || [])
          .filter(
            (c: IChannelType) =>
              selectableChannelTypes.find((ct) => ct === c.id) !== undefined
          )
          .map(
            (c) =>
              ({
                label: c.name,
                value: c.id.toString(),
              } as SelectItem)
          )
      )
    );
    return result?.model;
  }, [_accountService]);

  const onSubmit = useCallback(
    (data: any) => {
      if (data?.SourceChannel) {
        data.SourceChannel = parseInt(data.SourceChannel);
      }

      return _accountService.updateIntegrationSettings(data, onboarding);
    },
    [_accountService, onboarding]
  );

  const afterSubmit = useCallback(() => {
    if (onboarding) {
      refreshOnboardingProgress();
    }
  }, [onboarding, refreshOnboardingProgress]);

  const formValues = form.getValues();

  const sourceChannelIsSet =
    !!formValues.SourceChannel ||
    formValues.SourceChannel === SourceChannel.CustomFile;

  const usingNonSelectableChannel =
    !testingMode && sourceChannelIsSet && !onboarding
      ? formValues.SourceChannel == SourceChannel.Magento ||
        selectableChannelTypes.find((c) => c === formValues.SourceChannel) !==
          undefined
      : undefined;

  const channelType = sourceChannelIsSet
    ? allChannelTypes.find((c) => c.id === formValues.SourceChannel)
    : undefined;

  return (
    <Card withBorder data-testid="myaccount-integrationsettings">
      {!onboarding && (
        <Card.Section py="sm" px="xl">
          <Title order={4} mb="xs">
            Integration Settings
          </Title>
          <Divider />
        </Card.Section>
      )}
      <Card.Section py="sm" px="xl">
        {!onboarding && (
          <Alert
            color="yellow"
            icon={<IconAlertCircle size={16} />}
            title="Note"
            mb="lg"
          >
            MyDeal uses these settings to manage your products and orders on
            MyDeal. If you wish to change any of these settings please{" "}
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
              target="_blank"
            >
              submit a request <IconExternalLink size={14} />
            </Anchor>
          </Alert>
        )}
        <AuthenticatedForm
          dataProviderInstance={dataProviderInstance}
          form={form}
          fetchData={fetchData}
          submitLabel={onboarding ? "Save and Next" : "Update"}
          readOnly={!onboarding}
          onSubmit={onSubmit}
          afterSubmit={afterSubmit}
          allowSubmitWithNoChanges={onboarding}
        >
          <Title order={5}>
            How will you add products to your MyDeal store?
          </Title>
          {!usingNonSelectableChannel &&
          formValues.SourceChannel !== SourceChannel.ShopifyApp ? (
            <FormSelect
              label="Integration Choice:"
              withAsterisk
              data={
                testingMode ? testingDropdownChannelTypes : dropdownChannelTypes
              }
              parentForm={form}
              fieldName="SourceChannel"
              placeholder="-Select-"
              readOnly={!onboarding}
              withinPortal
              numericValue
            />
          ) : (
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label>Integration Choice</Label>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <TextInput value={channelType?.name} />
              </Grid.Col>
            </Grid>
          )}
          {(channelType?.id === SourceChannel.Shopify ||
            channelType?.id === SourceChannel.ShopifyApp ||
            channelType?.id === SourceChannel.Neto ||
            channelType?.id === SourceChannel.NetoApp ||
            (!onboarding && channelType?.isExternal)) && (
            <CustomIntegrationSettings
              channelType={channelType}
              form={form}
              onboarding={onboarding}
            />
          )}

          {(channelType?.id === SourceChannel.Magento ||
            channelType?.id === SourceChannel.Magento2) && (
            <MagentoIntegrationSettings
              channelType={channelType}
              form={form}
              onboarding={onboarding}
            />
          )}

          {channelType?.id === SourceChannel.WooCommerce && (
            <WooCommerceSettings form={form} onboarding={onboarding} />
          )}
        </AuthenticatedForm>
      </Card.Section>
    </Card>
  );
};
