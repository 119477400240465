// enums
export enum OrderType {
  Normal = 0,
  Addon = 1,
  Special = 2,
  CreditNote = 3,
  SupplierPayment = 4,
  SupplierDeduction = 5,
  CouponRebate = 6,
  MDRestockingFee = 7,
  InsufficentCredit = 8,
  SupplierFee = 9,
  SupplierSubscriptionFee = 10,
  RefundFee = 11,
  MyMoney = 12,
}

export enum RefundType {
  None = 0,
  FullRefund = 1,
  PartialRefund = 2,
  CreditRefund = 3,
  MoneyRefund = 4,
}

export enum VoucherState {
  Processing = 0,
  CardApproved = 1,
  CardDeclined = 2,
  Refunded = 3,
  Purchased = 4,
}

export enum NoteTypes {
  SUPPLIER_NOTE = 1,
  ORDER_NOTE = 2,
  SUPPLIER_REBATE = 3,
  ORDER_REFERENCE_NOTE = 5,
}

export enum DealType {
  Normal = 0,
  SupplierMisc = 2,
  SupplierFee = 6,
  SupplierSubscriptionFee = 7,
  SupplierShopnEarnCreditChargeBack = 10,
  SupplierCouponMisc = 11,
}

// end enums

export interface IViewBidDealNoteModel {
  NoteId: number;
  NoteType: NoteTypes;
  NoteContent: string;
  TimeCreated: string | null;
  BiddealID: number;
}

export interface IViewDealModel {
  DealID: number;
  CompanyID: number;
  MainDeal: string;
  CustomerPrice: number;
  CompanyPayPrice: number;
  RRP: number;
  DealEndTime: string | null;
  DealStartTime: string | null;
  DealCreatedTime: string | null;
  DealTitle: string;
  Thumbnail: string;
  Url: string;
  Posted: boolean;
  Quota: number;
  ExternalID: string;
  Notes: string;
  Approved: boolean;
  Terminated: boolean;
  FreightSchemeID: number;
  FlatRateFreight: number | null;
  Preselling: boolean;
  ExternalFreightID: string;
  FlatRateStateMetro: boolean;
  FlatRateShippingText: string;
  BrandID: number | null;
  MaxDaysForDelivery: number | null;
  S3Images: string;
  S3Thumbnail: string;
  DealType: DealType;
  IsLimitedFreeShipping: boolean;
  FlatRateExpress: number | null;
  Discontinued: boolean;
  SKU: string;
}

export interface IAddonOrderViewModel {
  BidDealID: number;
  DealID: number;
  AcceptedTime: string;
  TotalAmount: number;
  Status: VoucherState;
  CompanyTickUsed: boolean;
  OrderType: OrderType;
  RefundType: RefundType;
  AcknowledgedDate: string | null;
  AddonRefunds: any;
  AddOnPaymentReciepts: any[];
}

export interface IOrderTrackingModel {
  BidDealGroupID: number;
  BidDealID?: number;
  TrackingCode: string;
  DispatchCarrier: string;
}

export interface ISellerNotesModel {
  BidDealID: number;
  BidDealNotes: IViewBidDealNoteModel[];
}

export interface IOrderViewModel {
  BidDealID: number;
  CustomerID: number;
  DealID: number;
  AcceptedTime: string;
  Qty: number;
  PaymentID: number | null;
  TotalAmount: number;
  Status: VoucherState;
  IPAddress: string;
  RedirectEmail: string;
  RedirectFName: string;
  RedirectLName: string;
  ReferrerID: number | null;
  Expires: string | null;
  PostalAddress: string;
  PostalSuburb: string;
  PostalPostcode: string;
  CustomerTickUsed: boolean;
  CompanyTickUsed: boolean;
  PostalState: string;
  Refunded: string | null;
  TrackingCode: string;
  DispatchDate: string | null;
  PostalAddress2: string;
  PostalCompanyName: string;
  SelectedOption: string;
  DispatchCarrier: string;
  DeliveryID: number | null;
  VariantID: number | null;
  CustomerPrice: number;
  CompanyPayPrice: number;
  Notes: string;
  IsFreight: boolean;
  FreightPostalStateID: number | null;
  FreightPostcode: string;
  RedirectPhone: string;
  SupplierNotes: string;
  ParentID: number | null;
  DeliveryOnHold: boolean;
  OrderType: OrderType;
  RefundType: RefundType;
  Acknowledged: boolean | null;
  CompanyID: number | null;
  AcknowledgedDate: string | null;
  IsDispatchReminderDisabled: boolean | null;
  OffHoldDate: string | null;
  LastDispatchReminderSentDate: string | null;
  SKU: string;
  ExternalID: string;
  TotalFreightAmount: number | null;
  DealViewModel: IViewDealModel;
  BidDealNotes: IViewBidDealNoteModel[];
  IsCombinedFreightOrder: boolean;
  BidDealGroupID: number;
  AddonOrders: IAddonOrderViewModel[];
  RefundViewModel: any;
  IsVoucher: boolean;  
}

export interface IOrderGroupViewModel {
  BidDealGroupID: number;
  Posted: boolean;
  RedirectEmail: string;
  RedirectFName: string;
  RedirectLName: string;
  PostalAddress: string;
  PostalAddress2: string;
  PostalCompanyName: string;
  RedirectPhone: string;
  PostalSuburb: string;
  PostalPostcode: string;
  PostalState: string;
  TrackingCode: string;
  DispatchCarrier: string;
  Expires: string | null;
  Orders: IOrderViewModel[];
  TotalFreightAmount: number;
  FormattedTotalFreightAmount: string;
  PurchaseDate: string;
  FormattedPurchaseDate: string;
  CurrentBidDealID: number;
  CompanyTickUsed: boolean;
  IsVoucher: boolean;
  HasDeliveryOnHold: boolean;
  DispatchDate: string | null;
  CustomerMarketplace: string;
}

export interface IUpdateShippingModel {
  BidDealGroupID: number;
  RedirectFName: string;
  RedirectLName: string;
  PostalCompanyName: string;
  PostalAddress: string;
  PostalAddress2: string;
  PostalSuburb: string;
  PostalPostcode: string;
  PostalState: string;
  RedirectPhone: string;
  BidDealID: number | null;
  CurrentBidDealID: number;
}

export interface ISellerNoteModel {
  bidDealID: number;
  note: string;
}

export enum AddOnRequestReason {
  None = 0,
  SparePart = 1,
  REDELIVERY = 2,
  AdditionalFreight = 3,
  Other = 4,
}

export interface IAddonPaymentViewModel {
  ForBidDealID: number;
  AddOnRequestReason: AddOnRequestReason;
  Amount: number;
  CustomerNotes: string;
}

export interface IDispatchInfoViewModel {
  BidDealGroupId: number | null;
  TrackingNumber: string;
  Courier: string;
  CourierSelect: string;
  BidDealId: number | null;
  Used: boolean | null;
  IsVoucherDeal: boolean;
}

// import { SelectListItem } from "./selectListItem";
// import { VoucherState } from "./voucherState";
// import { OrderType } from "./orderType";
// import { AddonOrderViewItem } from "./addonOrderViewItem";

export interface SelectListItem {
  Disabled: boolean;
  Selected: boolean;
  Text: string;
  Value: string;
}

export interface AddonOrderViewItem {
  BidDealId: number;
  InvoiceName: string;
  Status: VoucherState;
  IsPaid: boolean;
  Amount: number;
}

export interface RefundViewModel {
  RefundedCustomerPrice: number;
  OrderItemModel: OrderItemModel;
  RefundItemModel: RefundItemModel;
  RefundReason: number | null;
  RefundReasonList: SelectListItem[];
  RefundNote: string;
  IsSentEmailToBuyer: boolean;
  RefundHistoryModels: RefundHistoryModel[];
  AdditionalNote: string;
  PaymentReceiptId: number;
  IsCreateRefundCreditOffer: boolean;
  IsEligibleForRefundCreditOffer: boolean;
  HasPartialRefundOrCredit: boolean;
  HasPendingRefundCreditOffer: boolean;
  HasCreditOrGiftCardApplied: boolean;
  HasRefund: boolean;
  IsFullRefundRequired: boolean;
}

export interface OrderItemModel {
  OrderNumber: number;
  ParentOrderNumber: number | null;
  Recipient: string;
  AddressLine: string;
  AddressLine2: string;
  Suburb: string;
  State: string;
  Postcode: string;
  PhoneNumber: string;
  Status: VoucherState;
  OrderDate: string;
  Qty: number;
  Sku: string;
  UnitPrice: number;
  TotalShippingPrice: number;
  TrackingNumber: string;
  Courier: string;
  SupplierNotes: string[];
  TotalSalePrice: number;
  OrderType: OrderType;
  HasFreight: boolean;
  AddonOrderViewItems: AddonOrderViewItem[];
  IsDeliveryOnHold: boolean;
  IsPaid: boolean;
  InvoiceName: string;
  CustomerMarketplace: string;
  CustomerMarketplaceDisplayName: string;
}

export interface RefundItemModel {
  BidDealId: number;
  FreightBidDealId: number | null;
  Qty: number;
  ProductTitle: string;
  VariantOption: string;
  RefundSalePrice: number;
  RefundCostPrice: number;
  RefundShippingPrice: number | null;
  RefundShippingCostPrice: number | null;
  RefundTotalAmount: number;
  IsRefundable: boolean;
  OriginalFreightAmount: number | null;
  BidDealGroupId: number | null;
}

export interface RefundHistoryModel {
  TransactionDate: string;
  RefundAmount: number;
  PaymentMethod: string;
  Approved: boolean;
  RefundHistoryOrderItemModels: RefundHistoryOrderItemModel[];
  RefundReason: string;
  RefundNoteContent: string;
  OrderNo?: string;
}

export interface RefundHistoryOrderItemModel {
  BidDealId: number;
  CustomerPrice: number;
}
