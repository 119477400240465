import { IDataContextProvider } from "@mydeal/core";
import {
  IBankDetailModel,
  IChangePasswordModel,
  IChannelType,
  IContactDetailModel,
  IIntegrationConfigModel,
  IProductSettingModel,
  ISellerProfileModel,
  IShippingSettingsModel,
} from "@types";

export const accountService = (dataProvider: IDataContextProvider) => ({
  loadContactDetails: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/ContactDetails`,
      })
      .then((result) => {
        return result?.data as IContactDetailModel;
      });
  },

  updateContactDetails: async (model: any, onboarding: boolean = false) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/MyAccount/ContactDetails`,
        payload: model,
        query: {
          onboarding,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  loadBankDetails: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/BankDetails`,
      })
      .then((result) => {
        return result?.data as IBankDetailModel;
      });
  },

  changePassword: async (
    model: Partial<IChangePasswordModel>,
    onboarding: boolean = false
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/MyAccount/ChangePassword`,
        payload: model,
        query: {
          onboarding,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  getPublicProfile: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/PublicProfile`,
      })
      .then((result) => {
        return result?.data as ISellerProfileModel;
      });
  },

  updatePublicProfile: async (model: any, onboarding: boolean = false) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/MyAccount/PublicProfile`,
        payload: model,
        query: {
          onboarding,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  loadIntegrationSettings: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/IntegrationSettings`,
      })
      .then((result) => {
        return result?.data as {
          model?: IIntegrationConfigModel;
          channelTypes: IChannelType[];
        };
      });
  },

  updateIntegrationSettings: async (
    model: Partial<IProductSettingModel>,
    onboarding: boolean = false
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/MyAccount/IntegrationSettings`,
        payload: model,
        query: {
          onboarding,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  loadProductSettings: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/ProductSettings`,
      })
      .then((result) => {
        return result?.data as IProductSettingModel;
      });
  },

  updateProductSettings: async (
    model: Partial<IProductSettingModel>,
    onboarding: boolean = false
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/MyAccount/ProductSettings`,
        payload: model,
        query: {
          onboarding,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  loadShippingSettings: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/ShippingSettings`,
      })
      .then((result) => {
        return result?.data as IShippingSettingsModel;
      });
  },
});
