import {
  BackgroundImage,
  Box,
  Button,
  Center,
  Container,
  Stack,
  Text,
  createStyles,
  MediaQuery,
} from "@mantine/core";
import heroBanner from "../../assets/images/hero-banner.png";
import { FeaturesCards } from "./FeaturesCard";
import { LandingTab } from "./landingTab";
import { useRouterContext } from "@mydeal/core";
import { useRollbar } from "@rollbar/react";

const useStyles = createStyles((theme) => ({
  buttonWrapper: {
    backgroundColor: theme.white,
    justifyContent: "center",
    display: "flex",
  },
}));

export const Home = () => {
  const { Link } = useRouterContext();

  return (
    <>
      {/* main banner */}
      <Container size="xl" px={0}>
        <Box mx="auto">
          <MediaQuery largerThan="sm" styles={{ backgroundPosition: "left" }}>
            <BackgroundImage
              src={heroBanner}
              radius="sm"
              sx={{
                height: 465,
                width: "100%",
                backgroundSize: "auto",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
              }}
            >
              <Center sx={{ height: "100%" }}>
                <Stack>
                  <Text align="center" fz="xl">
                    Attract customers, <span>sell your products, </span> <br />{" "}
                    <span>
                      & <b>grow your business.</b>
                    </span>
                  </Text>

                  <Button
                    component={Link}
                    to="/seller-application"
                    size="xl"
                    radius="xl"
                  >
                    Start Selling Now
                  </Button>
                </Stack>
              </Center>
            </BackgroundImage>
          </MediaQuery>
        </Box>
      </Container>
      {/* Featue Cards */}
      <FeaturesCards />
      {/* Tab Area */}
      <LandingTab />
    </>
  );
};
