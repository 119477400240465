import {
  createStyles,
  Text,
  Group,
  ActionIcon,
  Container,
  Image,
} from "@mantine/core";
import {
  IconBrandTwitter,
  IconBrandYoutube,
  IconBrandInstagram,
  IconBrandFacebook,
  IconBrandPinterest,
} from "@tabler/icons-react";
import logo from "../../assets/images/logo_white.png";

const useStyles = createStyles((theme) => ({
  footer: {
    backgroundColor: theme.colors.mydealDark[4],
    paddingBottom: theme.spacing.lg,
    borderTop: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[5] : theme.colors.gray[2]
    }`,
  },

  inner: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    padding: `${theme.spacing.md} ${theme.spacing.md}`,

    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },

  copyRightText: {
    [theme.fn.smallerThan("md")]: {
      marginTop: theme.spacing.lg,
      marginBottom: theme.spacing.lg,
    },
  },

  socialTitle: {
    marginBottom: 16,
    [theme.fn.largerThan("md")]: {
      marginBottom: 0,
    },
  },

  socialIcons: {
    marginLeft: theme.spacing.md,
    [theme.fn.smallerThan("md")]: {
      flexDirection: "column",
    },
  },
  actionIcon: {
    backgroundColor: theme.colors.gray[3],
    ":hover": {
      backgroundColor: theme.colors.gray[5],
    },
  },
  icon: {
    ":hover": {
      backgroundColor: theme.primaryColor,
    },
  },
}));

export const SimpleFooter = ({ children }: any) => {
  const { classes, cx, theme } = useStyles();
  return (
    <div className={classes.footer} data-testid="landingpage-footer">
      <Container size="lg" my="xl" py={0}>
        <div className={classes.inner}>
          <Image src={logo} alt="MyDeal Logo" width={200} />
          <Text
            className={classes.copyRightText}
            size="md"
            color={theme.white}
            align="center"
          >
            Copyright 2023 Mydeal.com.au - © All rights reserved
          </Text>
          <Group
            spacing={0}
            className={classes.socialIcons}
            position="right"
            noWrap
          >
            <Group noWrap spacing={10}>
              <ActionIcon
                component="a"
                className={classes.actionIcon}
                href="https://www.facebook.com/mydealaustralia/"
                target="_blank"
                size="lg"
                radius="xl"
                variant="filled"
                ml="md"
              >
                <IconBrandFacebook
                  size={18}
                  stroke={1.5}
                  color={theme.colors.gray[9]}
                />
              </ActionIcon>
              <ActionIcon
                component="a"
                className={classes.actionIcon}
                href="https://twitter.com/mydealaustralia"
                target="_blank"
                size="lg"
                radius="xl"
                variant="filled"
                ml="md"
              >
                <IconBrandTwitter
                  size={18}
                  stroke={1.5}
                  color={theme.colors.gray[9]}
                />
              </ActionIcon>
              <ActionIcon
                component="a"
                className={classes.actionIcon}
                href="https://www.pinterest.com.au/MyDeal_Australia/"
                target="_blank"
                size="lg"
                radius="xl"
                variant="filled"
                ml="md"
              >
                <IconBrandPinterest
                  size={18}
                  stroke={1.5}
                  color={theme.colors.gray[9]}
                />
              </ActionIcon>
              <ActionIcon
                component="a"
                className={classes.actionIcon}
                href="https://www.youtube.com/@MyDealAustralia"
                target="_blank"
                size="lg"
                radius="xl"
                variant="filled"
                ml="md"
              >
                <IconBrandYoutube
                  size={18}
                  stroke={1.5}
                  color={theme.colors.gray[9]}
                />
              </ActionIcon>
              <ActionIcon
                component="a"
                className={classes.actionIcon}
                href="https://www.instagram.com/mydealaustralia/"
                target="_blank"
                size="lg"
                radius="xl"
                variant="filled"
                ml="md"
              >
                <IconBrandInstagram
                  size={18}
                  stroke={1.5}
                  color={theme.colors.gray[9]}
                />
              </ActionIcon>
            </Group>
          </Group>
        </div>
      </Container>
    </div>
  );
};
