import { Button, Group, Stack, Text } from "@mantine/core";
import { Link } from "@mydeal/react-router";
import { IconReceiptRefund } from "@tabler/icons-react";
import { AddonOrderViewItem, IOrderViewItem, VoucherState } from "../../types";
import { StaticDataTable } from "@mydeal/ui-mantine";

interface IAddonModalProps {
  order: IOrderViewItem;
}

export const AddonModal = ({ order }: IAddonModalProps) => {
  return (
    <StaticDataTable<any>
      data={order.AddonOrderViewItems}
      hasPagination={false}
      mantineTableBodyRowProps={{}}
      columns={[
        {
          fieldName: "BidDealId",
          header: "Order No",
          size: 250,
        },
        {
          fieldName: "Status",
          header: "Status",
          size: 250,
          cellTemplate: (value: any, row: any) => {
            const _row: AddonOrderViewItem = row;

            return (
              <>
                <Stack spacing="sm">
                  {VoucherState[_row.Status]}
                  {/* we hide it atm based on TU-596 */}
                  {/* <Group>
                    <Text fw="bold">Status: </Text>
                    {VoucherState[_row.Status]}
                  </Group>
                  <Group>
                    <Text fw="bold">Payment Status: </Text>
                    {_row.IsPaid ? "Paid" : "Unpaid"}
                  </Group> */}
                  {/* need to fix post live release */}
                  {/* <Group>
                    <Text fw="bold">Invoice Name: </Text>
                    {_row.InvoiceName}
                  </Group> */}
                </Stack>
              </>
            );
          },
        },
        {
          fieldName: "Amount",
          header: "Amount",
          size: 250,
          cellTemplate: (value: any) => {
            return <>${value}</>;
          },
        },
        {
          fieldName: "BidDealId",
          header: "Action",
          size: 250,
          cellTemplate: (value: any, row: any) => {
            const _row: AddonOrderViewItem = row;
            return (
              <>
                <Button
                  size="xs"
                  leftIcon={<IconReceiptRefund size={16} />}
                  component={Link}
                  to={`/order/${_row.BidDealId}/refund`}
                >
                  Issue Refund
                </Button>
              </>
            );
          },
        },
      ]}
    />
  );
};
