import {
  Button,
  Text,
  Anchor,
  Stack,
  Group,
  Modal,
  Badge,
  Tooltip,
  Box,
} from "@mantine/core";
import { Link } from "@mydeal/react-router";
import { DealType } from "@pages/orders/types";
import {
  IconBuildingCommunity,
  IconCalendar,
  IconEdit,
  IconMapPin,
  IconNumber,
  IconPhone,
  IconTrash,
  IconTruckDelivery,
  IconUser,
  IconLayersLinked,
} from "@tabler/icons-react";
import { format } from "date-fns";

import { formatAddress } from "@utils/formatAddress";
import {
  ReactNode,
  Ref,
  useCallback,
  useImperativeHandle,
  useMemo,
  useRef,
  useState,
} from "react";
import { AddonModal } from "./AddonModal";
import { IOrderViewItem, VoucherState } from "../../types";
import { TrackingModal } from "@pages/orders/TrackingModal";
import { useDataProvider, useNotification } from "@mydeal/core";
import { orderService } from "@services/order.service";
import { SellerNotesModal } from "@pages/orders/SellerNotesModal";
import { MarketplaceBadge } from "../shared/MarketplaceBadge";
import {
  DynamicDataTable,
  IDynamicDataTable,
  IDynamicFetchParameters,
  IDataTableColumnProps,
} from "@mydeal/ui-mantine";

/**
 *
 * @param model  ISearchViewModel type
 * @param fetchdata function to re-fetch and update the model passed to this component
 *
 */
export interface IOrderOrDealTableProps {
  fetchData: (parameters: IDynamicFetchParameters) => Promise<any>;
  getCount: (response: any) => number;
  getData: (response: any) => IOrderViewItem[];
  columnSelector?: (
    response: any
  ) => IDataTableColumnProps<IOrderViewItem>[] | undefined;
  emptyMessage?: ReactNode;
  showClientListLink: boolean;
}

export interface IOrderTable {
  refresh(): void;
}

const getOrderColumns = (
  showClientListLink: boolean,
  setOpenAddon: (value: boolean) => void,
  setCurrentOrder: (value: IOrderViewItem) => void,
  setOpenTrackingModal: (value: boolean) => void,
  setOpenSellerNotesModal: (value: boolean) => void,
  handleRemoveTracking: (BidDealGroupID: number, BidDealID: number) => void
): IDataTableColumnProps<any>[] => [
  {
    fieldName: "BidDealId",
    header: "Order No",
    size: 180,
    cellTemplate: (value: any, _row: IOrderViewItem) => {
      return (
          <Stack spacing={5}>              
          <MarketplaceBadge text={_row.CustomerMarketplace}></MarketplaceBadge>
          <Anchor component={Link} to={`/order/${value}`}>
                {value}
          </Anchor>
          {_row.BidDealGroupId && (
            <Group spacing={2} p={0}>
              {_row.IsCombinedFreightOrder && (
                <Tooltip label="This order is part of a combined order">
                  <Box sx={{ display: "flex", alignItems: "center" }}>
                    <IconLayersLinked size={14} />
                  </Box>
                </Tooltip>
              )}
              <Text fw="bold">Order Group No: {_row.BidDealGroupId}</Text>
            </Group>
          )}
          {showClientListLink && (
            <Anchor component={Link} to={`/mydeals/client-list/${_row.DealId}`}>
              <Text fw="bold">[Client List]</Text>
            </Anchor>
          )}
        </Stack>
      );
    },
  },
  {
    fieldName: "ShippingAddress",
    header: "Customer Details",
    size: 170,
    cellTemplate: (value: any, _row: IOrderViewItem) => {
      return (
        <>
          {_row.DealType === DealType.Normal && (
            <Stack spacing="sm">
              <Group spacing={5}>
                <IconUser size={16} /> {_row.CustomerName}
              </Group>
              {_row.CompanyName && (
                <Group spacing={5}>
                  <IconBuildingCommunity size={16} />
                  {_row.CompanyName && <div>{_row.CompanyName}</div>}
                </Group>
              )}

              <Group spacing={5}>
                <IconMapPin size={16} />
                {formatAddress(_row.ShippingAddress)}
              </Group>
              <Group spacing={5}>
                <IconPhone size={16} /> {_row.PhoneNo || "n/a"}
              </Group>
            </Stack>
          )}
        </>
      );
    },
  },
  {
    fieldName: "OrderValue",
    header: "Order Details",
    size: 180,
    cellTemplate: (value: any, _row: IOrderViewItem) => {
      // need to add mydeal payment status and invoice number label
      const orderStatus = () => {
        return (
          <>
            {!_row.IsDeliveryOnHold && <Text>{VoucherState[_row.Status]}</Text>}
            {_row.IsDeliveryOnHold && _row.Status === VoucherState.Refunded && (
              <Text color="red">Refunded</Text>
            )}
            {_row.IsDeliveryOnHold &&
              _row.Status !== VoucherState.Refunded &&
              !_row.InvoiceName && <Text>On Hold</Text>}
          </>
        );
      };

      return (
        <>
          {_row.DealType === DealType.Normal && (
            <Stack spacing={5} fz="xs">
              <Group spacing={5}>
                <Text fw="bold">Status: </Text>
                {orderStatus()}
              </Group>
              {_row.IsPaid && (
                <Group spacing={5}>
                  <Text fw="bold">Seller Payment Status: </Text>
                  <Text>Paid</Text>
                </Group>
              )}
              {_row.InvoiceName && (
                <Group spacing={5}>
                  <Text fw="bold">Invoice: </Text>
                  <Text>{_row.InvoiceName}</Text>
                </Group>
              )}
              <Group spacing={5}>
                <Text fw="bold">Date: </Text>
                {new Date(_row.AcceptedTime).toLocaleDateString("en-GB")}
              </Group>
              <Group spacing={5}>
                <Text fw="bold">Order: </Text>
                {_row.OrderQty}x (SKU: {_row.ProductSKU})
              </Group>
              <Group spacing={5}>
                <Text fw="bold">Price (Per Unit): </Text>
                {`$${_row.SellPricePerUnit.toFixed(2)}`}
              </Group>
              <Group spacing={5}>
                <Text fw="bold">Shipping: </Text>
                {`$${_row.FreightAmount.toFixed(2)}`}
              </Group>
              <Group spacing={5}>
                <Text fw="bold">Total Sale Price: </Text>
                {`$${_row.TotalSalePrice.toFixed(2)}`}
              </Group>
              {_row.AddonOrderViewItems &&
                _row.AddonOrderViewItems.length > 0 && (
                  <Group spacing={5}>
                    <Text fw="bold">Addon: </Text>
                    <Anchor
                      onClick={() => {
                        setCurrentOrder(_row);
                        setOpenAddon(true);
                      }}
                    >
                      {_row.AddonOrderViewItems.length}(View)
                    </Anchor>
                  </Group>
                )}
            </Stack>
          )}
        </>
      );
    },
  },
  {
    fieldName: "CompanyTickUsed",
    header: "Shipping Status",
    size: 170,
    cellTemplate: (value: any, _row: IOrderViewItem) => {
      return (
        <>
          <Stack spacing="xs">
            {_row.TotalSalePrice >= 0 && !_row.IsDeliveryOnHold && (
              <>
                {_row.IsCombinedFreightOrder ? (
                  <>
                    {_row.CompanyTickUsed ? (
                      <>
                        <Badge color="green">Dispatched</Badge>
                      </>
                    ) : (
                      <>
                        <Badge color="blue">Pending Dispatch</Badge>

                        <Button
                          variant="default"
                          component={Link}
                          size="xs"
                          to={`/order/${_row.BidDealId}`}
                          // onClick={() => {
                          //   setCurrentOrder(_row);
                          //   setOpenTrackingModal(true);
                          // }}
                          leftIcon={<IconEdit size={14} />}
                        >
                          Add Tracking
                        </Button>
                      </>
                    )}
                  </>
                ) : (
                  <>
                    {_row.CompanyTickUsed ? (
                      <>
                        <Badge color="green">Dispatched</Badge>
                        <>
                          {_row.DispatchDate && (
                            <Group spacing="xs">
                              <IconCalendar size={14} stroke={1} />
                              {format(
                                new Date(_row.DispatchDate),
                                "dd/MM/yyyy"
                              )}
                            </Group>
                          )}
                          <Group spacing="xs">
                            <IconTruckDelivery size={14} stroke={1} />
                            {_row.Courier}
                          </Group>
                          <Group spacing="xs">
                            <IconNumber size={14} stroke={1} />
                            {_row.TrackingNo}
                          </Group>
                          {!_row.IsDeliveryOnHold && (
                            <Group spacing={5}>
                              <Button
                                variant="default"
                                size="xs"
                                onClick={() => {
                                  setCurrentOrder(_row);
                                  setOpenTrackingModal(true);
                                }}
                                leftIcon={<IconEdit size={14} />}
                              >
                                Edit
                              </Button>
                              <Button
                                variant="outline"
                                color="red"
                                size="xs"
                                onClick={() => {
                                  handleRemoveTracking(
                                    _row.BidDealGroupId,
                                    _row.BidDealId
                                  );
                                }}
                                leftIcon={<IconTrash size={14} />}
                              >
                                Remove
                              </Button>
                            </Group>
                          )}
                        </>
                      </>
                    ) : (
                      <>
                        <Badge color="blue">Pending Dispatch</Badge>
                        <Button
                          variant="default"
                          size="xs"
                          onClick={() => {
                            setCurrentOrder(_row);
                            setOpenTrackingModal(true);
                          }}
                          leftIcon={<IconEdit size={14} />}
                        >
                          Add Tracking
                        </Button>
                      </>
                    )}
                  </>
                )}
              </>
            )}
          </Stack>
        </>
      );
    },
  },
  {
    fieldName: "NoteCount",
    header: "Notes/Contact",
    size: 150,
    cellTemplate: (info: any, _row: IOrderViewItem) => {
      return (
        <>
          <Stack spacing="xs">
            <Anchor
              onClick={() => {
                setCurrentOrder(_row);
                setOpenSellerNotesModal(true);
              }}
            >
              <Group>
                View/Add notes
                {_row.NoteCount > 0 && (
                  <Badge size="sm" variant="filled" color="#F04A1C">
                    {_row.NoteCount}
                  </Badge>
                )}
              </Group>
            </Anchor>
            <Anchor
              component={Link}
              to={`/info/order-help/${_row.BidDealId.toString()}`}
            >
              Contact Customer
            </Anchor>
            <Anchor component={Link} to={`/order/${_row.BidDealId}/refund`}>
              Issue Refund
            </Anchor>
          </Stack>
        </>
      );
    },
  },
];

export const OrderTable = ({
  fetchData,
  getData,
  getCount,
  columnSelector,
  emptyMessage,
  showClientListLink,
  controlRef,
}: IOrderOrDealTableProps & {
  controlRef?: Ref<IOrderTable>;
}) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const [openAddon, setOpenAddon] = useState(false);
  const [currentOrder, setCurrentOrder] = useState<IOrderViewItem>();
  const [openTrackingModal, setOpenTrackingModal] = useState(false);
  const [openSellerNotesModal, setOpenSellerNotesModal] =
    useState<boolean>(false);

  const dtRef = useRef<IDynamicDataTable>(null);

  const refreshTable = useCallback(() => {
    if (dtRef.current) {
      dtRef.current.refresh();
    }
  }, []);

  useImperativeHandle(controlRef, () => ({
    refresh() {
      refreshTable();
    },
  }));

  const handleRemoveTracking = useCallback(
    (BidDealGroupID: number, BidDealID: number) => {
      if (window.confirm("Are you sure to remove tracking info?")) {
        _orderService.removeDispatchInfo(BidDealGroupID, BidDealID).then(() => {
          notify?.({
            type: "success",
            message: "Tracking info has been removed successfully.",
            description: "",
          });
          refreshTable();
        });
      }
    },
    [_orderService, refreshTable, notify]
  );

  const toggleTrackingModal = (reload?: boolean) => {
    setOpenTrackingModal(!openTrackingModal);
    if (reload) {
      refreshTable();
    }
  };
  const toggleSellerNotesModal = (reload?: boolean) => {
    setOpenSellerNotesModal(!openSellerNotesModal);
    if (reload) {
      refreshTable();
    }
  };

  const tableColumns = useMemo(
    () =>
      getOrderColumns(
        showClientListLink,
        setOpenAddon,
        setCurrentOrder,
        setOpenTrackingModal,
        setOpenSellerNotesModal,
        handleRemoveTracking
      ),
    [
      setOpenAddon,
      setCurrentOrder,
      setOpenTrackingModal,
      setOpenSellerNotesModal,
      handleRemoveTracking,
    ]
  );

  // render

  return (
    <>
      <DynamicDataTable<any, IOrderViewItem>
        fetchData={fetchData}
        getData={getData}
        getCount={getCount}
        controlRef={dtRef}
        columns={tableColumns}
        columnSelector={columnSelector}
        emptyMessage={emptyMessage}
        mantineTableProps={{}}
        mantineTableBodyRowProps={({ row }: any) => ({
          sx: (theme: any) => ({
            backgroundColor:
              row.original.Status === VoucherState.Refunded
                ? theme.colorScheme === "dark"
                  ? theme.fn.rgba(theme.colors.red[6], 0.2)
                  : theme.colors.red[1]
                : row.original.IsDeliveryOnHold
                ? theme.colorScheme === "dark"
                  ? theme.colors.dark[4]
                  : theme.colors.gray[4]
                : undefined,
          }),
        })}
      />

      {/* Addon Modal */}
      <Modal
        opened={openAddon}
        size="70%"
        onClose={() => setOpenAddon(false)}
        closeOnClickOutside={false}
        title={
          <Text fw={700}>Addon details for {currentOrder?.BidDealId}</Text>
        }
      >
        {currentOrder && <AddonModal order={currentOrder} />}
      </Modal>

      {/* Add/edit tracking modal */}
      <Modal
        opened={openTrackingModal}
        size="lg"
        onClose={() => toggleTrackingModal(false)}
        closeOnClickOutside={false}
        title={<Text fw={700}>Add/Edit Tracking</Text>}
      >
        {currentOrder && (
          <TrackingModal
            trackingModel={{
              BidDealGroupID: currentOrder.BidDealGroupId,
              BidDealID: currentOrder.BidDealId,
              TrackingCode: currentOrder.TrackingNo,
              DispatchCarrier: currentOrder.Courier,
            }}
            onClose={(reload) => {
              toggleTrackingModal(reload);
            }}
          />
        )}
      </Modal>

      {/* View/add seller notes modal */}
      <Modal
        opened={openSellerNotesModal}
        size="lg"
        onClose={() => toggleSellerNotesModal(false)}
        closeOnClickOutside={false}
        title={<Text fw={700}>Notes for {currentOrder?.BidDealId}</Text>}
      >
        {currentOrder && (
          <SellerNotesModal
            bidDealId={currentOrder.BidDealId}
            toggle={(reload) => {
              toggleSellerNotesModal(reload);
            }}
          />
        )}
      </Modal>
    </>
  );
};
