import { useMemo, useState } from "react";
import { Label, PageTitle } from "@components";
import {
  Anchor,
  Card,
  createStyles,
  Text,
  Title,
  LoadingOverlay,
  Stack,
  Badge,
  Grid,
  Group,
  Space,
  Mark,
} from "@mantine/core";
import Moment from "react-moment";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNavigation,
  useNotification,
  useRouterContext,
} from "@mydeal/core";
import { enquiryInboxService } from "@services";
import { SatisfactionList, TicketStatus } from "./enquiryInbox.types";
import { IconArrowBackUp } from "@tabler/icons-react";
import { TicketDetails } from "./ticketDetails";
import { TicketStatusCard } from "./ticketStatusCard";
import { useRollbar } from "@rollbar/react";
import { MarketplaceBadge } from "../../components/shared/MarketplaceBadge";

interface IRouteParams {
  ticketId: string;
}

export const ViewTicket = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const { Link } = useRouterContext();
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { useParams } = useRouterContext();
  const { ticketId } = useParams<IRouteParams>();
  const { push } = useNavigation();
  const { rollbar } = useRollbar();
  const { open: notify } = useNotification();

  const [loading, setLoading] = useState<boolean>(false);
  const [model, setModel] = useState<any>();
  const [linkedTicket, setLinkedTicket] = useState<any>();
  const [firstComment, setFirstComment] = useState<any>();
  const [enableReplyMode, setEnableReplyMode] = useState<boolean>(false);
  const [baselink, setBaseLink] = useState<string>("");
  const [baseTitle, setBaseTitle] = useState<string>("");

  const fetchData = async (currentTicket: BigInt) => {
    setLoading(true);
    await _enquiryInboxService
      .viewTicket(currentTicket)
      .then((result) => {
        if (result) {
          if (!result.isShowableTicket) {
            push(`/enquiryinbox/open-tickets`);
          }
          if (result.ticketModel) {
            setModel(result.ticketModel);
            if (result.ticketModel && result.ticketModel.TicketModel) {
              generateBaseLinkAndTitle(result.ticketModel.TicketModel);
            }
          }
          setEnableReplyMode(result.enableReplyMode);
          if (result.linkedTicket) {
            setLinkedTicket(result.linkedTicket);
          }
          if (result.firstComment) {
            setFirstComment(result.firstComment);
          }
        }
      })
      .catch((err) => {
        rollbar.error(err);
        const {
          response: {
            statusText,
            data: { ErrorMessage, title },
          },
        } = err;      
        notify?.({
          type: "error",
          message: ErrorMessage ?? statusText,
          description: ErrorMessage ?? title,
        });
        push(`/enquiryinbox/open-tickets`);
      })
      .finally(() => setLoading(false));
  };

  const generateBaseLinkAndTitle = (ticketModel: any) => {
    if (ticketModel.IsSellerEscalation) {
      setBaseLink("/enquiryinbox/escalations");
      setBaseTitle("Escalation from MyDeal");
      return;
    }

    switch (ticketModel.status) {
      case TicketStatus[TicketStatus.Open].toLowerCase(): {
        setBaseLink("/enquiryinbox/open-tickets");
        setBaseTitle("Open Tickets");
        break;
      }
      case TicketStatus[TicketStatus.Pending].toLowerCase(): {
        setBaseLink("/enquiryinbox/pending-tickets");
        setBaseTitle("Pending Tickets");
        break;
      }
      case TicketStatus[TicketStatus.Solved].toLowerCase(): {
        setBaseLink("/enquiryinbox/solved-tickets");
        setBaseTitle("Solved Tickets");
        break;
      }
      case TicketStatus[TicketStatus.Escalation].toLowerCase(): {
        setBaseLink("/enquiryinbox/escalations");
        setBaseTitle("Escalation from MyDeal");
        break;
      }
      case TicketStatus[TicketStatus.SolvedEscalation].toLowerCase(): {
        setBaseLink("/enquiryinbox/solved-escalations");
        setBaseTitle("Solved Escalations");
        break;
      }
      default: {
        setBaseLink("/enquiryinbox/open-tickets");
        setBaseTitle("Open Tickets");
        break;
      }
    }
  };

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(true);
    var currentTicket = JSON.parse(ticketId);
    fetchData(currentTicket);
  });

  return (
    <>
      <PageTitle
        title="View Ticket"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Enquiry Inbox" },
          {
            name: baseTitle ? <Text>{baseTitle}</Text> : "",
            route: baselink ? baselink : "",
          },
          { name: <Text>{`Ticket #${ticketId}`}</Text> },
        ]}
      />
      <Card withBorder mih={300}>
        <Card.Section inheritPadding mb="lg">
          <LoadingOverlay visible={loading} />
          {model && model.FlagFailedToFetchTicket && (
            <Text
              c="orange"
              fz="lg"
            >{`We are unable to fetch ticket ${ticketId} at this time. Please try again later.`}</Text>
          )}
        </Card.Section>
        {model && model.TicketModel && (
          <>
            <Card.Section inheritPadding>
              <Card>
                <Card.Section inheritPadding mb="lg">
                  <Group position="apart">
                    <Title order={2}>{model.TicketModel.Subject}</Title>
                    <div>
                      {!model.TicketModel.IsSellerEscalation &&
                        !model.TicketModel.IsSellerOrderHelp && (
                          <>
                            {model.TicketModel.SlaBreachText != "" &&
                              model.TicketModel.SlaBreachText != null && (
                                <>
                                  {model.TicketModel.SlaBreachText ==
                                  "paused" ? (
                                    <Badge
                                      size="sm"
                                      variant="filled"
                                      color={
                                        model.TicketModel.IsSlaBreached
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {" "}
                                      SLA Paused{" "}
                                    </Badge>
                                  ) : (
                                    <Badge
                                      size="sm"
                                      variant="filled"
                                      color={
                                        model.TicketModel.IsSlaBreached
                                          ? "red"
                                          : "green"
                                      }
                                    >
                                      {model.TicketModel.SlaBreachMetric !=
                                      "first_reply_time"
                                        ? " Reply in "
                                        : " Solve in "}
                                      {model.TicketModel.SlaBreachText}
                                    </Badge>
                                  )}
                                </>
                              )}
                          </>
                        )}
                    </div>
                  </Group>
                </Card.Section>
                <Card.Section inheritPadding>
                  <Group position="apart" align="flex-end">
                    <Card>
                      <Card.Section>
                        <TicketStatusCard
                          ticketStatus={model.TicketModel.Status}
                          hasFreightProtection={
                            model.TicketModel.HasFreightProtection
                          }
                          isEscalation={model.TicketModel.IsEscalation}
                          isSellerEscalation={
                            model.TicketModel.IsSellerEscalation
                          }
                        />
                      </Card.Section>
                      <Card.Section>
                        {firstComment && (
                          <Text fz="lg">
                            From:{" "}
                            {model.TicketModel.IsSellerEscalation
                              ? firstComment.AuthorName
                              : model.TicketModel.RequesterName}
                          </Text>
                        )}
                      </Card.Section>
                      <Card.Section>
                        <Text fz="lg">
                          Date Created:{" "}
                          <Moment format="DD/MM/YYYY hh:mm A">
                            {model.TicketModel.CreatedDate}
                          </Moment>
                        </Text>
                      </Card.Section>
                      <Card.Section>
                        {linkedTicket &&
                        model.TicketModel.IsEscalation &&
                        linkedTicket.ParentId != null ? (
                          <div>
                            <Text>
                              <Mark>
                                Escalation from MyDeal:&nbsp;
                                <Anchor
                                  href={`/EnquiryInbox/ViewTicket/${linkedTicket.TicketId}`}
                                >
                                  {linkedTicket.TicketId}
                                </Anchor>
                                &nbsp;MyDeal Escalation on Ticket ID:&nbsp;
                                <Anchor
                                  href={`/EnquiryInbox/ViewTicket/${linkedTicket.ParentId}`}
                                >
                                  {linkedTicket.ParentId}
                                </Anchor>
                              </Mark>
                            </Text>
                          </div>
                        ) : (
                          model.TicketModel.IsSellerEscalation &&
                          model.ParentTicketModel && (
                            <div>
                              <Group spacing="xs">
                                <Text>
                                  <Mark>
                                    Ticket from Customer:&nbsp;
                                    <Anchor
                                      href={`/EnquiryInbox/ViewTicket/${model.ParentTicketModel.TicketId}`}
                                    >
                                      {model.ParentTicketModel.TicketId}
                                    </Anchor>
                                    &nbsp; {model.ParentTicketModel.Subject}
                                    &nbsp;
                                    {model.ParentTicketModel.OrderId}
                                  </Mark>
                                </Text>
                                <TicketStatusCard
                                  ticketStatus={model.ParentTicketModel.Status}
                                  hasFreightProtection={false}
                                  isEscalation={false}
                                  isSellerEscalation={false}
                                />
                              </Group>
                            </div>
                          )
                        )}
                      </Card.Section>
                    </Card>
                    {model.TicketModel.Status !==
                      TicketStatus[TicketStatus.Closed].toLowerCase() && (
                      <Anchor href="#Reply">
                        <Text fz="lg">
                          <IconArrowBackUp size={16} />
                          &nbsp;Reply
                        </Text>
                      </Anchor>
                    )}
                    <div>&nbsp;</div>
                  </Group>
                </Card.Section>
              </Card>
            </Card.Section>
            <Space h="xs" />
            <Card.Section inheritPadding>
              <Card>
                <Card.Section inheritPadding mb="lg">
                  <Stack>
                    <Grid>
                      <Grid.Col span={8}>
                        <Stack>
                          <TicketDetails
                            currentTicket={ticketId}
                            ticketDetailsModel={model}
                            enableReplyMode={enableReplyMode}
                            firstComment={firstComment}
                            fetchData={(i: BigInt) => fetchData(i)}
                          />
                        </Stack>
                      </Grid.Col>
                      <Grid.Col span={4}>
                        <Stack>
                          {model.TicketModel &&
                            !model.TicketModel.IsSellerEscalation &&
                            model.SatifactionRatingModel &&
                            model.SatifactionRatingModel.Id &&
                            model.SatifactionRatingModel.Id > 0 &&
                             (
                              <Card withBorder>
                                <Card.Section inheritPadding py="xs">
                                  <Stack>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Rating:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Badge
                                          size="sm"
                                          variant="filled"
                                          color={
                                            model.TicketModel.SatisfactionScore.toLowerCase() ==
                                            SatisfactionList.good
                                              ? "green"
                                              : model.TicketModel.SatisfactionScore.toLowerCase() ==
                                                SatisfactionList.bad
                                              ? "red"
                                              : "gray"
                                          }
                                        >
                                          {model.TicketModel.SatisfactionText}
                                        </Badge>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Date:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Moment format="DD/MM/YYYY hh:mm A">
                                          {model.SatifactionRatingModel.UpdatedDate}
                                        </Moment>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Reason:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>
                                          {model.SatifactionRatingModel.Reason}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Comment:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>
                                          {model.SatifactionRatingModel.Comment}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                  </Stack>
                                </Card.Section>
                              </Card>
                            )}
                          {model.OrderModel.OrderId != 0 ? (
                            <>
                              <Card withBorder>
                                <Card.Section
                                  withBorder
                                  inheritPadding
                                  py="xs"
                                  sx={(theme) => ({
                                    backgroundColor:
                                      theme.colorScheme === "dark"
                                        ? theme.colors.dark[7]
                                        : theme.colors.gray[0],
                                  })}
                                >
                                  <Text fz="lg" fw={700}>
                                    Order Details
                                  </Text>
                                </Card.Section>
                                <Card.Section inheritPadding py="xs">
                                  <Stack>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Order Number:</Label>                                        
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Anchor
                                          component={Link}
                                          to={`/order/${model.OrderModel.OrderId}`}
                                        >
                                          {model.OrderModel.OrderId}
                                        </Anchor>
                                        <MarketplaceBadge text={model.OrderModel.CustomerMarketplace} applyLeftMargin={ true }></MarketplaceBadge>
                                      </Grid.Col>
                                    </Grid>
                                    {model.OrderModel.OrderGroupId != null && (
                                      <Grid>
                                        <Grid.Col span={4}>
                                          <Label>Order Group Number:</Label>
                                        </Grid.Col>
                                        <Grid.Col span={8}>
                                          <Text>
                                            {model.OrderModel.OrderGroupId}
                                          </Text>
                                        </Grid.Col>
                                      </Grid>
                                    )}

                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Purchase Date:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>
                                          {" "}
                                          <Moment format="DD/MM/YYYY hh:mm A">
                                            {model.OrderModel.PurchaseDate}
                                          </Moment>{" "}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>QTY:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>{model.OrderModel.Qty}</Text>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Price (per unit):</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>
                                          $
                                          {model.OrderModel.PricePerUnit.toFixed(
                                            2
                                          )}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Shipping:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        {model.OrderModel.ShippingAmount !=
                                        null ? (
                                          <>
                                            <Text>
                                              $
                                              {model.OrderModel.ShippingAmount.toFixed(
                                                2
                                              )}
                                              {model.OrderModel
                                                .FreightPostcode != null &&
                                              model.OrderModel
                                                .FreightPostcode != ""
                                                ? `(${model.OrderModel.FreightPostcode})`
                                                : model.OrderModel.FreightType}
                                            </Text>
                                          </>
                                        ) : (
                                          <Text>Free</Text>
                                        )}
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Total Sale Price:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>
                                          $
                                          {model.OrderModel.TotalAmount.toFixed(
                                            2
                                          )}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>Product Name:</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Anchor
                                          href={model.OrderModel.DealUrl}
                                          target="_blank"
                                        >
                                          {model.OrderModel.DealTitle}
                                        </Anchor>
                                      </Grid.Col>
                                    </Grid>
                                    <Grid>
                                      <Grid.Col span={4}>
                                        <Label>SKU (Option):</Label>
                                      </Grid.Col>
                                      <Grid.Col span={8}>
                                        <Text>
                                          {model.OrderModel.Sku}
                                          {model.OrderModel.SelectedOption !==
                                            null &&
                                          model.OrderModel.SelectedOption != ""
                                            ? `(${model.OrderModel.SelectedOption})`
                                            : ""}
                                        </Text>
                                      </Grid.Col>
                                    </Grid>
                                    {model.OrderModel.AddonCount > 0 && (
                                      <Grid>
                                        <Grid.Col span={4}>
                                          <Label>Addon:</Label>
                                        </Grid.Col>
                                        <Grid.Col span={8}>
                                          <Text>
                                            {model.OrderModel.AddonCount}(
                                            <Anchor
                                              component={Link}
                                              to={`/order/${model.OrderModel.OrderId}`}
                                            >
                                              View
                                            </Anchor>
                                            )
                                          </Text>
                                        </Grid.Col>
                                      </Grid>
                                    )}
                                  </Stack>
                                </Card.Section>
                              </Card>
                              <Card withBorder>
                                <Card.Section
                                  withBorder
                                  inheritPadding
                                  py="xs"
                                  sx={(theme) => ({
                                    backgroundColor:
                                      theme.colorScheme === "dark"
                                        ? theme.colors.dark[7]
                                        : theme.colors.gray[0],
                                  })}
                                >
                                  <Text fz="lg" fw={700}>
                                    Shipping Info
                                  </Text>
                                </Card.Section>
                                <Card.Section inheritPadding py="xs">
                                  {model.OrderModel.Dispatched ? (
                                    <Badge
                                      size="xs"
                                      variant="filled"
                                      color="green"
                                    >
                                      Dispatched
                                    </Badge>
                                  ) : (
                                    <Badge
                                      size="xs"
                                      variant="filled"
                                      color="blue"
                                    >
                                      Pending Dispatch
                                    </Badge>
                                  )}
                                  <br /> <br />
                                  <Text fz="lg">
                                    {model.OrderModel.ShippingFullName}
                                    <br />
                                    {model.OrderModel.ShippingCompanyName !=
                                      null &&
                                    model.OrderModel.ShippingCompanyName !=
                                      "" ? (
                                      <>
                                        {model.OrderModel.ShippingCompanyName}
                                        <br />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {model.OrderModel.ShippingAddress1} <br />
                                    {model.OrderModel.ShippingAddress2 !=
                                      null &&
                                    model.OrderModel.ShippingAddress2 != "" ? (
                                      <>
                                        {model.OrderModel.ShippingAddress2}
                                        <br />
                                      </>
                                    ) : (
                                      <></>
                                    )}
                                    {model.OrderModel.ShippingPostal}
                                    <br /> {model.OrderModel.ShippingPhone}
                                  </Text>
                                </Card.Section>
                              </Card>
                            </>
                          ) : (
                            <>
                              {model.TicketProductQuestionModel.DealId != 0 && (
                                <>
                                  <Card withBorder>
                                    <Card.Section
                                      withBorder
                                      inheritPadding
                                      py="xs"
                                      sx={(theme) => ({
                                        backgroundColor:
                                          theme.colorScheme === "dark"
                                            ? theme.colors.dark[7]
                                            : theme.colors.gray[0],
                                      })}
                                    >
                                      <Text fz="lg" fw={700}>
                                        Customer
                                      </Text>
                                    </Card.Section>
                                    <Card.Section inheritPadding py="xs">
                                      <Stack>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Customer Name:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              {
                                                model.TicketProductQuestionModel
                                                  .RequesterName
                                              }
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Shipping Suburb:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              {
                                                model.TicketProductQuestionModel
                                                  .ShippingSuburb
                                              }
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                      </Stack>
                                    </Card.Section>
                                  </Card>
                                  <Card withBorder shadow="md">
                                    <Card.Section
                                      withBorder
                                      inheritPadding
                                      py="xs"
                                      sx={(theme) => ({
                                        backgroundColor:
                                          theme.colorScheme === "dark"
                                            ? theme.colors.dark[7]
                                            : theme.colors.gray[0],
                                      })}
                                    >
                                      <Text fz="lg" fw={700}>
                                        Product Details
                                      </Text>
                                    </Card.Section>
                                    <Card.Section inheritPadding py="xs">
                                      <Stack>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Product Name:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Anchor
                                              href={
                                                model.TicketProductQuestionModel
                                                  .DealUrl
                                              }
                                              target="_blank"
                                            >
                                              {
                                                model.TicketProductQuestionModel
                                                  .ProductName
                                              }
                                            </Anchor>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>SKU (Options):</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              {
                                                model.TicketProductQuestionModel
                                                  .Sku
                                              }
                                              {model.TicketProductQuestionModel
                                                .Options !== null &&
                                              model.TicketProductQuestionModel
                                                .Options != ""
                                                ? `(${model.TicketProductQuestionModel.Options})`
                                                : ""}
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>QTY:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              {
                                                model.TicketProductQuestionModel
                                                  .Quantity
                                              }
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Price (per Unit):</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              $
                                              {model.TicketProductQuestionModel.PricePerUnit.toFixed(
                                                2
                                              )}
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Total Sale Price:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              $
                                              {model.TicketProductQuestionModel.TotalAmount.toFixed(
                                                2
                                              )}
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Freight Type:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              {
                                                model.TicketProductQuestionModel
                                                  .FreightType
                                              }
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                        <Grid>
                                          <Grid.Col span={4}>
                                            <Label>Current Stock:</Label>
                                          </Grid.Col>
                                          <Grid.Col span={8}>
                                            <Text>
                                              {
                                                model.TicketProductQuestionModel
                                                  .CurrentStock
                                              }
                                            </Text>
                                          </Grid.Col>
                                        </Grid>
                                      </Stack>
                                    </Card.Section>
                                  </Card>
                                </>
                              )}
                            </>
                          )}
                        </Stack>
                      </Grid.Col>
                    </Grid>
                  </Stack>
                </Card.Section>
              </Card>
            </Card.Section>
          </>
        )}
      </Card>
    </>
  );
};
