import * as yup from "yup";
import {
  Alert,
  Button,
  Divider,
  Group,
  Stack,
  Text,
  SelectItem,
} from "@mantine/core";
import { IconAlertCircle } from "@tabler/icons-react";
import { useDataProvider, useNotification } from "@mydeal/core";
import { IOrderViewModel } from "./types";
import { useMemo, useState } from "react";
import { orderService } from "@services";
import {
  useFocusableForm,
  FormMoneyInput,
  FormSelect,
  FormTextArea,
  FormTextField,
} from "@mydeal/ui-mantine";
import moment from "moment";

const AddOnPaymentSchema = yup.object({
  Amount: yup
    .number()
    .required("An amount must be entered")
    .min(0, "Please enter a correct amount")
    .max(1000, "Amount must be between 0 to 1000"),
  AddOnRequestReason: yup.string().required("A reason must be selected"),
  CustomerNotes: yup.string().required("Please enter notes to customer"),
});

interface IAdditionalPaymentModalProps {
  order: IOrderViewModel;
  AddOnRequestReasonSelectList?: any[];
  toggle: (reload?: boolean) => void;
}

export const AdditionalPaymentModal = ({
  order,
  AddOnRequestReasonSelectList,
  toggle,
}: IAdditionalPaymentModalProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<any>({
    initialValues: {
      ForBidDealID: order.BidDealID,
    },
    schema: AddOnPaymentSchema,
  });

  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);

  const onSubmit = (data: any) => {
    setLoading(true);

    _orderService
      .requestPayment(data)
      .then((result) => {
        if (result.success) {
          notify?.({
            type: "success",
            message: result.SuccessMessage,
            description: "",
          });
          toggle(true);
        } else {
          notify?.({
            type: "error",
            message: "Something went wrong. Please try again.",
            description: "",
          });
        }
      })
      .catch((err) => {
        const {
          response: {
            statusText,
            data: { ErrorMessage, title },
          },
        } = err;

        notify?.({
          type: "error",
          message: statusText,
          description: ErrorMessage ?? title,
        });       
      })
      .finally(() => {
        setLoading(false);
      });
  };
  const formValues = form.getValues();
  return (
    <>
      <form onSubmit={form.onSubmit(onSubmit)}>
        <Stack>
          <div className="row">
            <div className="col-6">
              <Alert color="info" className="h-100">
                <b>Customer</b> <br />
                <hr />
                <label id="RedirectFName_viewaddon">
                  {order.RedirectFName}
                </label>{" "}
                <label id="RedirectLName_viewaddon">
                  {order.RedirectLName}
                </label>
                <div id="PostalCompanyName_viewaddon">
                  {order.PostalCompanyName}
                </div>
                <div id="PostalAddress_viewaddon">{order.PostalAddress}</div>
                <div id="PostalAddress2_viewaddon">{order.PostalAddress2}</div>
                <label id="PostalSuburb_viewaddon">
                  {order.PostalSuburb}
                </label>{" "}
                <label id="PostalState_viewaddon">{order.PostalState}</label>{" "}
                <label id="PostalPostcode_viewaddon">
                  {order.PostalPostcode}
                </label>{" "}
                <br />
                <label id="RedirectPhone_viewaddon">
                  {order.RedirectPhone}
                </label>{" "}
                <br />
              </Alert>
            </div>
            <div className="col-6">
              <Alert color="info" className="h-100">
                <b>Order Details</b> <br />
                <hr />
                Date: {moment(order.AcceptedTime).format("DD/MM/YYYY hh:mm:ss A")}<br />
                Order: {order.Qty} x (SKU: {order.SKU}) <br />
                Price(<i>Per Unit</i>): ${order.CustomerPrice.toFixed(2)} <br />
                {order.TotalFreightAmount && (
                  <>
                    Shipping: ${order.TotalFreightAmount.toFixed(2)} <br />
                  </>
                )}
                Total sale price: $
                {(order.TotalAmount + (order.TotalFreightAmount ?? 0)).toFixed(
                  2
                )}
              </Alert>
            </div>
          </div>

          <Divider />

          <Stack spacing="sm">          
            <FormSelect
              withAsterisk
              label="Select a reason:"
              placeholder="-- SELECT --"
              disabled={step !== 1}
              data={
                AddOnRequestReasonSelectList?.map((item) => {
                  return {
                    value: item.Value,
                    label: item.Text,
                  };
                }) as SelectItem[]
              }
              fieldName="AddOnRequestReason"
              parentForm={form}
            />

            <FormMoneyInput
              name="Amount"
              label="Request Amount:"
              fieldName="Amount"
              disabled={step !== 1}
              parentForm={form}
              withAsterisk
              min={0}
            />

            <FormTextArea
              withAsterisk
              label="Notes to customer (included in payment request email):"
              disabled={step !== 1}
              fieldName="CustomerNotes"
              parentForm={form}
              placeholder="Maximum 500 characters"
              maxLength={500}
            />
          </Stack>       
          {step === 1 && (
            <Group>
              <Button
                type="button"
                variant="light"
                onClick={() => {
                  toggle();
                }}
              >
                Cancel
              </Button>
              <Button
                type="button"
                onClick={() => {               
                  var result = form.validate();                   
                  if(!result.hasErrors){
                    setStep(2);
                  }                 
                }}
              >
                Next
              </Button>
            </Group>
          )}
          {step === 2 && (
            <>
              <Alert
                color="yellow"
                title="NOTE"
                icon={<IconAlertCircle size={16} />}
              >
                <Text>
                  Clicking on the Request Payment button will send an email to
                  the customer with a payment link
                </Text>
              </Alert>
              <Group>
                <Button
                  type="button"
                  variant="light"
                  onClick={() => {                  
                    setStep(1);
                  }}
                >
                  Back
                </Button>
                <Button type="submit" loading={loading}>
                  Request payment (${formValues.Amount?.toFixed(2)})
                </Button>
              </Group>
            </>
          )}
        </Stack>
      </form>
    </>
  );
};
