import { PageTitle } from "@components/shared";
import { ShippingAssignmentEditor } from "./ShippingAssignmentEditor";

export const ShippingAssignment = () => {
  return (
    <>
      <PageTitle
        title="Shipping Assignment"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Shipping Settings", route: "/shipping" },
          { name: "Shipping Assignment" },
        ]}
      />

      <ShippingAssignmentEditor onboarding={false} />
    </>
  );
};
