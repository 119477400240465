import { useEffect, useMemo, useState } from "react";
import {
  Button,
  Group,
  Text,
  Paper,
  Select,
  TextInput,
  Anchor,
  ActionIcon,
  createStyles,
} from "@mantine/core";
import {
  ITicketsTableRequestModel,
  SearchKeywordList,
} from "./enquiryInbox.types";
import { useDataProvider, useRouterContext } from "@mydeal/core";
import { enquiryInboxService } from "@services";
import { IconSearch } from "@tabler/icons-react";

const useStyles = createStyles((theme) => ({
  searchContainer: {
    gap: 0,
    border: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[2]
    }`,
    borderRadius: 10,
    maxWidth: 250,
    padding: 3,
  },
}));

interface SearchTicketsCardProps {
  isEscalationProp?: string;
  searchKeywordProp?: string;
}

export const SearchTicketsCard = ({
  isEscalationProp,
  searchKeywordProp,
}: SearchTicketsCardProps) => {
  const { Link } = useRouterContext();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const { classes, cx, theme } = useStyles();
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );

  const [searchKeyword, setSearchKeyword] = useState<string>("");
  const [searchType, setSearchType] = useState<any>("");
  const [searchKeyInput, setSearchKeyInput] = useState<string>("");
  const [isEscalation, setIsEscalation] = useState<boolean>(false);

  const ticketTypes = [
    {
      value: SearchKeywordList.CustomerTickets,
      label: SearchKeywordList.CustomerTickets.toString(),
    },
    {
      value: SearchKeywordList.EscalatedTickets,
      label: SearchKeywordList.EscalatedTickets.toString(),
    },
  ];
  const errorText =
    "Please input Ticket ID, Order ID or Customer Name to search for tickets";

  useEffect(() => {
    setSearchKeyword(searchKeywordProp ? searchKeywordProp.toString() : "");
    setSearchKeyInput(
      searchKeywordProp ? searchKeywordProp.toString().replace("#", "") : ""
    );
    var loadEscalatedTickets = JSON.parse(
      isEscalationProp ? isEscalationProp : "false"
    );
    setSearchType(
      loadEscalatedTickets
        ? SearchKeywordList.EscalatedTickets.toString()
        : SearchKeywordList.CustomerTickets.toString()
    );
    setIsEscalation(loadEscalatedTickets);
  }, []);

  const handleSearhKeywordChange = (event: any) => {
    var val = event.currentTarget.value;
    setSearchKeyword(val);
    setSearchKeyInput(val.replace("#", ""));
  };

  const handleSearhTypeChange = (val: any) => {
    setSearchType(val?.toString());
    setIsEscalation(
      val?.toString() == SearchKeywordList.EscalatedTickets.toString()
    );
  };

  return (
    <>
      <Paper
        p="xl"
        sx={{ position: "relative", marginBottom: "20px" }}
        data-testid="enquiry-search"
      >
        <Group position="right">
          <Text fz="sm">
            Search By Ticket ID/Order Group No/Order No/Customer Name in
          </Text>
          <Select
            defaultValue={SearchKeywordList.CustomerTickets.toString()}
            data={ticketTypes}
            value={searchType}
            onChange={(val) => {
              handleSearhTypeChange(val);
            }}
            size="sm"
          />
          <Group className={classes.searchContainer} position="right">
            <TextInput
              radius="md"
              placeholder="Search"
              size="sm"
              icon={<IconSearch size={16} stroke={1.5} />}
              styles={{ rightSection: { pointerEvents: "none" } }}
              variant="unstyled"
              onChange={handleSearhKeywordChange}
              value={searchKeyword}
            />
            <ActionIcon
              disabled={!searchKeyword || searchKeyword.trim() === ""}
              component="a"
              href={`/enquiryinbox/search-tickets/${isEscalation}/${searchKeyInput}`}
              color="#F04A1C"
              size="lg"
              radius="md"
              variant="filled"
            >
              <IconSearch size="16" />
            </ActionIcon>
          </Group>
        </Group>
      </Paper>
    </>
  );
};
