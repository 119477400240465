export const formatAddress = (address: any) => {
  return (
    <span>
      {address.AddressLine1}
      <br />
      {address.AddressLine2 ? (
        <>
          {address.AddressLine2}
          <br />
        </>
      ) : (
        ''
      )}
      {`${address.Suburb}, ${address.PostalState}, ${address.PostalCode}`}
    </span>
  );
};
