import { Card } from "@mantine/core";
import { ContactDetailsEditor } from "./ContactDetailsEditor";
import { PageTitle } from "@components/shared";

export const ContactDetails = () => {
  return (
    <>
      <PageTitle
        title="Contact Details"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Company" },
          { name: "Contact Details" },
        ]}
      />

      <Card withBorder mih={300} p="xl">
        <ContactDetailsEditor onboarding={false} />
      </Card>
    </>
  );
};
