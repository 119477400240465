import React, { useState, useRef, useMemo } from "react";
import {
  Text,
  LoadingOverlay,
  Anchor,
  Badge,
  createStyles,
  Group,
  Select,
  SelectItem,
} from "@mantine/core";
import {
  useAuthenticatedApi,
  useDataProvider,
  useRouterContext,
} from "@mydeal/core";
import { enquiryInboxService } from "@services";
import {
  TicketStatus,
  TicketPriority,
  SatisfactionList,
  ITicketsTableRequestModel,
} from "./enquiryInbox.types";
import { IconAlertTriangle } from "@tabler/icons-react";
import { TicketStatusCard } from "./ticketStatusCard";
import {
  DynamicDataTable,
  IDynamicFetchParameters,
  IDataTableColumnProps,
} from "@mydeal/ui-mantine";

const useStyles = createStyles((theme) => ({
  ticketStatusBadge: {
    minWidth: 20,
    width: "auto",
    height: 20,
    padding: 2,
    marginLeft: 5,
  },
  slaBadge: {
    minWidth: 50,
    width: "auto",
    height: 30,
    padding: 2,
    marginLeft: 5,
  },
}));

interface TicketsDataTableProps {
  ticketStatus: TicketStatus;
  refreshTicketsCount?: any;
}

const getColumns = (
  ticketStatus: TicketStatus,
  Link: React.FC<any>,
  classes: ReturnType<typeof useStyles>["classes"],
  satisfactionList: any
) => {
  const columns: IDataTableColumnProps<any>[] = [
    {
      fieldName: "TicketId",
      header: "Ticket ID",
      size: 80,
      sortable: true,
      cellTemplate: (value: any, row: any) => (
        <>
          <Anchor component={Link} to={`/EnquiryInbox/ViewTicket/${value}`}>
            <span>#</span>
            {value} <span> </span>
          </Anchor>

          {row.Priority === TicketPriority.High && (
            <IconAlertTriangle color="red" size={14} />
          )}
        </>
      ),
    },
  ];
  if (
    ticketStatus === TicketStatus.Open ||
    ticketStatus === TicketStatus.Pending
  ) {
    columns.push({
      fieldName: "Priority",
      header: "Priority",
      size: 80,
      sortable: true,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }

  columns.push({
    fieldName: "Subject",
    header: "Subject",
    size: 290,
    sortable: true,
    cellTemplate: (value: any, row: any) => (
      <>
        <TicketStatusCard
          hasFreightProtection={row.HasFreightProtection}
          isEscalation={row.IsEscalation}
          isSellerEscalation={false}
          ticketStatus={TicketStatus[ticketStatus].toLowerCase()}
        />
        <Text>{value}</Text>
        {row.OrderId ? (
          <Text>{`From: ${row.RequesterName} (${row.OrderId})`}</Text>
        ) : (
          <Text>{`From: ${row.RequesterName}`}</Text>
        )}
      </>
    ),
  });

  if (
    ticketStatus === TicketStatus.Open ||
    ticketStatus === TicketStatus.Pending
  ) {
    columns.push({
      fieldName: "OrderGroupId",
      header: "Order Group No",
      size: 90,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }
  if (
    ticketStatus === TicketStatus.Open ||
    ticketStatus === TicketStatus.Pending
  ) {
    columns.push({
      fieldName: "OrderId",
      header: "Order No",
      size: 90,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }

  columns.push({
    fieldName: "CreatedDatePretty",
    header: "Created Date",
    size: 130,
    sortable: true,
    cellTemplate: (value: any, row: any) => (
      <>
        <Text>{value}</Text>
        {row.DaysSinceCreated > 2 ? (
          <Text color="orange">{`(${row.DaysSinceCreated} days)`}</Text>
        ) : (
          ""
        )}
      </>
    ),
  });

  if (
    ticketStatus !== TicketStatus.Solved &&
    ticketStatus !== TicketStatus.SolvedEscalation
  ) {
    columns.push({
      fieldName: "AgentLastResponseDate",
      header:
        ticketStatus === TicketStatus.Escalation
          ? "MyDeal Latest Update"
          : "Seller Last Response",
      size: 130,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }

  if (
    ticketStatus === TicketStatus.Open ||
    ticketStatus === TicketStatus.Pending
  ) {
    columns.push({
      fieldName: "CustomerLastResponseDate",
      header: "Customer Last Response",
      size: 130,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }
  if (ticketStatus === TicketStatus.Escalation) {
    columns.push({
      fieldName: "SellerLastResponseDatePretty",
      header: "Seller Latest Update",
      size: 130,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }

  if (
    ticketStatus !== TicketStatus.Solved &&
    ticketStatus !== TicketStatus.SolvedEscalation
  ) {
    columns.push({
      fieldName: "SlaBreachText",
      header: "Next SLA Breach",
      size: 80,
      sortable: false,
      cellTemplate: (value: any, row: any) => (
        <>
          {row.SlaBreachStage &&
          row.SlaBreachStage.toLowerCase() === "paused" ? (
            <Badge
              radius="sm"
              variant="filled"
              color="green"
              className={classes.slaBadge}
            >
              Paused
            </Badge>
          ) : (
            <Badge
              radius="sm"
              variant="filled"
              color="red"
              className={classes.slaBadge}
            >
              {value}
            </Badge>
          )}
        </>
      ),
    });
  }

  if (ticketStatus === TicketStatus.Solved) {
    columns.push({
      fieldName: "SolvedDate",
      header: "Solved Date",
      size: 150,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });

    const satisfactionScore: IDataTableColumnProps<any> = {
      fieldName: "SatisfactionScore",
      header: "Satisfaction",
      size: 150,
      sortable: false,
      cellTemplate: (value: any, row: any) => {
        var color = "white";
        switch (value.toLowerCase()) {
          case SatisfactionList.good: {
            color = "green";
            break;
          }
          case SatisfactionList.bad: {
            color = "red";
            break;
          }
          default: {
            color = "gray";
            break;
          }
        }
        return (
          <>
            <Badge size="sm" variant="filled" color={color}>
              {row.SatisfactionText}
            </Badge>
          </>
        );
      },
    };

    if (satisfactionList) {
      satisfactionScore.filterOptions = satisfactionList.map(
        (item: { Value: any; Text: any }) =>
          ({
            value: item.Value,
            label: item.Text,
          } as SelectItem)
      );
    }

    columns.push(satisfactionScore);
  }
  if (ticketStatus === TicketStatus.SolvedEscalation) {
    columns.push({
      fieldName: "UpdatedDatePretty",
      header: "Last Updated",
      size: 150,
      sortable: false,
      cellTemplate: (value: any) => <Text>{value}</Text>,
    });
  }

  return columns;
};

export const TicketsDataTable = ({
  ticketStatus,
  refreshTicketsCount,
}: TicketsDataTableProps) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const { Link } = useRouterContext();
  const { classes } = useStyles();

  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [loading, setLoading] = useState<boolean>(true);
  const [model, setModel] = useState<any>();

  const satisfactionList = model?.ticketModel?.SatisfactionList;

  const columns = useMemo(
    () => getColumns(ticketStatus, Link, classes, satisfactionList),
    [Link, classes, ticketStatus, satisfactionList]
  );

  const fetchData = React.useCallback(
    async ({ pageSize, pageIndex, sort, filters }: IDynamicFetchParameters) => {
      var dataTableRequest: ITicketsTableRequestModel = {
        PageIndex: pageIndex,
        PageSize: pageSize,
        Sort: sort.length > 0 ? sort[0].id : "",
        SortType: sort.length > 0 ? (sort[0].desc ? "desc" : "asc") : "",
        Filters: filters
          ? filters.map((f) => ({ FilterType: f.id, Value: f.value as string }))
          : undefined,
      };
      const result = await _enquiryInboxService.loadTickets(
        ticketStatus,
        dataTableRequest
      );
      setModel(result);
      if (refreshTicketsCount) {
        refreshTicketsCount(
          result && result.ticketModel ? result.ticketModel.TotalTicket : 0
        );
      }
      return result;
    },
    [_enquiryInboxService, refreshTicketsCount, ticketStatus]
  );

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  return (
    <>
      <LoadingOverlay visible={loading} />
      {!loading && (
        <DynamicDataTable<any, any>
          sortable={true}
          initialSorting={[{ id: "Priority", desc: true }]}
          filterable={true}
          mantineTableBodyRowProps={{}}
            initialFilters={[
            { id: "SatisfactionScore", value: SatisfactionList.ShowAll },
          ]}
          fetchData={fetchData}
          getData={(response) => response?.ticketModel?.TicketModels}
          getCount={(response) => response?.ticketModel?.TotalTicket || 0}
          // ref={ticketDataTableRef}
          columns={columns}
        />
      )}
    </>
  );
};
