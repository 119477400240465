import { useMemo, useState } from "react";
import * as yup from "yup";
import { Label, PageTitle, ErrorAlert } from "@components/shared";
import {
  useFocusableForm,
  FormSelect,
  FormTextArea,
  StaticDataTable,
} from "@mydeal/ui-mantine";
import {
  Alert,
  Anchor,
  Card,
  Group,
  LoadingOverlay,
  SimpleGrid,
  Table,
  Text,
  Title,
  Stack,
  Button,
  NumberInput,
  Divider
} from "@mantine/core";
import {
  useDataProvider,
  useRouterContext,
  useNotification,
  useAuthenticatedApi,
} from "@mydeal/core";
import { orderService } from "@services";
import {
  IconAlertCircle,
  IconCurrencyDollar,
  IconExternalLink,
  IconInfoCircle,
  IconMapPin,
  IconPhone,
  IconUser,
} from "@tabler/icons-react";

import {
  OrderItemModel,
  OrderType,
  RefundHistoryModel,
  RefundViewModel,
  VoucherState,
} from "./types";
import moment from "moment";
import { MarketplaceBadge } from "../../components/shared/MarketplaceBadge";

interface IRouteParams {
  dealId: string;
}

export const Refund = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _orderService = useMemo(
    () => orderService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { useParams, Link } = useRouterContext();
  const { dealId } = useParams<IRouteParams>();
  const { open: notify } = useNotification();

  const [model, setModel] = useState<RefundViewModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [isAddon, setIsAddon] = useState<boolean>(false);
  const [refundTotalAmount, setRefundTotalAmount] = useState<number>(0);
  const [refundSalePrice, setRefundSalePrice] = useState<number>(0);
  const [refundShippingPrice, setRefundShippingPrice] = useState<number>(0);
  const [showFullRefundRequiredError, setShowFullRefundRequiredError] = useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    await _orderService
      .getRefundDetails(Number(dealId))
      .then((result: any) => {
        setIsAddon(result.OrderItemModel.OrderType === 1); //TODO: use enum
        setModel(result);
        setRefundTotalAmount(result?.RefundItemModel?.RefundTotalAmount);
        setRefundSalePrice(result?.RefundItemModel?.RefundSalePrice);
        setRefundShippingPrice(result?.RefundItemModel?.RefundShippingPrice);
        form.setValues(result);
      })
      .finally(() => setLoading(false));
  };

  useAuthenticatedApi(dataProviderInstance, () => {
    fetchData();
  });

  const form = useFocusableForm<{[Property in keyof RefundViewModel]: any;}>({
    schema: yup.object({
      RefundItemModel: yup.object({
        RefundSalePrice: yup.mixed().required("Item amount is required"),
        RefundShippingPrice: yup
          .mixed()
          .required("Shipping amount is required"),
      }),
      RefundReason: yup.mixed().required("Please select a reason"),
      AdditionalNote: yup.mixed().required("Please enter notes to customer"),
    }),
  });

  const fullRefundIsRequired = (data: Partial<RefundViewModel>) => {
    const showError = data.IsFullRefundRequired === true && refundTotalAmount < (data.OrderItemModel?.TotalSalePrice ?? 0);
    setShowFullRefundRequiredError(showError);
    return showError;
  }

  const onSubmit = (data: Partial<RefundViewModel>) => {
    if (fullRefundIsRequired(data)) {
      return;
    }

    setLoading(true);

    _orderService
      .saveRefund(data)
      .then((result: any) => {
        notify?.({
          type: "success",
          message: result.SuccessMessage,
          description: "",
        });
        fetchData();
      })
      .catch((err) => {
        const {
          response: {
            statusText,
            data: { ErrorMessage, title },
          },
        } = err;

        notify?.({
          type: "error",
          message: ErrorMessage ?? title,
          description: "",
        });
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const renderOrderStatus = (status: VoucherState) => {
    switch (status) {
      case VoucherState.Processing:
        return "Processing";
      case VoucherState.CardApproved:
        return "Card Approved";
      case VoucherState.CardDeclined:
        return "Card Declined";
      case VoucherState.Refunded:
        return "Refunded";
      case VoucherState.Purchased:
        return "Purchased";
    }
    return "";
  };

  const renderOrderStatusColumn = (order: OrderItemModel) => {
    if (order.OrderType === OrderType.Addon) {
      return <Text>{renderOrderStatus(order.Status)}</Text>;
    }

    if (!order.IsDeliveryOnHold) {
      return (
        <Stack>
          <Text>{renderOrderStatus(order.Status)}</Text>
          {order.IsPaid && (
            <>
              <Text>Paid</Text>{" "}
              {order.InvoiceName && <Text>{order.InvoiceName}</Text>}
            </>
          )}
        </Stack>
      );
    } else {
      if (order.Status === VoucherState.Refunded) return <Text>Refunded</Text>;
      else if (order.IsPaid)
        return (
          <>
            <Text>Paid</Text>
            {order.InvoiceName && <Text>{order.InvoiceName}</Text>}
          </>
        );
      else {
        return <Text>On Hold</Text>;
      }
    }
  };

  return (
    <>
      <PageTitle
        title={`Issue Refund`}
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Deals", route: "/mydeals" },
          {
            name: "Order details",
            route: `/order/${isAddon ? model?.OrderItemModel?.ParentOrderNumber : dealId
              }`,
          },
          { name: "Issue Refund" },
        ]}
      />

      <Card withBorder mih={300} data-testid="myaccount-bankdetails">
        <Card.Section py="sm" px="xl">
          <Group position="apart">
            <Title order={5} mb="xs">
              Issue Refund
            </Title>
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10036578637967"
              fz="md"
              target="_blank"
              fw="normal"
            >
              All about refunds <IconExternalLink size={14} />
            </Anchor>
          </Group>
          <Divider />
        </Card.Section>

        <Card.Section py="sm" px="xl">
          <LoadingOverlay visible={loading} />
          <Stack spacing={"lg"}>
            {model && (
              <>
                <Card>
                  <Card.Section p="sm">
                    <Title order={4}>Order items:</Title>
                  </Card.Section>
                  <Card.Section p="sm">
                    <StaticDataTable<OrderItemModel>
                      data={[model.OrderItemModel]}
                      hasPagination={false}
                      mantineTableBodyRowProps={{}}
                                    columns={[
                        {
                          fieldName: "OrderNumber",
                          header: "Order No.",
                          size: 150,
                          cellTemplate: (value: any, order: OrderItemModel) => {
                              return (
                                  <Stack>
                                      <MarketplaceBadge text={model.OrderItemModel.CustomerMarketplace}></MarketplaceBadge>
                                    {(order.OrderType === OrderType.Normal ||
                                     order.OrderType === OrderType.Special) &&
                                     (order.Status === VoucherState.Purchased ||
                                     order.Status === VoucherState.CardApproved) ? 
                                     (<Anchor component={Link} to={`/order/${order.OrderNumber}`}>{order.OrderNumber}</Anchor>) : 
                                     (<Text>{order.OrderNumber}</Text>)}
                                  </Stack>)
                              },
                          },
                        {
                          fieldName: "Recipient",
                          header: "Recipient",
                          size: 150,
                          cellTemplate: (value: any) => (
                            <Group>
                              <IconUser size={16} /> {value}
                            </Group>
                          ),
                        },
                        {
                          fieldName: "AddressLine",
                          header: "Address",
                          size: 200,
                          cellTemplate: (value: any, order: OrderItemModel) => {
                            return (
                              <>
                                {value && (
                                  <Stack spacing="sm">
                                    <Group>
                                      <IconMapPin size={16} />
                                      <span>
                                        {order.AddressLine}
                                        <br />
                                        {order.AddressLine2 ? (
                                          <>
                                            {order.AddressLine2}
                                            <br />
                                          </>
                                        ) : (
                                          ""
                                        )}
                                        {`${order.Suburb}, ${order.State}, ${order.Postcode}`}
                                      </span>
                                    </Group>
                                    <Group>
                                      <IconPhone size={16} />{" "}
                                      {order.PhoneNumber || "n/a"}
                                    </Group>
                                  </Stack>
                                )}
                              </>
                            );
                          },
                        },
                        {
                          fieldName: "OrderType",
                          header: "Status",
                          size: 150,
                          cellTemplate: (value: any, order: OrderItemModel) => {
                            return <>{renderOrderStatusColumn(order)}</>;
                          },
                        },
                        {
                          fieldName: "OrderDate",
                          header: "Order Details",
                          size: 150,
                          cellTemplate: (value: any, order: OrderItemModel) => {
                            return (
                              <>
                                <Stack spacing={5} fz="xs">
                                  <Group spacing={5}>
                                    <Text fw="bold">Date: </Text>
                                    {new Date(
                                      order.OrderDate
                                    ).toLocaleDateString("en-GB")}
                                  </Group>
                                  <Group spacing={5}>
                                    <Text fw="bold">Order: </Text>
                                    {order.Qty}x (SKU: {order.Sku})
                                  </Group>
                                  <Group spacing={5}>
                                    <Text fw="bold">Price(Per Unit): </Text>
                                    {`$${order.UnitPrice.toFixed(2)}`}
                                  </Group>
                                  <Group spacing={5}>
                                    <Text fw="bold">Shipping: </Text>
                                    {`$${order.TotalShippingPrice.toFixed(2)}`}
                                  </Group>
                                  <Group spacing={5}>
                                    <Text fw="bold">Total Sale Price: </Text>
                                    {`$${order.TotalSalePrice.toFixed(2)}`}
                                  </Group>
                                </Stack>
                              </>
                            );
                          },
                        },
                        {
                          fieldName: "TrackingNumber",
                          header: "Sent",
                          size: 100,
                          cellTemplate: (value: any, order: OrderItemModel) => {
                            return (
                              <>
                                <Stack spacing={5} fz="xs">
                                  <Group spacing={5}>
                                    <Text fw="bold">Tracking No: </Text>
                                    {order.TrackingNumber}
                                  </Group>
                                  <Group spacing={5}>
                                    <Text fw="bold">Courier: </Text>
                                    {order.Courier}
                                  </Group>
                                  <Text fw="bold">Supplier Notes: </Text>

                                  <Stack spacing={1} fz="xs">
                                    {order.SupplierNotes.map(
                                      (note: string, index) => (
                                        <Text size={"xs"} key={index}>
                                          {note}
                                        </Text>
                                      )
                                    )}
                                  </Stack>
                                </Stack>
                              </>
                            );
                          },
                        },
                      ]}
                    />
                  </Card.Section>
                  <Card.Section p="sm">
                    <SimpleGrid cols={3}>
                      {model.IsEligibleForRefundCreditOffer &&
                        model.HasPendingRefundCreditOffer ? (
                        <Alert
                          icon={<IconInfoCircle size={18} />}
                          title="This order has a pending credit refund offer."
                        >
                          <span></span>
                        </Alert>
                      ) : model.RefundItemModel.IsRefundable ? (
                        <Alert
                          title={`$${model.RefundItemModel.RefundTotalAmount.toFixed(
                            2
                          )} available to refund`}
                          color="blue"
                          icon={<IconInfoCircle size={18} />}
                        >
                          <Text color={"dimmed"} fw="bold">
                            {`Amount refunded to date: $${model.RefundedCustomerPrice.toFixed(
                              2
                            )}`}
                          </Text>
                        </Alert>
                      ) : (
                        <Alert
                          title={`Non refundable purchase.`}
                          icon={<IconAlertCircle size={18} />}
                          color="red"
                        >
                          <Text color={"dimmed"} fw="bold">
                            Gift card purchases are classified as non refundable
                            purchases.
                          </Text>
                        </Alert>
                      )}
                    </SimpleGrid>
                  </Card.Section>
                </Card>
                {model.RefundHistoryModels.length > 0 && (
                  <Card withBorder>
                    <Card.Section p="sm">
                      <Title order={4}>View Refund History</Title>
                    </Card.Section>
                    <Card.Section p="sm">
                      <StaticDataTable<RefundHistoryModel>
                        data={model.RefundHistoryModels}
                        hasPagination={false}
                        mantineTableBodyRowProps={{}}
                                        columns={[
                          {
                            fieldName: "TransactionDate",
                            header: "Date",
                            size: 200,
                            cellTemplate: (value: any) => (
                              <>{moment(value).format("DD/MM/YYYY")}</>
                            ),
                          },
                          {
                            fieldName: "OrderNo",
                            header: "Order No.",
                            size: 200,
                            cellTemplate: (
                              value: any,
                              item: RefundHistoryModel
                            ) => {
                              return (
                                <>
                                  {item.RefundHistoryOrderItemModels.map(
                                    (item, index) => (
                                      <div key={index}>{item.BidDealId}</div>
                                    )
                                  )}
                                </>
                              );
                            },
                          },

                          {
                            fieldName: "RefundHistoryOrderItemModels",
                            header: "Refund Amount",
                            size: 200,
                            cellTemplate: (
                              value: any,
                              item: RefundHistoryModel
                            ) => {
                              return (
                                <>
                                  {item.RefundHistoryOrderItemModels.map(
                                    (item, index) => (
                                      <div
                                        key={index}
                                      >{`$${item.CustomerPrice.toFixed(
                                        2
                                      )}`}</div>
                                    )
                                  )}
                                </>
                              );
                            },
                          },
                          {
                            fieldName: "PaymentMethod",
                            header: "Payment Method",
                            size: 200,
                            cellTemplate: (value: any) => <div>{value}</div>,
                          },
                          {
                            fieldName: "RefundReason",
                            header: "Summary",
                            size: 200,
                            cellTemplate: (
                              value: any,
                              item: RefundHistoryModel
                            ) => {
                              return (
                                <>
                                  <strong>{item.RefundReason}</strong>
                                  <div>{item.RefundNoteContent}</div>
                                </>
                              );
                            },
                          },
                          {
                            fieldName: "Approved",
                            header: "Status",
                            size: 200,
                            cellTemplate: (value: any) => (
                              <span>{value ? "Approved" : "Processing"}</span>
                            ),
                          },
                        ]}
                      />
                    </Card.Section>
                  </Card>
                )}
                <Card>
                  <Card.Section>
                    {model.RefundItemModel.RefundTotalAmount > 0 &&
                      model.RefundItemModel.IsRefundable &&
                      !model.HasPendingRefundCreditOffer && (
                        <Card withBorder p="sm">
                          <Card.Section
                            withBorder
                            p="sm"
                            sx={(theme) => ({
                              backgroundColor:
                                theme.colorScheme === "dark"
                                  ? theme.colors.dark[7]
                                  : theme.colors.gray[0],
                            })}
                          >
                            <Title order={4}>Issue refund</Title>
                          </Card.Section>
                          <Card.Section p="sm">
                            <form onSubmit={form.onSubmit(onSubmit)}>
                              <Stack spacing={"sm"}>
                                <Table
                                  withBorder
                                  withColumnBorders
                                  striped
                                  mb="md"
                                >
                                  <thead>
                                    <tr>
                                      <th colSpan={2}>
                                        <Text fw="bold">{`${model.RefundItemModel.Qty
                                          } x ${isAddon
                                            ? "Additional Payment for"
                                            : ""
                                          } ${model.RefundItemModel.ProductTitle
                                          } ${model.RefundItemModel.VariantOption
                                            ? "," +
                                            model.RefundItemModel
                                              .VariantOption
                                            : ""
                                          }`}</Text>
                                      </th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr>
                                      <td width="250"></td>
                                      <td>
                                        <strong>Refund Amount</strong>
                                      </td>
                                    </tr>
                                    <tr>
                                      <td>
                                        <Label required>Item</Label>{" "}
                                      </td>
                                      <td>
                                        <NumberInput
                                          icon={
                                            <IconCurrencyDollar size={14} />
                                          }
                                          {...form.getInputProps(
                                            "RefundItemModel.RefundSalePrice"
                                          )}
                                          min={0}
                                          max={
                                            model.RefundItemModel
                                              .RefundSalePrice
                                          }
                                          precision={2}
                                          step={0.01}
                                          onChange={(data) => {
                                            if (typeof data === "number") {
                                              setRefundSalePrice(data);
                                              setRefundTotalAmount(
                                                data +
                                                (refundShippingPrice
                                                  ? refundShippingPrice
                                                  : 0)
                                              );
                                              form.setFieldValue(
                                                "RefundItemModel.RefundSalePrice",
                                                Number(data)
                                              );
                                            }
                                          }}
                                        />
                                      </td>
                                    </tr>
                                    {model.OrderItemModel.HasFreight &&
                                      !isAddon && (
                                        <tr>
                                          <td>
                                            <Label required>Shipping</Label>
                                          </td>
                                          <td>
                                            <NumberInput
                                              icon={
                                                <IconCurrencyDollar size={14} />
                                              }
                                              {...form.getInputProps(
                                                "RefundItemModel.RefundShippingPrice"
                                              )}
                                              min={0}
                                              max={
                                                model.RefundItemModel
                                                  .RefundShippingPrice!
                                              }
                                              precision={2}
                                              step={0.01}
                                              onChange={(data) => {
                                                if (typeof data === "number") {
                                                  setRefundShippingPrice(data);
                                                  setRefundTotalAmount(
                                                    refundSalePrice +
                                                    (data ? data : 0)
                                                  );
                                                  form.setFieldValue(
                                                    "RefundItemModel.RefundShippingPrice",
                                                    Number(data)
                                                  );
                                                }
                                              }}
                                            />

                                            {model.RefundItemModel
                                              .OriginalFreightAmount != null &&
                                              model.RefundItemModel
                                                .BidDealGroupId != null && (
                                                <strong>
                                                  This item is part of a
                                                  combined order{" "}
                                                  <Anchor
                                                    href={`/Search?query=${model.RefundItemModel.BidDealGroupId}`}
                                                  >
                                                    {
                                                      model.RefundItemModel
                                                        .BidDealGroupId
                                                    }
                                                  </Anchor>
                                                </strong>
                                              )}
                                          </td>
                                        </tr>
                                      )}
                                    <tr>
                                      <td>
                                        <strong> Total Refund Amount</strong>
                                      </td>
                                      <td>
                                        <strong>
                                          {`$${refundTotalAmount.toFixed(2)}`}
                                        </strong>
                                      </td>
                                    </tr>
                                  </tbody>
                                </Table>
                                <FormSelect
                                  data={model.RefundReasonList.map((item) => {
                                    return {
                                      value: item.Value,
                                      label: item.Text,
                                    };
                                  })}
                                  withAsterisk
                                  label="Reason:"
                                  placeholder="-- SELECT --"
                                  fieldName="RefundReason"
                                  parentForm={form}
                                />
                                <FormTextArea
                                  label="Refund Notes (optional) Only viewable to you and MyDeal"
                                  fieldName="RefundNote"
                                  placeholder="Maximum 300 characters"
                                  maxLength={300}
                                  parentForm={form}
                                />
                                {/* <Checkbox
                                label="Is sent email to buyer"
                                {...form.getInputProps("IsSentEmailToBuyer", {
                                  type: "checkbox",
                                })}
                              /> */}
                                <FormTextArea
                                  withAsterisk
                                  label="Notes to customer (included in refund notification)"
                                  maxLength={500}
                                  placeholder="Maximum 500 characters"
                                  fieldName="AdditionalNote"
                                  parentForm={form}
                                />

                                <Alert title="NOTE" color={"yellow"}>
                                  <ul>
                                    <li>Issuing a refund cannot be undone</li>
                                    <li>
                                      Please update and/or resolve any
                                      associated tickets if applicable.
                                    </li>
                                  </ul>
                                </Alert>
                                <Group>
                                  <Button
                                    type="submit"
                                    onClick={() => {
                                      form.setFieldValue(
                                        "IsCreateRefundCreditOffer",
                                        false
                                      );
                                    }}
                                    loading={loading}
                                  >
                                    {`Refund $${refundTotalAmount.toFixed(2)}`}
                                  </Button>
                                  {model.IsEligibleForRefundCreditOffer && (
                                    <Button
                                      type="submit"
                                      onClick={() => {
                                        form.setFieldValue(
                                          "IsCreateRefundCreditOffer",
                                          true
                                        );
                                      }}
                                      disabled={
                                        model.HasPartialRefundOrCredit ||
                                        model.HasCreditOrGiftCardApplied ||
                                        model.HasPendingRefundCreditOffer ||
                                        refundTotalAmount.toFixed(2) !==
                                        model.RefundItemModel.RefundTotalAmount.toFixed(
                                          2
                                        )
                                      }
                                      loading={loading}
                                    >
                                      Send Refund Offer
                                    </Button>
                                  )}
                                </Group>
                                {showFullRefundRequiredError && <ErrorAlert>
                                  Your refund request could not be processed. Only full refunds are accepted on orders “Pending Dispatch” by {model.OrderItemModel.CustomerMarketplaceDisplayName}. If you cannot fulfil this order we advise you to:
                                  <ol>
                                    <li>Do not dispatch the available items</li>
                                    <li>Resubmit a full-refund request</li>
                                    <li>Request the customer to reorder with the adjusted quantity</li>
                                  </ol>
                                </ErrorAlert>}
                              </Stack>
                            </form>
                          </Card.Section>
                        </Card>
                      )}
                  </Card.Section>
                </Card>
              </>
            )}
          </Stack>
        </Card.Section>
      </Card>
    </>
  );
};
