import { useState, ReactNode } from "react";
import {
  IconAdjustments,
  IconBookmark,
  IconHeart,
  IconShare,
  IconX,
} from "@tabler/icons-react";
import {
  Card,
  Image,
  Text,
  ActionIcon,
  Group,
  Center,
  Avatar,
  createStyles,
  Title,
  Modal,
  Grid,
  Blockquote,
  ScrollArea,
} from "@mantine/core";
import { useMediaQuery } from "@mantine/hooks";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
  },

  title: {
    display: "block",
    marginTop: theme.spacing.md,
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
    color: theme.primaryColor[7],
  },

  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    }),
  },

  footer: {
    marginTop: theme.spacing.md,
  },

  modelWrapper: {
    maxWidth: "1600px",
  },

  imageWrapper: {
    "&:hover": {
      cursor: "pointer",
    },
  },

  closeButton: {
    position: "absolute",
    top: "10px",
    right: "10px",
  },
}));

interface ArticleCardProps {
  image: string;
  link: string;
  title: string;
  author: string;
}

export function ArticleCard({
  className,
  image,
  link,
  title,
  author,
  children,
  ...others
}: ArticleCardProps &
  Omit<React.ComponentPropsWithoutRef<"div">, keyof ArticleCardProps>) {
  const { classes, cx, theme } = useStyles();
  const [opened, setOpened] = useState(false);
  const isMobileBreakPoint = useMediaQuery("(max-width: 520px)");
  return (
    <>
      <Modal
        opened={opened}
        onClose={() => setOpened(false)}
        title=""
        className={cx(classes.modelWrapper, className)}
        centered
        size={isMobileBreakPoint ? "100%" : "75%"}
        withCloseButton={false}
      >
        <ActionIcon
          className={cx(classes.closeButton, className)}
          size="lg"
          radius="xl"
          variant="light"
          color={theme.primaryColor}
          onClick={() => setOpened(false)}
        >
          <IconX size={24} />
        </ActionIcon>
        <Grid pt="40px">
          <Grid.Col sm={12} md={4} lg={4}>
            <Card withBorder radius="md">
              <Image src={image} />
            </Card>
            <Blockquote cite={`- ${author}`} color="brand" pt="md">
              <Title color="brand" size="md">
                {title}
              </Title>
            </Blockquote>
          </Grid.Col>
          <Grid.Col sm={12} md={8} lg={8}>
            <ScrollArea style={{ height: 600 }} type="auto">
              {children}
            </ScrollArea>
          </Grid.Col>
        </Grid>
      </Modal>

      <a
        onClick={() => setOpened(true)}
        className={cx(classes.imageWrapper, className)}
      >
        <Card
          withBorder
          radius="md"
          className={cx(classes.card, className)}
          {...others}
        >
          <Card.Section>
            <Image src={image} height={180} />
          </Card.Section>

          <Title order={4} color="brand" weight={500}>
            " {title} "
          </Title>

          <Group position="apart" className={classes.footer}>
            <Center>
              <Avatar
                size={30}
                radius="xl"
                mr="xs"
                color={theme.primaryColor}
              />
              <Text size="sm" inline>
                {author}
              </Text>
            </Center>
          </Group>
        </Card>
      </a>
    </>
  );
}
