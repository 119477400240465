import { Label } from "@components/shared";
import { FormSwitch, FormTextField, IFocusableForm } from "@mydeal/ui-mantine";

import {
  Card,
  Divider,
  Grid,
  Group,
  Stack,
  Text,
  TextInput,
  Title,
} from "@mantine/core";
import { IChannelType, IIntegrationConfigModel, SourceChannel } from "@types";

export const CustomIntegrationSettings = ({
  channelType,
  form,
  onboarding,
}: {
  channelType: IChannelType;
  form: IFocusableForm<Partial<IIntegrationConfigModel>>;
  onboarding: boolean;
}) => {
  const { AutoPriceUpdate, SellerId, SellerToken } = form.getValues();

  return (
    <Card withBorder mt="xl">
      <Card.Section p="sm">
        <Group position="apart" align="center">
          <Title order={5} mb="xs">
            {(channelType.id === SourceChannel.ShopifyApp ||
              channelType.id === SourceChannel.Shopify) && (
              <>Shopify Configuration Settings</>
            )}

            {(channelType.id === SourceChannel.NetoApp ||
              channelType.id === SourceChannel.Neto) && (
              <>Neto Configuration Settings</>
            )}
          </Title>
        </Group>
        <Divider variant="dashed" />
      </Card.Section>
      <Stack spacing="md">
        {(channelType.id === SourceChannel.ShopifyApp ||
          channelType.id === SourceChannel.Shopify) && (
          <FormTextField
            label="Shopify Store URL"
            readOnly={true}
            parentForm={form}
            fieldName="ShopifyAppStoreUrl"
          />
        )}
        {(channelType.id === SourceChannel.NetoApp ||
          channelType.id === SourceChannel.Neto) && (
          <FormTextField
            label="Neto Store URL"
            readOnly={!onboarding}
            parentForm={form}
            fieldName="NetoAppStoreUrl"
          />
        )}
        {!onboarding && channelType.isExternal && (
          <Title order={5}>Connection Details</Title>
        )}
        <Divider />
        {(channelType.id === SourceChannel.ShopifyApp ||
          channelType.id === SourceChannel.Shopify) && (
          <>
            <FormSwitch
              size="lg"
              readOnly={!onboarding}
              label="Your product prices on MyDeal will be the same as on your Shopify store"
              onLabel="Yes"
              description="Price will be fetched from shopify store automatically"
              offLabel="No"
              parentForm={form}
              fieldName="AutoPriceUpdate"
            />

            {AutoPriceUpdate && (
              <Text>
                Your product prices on MyDeal will be the same as on your
                Shopify store
              </Text>
            )}
            {typeof AutoPriceUpdate !== "undefined" && !AutoPriceUpdate && (
              <Text>
                Your products will be imported to MyDeal without any price and
                they will not go live until prices are assigned manually via the
                MyDeal Seller Portal
              </Text>
            )}
            <Text weight={600}>
              Note: Stock, orders and tracking details will be synced
              automatically with your Shopify store
            </Text>
            <hr />
          </>
        )}

        {!onboarding && channelType.isExternal && (
          <>
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label>Mydeal Seller ID</Label>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <TextInput readOnly value={SellerId} />
              </Grid.Col>
            </Grid>
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label>API Token</Label>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <TextInput readOnly value={SellerToken} />
              </Grid.Col>
            </Grid>
          </>
        )}
      </Stack>
    </Card>
  );
};
