import { useEffect, useMemo, useState } from "react";
import { useAuthenticatedApi, useDataProvider } from "@mydeal/core";
import { couponService } from "@services/coupon.service";
import { CouponStatus, IPromoEventModel } from "./types";
import { CouponPromoModel } from "./CouponPromoModel";
import { PageTitle } from "@components/shared";
import {
  Alert,
  Anchor,
  Button,
  Divider,
  Group,
  LoadingOverlay,
  Modal,
  Paper,
  Space,
  Stack,
  Table,
  createStyles,
  Title,
} from "@mantine/core";
import {
  IconAlertCircle,
  IconEdit,
  IconExternalLink,
  IconMinus,
  IconPlus,
} from "@tabler/icons-react";
import { GetCreditDebitsReport } from "./GetCreditDebitReport";
import { CancleCouponPromoModel } from "./CancelCouponPromoModel";
import moment from "moment";
const useStyles = createStyles((theme) => ({
  underlineText: {
    textDecoration: "underline",
  },
}));

export const CouponPromotion = () => {
  const { classes } = useStyles();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _couponServices = couponService(dataProviderInstance);
  const [couponModel, setCouponModel] = useState<IPromoEventModel>();
  const [loading, setLoading] = useState<boolean>(false);
  const [openCouponModal, setOpenCouponModal] = useState<boolean>(false);
  const [openCancelCouponModal, setOpenCancelCouponModal] =
    useState<boolean>(false);

  const fetchData = async () => {
    setLoading(true);
    await _couponServices
      .loadCoupon()
      .then((result: any) => {
        if (result) {
          if (result.Model.length > 0) {
            result.Model[0].StartDate = new Date(result.Model[0].StartDate);
            result.Model[0].ExpiryDate = new Date(result.Model[0].ExpiryDate);
          }
          setCouponModel(result);        
        } else {
          setCouponModel({
            CouponID: 0,
            Model: [],
          } as any);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const toggleCouponPromoModel = (reload?: boolean) => {
    setOpenCouponModal(!setOpenCouponModal);
    if (reload) {
      fetchData();
    }
  };

  const toggleCancelCouponPromoModel = (reload?: boolean) => {
    setOpenCancelCouponModal(!setOpenCancelCouponModal);
    if (reload) {
      fetchData();
    }
  };

  useAuthenticatedApi(dataProviderInstance, () => {
    fetchData();
  });

  return (
    <>
      <>
        <Stack>
          <LoadingOverlay visible={loading} />
          <PageTitle
            title="Coupon Promotion"
            breadcrumbs={[
              { name: "Home", route: "/dashboard" },
              { name: "Coupon Promotion" },
            ]}
          />

          {couponModel && (
            <>
              {couponModel.Model.length == 0 && (
                <Alert
                  icon={<IconAlertCircle size={16} />}
                  title=""
                  color="yellow"
                >
                  <strong>Note:</strong> You currently have no active/pending
                  coupon set-up. To set-up a coupon simply click the "{" "}
                  <span className={classes.underlineText}>Create Coupon</span>"
                  button
                </Alert>
              )}
              <Group>
                {couponModel.Model.length === 0 ? (
                  <Stack>
                    <Button
                      size="md"
                      leftIcon={<IconPlus size={16} />}
                      onClick={() => {
                        setOpenCouponModal(true);
                      }}
                    >
                      Create Coupon
                    </Button>
                  </Stack>
                ) : (
                  <>
                    <Button
                      onClick={() => {
                        setOpenCouponModal(true);
                      }}
                       size="md"
                       disabled={couponModel.Model[0].Status === 0}
                      leftIcon={<IconEdit size={16} />}
                    >
                      Edit Coupon
                    </Button>

                    <Button
                      onClick={() => {
                        setOpenCancelCouponModal(true);
                      }}
                      size="md"
                      variant="outline"
                    >
                      Cancel Coupon
                    </Button>
                  </>
                )}
              </Group>

              <Paper p="xl" sx={{ position: "relative" }}>
                <Table striped withColumnBorders>
                  <thead>
                    <tr>
                      <th>Start Date</th>
                      <th>Expiry Date</th>
                      <th>Discount Percentage (%)</th>
                      <th>Minimum Cart Value</th>
                      <th>Status</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr
                      key={
                        couponModel?.Model[0] !== undefined
                          ? couponModel?.Model[0].CouponID
                          : ""
                      }
                    >
                      <td>
                        {couponModel?.Model[0] !== undefined
                          ? moment(couponModel.Model[0].StartDate).format(
                              "DD/MM/YYYY"
                            )
                          : ""}
                      </td>
                      <td>
                        {couponModel?.Model[0] !== undefined
                          ? moment(couponModel.Model[0].ExpiryDate).format(
                              "DD/MM/YYYY"
                            )
                          : ""}
                      </td>
                      <td>
                        {couponModel?.Model[0] !== undefined
                          ? couponModel.Model[0].DiscountPercentage
                          : ""}
                      </td>
                      <td>
                        {couponModel?.Model[0] !== undefined
                          ? couponModel.Model[0].MinCartValue
                          : ""}
                      </td>
                      <td>
                        {couponModel?.Model[0] !== undefined
                          ? CouponStatus[couponModel?.Model[0].Status]
                          : ""}
                      </td>
                    </tr>
                  </tbody>
                </Table>

                {couponModel.SellerUrl && (
                  <>
                    <Space h="lg" />
                    <Anchor
                      href={couponModel.SellerUrl}
                      target="_blank"
                      size={14}
                    >
                      View your MyDeal store
                      <IconExternalLink size={14} />
                    </Anchor>
                  </>
                )}
              </Paper>
              {couponModel.HasPromoEvents && (
                <Paper p="xl" sx={{ position: "relative" }}>
                  <GetCreditDebitsReport />
                </Paper>
              )}

              {/* Create or Edit coupon model */}
              <Modal
                opened={openCouponModal}
                size="xl"
                onClose={() => setOpenCouponModal(false)}
                closeOnClickOutside={false}
                title={
                  <Title order={3} fw={700}>
                    {couponModel.Model.length > 0
                      ? "Edit Coupon Promotion"
                      : "Create Coupon Promotion"}
                  </Title>
                }
              >
                <Divider my="sm" />
                <CouponPromoModel
                  model={
                    couponModel.Model[0] ? couponModel.Model[0] : undefined
                  }
                  onClose={(reload) => {
                    toggleCouponPromoModel(reload);
                  }}
                />
              </Modal>
              {/* Cancel coupon model */}
              <Modal
                opened={openCancelCouponModal}
                size="lg"
                onClose={() => setOpenCancelCouponModal(false)}
                closeOnClickOutside={false}
                title={
                  <Title order={3} fw={700}>
                    Cancel Coupon Promotion
                  </Title>
                }
              >
                <Divider my="sm" />
                {couponModel && (
                  <CancleCouponPromoModel
                    couponId={couponModel.Model[0]?.CouponID}
                    promotionalEventId={
                      couponModel.Model[0]?.PromotionalEventId
                    }
                    onClose={(reload) => {
                      toggleCancelCouponPromoModel(reload);
                    }}
                  />
                )}
              </Modal>
            </>
          )}
        </Stack>
      </>
    </>
  );
};
