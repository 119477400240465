import { useCallback, useMemo } from "react";
import * as yup from "yup";
import { useDataProvider } from "@mydeal/core";
import { CombinedFreightOfferType, ICombinedFreightOfferModel } from "@types";
import { shippingService } from "@services/shipping.service";
import { Label, PageTitle } from "@components/shared";
import {
  useFocusableForm,
  FormMoneyInput,
  FormRadio,
  FormRadioGroup,
  AuthenticatedForm,
} from "@mydeal/ui-mantine";
import {
  Alert,
  Anchor,
  Box,
  Card,
  Divider,
  Grid,
  Radio,
  Stack,
  Text,
  Title,
  Group,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { openConfirmModal } from "@mantine/modals";

const ShippingOfferSchema = yup.object<{
  [Property in keyof ICombinedFreightOfferModel]: any;
}>({
  PriceFrom: yup
    .number()
    .nullable()
    .when("IsSupportCombinedFreightOffer", {
      is: "true",
      then: (schema) =>
        schema
          .typeError("This field is required.")
          .required("This field is required.")
          .min(1, "Minimum Spend must be greater than 0")
          .max(1000, "Minimum Spend must be less than or equal to 1000"),
    }),
  FlatRateAmount: yup
    .number()
    .nullable()
    .when(["IsSupportCombinedFreightOffer", "CombinedFreightOfferType"], {
      is: (IsSupportCombinedFreightOffer: any, CombinedFreightOfferType: any) =>
        IsSupportCombinedFreightOffer === "true" &&
        CombinedFreightOfferType === "1",
      then: (schema) =>
        schema
          .typeError("This field is required.")
          .required("This field is required.")
          .min(1, "Flat Rate amount must be greater than 0")
          .max(1000, "Flat Rate amount must be less than or equal to 1000"),
    }),
});

export const ShippingOffers = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _shippingService = useMemo(
    () => shippingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<ICombinedFreightOfferModel>({
    schema: ShippingOfferSchema,
  });

  const fetchData = useCallback(async () => {
    const result = await _shippingService.getShippingOffer();
    if (result) {
      result.IsSupportCombinedFreightOffer =
        result.IsSupportCombinedFreightOffer?.toString();
      result.CombinedFreightOfferType =
        result.CombinedFreightOfferType?.toString();
    }
    return result as ICombinedFreightOfferModel;
  }, [_shippingService]);

  const onSubmit = useCallback(
    async (data: any) => {
      return new Promise((resolve, reject) => {
        openConfirmModal({
          title: (
            <Text fw="bold" fz="sm">
              You are about to change the shipping offers for your store.
            </Text>
          ),
          children: (
            <Text size="sm">
              Changes will reflect immediately. Do you want to continue? Setting
              changes are recommended to be performed during off peak hours.
            </Text>
          ),
          labels: { confirm: "Proceed", cancel: "Cancel" },
          onConfirm: () => {
            const apiData = {
              ...data,
              PriceFrom: Number(data.PriceFrom),
              FlatRateAmount:
                Number(data.FlatRateAmount) > 0
                  ? Number(data.FlatRateAmount)
                  : null,
              IsSupportCombinedFreightOffer:
                data.IsSupportCombinedFreightOffer === "true" ? true : false,
            };
            _shippingService
              .updateShippingOffer(apiData)
              .then(resolve)
              .catch(reject);
          },
          onCancel: () => reject({ ErrorMessage: "Cancelled save." }),
        });
      });
    },
    [_shippingService]
  );

  const formValues = form.getValues();

  return (
    <>
      <PageTitle
        title="Shipping Offers"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Shipping Settings", route: "/shipping" },
          { name: "Shipping Offers" },
        ]}
      />

      <Card withBorder mih={300} data-testid="shipping-shippingoffer">
        <Card.Section py="sm" px="xl">
          <Title order={5} mb="xs">
            Shipping Offers
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section py="sm" px="xl">
          <AuthenticatedForm
            dataProviderInstance={dataProviderInstance}
            form={form}
            fetchData={fetchData}
            submitLabel="Update"
            onSubmit={onSubmit}
          >
            <Alert color="blue">
              Opt in to offer shipping discounts when a customer spends over a
              certain amount on your products.{" "}
              <Anchor
                href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10008779296399"
                target="_blank"
              >
                Read more <IconExternalLink size={14} />
              </Anchor>
            </Alert>

            <Grid>
              <Grid.Col md={2}>
                <Text fz="md" mt="xs">
                  Enable shipping offers
                </Text>
              </Grid.Col>
              <Grid.Col md={8}>
                <FormRadioGroup
                  fieldName="IsSupportCombinedFreightOffer"
                  parentForm={form}
                >
                  <Group mt="sm" align="left">
                    <Radio value="true" label="Yes" />
                    <Radio value="false" label="No" />
                  </Group>
                </FormRadioGroup>
              </Grid.Col>
            </Grid>

            {formValues.IsSupportCombinedFreightOffer === "true" && (
              <Stack spacing="md" mt="md">
                <Label>
                  <>Select the type of shipping offer:</>
                </Label>

                <FormRadio
                  name="CombinedFreightOfferType"
                  fieldName="CombinedFreightOfferType"
                  parentForm={form}
                  value={CombinedFreightOfferType.Free}
                  checked={
                    formValues.CombinedFreightOfferType ===
                    CombinedFreightOfferType.Free
                  }
                  label={
                    <>
                      <strong>Free Shipping</strong> - Offer free shipping when
                      a customer spends over a certain amount (excludes
                      shipping)
                    </>
                  }
                />

                {formValues.CombinedFreightOfferType ===
                  CombinedFreightOfferType.Free && (
                  <Box ml={50}>
                    <FormMoneyInput
                      withAsterisk
                      w={370}
                      label="To receive this offer the customer must spend over:"
                      fieldName="PriceFrom"
                      parentForm={form}
                      min={0}
                    />
                  </Box>
                )}
                <div className="col-md-12 mb-3">
                  <FormRadio
                    name="CombinedFreightOfferType"
                    fieldName="CombinedFreightOfferType"
                    parentForm={form}
                    value={Number(CombinedFreightOfferType.FlatRate)}
                    checked={
                      formValues.CombinedFreightOfferType ===
                      CombinedFreightOfferType.FlatRate
                    }
                    label={
                      <>
                        <strong>Flat Rate Shipping</strong> - Offer a flat rate
                        shipping amount when a customer spends over a certain
                        amount (excludes shipping)
                      </>
                    }
                  />
                </div>
                {formValues.CombinedFreightOfferType ===
                  CombinedFreightOfferType.FlatRate && (
                  <Box ml={50}>
                    <Stack>
                      <FormMoneyInput
                        withAsterisk
                        w={370}
                        label="To receive this offer the customer must spend over:"
                        fieldName="PriceFrom"
                        parentForm={form}
                        min={0}
                      />
                      <FormMoneyInput
                        withAsterisk
                        w={370}
                        label="The flat shipping rate is:"
                        fieldName="FlatRateAmount"
                        parentForm={form}
                        min={0}
                      />
                    </Stack>
                  </Box>
                )}
              </Stack>
            )}
          </AuthenticatedForm>
        </Card.Section>
      </Card>
    </>
  );
};
