import { MantineSize, Text, TextProps } from "@mantine/core";
import useStyles from "./Label.styles";

export interface ILabelProps extends TextProps {
  required?: boolean;
  /** Predefined label size */
  size?: MantineSize;
}

export const Label = ({
  children,
  size = "sm",
  required,
  ...rest
}: ILabelProps) => {
  const { classes } = useStyles({ size });

  return (
    <Text component="label" fw={600} fz="sm" {...rest}>
      {children}
      {required && (
        <span className={classes.required} aria-hidden>
          {" *"}
        </span>
      )}
    </Text>
  );
};
