import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import { useDataProvider } from "@mydeal/core";
import { accountService } from "@services";
import {
  AuthenticatedForm,
  FormRadioGroup,
  FormRichTextField,
  FormTextArea,
  FormTextField,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import {
  Anchor,
  Button,
  Card,
  Collapse,
  Divider,
  Grid,
  Group,
  Image,
  Radio,
  Text,
  Title,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import sizing_example from "../../assets/images/selleronboarding_sizing_example.png";
import { ISellerProfileModel } from "@types";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import { Label } from "@components/shared";
import { forEach } from "@tiptap/core/dist/packages/core/src/commands";

const tags = ["<script", "<iframe", "&lt;script", "&lt;iframe", "alert", "onclick", "onchange", "href"];
const noUrlRule = /(https?:\/\/)?([\da-z\.-]+)\.([a-z]{2,6})([\/\w\.-]*)*\/?/g;
const hasCrossSiteScriptTags = (html: string | null | undefined) => {

    var matchFound = true;
    if (html) {
        tags.forEach((tag, idx) => {
            if (html.indexOf(tag) !== -1) {
                matchFound = false;
            }
        });
    }
    return matchFound;
};
const getHtmlLength = (html: string | null | undefined) => {
    if (html) {
        var div = document.createElement("div");
        div.innerHTML = html;
        return div.innerText?.length ?? 0;
    }
    return 0;
};

const SellerProfileSchema = yup.object<{
  [Property in keyof IPublicProfileEditor]: any;
}>({
    StoreDisplayName: yup
        .string()
        .nullable()
        .required("Required")
        .matches(/^[\w\-!.\s]+$/, '* This field cannot contain special characters'),
  ProfileDescription: yup
    .string()
    .nullable()
    .required("Required")
    .min(
      15,
      "The field Profile Description must be a minimum length of '15'"
    )
    .max(
      1500,
      "The field Profile Description must be a maximum length of '1500'"
    )
    .test(
      "hasURL",
      "This field does not allow URL",
      (value) => !noUrlRule.test(value!)
    )
    .test(
        "hasXss",
        "This field does not allow dangerous script content",
        (value) => hasCrossSiteScriptTags(value)),
  WarrantyAndRefundDetails: yup
    .string()
    .nullable()
    .required("Required")
    .test("minHtml", "Required", (value) => getHtmlLength(value) > 0)
    .test(
      "minHtml",
      "The field Warranty and Return Details must be a minimum length of '15'",
      (value) => getHtmlLength(value) >= 15
    )
    .test(
      "hasURL",
      "This field does not allow URL",
      (value) => !noUrlRule.test(value!)
    ).test(
      "hasXss",
      "This field does not allow dangerous script content",
        (value) => hasCrossSiteScriptTags(value)),
  HasSellFashionProduct: yup.string().nullable().required("Required"),
  CareAndSizingDetails: yup
    .string()
    .nullable()
    .when("HasSellFashionProduct", {
      is: "true",
      then: (schema) =>
        schema
          .required("Required")
          .test("minHtml", "Required", (value) => getHtmlLength(value) > 0)
          .test(
            "hasURL",
            "This field does not allow URL",
            (value) => !noUrlRule.test(value!)
          ),
    }),
  HasLiquorProduct: yup.string().nullable().required("Required"),
  LiquorLicenceName: yup
    .string()
    .nullable()
    .when("HasLiquorProduct", {
      is: "true",
      then: (schema) =>
        schema
          .min(
            5,
            "The field Licensee Name must be a minimum length of '5'"
          )
          .max(
            250,
            "The field Licensee Name must be a maximum length of '250'"
          )
          .required("Required"),
    }),
  LiquorLicenceNumber: yup
    .string()
    .nullable()
    .when("HasLiquorProduct", {
      is: "true",
      then: (schema) =>
        schema
          .min(
            5,
            "The field Liquor Licence Number must be a minimum length of '5'"
          )
          .max(
            250,
            "The field Liquor Licence Number must be a maximum length of '250'"
          )
          .required("Required"),
    }),
    LiquorLicenceAdditionalWording: yup
      .string()
      .nullable()
      .when("HasLiquorProduct", {
        is: "true",
        then: (schema) =>
          schema
            .test(
              "minHtml",
              "The field Wording required by your licence or local state registration must be a minimum length of '5'",
              (value) => getHtmlLength(value) >= 5 || getHtmlLength(value) == 0
            )
            .test(
              "minHtml",
              "The field Wording required by your licence or local state registration must be a maximum length of '3000'",
              (value) => getHtmlLength(value) <= 3000
            )
            .test(
              "hasURL",
              "This field does not allow URL",
              (value) => !noUrlRule.test(value!)
            ),
    }),
  DeliveryDetails: yup
    .string()
    .nullable()
    .required("Required")
    .test("minHtml", "Required", (value) => getHtmlLength(value) > 0)
    .test(
      "hasURL",
      "This field does not allow URL",
      (value) => !noUrlRule.test(value!)
    ),
});

export interface IPublicProfileEditor {
  onboarding: boolean;
}

export const PublicProfileEditor = ({ onboarding }: IPublicProfileEditor) => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<ISellerProfileModel>({
    schema: SellerProfileSchema,
  });

  const [model, setModel] = useState<ISellerProfileModel>();
  const [isWarrantyOpen, setIsWarrantyOpen] = useState<boolean>(false);
  const [isDescriptionOpen, setIsDescriptionOpen] = useState<boolean>(false);
  const [isSizingOpen, setIsSizingOpen] = useState<boolean>(false);
  const [isLiquorLicenceOpen, setIsLiquorLicenceOpen] = useState<boolean>(false);
  const [isDeliveryOpen, setIsDeliveryOpen] = useState<boolean>(false);

  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const fetchData = useCallback(async () => {
    const result = await _accountService.getPublicProfile();
    if (result) {
      result.HasSellFashionProduct = result.HasSellFashionProduct?.toString();
      result.HasLiquorProduct = result.HasLiquorProduct?.toString();
      setModel(result);
    }
    return result;
  }, [_accountService]);

  const onSubmit = useCallback(
    async (data: any) => {
      const result = await _accountService.updatePublicProfile(
        data,
        onboarding
      );
      if (onboarding) {
        refreshOnboardingProgress();
      }
      return result;
    },
    [_accountService, onboarding, refreshOnboardingProgress]
  );

  const getImageSourceData = useCallback(
    async (file: any) => {
      return await new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = () =>
          reject({
            statusCode: 400,
            response: {
              data: { errors: [], title: "Error occured while reading file." },
            },
          });
        return Promise.resolve(reader.result);
      });
    },
    []
  );
  const formValues = form.getValues();

  return (
    <Card withBorder mih={300} data-testid="myaccount-profilepagesettings">
      {!onboarding && (
        <Card.Section py="sm" px="xl">
          <Group position="apart">
            <Title order={5} mb="xs">
              Profile Page Settings
            </Title>
            <Anchor
              href={model?.StoreProfileUrl}
              target="_blank"
              rel="noreferrer"
            >
              View Your Profile Page <IconExternalLink size={14} />
            </Anchor>
          </Group>
          <Divider />
        </Card.Section>
      )}
      <Card.Section py="sm" px="xl">
        <AuthenticatedForm
          dataProviderInstance={dataProviderInstance}
          form={form}
          fetchData={fetchData}
          submitLabel={onboarding ? "Save and Next" : "Update"}
          onSubmit={onSubmit}
          allowSubmitWithNoChanges={onboarding}
        >
          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label required>Store Display Name:</Label>
              <Text color="dimmed">
                This name will appear on your product listings and MyDeal store
                page
              </Text>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextField fieldName="StoreDisplayName" parentForm={form} />
            </Grid.Col>
          </Grid>
          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label required>Profile Description:</Label>
              <Text color="dimmed">
                Provide a short plain text description about your business and
                the types of products you sell. This text will be displayed on
                your profile page
              </Text>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormTextArea
                autosize
                minRows={5}
                maxRows={5}
                fieldName="ProfileDescription"
                parentForm={form}
              />
              <Button
                variant="subtle"
                onClick={() => {
                  setIsDescriptionOpen(!isDescriptionOpen);
                }}
                size="xs"
                mb="sm"
              >
                See example
              </Button>
              <Collapse in={isDescriptionOpen}>
                <Card withBorder>
                  <p>
                    We are a fast-growing online retailer of home and lifestyle
                    products for the indoors and outdoors.
                  </p>
                  <p>
                    Our products range from furniture to pet products! With over
                    20 years experience, our team is dedicated to providing
                    great quality products for your home with reliable shipping
                    and great customer service.
                  </p>
                  <p>
                    We only select the finest products and aim to exceed
                    customer expectations while offering the most competitive
                    pricing.
                  </p>
                </Card>
              </Collapse>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label required>Warranty and Return Details:</Label>
              <Text color="dimmed">
                Provide the warranty and returns policy information that applies
                to all of your products. This text will be displayed on your
                profile page
              </Text>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormRichTextField
                fieldName="WarrantyAndRefundDetails"
                parentForm={form}
              />
              <Button
                variant="subtle"
                onClick={() => {
                  setIsWarrantyOpen(!isWarrantyOpen);
                }}
                size="xs"
                mb="sm"
              >
                See example
              </Button>
              <Collapse in={isWarrantyOpen}>
                <Card withBorder>
                  <strong>Change of Mind Returns:</strong>
                  <p>
                    We want you to love your product so if you are not
                    completely satisfied with your purchase let us know.
                  </p>
                  <p>
                    Most products come with a 30 day change of mind policy. If
                    at any time within the first 30 days from purchase date you
                    are not completely satisfied with your order, please contact
                    us via your MyDeal Account for return approval. Once
                    received and inspected, we will facilitate an agreed
                    resolution.
                  </p>
                  <strong>Please Note:</strong>
                  <ul>
                    <li>Postage is paid by the customer</li>
                    <li>
                      The product must be returned in the original packaging,
                      accessories included
                    </li>
                    <li>
                      We do not accept responsibility for return items until
                      they reach our warehouse, so we strongly recommend using
                      tracking and/or insurance for the delivery
                    </li>
                    <li>Fees may apply</li>
                  </ul>
                  <strong>Warranty Claims:</strong>
                  <p>
                    Most of our products are supported by a 12 month warranty.
                    This warranty covers all manufacturer faults with the
                    product. Some of our products may have a different warranty
                    period, please check the product description for this.
                    Should an issue arise with your order, please contact us via
                    your MyDeal Account to raise a warranty claim.
                  </p>
                </Card>
              </Collapse>
            </Grid.Col>
          </Grid>

          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label required>Do You Sell Fashion Related Products?</Label>
            </Grid.Col>
            <Grid.Col md={2} sm={2}>
              <FormRadioGroup
                name="HasSellFashionProduct"
                fieldName="HasSellFashionProduct"
                parentForm={form}
              >
                <Group>
                  <Radio value={"true"} label="YES" />
                  <Radio value={"false"} label="NO" />
                </Group>
              </FormRadioGroup>
            </Grid.Col>
          </Grid>

          {formValues.HasSellFashionProduct?.toString() === "true" && (
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Care and Sizing Details</Label>
                <Text color="dimmed">
                  Provide care and sizing information for your fashion products.
                  This information will be displayed on your profile page AND
                  all of your fashion product listing pages
                </Text>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <FormRichTextField
                  fieldName="CareAndSizingDetails"
                  parentForm={form}
                  enableImgExtension={true}
                  getImageData={getImageSourceData}
                />
                <Button
                  variant="subtle"
                  onClick={() => {
                    setIsSizingOpen(!isSizingOpen);
                  }}
                  size="xs"
                  mb="sm"
                >
                  See example
                </Button>
                <Collapse in={isSizingOpen}>
                  <Card withBorder>
                    <strong>Size Guide</strong>
                    <Card withBorder m="lg" shadow="xs">
                      <Image src={sizing_example} alt="" radius="md" />
                    </Card>
                    <strong>Care</strong>
                    <p>
                      Our products are made with quality materials, see
                      individual product descriptions for materials used.
                    </p>
                    <ul>
                      <li>
                        For leather items we recommend regularly using a good
                        quality polish or conditioner to avoid the leather
                        drying out.
                      </li>
                      <li>
                        For suede items we highly recommend protecting the
                        material using a suede waterproof protecting spray
                        before use and regularly
                      </li>
                    </ul>
                  </Card>
                </Collapse>
              </Grid.Col>
            </Grid>
          )}

          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label required>Do you sell liquor related products?</Label>
            </Grid.Col>
            <Grid.Col md={2} sm={2}>
              <FormRadioGroup
                name="HasLiquorProduct"
                fieldName="HasLiquorProduct"
                parentForm={form}
                onChange={(data) => {
                  form.setFieldValue("HasLiquorProduct", data);
                  if (data == "false") {
                    form.setFieldValue("LiquorLicenceName", "");
                    form.setFieldValue("LiquorLicenceNumber", "");
                    form.setFieldValue("LiquorLicenceAdditionalWording", "");
                  }
                }}
              >
                <Group>
                  <Radio value={"true"} label="YES" />
                  <Radio value={"false"} label="NO" />
                </Group>
              </FormRadioGroup>
            </Grid.Col>
          </Grid>

          {formValues.HasLiquorProduct?.toString() === "true" && (
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Licensee Name:</Label>
                <Text color="dimmed">
                  Provide the licensee as listed on your  Liquor Licence.
                </Text>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <FormTextField fieldName="LiquorLicenceName" parentForm={form} />
              </Grid.Col>
            </Grid>
          )}

          {formValues.HasLiquorProduct?.toString() === "true" && (
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label required>Liquor Licence Number:</Label>
                <Text color="dimmed">
                  This will be displayed on all pages that contain liquor.
                </Text>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <FormTextField fieldName="LiquorLicenceNumber" parentForm={form} />
                <Button
                  variant="subtle"
                  onClick={() => {
                    setIsLiquorLicenceOpen(!isLiquorLicenceOpen);
                  }}
                  size="xs"
                  mb="sm"
                >
                  Preview how this text will appear on your product page
                </Button>
                <Collapse in={isLiquorLicenceOpen}>
                  <Card withBorder>
                    <p>
                      Products offered and supplied by <b>{formValues.LiquorLicenceName || "Licensee Name"}</b>, 
                      Liquor Licence <b>{formValues.LiquorLicenceNumber || "ABC12345678"}</b>. 
                      It is against the law to sell or supply alcohol to, or to obtain alcohol on behalf of, 
                      a person under the age of 18 years. ID may be checked upon delivery.
                    </p>
                  </Card>
                </Collapse>
              </Grid.Col>
            </Grid>
          )}

          {formValues.HasLiquorProduct?.toString() === "true" && (
            <Grid>
              <Grid.Col md={4} sm={6}>
                <Label>Wording required by your licence or local state registration:</Label>
                <Text color="dimmed">
                  This will be displayed on all pages that contain liquor.
                </Text>
              </Grid.Col>
              <Grid.Col md={8} sm={6}>
                <FormRichTextField
                  fieldName="LiquorLicenceAdditionalWording"
                  parentForm={form}
                />
              </Grid.Col>
            </Grid>
          )}

          <Grid>
            <Grid.Col md={4} sm={6}>
              <Label required>Delivery information for your products:</Label>
              <Text color="dimmed">
                This information will be displayed on all product pages
              </Text>
            </Grid.Col>
            <Grid.Col md={8} sm={6}>
              <FormRichTextField
                fieldName="DeliveryDetails"
                parentForm={form}
              />
              <Button
                variant="subtle"
                onClick={() => {
                  setIsDeliveryOpen(!isDeliveryOpen);
                }}
                size="xs"
                mb="sm"
              >
                See example
              </Button>
              <Collapse in={isDeliveryOpen}>
                <Card withBorder>
                  <p>
                    Delivery varies by location, please see the expected
                    timeframe to receive your product below:
                  </p>
                  <ul>
                    <li>Metro: 1-4 business days</li>
                    <li>Regional: 1-6 business days</li>
                  </ul>
                  <p>No delivery to PO Box addresses or Parcel Lockers.</p>
                  <p>
                    If you are unavailable at the time of delivery re-delivery
                    fees may apply.
                  </p>
                </Card>
              </Collapse>
            </Grid.Col>
          </Grid>
        </AuthenticatedForm>
      </Card.Section>
    </Card>
  );
};
