import {
  withAuthenticationRequired,
  WithAuthenticationRequiredOptions,
} from "@auth0/auth0-react";
import { LoadingOverlay } from "@mantine/core";
import { IndividualLoginAuth0Context } from "@providers";
import withOnboardingRequired from "@utils/withOnboardingRequired";
import withRolesCheck from "@utils/withRolesCheck";
import { ComponentType } from "react";

const defaultOnRedirecting = (): JSX.Element => (
  <LoadingOverlay visible={true} />
);

export const OnboardedRoute = <P extends object>({
  component,
  options = {},
  roles = [],
  ...props
}: {
  component: ComponentType<Omit<P, "component" | "options" | "roles">>;
  options?: WithAuthenticationRequiredOptions;
  roles?: string[];
} & P): JSX.Element => {
  options.onRedirecting = options.onRedirecting || defaultOnRedirecting;
  options.context = IndividualLoginAuth0Context;

  let innerRoute: ComponentType<Omit<P, "component" | "options" | "roles">> =
    withOnboardingRequired(component, {
      onRedirecting: options?.onRedirecting,
    });

  if (roles && roles.length > 0) {
    innerRoute = withRolesCheck(
      component,
      options.context,
      roles,
      options?.onRedirecting
    );
  }

  const Component = withAuthenticationRequired(innerRoute, options);
  return <Component {...props} />;
};
