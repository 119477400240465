import { IDataContextProvider } from "@mydeal/core";

export const searchService = (dataProvider: IDataContextProvider) => ({
  search: async (searchModel: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/dashboard/search`,
        payload: searchModel,
      })
      .then((result) => {
        return result?.data;
      });
  },
});
