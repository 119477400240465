import { FormSelect, FormTextField, IFocusableForm } from "@mydeal/ui-mantine";
import React, { useEffect } from "react";
import { IFreightSchemeModel } from "./FreightForm.types";
import { FormLocationField } from "@components/shared";

interface IConfigAusPostProps {
  parentForm: IFocusableForm<Partial<IFreightSchemeModel>>;
}

export const ConfigAusPost: React.FC<IConfigAusPostProps> = ({
  parentForm,
}) => {
  const serviceCodeOptions: any[] = [
    { value: "AUS_PARCEL_REGULAR", label: "AUS_PARCEL_REGULAR" },
    {
      value: "AUS_PARCEL_REGULAR_SATCHEL_3KG",
      label: "AUS_PARCEL_REGULAR_SATCHEL_3KG",
    },
    { value: "AUS_PARCEL_EXPRESS", label: "AUS_PARCEL_EXPRESS" },
    {
      value: "AUS_PARCEL_EXPRESS_SATCHEL_3KG",
      label: "AUS_PARCEL_EXPRESS_SATCHEL_3KG",
    },
  ];

  const apiUrl = parentForm.getValues().ApiUrl;
  useEffect(() => {
    const newValue = "https://digitalapi.auspost.com.au";
    if (apiUrl !== newValue) {
      parentForm.setFieldValue("ApiUrl", newValue);
    }
  }, [apiUrl, parentForm]);

  return (
    <>
      <FormTextField
        label="API Url:"
        withAsterisk
        fieldName="ApiUrl"
        parentForm={parentForm}
        disabled
      />

      <FormTextField
        label="Password / Token:"
        withAsterisk
        fieldName="ApiPassword"
        parentForm={parentForm}
      />

      <FormSelect
        placeholder="Select an option"
        label="Service Code:"
        withAsterisk
        fieldName="ShippingMethodName"
        parentForm={parentForm}
        data={serviceCodeOptions}
      />

      <FormLocationField
        placeholder="Enter Postcode or Suburb"
        label="From location:"
        fieldMap={{
          location: "FromLocation",
          suburb: "FromSuburb",
          postcode: "FromPostcode",
          state: "FromState",
        }}
        parentForm={parentForm}
      />
    </>
  );
};
