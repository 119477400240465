import { IDataContextProvider } from "@mydeal/core";

export const couponService = (dataProvider: IDataContextProvider) => ({
  loadCoupon: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/coupon`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  addCoupon: async (data: any) => {
    return await dataProvider
      .create({ resource: `coupon`, variables: data })
      .then((result) => {
        return result?.data;
      });
  },

  editCoupon: async (data: any) => {
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "put",
        url: `${baseUrl}/Coupon`,
        payload: data,
      })
      .then((result) => {
        return result?.data;
      });
  },

  removeCoupon: async (couponId?: number, promotionalEventId?: number) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "delete",
        url: `${baseUrl}/Coupon?couponId=${couponId}&promotionalEventId=${promotionalEventId}`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  exportReport: async (
    startDateStr: string | Date,
    endDateStr: string | Date
  ) => {
    const baseUrl = dataProvider.getApiUrl();
    return dataProvider
      .download({
        resource: `coupon/export?startDateStr=${startDateStr}&endDateStr=${endDateStr}`,
      })
      .then((result) => {
        return result;
      });
  },
});
