import {
  Card,
  CardProps,
  CardSection,
  Flex,
  Stack,
  Title,
} from "@mantine/core";
import { ReactNode } from "react";
import { Grid } from "react-bootstrap-icons";

interface DashboardWidgetProps {
  title?: string;
  children: string | ReactNode;
}

export const DashboardWidget = ({
  title,
  children,
  ...rest
}: DashboardWidgetProps) => {
  return (
    <Card p="0">
      <Title order={4} mb="xs">
        {title}
      </Title>
      {children}
    </Card>
  );
};
