import { IDataContextProvider } from "@mydeal/core";

export const dealsService = (dataProvider: IDataContextProvider) => ({
  list: async (searchModel: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/MyDeals/DealList`,
        payload: searchModel,
      })
      .then((result) => {
        return result?.data;
      });
  },

  importStuff: async (resource: string, entity: any) => {
    return dataProvider
      .upload({ resource: resource, variables: entity })
      .then((result) => {
        return result?.data;
      });
  },

  exportStuff: async (
    resource: string,
    includeDiscountinuedProducts: boolean
  ) => {
    return dataProvider
      .download({
        resource: `${resource}?includeDiscountinuedProducts=${includeDiscountinuedProducts}`,
      })
      .then((result) => {
        return result;
      });
  },

  getUncategorisedProductCount: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyDeals/GetUncategorisedProductCount`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  exportProducts: async (includeDiscountinuedProducts: boolean) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/MyDeals/ProductExport?includeDiscountinuedProducts=${includeDiscountinuedProducts}`,
      })
      .then((result) => {
        return result?.data;
      });
  },
  exportUncategorisedProducts: async () => {
   
    return dataProvider
    .download({
      resource: `MyDeals/ExportUncategorisedProducts`,
    })
    .then((result) => {
      return result;
    });
  },
});
