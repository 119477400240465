import {
  Card,
  CardProps,
  createStyles,
  Flex,
  SimpleGrid,
  Title,
} from "@mantine/core";
import { ColumnSizing, flexRender } from "@tanstack/react-table";
import { ReactNode } from "react";

interface DashboardCardProps extends CardProps {
  title: string | ReactNode;
}

const useStyles = createStyles((theme) => ({
  card: {
    diplay: "flex",
    height: "100%",
  },
  cardChildren: {
    height: "100%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    padding: 10,
  },
  cardTitle: {
    fontWeight: 600,
    fontSize: "lg",
  },
}));

export const DashboardCard = ({
  title,
  children,
  ...rest
}: DashboardCardProps) => {
  const { classes, cx, theme } = useStyles();
  return (
    <Card withBorder {...rest} className={cx(classes.card)}>
      <Card.Section
        withBorder
        p="xs"
        bg={
          theme.colorScheme === "dark"
            ? theme.colors.dark[4]
            : theme.colors.gray[3]
        }
        className={cx(classes.cardTitle)}
      >
        <Title order={6}>{title}</Title>
      </Card.Section>
      <Card.Section className={cx(classes.cardChildren)}>
        {children}
      </Card.Section>
    </Card>
  );
};
