import {
  BankDetails,
  ChangePassword,
  ContactDetails,
  CouponPromotion,
  Dashboard,
  IntegrationSettings,
  Invitations,
  OrderDetails,
  ProductSettings,
  PublicProfile,
  Refund,
  MultiFactorAuthentication,
  UserManagement,
} from "@pages";
import { SearchResult } from "@pages/searchResult";
import { DealsList } from "@pages/mydeals";
import { FreightForm, FreightListPage } from "@pages/shipping/manageFreight";
import { ClientList } from "@pages/clientList";
import { OrderHelp } from "@pages/info";
import { Invoices } from "@pages/payments";
import { RequestDashboard, ProductImportResult } from "@pages/job";
import {
  OpenTickets,
  PendingTickets,
  SolvedTickets,
  Escalations,
  SolvedEscalations,
  SearchResults,
  ViewTicket,
} from "@pages/enquiryInbox";
import {
  CombinedShipping,
  ShippingAssignment,
  ShippingOffers,
  ShippingSettings,
} from "@pages/shipping";
import {
  OnboardedRoute,
  OnboardingContactDetails,
  OnboardingFinalStep,
  OnboardingIntegrationConfiguration,
  OnboardingProductSetting,
  OnboardingSellerProfile,
  OnboardingSetPassword,
  OnboardingShippingAssignment,
  OnboardingShippingOption,
  OnboardingWelcomePage,
} from "@pages/onboarding";
import { ComponentType } from "react";
import { WithAuthenticationRequiredOptions } from "@auth0/auth0-react";

const createRoute = <P extends object>(
  path: string,
  props: {
    component: ComponentType<Omit<P, "component" | "options" | "roles">>;
    options?: WithAuthenticationRequiredOptions;
    roles?: string[];
  } & P
) => ({
  path,
  element: <OnboardedRoute {...props} />,
});

export const authenticatedRoutes = [
  createRoute("/user/change-password", { component: ChangePassword }),
  createRoute("/user/mfa", { component: MultiFactorAuthentication }),

  createRoute("/admin/user-management", {
    component: UserManagement,
    roles: ["SellerAdministrator"],
  }),
  createRoute("/admin/invitations", {
    component: Invitations,
    roles: ["SellerAdministrator"],
  }),

  createRoute("/onboarding/set-password", { component: OnboardingSetPassword }),
  createRoute("/onboarding/welcome", { component: OnboardingWelcomePage }),
  createRoute("/onboarding/contact-detail", {
    component: OnboardingContactDetails,
  }),
  createRoute("/onboarding/seller-profile", {
    component: OnboardingSellerProfile,
  }),
  createRoute("/onboarding/integration-configuration", {
    component: OnboardingIntegrationConfiguration,
  }),
  createRoute("/onboarding/product-setting", {
    component: OnboardingProductSetting,
  }),
  createRoute("/onboarding/shipping-option", {
    component: OnboardingShippingOption,
  }),
  createRoute("/onboarding/shipping-option/create", {
    component: FreightForm,
    onboarding: true,
  }),
  createRoute("/onboarding/shipping-option/edit/:id", {
    component: FreightForm,
    onboarding: true,
  }),
  createRoute("/onboarding/shipping-assignment", {
    component: OnboardingShippingAssignment,
  }),
  createRoute("/onboarding/final-step", { component: OnboardingFinalStep }),

  createRoute("/dashboard", { component: Dashboard }),

  createRoute("/coupon", { component: CouponPromotion }),

  createRoute("/myaccount/change-password", { component: ChangePassword }),
  createRoute("/myaccount/contact-details", { component: ContactDetails }),
  createRoute("/myaccount/bank-details", { component: BankDetails }),
  createRoute("/myaccount/profile", { component: PublicProfile }),
  createRoute("/myaccount/integration", { component: IntegrationSettings }),
  createRoute("/myaccount/product-settings", { component: ProductSettings }),
  createRoute("/mydeals", { component: DealsList }),
  createRoute("/mydeals/client-list/:dealId", { component: ClientList }),
  createRoute("/mydeals/client-list", { component: ClientList }),

  createRoute("/order/:dealId", { component: OrderDetails }),
  createRoute("/order/:dealId/refund", { component: Refund }),

  createRoute("/payments", { component: Invoices }),

  createRoute("/shipping", { component: ShippingSettings }),
  createRoute("/shipping/manage-freight", { component: FreightListPage }),
  createRoute("/shipping/manage-freight/create", {
    component: FreightForm,
    onboarding: false,
  }),
  createRoute("/shipping/manage-freight/edit/:id", {
    component: FreightForm,
    onboarding: false,
  }),
  createRoute("/shipping/shipping-offers", { component: ShippingOffers }),
  createRoute("/shipping/combined-shipping", { component: CombinedShipping }),
  createRoute("/shipping/shipping-assignment", {
    component: ShippingAssignment,
  }),

  createRoute("/enquiryinbox/open-tickets", { component: OpenTickets }),
  createRoute("/enquiryinbox/pending-tickets", { component: PendingTickets }),
  createRoute("/enquiryinbox/solved-tickets", { component: SolvedTickets }),
  createRoute("/enquiryinbox/escalations", { component: Escalations }),
  createRoute("/enquiryinbox/solved-escalations", {
    component: SolvedEscalations,
  }),
  createRoute("/enquiryinbox/search-tickets/:isEscalation/:searchKeyword", {
    component: SearchResults,
  }),
  createRoute("/EnquiryInbox/ViewTicket/:ticketId", { component: ViewTicket }),

  createRoute("/info/order-help/:bigDealId", { component: OrderHelp }),

  createRoute("/job/requestDashboard", { component: RequestDashboard }),
  createRoute("/job/productImportResult/:jobId", {
    component: ProductImportResult,
  }),

  createRoute("/search", { component: SearchResult }),
];
