import { useCallback, useMemo } from "react";
import * as yup from "yup";
import { useDataProvider, useRouterContext } from "@mydeal/core";
import {
  ShippingAssignmentType,
  IShippingAssignmentModel,
  FreightType,
  IFreightAssignmentSettingModel,
} from "@types";
import { shippingService } from "@services/shipping.service";
import {
  useFocusableForm,
  FormNumberInput,
  FormRadioGroup,
  FormSelect,
  AuthenticatedForm,
} from "@mydeal/ui-mantine";
import {
  Alert,
  Anchor,
  Card,
  Divider,
  Grid,
  List,
  Radio,
  SelectItem,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";
import { ShippingAssignmentTable } from "./ShippingAssignmentTable";
import { format } from "date-fns";
import { Label } from "@components/shared";

const FreightAssignmentItemSchema = yup.object<{
  [Property in keyof IFreightAssignmentSettingModel]: any;
}>({
  FreightAssignmentSettingID: yup.number(),
  CompanyID: yup.mixed(),
  PricedBased: yup.boolean(),
  WeightBased: yup.boolean(),
  From: yup.number().label("Range From").nullable().required(),
  To: yup.number().label("Range To").nullable().required(),
  FreightType: yup
    .number()
    .label("Shipping Option")
    .nullable()
    .required()
    .oneOf(
      Object.values(FreightType) as number[],
      "Must be a valid shipping option"
    ),
  FreightSchemeID: yup
    .number()
    .label("Value")
    .nullable()
    .when("FreightType", {
      is: FreightType.Custom,
      then: (schema) => schema.required(),
    }),
  FreightFlatRate: yup
    .number()
    .label("Value")
    .nullable()
    .when("FreightType", {
      is: (freightType: FreightType) =>
        freightType === FreightType.FlatRate ||
        freightType === FreightType.FlatAnyQty,
      then: (schema) => schema.required(),
    }),
  IsDeleted: yup.boolean(),
  FromPlaceHolder: yup.string().nullable(),
  ToPlaceHolder: yup.string().nullable(),
});

const ShippingAssignmentSchema = yup.object<{
  [Property in keyof IShippingAssignmentModel]: any;
}>({
  ShippingAssignmentType: yup
    .number()
    .label("Shipping Assignment Type")
    .required()
    .oneOf(
      Object.values(ShippingAssignmentType) as number[],
      "Must be a valid shipping assignment type"
    ),
  GlobalSettingFreightType: yup
    .number()
    .label("Shipping Option")
    .nullable()
    .when("ShippingAssignmentType", {
      is: ShippingAssignmentType.GlobalSetting,
      then: (schema) =>
        schema
          .required()
          .oneOf(
            Object.values(FreightType) as number[],
            "Must be a valid shipping option"
          ),
    }),
  GlobalSettingFreightSchemeID: yup
    .number()
    .label("Freight Scheme")
    .nullable()
    .when(["ShippingAssignmentType", "GlobalSettingFreightType"], {
      is: (
        shippingAssignmentType: ShippingAssignmentType,
        freightType: FreightType
      ) =>
        shippingAssignmentType === ShippingAssignmentType.GlobalSetting &&
        freightType === FreightType.Custom,
      then: (schema) => schema.required(),
    }),
  GlobalSettingFlatRateAmount: yup
    .number()
    .label("Flat Rate Amount")
    .nullable()
    .when(["ShippingAssignmentType", "GlobalSettingFreightType"], {
      is: (
        shippingAssignmentType: ShippingAssignmentType,
        freightType: FreightType
      ) =>
        shippingAssignmentType === ShippingAssignmentType.GlobalSetting &&
        (freightType === FreightType.FlatRate ||
          freightType === FreightType.FlatAnyQty),
      then: (schema) => schema.required(),
    }),
  NextProductSyncAt: yup.string().nullable(),
  FlagPriceBasedFreightAvailable: yup.boolean().nullable(),
  PriceBasedFreightAssignmentSettingModels: yup
    .array()
    .when("ShippingAssignmentType", {
      is: ShippingAssignmentType.ProductPrice,
      then: (schema) => schema.required().of(FreightAssignmentItemSchema),
    }),
  WeightBasedFreightAssignmentSettingModels: yup
    .array()
    .when("ShippingAssignmentType", {
      is: ShippingAssignmentType.ProductWeight,
      then: (schema) => schema.required().of(FreightAssignmentItemSchema),
    }),
  GlobalFreightTypeSelectList: yup.mixed().nullable(),
  FreightSchemeSelectList: yup.mixed().nullable(),
});

const freightTypes: SelectItem[] = [
  { label: "-Select-", value: "" },
  { label: "Free Shipping", value: FreightType.FreeShipping.toString() },
  { label: "Flat Rate", value: FreightType.FlatRate.toString() },
  { label: "Flat Rate Any Quantity", value: FreightType.FlatAnyQty.toString() },
  { label: "Shipping Calculator", value: FreightType.Custom.toString() },
];

export interface IShippingAssignmentEditor {
  onboarding: boolean;
}

export const ShippingAssignmentEditor = ({
  onboarding,
}: IShippingAssignmentEditor) => {
  const form = useFocusableForm<IShippingAssignmentModel>({
    schema: ShippingAssignmentSchema,
  });

  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _shippingService = useMemo(
    () => shippingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const { Link } = useRouterContext();

  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const onSubmit = useCallback(
    async (data: Partial<IShippingAssignmentModel>) => {
      return await _shippingService.updateShippingAssignment(data, onboarding);
    },
    [_shippingService, onboarding]
  );

  const afterSubmit = useCallback(() => {
    if (onboarding) {
      refreshOnboardingProgress();
    }
  }, [onboarding, refreshOnboardingProgress]);

  const {
    NextProductSyncAt,
    ShippingAssignmentType: formShippingAssignmentType,
    GlobalSettingFreightType,
    FlagPriceBasedFreightAvailable,
    FreightSchemeSelectList,
    PriceBasedFreightAssignmentSettingModels,
    WeightBasedFreightAssignmentSettingModels,
  } = form.getValues();

  const freightSchemes =
    FreightSchemeSelectList?.map(
      (fs) =>
        ({
          label: fs.Text,
          value: fs.Value,
        } as SelectItem)
    ) || [];

  return (
    <Card withBorder mih={300}>
      {!onboarding && (
        <Card.Section py="sm" px="xl">
          <Title order={5} mb="xs">
            Shipping Assignment
          </Title>
          <Divider />
        </Card.Section>
      )}
      <Card.Section py="sm" px="xl">
        <AuthenticatedForm
          dataProviderInstance={dataProviderInstance}
          form={form}
          fetchData={_shippingService.getShippingAssignment}
          submitLabel={onboarding ? "Save and Next" : "Update"}
          onSubmit={onSubmit}
          afterSubmit={afterSubmit}
          allowSubmitWithNoChanges={onboarding}
        >
          <Anchor
            href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10004629941903"
            target="_blank"
          >
            Learn More <IconExternalLink size={16} />
          </Anchor>

          <Alert color="yellow">
            Note:
            <List>
              {!onboarding && (
                <List.Item>
                  Any changes made on this page will reflect on your existing
                  products at the time of your next product sync at{" "}
                  {NextProductSyncAt && (
                    <span>
                      {format(new Date(NextProductSyncAt), "dd/MM/yyyy")}
                    </span>
                  )}
                </List.Item>
              )}
              <List.Item>All prices must include GST</List.Item>
              {!onboarding && (
                <List.Item>
                  To create or edit a shipping calculator go to{" "}
                  <Anchor component={Link} to="/shipping/manage-freight">
                    Manage Freight Schemes
                  </Anchor>
                </List.Item>
              )}
            </List>
          </Alert>
          <FormRadioGroup
            fieldName="ShippingAssignmentType"
            parentForm={form}
            enumValue
          >
            <Stack>
              <Radio
                label={
                  <>
                    <Text fw="bold">Global Setting</Text>
                    <Text>
                      - Apply the same shipping option to all products
                      (Recommended)
                    </Text>
                  </>
                }
                value={Number(ShippingAssignmentType.GlobalSetting)}
              />

              <Radio
                label={
                  <>
                    <Text fw="bold">Based On Product Price</Text>
                    <Text>
                      {FlagPriceBasedFreightAvailable
                        ? "- Apply shipping options based on your product prices"
                        : "This option is not available when prices are assigned manually (i.e. when Automated Price Update = NO)"}
                    </Text>
                  </>
                }
                value={Number(ShippingAssignmentType.ProductPrice)}
              />

              <Radio
                label={
                  <>
                    <Text fw="bold">Based On Product Weight</Text>
                    <Text>
                      - Apply shipping options based on your product weights
                    </Text>
                  </>
                }
                value={Number(ShippingAssignmentType.ProductWeight)}
              />
            </Stack>
          </FormRadioGroup>

          {formShippingAssignmentType ===
            ShippingAssignmentType.GlobalSetting && (
            <Card withBorder p="md" pos="inherit">
              <Stack spacing="sm">
                <Grid>
                  <Grid.Col
                    md={3}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    <Label required>Select shipping option</Label>
                  </Grid.Col>
                  <Grid.Col md={4}>
                    <div className="me-2" id="GlobalSettingFreightTypeSection">
                      <FormSelect
                        parentForm={form}
                        fieldName="GlobalSettingFreightType"
                        data={freightTypes}
                        withAsterisk
                        placeholder="-Select-"
                        numericValue
                      />
                    </div>
                  </Grid.Col>
                  <Grid.Col
                    md={5}
                    sx={{ display: "flex", alignItems: "center" }}
                  >
                    {(GlobalSettingFreightType === FreightType.FlatRate ||
                      GlobalSettingFreightType === FreightType.FlatAnyQty) && (
                      <FormNumberInput
                        w="100%"
                        parentForm={form}
                        fieldName="GlobalSettingFlatRateAmount"
                      />
                    )}
                    {GlobalSettingFreightType === FreightType.Custom && (
                      <>
                        {(freightSchemes?.length || 0) > 0 ? (
                          <FormSelect
                            w="100%"
                            parentForm={form}
                            fieldName="GlobalSettingFreightSchemeID"
                            //label="Freight Scheme:"
                            data={freightSchemes}
                            withAsterisk
                            placeholder="-Select-"
                            numericValue
                          />
                        ) : (
                          <Text fz="sm">
                            No shipping calculator created.{" "}
                            <Anchor
                              component={Link}
                              to="/shipping/manage-freight"
                            >
                              Add Now
                            </Anchor>
                          </Text>
                        )}
                      </>
                    )}

                    <div>
                      <span
                        id="global-setting-error"
                        className="field-validation-error d-none"
                      ></span>
                    </div>
                  </Grid.Col>
                </Grid>
              </Stack>
            </Card>
          )}
          {formShippingAssignmentType ===
            ShippingAssignmentType.ProductPrice && (
            <ShippingAssignmentTable
              parentForm={form}
              type="Price"
              note={
                <ul>
                  <li>
                    Please make sure your highest priced product sits within
                    these ranges. Any products found beyond this range will
                    automatically be assigned the final shipping option defined
                    in your range.
                  </li>
                </ul>
              }
              items={PriceBasedFreightAssignmentSettingModels}
              itemsKey="PriceBasedFreightAssignmentSettingModels"
              freightSchemes={freightSchemes}
              freightTypes={freightTypes}
            />
          )}

          {formShippingAssignmentType ===
            ShippingAssignmentType.ProductWeight && (
            <ShippingAssignmentTable
              parentForm={form}
              type="Weight"
              note={
                <ul>
                  <li>
                    The weight range values must align with the same unit of
                    measurement as your product weight data
                  </li>
                  <li>
                    Please make sure your highest weight product sits within
                    these ranges. Any products found beyond this range will
                    automatically be assigned the final shipping option defined
                    in your range.
                  </li>
                </ul>
              }
              items={WeightBasedFreightAssignmentSettingModels}
              itemsKey="WeightBasedFreightAssignmentSettingModels"
              freightSchemes={freightSchemes}
              freightTypes={freightTypes}
            />
          )}
        </AuthenticatedForm>
      </Card.Section>
    </Card>
  );
};
