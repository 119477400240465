import { Fragment, useEffect, useMemo, useState } from "react";
import {
  Group,
  Box,
  Collapse,
  ThemeIcon,
  Text,
  UnstyledButton,
  createStyles,
  Badge,
} from "@mantine/core";
import {
  IconChevronLeft,
  IconChevronRight,
  TablerIconsProps,
} from "@tabler/icons-react";
import { useRouterContext } from "@mydeal/core";

const useStyles = createStyles((theme) => ({
  control: {
    fontWeight: 500,
    display: "block",
    width: "100%",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    color: theme.colorScheme === "dark" ? theme.colors.dark[0] : theme.black,
    fontSize: theme.fontSizes.sm,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  link: {
    fontWeight: 500,
    display: "block",
    textDecoration: "none",
    padding: `${theme.spacing.xs} ${theme.spacing.md}`,
    paddingLeft: 31,
    marginLeft: 30,
    fontSize: theme.fontSizes.sm,
    color:
      theme.colorScheme === "dark"
        ? theme.colors.dark[0]
        : theme.colors.gray[7],
    borderLeft: `1px solid ${
      theme.colorScheme === "dark" ? theme.colors.dark[4] : theme.colors.gray[3]
    }`,

    "&:hover": {
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[7]
          : theme.colors.gray[0],
      color: theme.colorScheme === "dark" ? theme.white : theme.black,
    },
  },

  chevron: {
    transition: "transform 200ms ease",
  },
  notificationBadge: {
    minWidth: 20,
    width: "auto",
    height: 20,
    padding: 2,
    marginLeft: 5,
  },

  linkActive: {
    "&, &:hover": {
      backgroundColor: theme.fn.variant({
        variant: "light",
        color: theme.primaryColor,
      }).background,
      color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
        .color,
      [`&`]: {
        color: theme.fn.variant({ variant: "light", color: theme.primaryColor })
          .color,
      },
    },
  },
}));

export interface ILinksGroupProps {
  icon: React.ComponentType<TablerIconsProps>;
  label: string;
  initiallyOpened?: boolean;
  route?: string;
  links?: ILinksGroupProps[];
  notifications?: number;
}

export const LinksGroup = ({
  icon: Icon,
  label,
  initiallyOpened,
  route,
  links,
  notifications,
}: ILinksGroupProps) => {
  const { Link, useLocation, useHistory } = useRouterContext();
  const history = useHistory();
  const { classes, theme } = useStyles();
  const hasLinks = Array.isArray(links);
  const [opened, setOpened] = useState(initiallyOpened || false);
  const ChevronIcon = theme.dir === "ltr" ? IconChevronRight : IconChevronLeft;
  const { pathname } = useLocation();

  const [activeLink, setActiveLink] = useState(pathname);

  useMemo(() => {
    if (hasLinks) {
      links.forEach((link) => {
        if (link.route === pathname) {
          setOpened(true);
          return;
        }
      });
    }
    return null;
  }, []);

  const items = (hasLinks ? links : []).map((link) => (
    <Fragment key={link.label}>
      <Text
        component={Link}
        className={`${classes.link} , ${
          pathname === link.route ? classes.linkActive : ""
        } `}
        to={link.route}
        key={link.label}
      >
        {link.label}
        {link.notifications != null ? (
          <Badge
            size="sm"
            variant="filled"
            color="#F04A1C"
            className={classes.notificationBadge}
          >
            {link.notifications}
          </Badge>
        ) : (
          ""
        )}
      </Text>
    </Fragment>
  ));

  return (
    <>
      <UnstyledButton
        onClick={() => {
          setOpened((o) => !o);
          if (route && pathname !== route) {
            history.push(route);
          }
        }}
        className={`${classes.control} , ${
          pathname === route ? classes.linkActive : ""
        }`}
        data-testid={`sidenav-${label}`}
      >
        <Group position="apart" spacing={0}>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <ThemeIcon variant="light" size={30}>
              <Icon size={18} />
            </ThemeIcon>
            {route ? (
              <Text component={Link} to={route} ml="md">
                {label}
              </Text>
            ) : (
              <Text ml="md">{label}</Text>
            )}
          </Box>
          {hasLinks && (
            <ChevronIcon
              className={classes.chevron}
              size={14}
              stroke={1.5}
              style={{
                transform: { opened }
                  ? `rotate(${theme.dir === "rtl" ? -90 : 90}deg)`
                  : "none",
              }}
            />
          )}
        </Group>
      </UnstyledButton>
      {hasLinks ? <Collapse in={opened}>{items}</Collapse> : null}
    </>
  );
};
