import { createStyles, Text, Title, TextInput, Button, Image, TypographyStylesProvider, Container, Flex, Stack, Space } from '@mantine/core';
import ImagePromotion01 from "../../assets/images/landingTabs/promotion/promotion-2.png";
import ImagePromotion02 from "../../assets/images/landingTabs/promotion/banner.jpg";
import ImagePromotion03 from "../../assets/images/landingTabs/promotion/promotion-1.png";

const useStyles = createStyles((theme) => ({
    wrapper: {
        display: 'flex',
        alignItems: 'center',
        padding: `calc(${theme.spacing.xl} * 2)`,

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            flexDirection: 'column-reverse',
            padding: theme.spacing.xs,
        },
    },

    wrapperRightImage: {
        '&body': {
            paddingRight: `calc(${theme.spacing.xl} * 2)`,
            paddingLeft: 0,
            [`@media (max-width: ${theme.breakpoints.sm})`]: {
                paddingRight: 0,
            },
        }
    },

    image: {
        maxWidth: '40%',

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            maxWidth: '100%',
        },
    },

    body: {
        maxWidth: '60%',
        paddingLeft: `calc(${theme.spacing.xl} * 2)`,

        [`@media (max-width: ${theme.breakpoints.sm})`]: {
            paddingLeft: 0,
            marginTop: theme.spacing.xl,
            maxWidth: '100%',
        },
    },

    title: {
        color: theme.colorScheme === 'dark' ? theme.white : theme.black,
        fontFamily: `Greycliff CF, ${theme.fontFamily}`,
        lineHeight: 1,
        marginBottom: theme.spacing.md,
    },

    centerContent: {
        display: "flex",
        justifyContent: "center",
    }
}));

export function TabPromotion() {
    const { classes } = useStyles();
    return (
        <>
            <div className={classes.wrapper}>
                <Image src={ImagePromotion01} className={classes.image} />
                <div className={classes.body}>
                    <Title className={classes.title} order={4}>Ongoing Promotional Opportunities</Title>
                    <Text size="md" color="dimmed">
                    At MyDeal we always striving to provide value to our customers. We run multiple promotions everyday to showcase our wide product range and give every seller the opportunity to have their products seen. All of our sellers are invited to participate in these events which we promote through powerful marketing channels, such as the homepage and our daily newsletters.
                    </Text>
                </div>
            </div>
            <div className={`${classes.wrapper} ${classes.wrapperRightImage}`}>
                <Stack>
                    <Title className={classes.title} order={4}>Exclusive Sponsorship Opportunities</Title>
                    <Text size="md" color="dimmed">
                        There might come a time where you want to give your products enhanced and exclusive exposure. We give our sellers the opportunity to run their own exclusive sale events on MyDeal with sponsorship packages at great prices. These sponsorship opportunities are available year-round and very popular around key retail events such as Christmas and End of Financial Year. MyDeal is also home of Australia’s Night Online - a national online retail event at EOFY and is exclusively available for MyDeal sellers to participate in.
                    </Text>
                    <Space h="lg" />
                    <Image src={ImagePromotion02}/>
                </Stack>
            </div>
            <div className={classes.wrapper}>
                <Image src={ImagePromotion03} className={classes.image} />
                <div className={classes.body}>
                    <Title className={classes.title} order={4}>Powerful Marketing Solutions</Title>
                    <Text size="md" color="dimmed">
                    Over the years MyDeal has built up a zenith of world class marketing strategies underpinned by an array of channels that expose the marketplace to millions of Australian consumers. This ensures that the marketplace is filled with thousands of customers every single day looking for value and looking for your products.
                    </Text>
                </div>
            </div>
        </>
    );
}