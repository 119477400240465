import { PageTitle } from "@components/shared";
import { Anchor, Card } from "@mantine/core";
import { IconExternalLink } from "@tabler/icons-react";
import { FreightList } from "./FreightList";

export const FreightListPage = () => {
  return (
    <>
      <PageTitle
        title="Manage Freight Schemes"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Shipping Settings", route: "/shipping" },
          { name: "Manage Freight Schemes" },
        ]}
        description={
          <>
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10009659355023"
              target="_blank"
            >
              Learn more about setting up freight schemes
              <IconExternalLink size={16} />
            </Anchor>
          </>
        }
      />
      <Card withBorder mih={300} p="xl">
        <FreightList onboarding={false} />
      </Card>
    </>
  );
};
