import { IOrderViewModel } from "./types";
import { RefundHistoryTable } from "./RefundHistoryTable";

interface IRefundTableProps {
  order: IOrderViewModel;
}

export const TabRefund = ({ order }: IRefundTableProps) => {
  return (
    <>
      <RefundHistoryTable refundHistoryModels={order.RefundViewModel.RefundHistoryModels}/>
    </>
  );
};
