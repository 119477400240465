import { IOrderViewModel } from "./types";

import { TabRefund } from "./TabRefund";
import { Alert, Grid, Stack, Tabs, Text } from "@mantine/core";
import { TabAdditionalPayments } from "./TabAdditionalPayments";

interface IOrderTabsProps {
  order: IOrderViewModel;
  reloadOrderDetails: any;
  paymentLink: any;
}

export const OrderTabs = ({
  order,
  reloadOrderDetails,
  paymentLink,
}: IOrderTabsProps) => {
  return (
    <>
      <Tabs defaultValue="sellerNotes" pt="xl">
        <Tabs.List>
          <Tabs.Tab value="sellerNotes">SELLER NOTES</Tabs.Tab>
          <Tabs.Tab value="additionalPayments">ADDITIONAL PAYMENT/S</Tabs.Tab>
          <Tabs.Tab value="refund">REFUND</Tabs.Tab>
        </Tabs.List>

        <Tabs.Panel value="sellerNotes" pt="sm">
          <>
            {order.BidDealNotes.length > 0 ? (
              <Grid>
                <Grid.Col md={9}>
                  <Stack spacing={5}>
                    {order.BidDealNotes.slice()
                      .reverse()
                      .map((bidDealNote, index) => (
                        <Alert key={index}>
                          <small>{bidDealNote.NoteContent}</small>
                        </Alert>
                      ))}
                  </Stack>
                </Grid.Col>
              </Grid>
            ) : (
              <Text align="center">No data</Text>
            )}
          </>
        </Tabs.Panel>

        <Tabs.Panel value="additionalPayments" pt="sm">
          <TabAdditionalPayments
            order={order}
            reloadOrderDetails={reloadOrderDetails}
            paymentLink={paymentLink}
          />
        </Tabs.Panel>

        <Tabs.Panel value="refund" pt="sm">
          {order.RefundViewModel.RefundHistoryModels.length > 0 ? (
            <TabRefund order={order} />
          ) : (
            <Alert
              sx={(theme) => ({
                backgroundColor:
                  theme.colorScheme === "dark"
                    ? theme.colors.dark[7]
                    : theme.colors.gray[0],
              })}
            >
              No data
            </Alert>
          )}
        </Tabs.Panel>
      </Tabs>
    </>
  );
};
