import { AppShell } from "@mantine/core";
import { SimpleHeader } from "./SimpleHeader";
import { SimpleFooter } from "./SimpleFooter";
import { Outlet } from "@mydeal/react-router";

export const SimpleLayout = () => {
  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      padding="md"
      header={<SimpleHeader />}
      footer={<SimpleFooter />}
      styles={(theme) => ({
        main: {
          paddingLeft: theme.spacing.md,
          paddingTop: "100px",

          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};
