import { useAuth0 } from "@auth0/auth0-react";
import { Center, Loader } from "@mantine/core";
import { useSearchParams } from "@mydeal/react-router";

import { IndividualLoginAuth0Context } from "@providers";
import { useEffect } from "react";

export const Login = () => {
  const [searchParams] = useSearchParams();
  const invitationParam = searchParams.get("invitation") || undefined;
  const organizationParam = searchParams.get("organization") || undefined;
  const { loginWithRedirect } = useAuth0(IndividualLoginAuth0Context);

  useEffect(() => {
    loginWithRedirect({
      authorizationParams: {
        invitation: invitationParam,
        organization: organizationParam,
      },
    });
  }, [invitationParam, loginWithRedirect, organizationParam]);

  return (
    <Center h="100vh">
      <Loader />
    </Center>
  );
};
