import { useCallback, useMemo } from "react";
import * as yup from "yup";
import { IBankDetailModel } from "@types";
import { Anchor, Card, Divider, Grid, Stack, Text, Title } from "@mantine/core";
import { Label, PageTitle } from "@components";
import { useDataProvider } from "@mydeal/core";
import { accountService } from "@services";
import { IconExternalLink } from "@tabler/icons-react";
import {
  AuthenticatedForm,
  FormTextField,
  useFocusableForm,
} from "@mydeal/ui-mantine";

export const BankDetails = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );

  const form = useFocusableForm<IBankDetailModel>({
    schema: yup.object(),
  });

  const fetchData = useCallback(async () => {
    return await _accountService.loadBankDetails();
  }, [_accountService]);

  const formValues = form.getValues();

  return (
    <>
      <PageTitle
        title="Bank Details"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Company" },
          { name: "Bank Details" },
        ]}
      />

      <Card withBorder mih={300} data-testid="myaccount-bankdetails">
        <Card.Section py="sm" px="xl">
          <Title order={5} mb="xs">
            Bank Details
          </Title>
          <Divider />
        </Card.Section>
        <Card.Section py="sm" px="xl">
          <AuthenticatedForm
            dataProviderInstance={dataProviderInstance}
            form={form}
            fetchData={fetchData}
            readOnly
          >
            {formValues.IsInternationalBank && (
              <>
                <Stack spacing="sm">
                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Account Name:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="InternationalAccountName"
                      />
                    </Grid.Col>
                  </Grid>

                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Account Number:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="InternationalAccountNo"
                      />
                    </Grid.Col>
                  </Grid>

                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>SWIFT Code:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="SwiftCode"
                      />
                    </Grid.Col>
                  </Grid>

                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Bank Name:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="InternationalBankName"
                      />
                    </Grid.Col>
                  </Grid>

                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>ISO Code:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="CountryIsoCode"
                      />
                    </Grid.Col>
                  </Grid>

                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Beneficiary Address:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="InternationalBankAddress"
                      />
                    </Grid.Col>
                  </Grid>
                </Stack>
              </>
            )}
            {!formValues.IsInternationalBank && (
              <>
                <Stack spacing="sm">
                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Account Name:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="AccountName"
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>BSB:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="BSB"
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Account:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="Account"
                      />
                    </Grid.Col>
                  </Grid>
                  <Grid>
                    <Grid.Col md={4} sm={6}>
                      <Label>Bank:</Label>
                    </Grid.Col>
                    <Grid.Col md={8} sm={6}>
                      <FormTextField
                        readOnly
                        parentForm={form}
                        fieldName="BankName"
                      />
                    </Grid.Col>
                  </Grid>
                </Stack>
              </>
            )}
          </AuthenticatedForm>

          <Text align="end" p="sm" fz="sm">
            To update your bank details follow the instructions listed{" "}
            <Anchor
              href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10021660357647"
              target="_blank"
              rel="noreferrer"
            >
              here. <IconExternalLink size={14} />
            </Anchor>
          </Text>
        </Card.Section>
      </Card>
    </>
  );
};
