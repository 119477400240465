import { Auth0ContextInterface, User, useAuth0 } from "@auth0/auth0-react";
import { LoadingOverlay } from "@mantine/core";
import { useRouterContext } from "@mydeal/core";
import { ComponentType, FC, useEffect, useMemo, useState } from "react";

const defaultOnRedirecting = (): JSX.Element => (
  <LoadingOverlay visible={true} />
);

const withRolesCheck = <P extends object>(
  Component: ComponentType<P>,
  context: React.Context<Auth0ContextInterface<User>>,
  roles: string[],
  onRedirecting?: () => JSX.Element
): FC<P> => {
  return function WithRolesCheck(props: P): JSX.Element {
    const { isLoading, user } = useAuth0(context);
    const { useHistory } = useRouterContext();
    const history = useHistory();
    const [redirectRequired, setRedirectRequired] = useState(true);
    const userRoles = useMemo(
      () => (user?.["https://mydeal.com.au/roles"] as string[]) || [],
      [user]
    );

    useEffect(() => {
      if (!isLoading) {
        let shouldRedirect = false;
        if (
          roles &&
          roles.length > 0 &&
          !userRoles.some((r) => roles.includes(r))
        ) {
          history.push("/dashboard");
          shouldRedirect = true;
        }
        if (redirectRequired !== shouldRedirect) {
          setRedirectRequired(shouldRedirect);
        }
      }
    }, [history, isLoading, redirectRequired, userRoles]);

    return !redirectRequired ? (
      <Component {...props} />
    ) : (
      (onRedirecting || defaultOnRedirecting)()
    );
  };
};

export default withRolesCheck;
