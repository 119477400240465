import {
  Anchor,
  Button,
  Card,
  Checkbox,
  Divider,
  Grid,
  Image,
  List,
  LoadingOverlay,
  Stack,
  Text,
  Title,
} from "@mantine/core";
import * as yup from "yup";
import {
  useAuthenticatedApi,
  useDataProvider,
  useNotification,
} from "@mydeal/core";
import { onboardingService } from "@services/onboarding.service";
import { IconExternalLink } from "@tabler/icons-react";
import { useContext, useMemo, useState } from "react";
import svgDone from "../../assets/images/onboarding-done-background.svg";
import { SourceChannel } from "@types";
import OnboardingStatusContext from "./onboardingStatusContext";
import { OnboardingState } from "./types";
import { useApiErrorHandler, useFocusableForm } from "@mydeal/ui-mantine";
import { useRefreshOnboardingProgress } from "@utils/useRefreshOnboardingProgress";

export const OnboardingFinalStep = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _onboardingService = useMemo(
    () => onboardingService(dataProviderInstance),
    [dataProviderInstance]
  );

  const [model, setModel] = useState<any>();
  const [loading, setLoading] = useState<boolean>(true);
  const [saving, setSaving] = useState<boolean>(false);
  const [agreedToLaunch, setAgreedToLaunch] = useState<boolean>(false);
  const { open: notify } = useNotification();

  const onboardingStatusContext = useContext(OnboardingStatusContext);
  const refreshOnboardingProgress = useRefreshOnboardingProgress();

  const form = useFocusableForm<any>({
    schema: yup.object(),
  });
  const apiErrorHandler = useApiErrorHandler(notify, form.setErrors);

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(true);
    _onboardingService
      .getFinalStep()
      .then((result: any) => setModel(result))
      .finally(() => setLoading(false));
  });

  const onSubmit = (data: any) => {
    setSaving(true);

    _onboardingService
      .saveFinalStep()
      .then(() => {
        refreshOnboardingProgress();
      })
      .catch(apiErrorHandler)
      .finally(() => {
        setSaving(false);
      });
  };

  if (loading) {
    return <LoadingOverlay visible={loading} />;
  }

  if (model && model.SourceChannel === SourceChannel.CustomFile) {
    return (
      <Grid mt="xl">
        <Grid.Col md={5}>
          <Image src={svgDone} />
        </Grid.Col>
        <Grid.Col md={6} offsetMd={1}>
          <Stack spacing="lg" fz="md">
            <Title order={2}>ALL DONE!</Title>
            <form onSubmit={form.onSubmit(onSubmit)}>
              <Button type="submit" color="primary" disabled={saving}>
                Start Adding Your Products
              </Button>
            </form>
          </Stack>
        </Grid.Col>
      </Grid>
    );
  } else if (!model.IsExternalSourceChannel) {
    return (
      <Stack spacing="lg" fz="md">
        <Title order={3}>Thank you for confirming your store settings</Title>
        <Card>
          <Stack spacing="md">
            <Text fz="md">
              As a final step please acknowledge that you have read the below
              help article/s which provide additional information for your
              integration. Once confirmed please click 'Import Products' to
              initiate a request to fetch your products and make them live on
              MyDeal.
            </Text>
            {model.HelpArticalUrl && (
              <>
                <Title order={5}>Articles</Title>
                <Card>
                  <List>
                    <List.Item>
                      <Anchor href={model.HelpArticalUrl} target="_blank">
                        Managing your MyDeal store with{" "}
                        {model.SourceChannelDisplayName} <IconExternalLink />
                      </Anchor>
                    </List.Item>
                  </List>
                </Card>
              </>
            )}
            {onboardingStatusContext.onboardingStatusModel.OnboardingState ===
              OnboardingState.FinalStep_ImportRequested && (
              <>
                <Text>
                  <Text span fw={600} color="green">
                    Request received!
                  </Text>{" "}
                  MyDeal is working on fetching all your products. Please be
                  aware this can sometimes take up to 24 hours so we'll send you
                  a notification via email once the process is complete.
                </Text>
                <Text>
                  In the meantime you may like to explore our
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb"
                    target="_blank"
                  >
                    Seller Help Centre <IconExternalLink />
                  </Anchor>
                  .
                </Text>
              </>
            )}
            {onboardingStatusContext.onboardingStatusModel.OnboardingState ===
              OnboardingState.FinalStep && (
              <>
                <Checkbox
                  label="I confirm I have read through the article/s above and I’m ready to launch my products on MyDeal"
                  checked={agreedToLaunch}
                  onChange={(event) =>
                    setAgreedToLaunch(event.currentTarget.checked)
                  }
                />
                <Divider my="sm" />
                <form onSubmit={form.onSubmit(onSubmit)}>
                  <Button
                    type="submit"
                    color="primary"
                    disabled={!agreedToLaunch || saving}
                  >
                    Import Products
                  </Button>
                </form>
              </>
            )}
          </Stack>
        </Card>
      </Stack>
    );
  } else {
    if (model.SourceChannelIsChannel) {
      return (
        <Stack spacing="lg" fz="md">
          <Title order={2}>Thank you for providing all the information</Title>
          <Card>
            <Stack spacing="md">
              <Title order={4}>Connection Details</Title>
              <Text>
                Please use the following credentials in{" "}
                {model.SourceChannelDisplayName} to integrate with MyDeal
              </Text>
              <Grid>
                <Grid.Col md={4} sm={6}>
                  <Text>MyDeal SellerID</Text>
                </Grid.Col>
                <Grid.Col md={8} sm={6}>
                  {model.CompanyID}
                </Grid.Col>
              </Grid>
              <Grid>
                <Grid.Col md={4} sm={6}>
                  <Text>API Token</Text>
                </Grid.Col>
                <Grid.Col md={8} sm={6}>
                  {model.ApiToken}
                </Grid.Col>
              </Grid>
              <Divider />
              <Title order={4}>Next steps</Title>
              <List>
                <List.Item>
                  Please follow the instructions provided by your channel
                  partner,
                  {model.SourceChannelDisplayName}, to input the above
                  connection details, finalise your setup and push your products
                  to MyDeal.
                </List.Item>
                <List.Item>
                  Once your products have been successfully launched on MyDeal
                  you will receive an email notification and you will be able to
                  access the Seller Portal by logging in using your username and
                  password.
                </List.Item>
                <List.Item>
                  Should you require any assistance connecting, please contact
                  your
                  {model.SourceChannelDisplayName} support agent or{" "}
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/requests/new"
                    target="_blank"
                  >
                    contact MyDeal here
                  </Anchor>
                  .
                </List.Item>
              </List>
            </Stack>
          </Card>
        </Stack>
      );
    } else {
      return (
        <Stack spacing="lg" fz="md">
          <Title order={2}>Thank you for providing all the information</Title>
          <Card>
            <Stack spacing="md">
              <Title order={4}>
                Follow these steps to complete setup via MyDeal API
              </Title>
              <Text>
                <Text span fw={600}>
                  1.
                </Text>{" "}
                Please allow up to 1 business day for your sandbox API
                credentials to be sent to your nominated technical email,{" "}
                {model.TechnicalContactEmail}
              </Text>
              <Text>
                <Text span fw={600}>
                  2.
                </Text>{" "}
                Read the below documentation which contains all the information
                relating to integrating with the MyDeal Universal API
              </Text>
              <List>
                <List.Item>
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10053910787599"
                    target="_blank"
                  >
                    Integration Overview <IconExternalLink />
                  </Anchor>
                </List.Item>
                <List.Item>
                  <Anchor
                    href="https://sellerhelp.woolworthsmarketplus.com.au/hc/en-gb/articles/10054305487759"
                    target="_blank"
                  >
                    Integration Steps <IconExternalLink />
                  </Anchor>
                </List.Item>
                <List.Item>
                  <Anchor
                    href={`https://${model.CDNName}/marketplace/MyDealAPIDocumentation_latest.pdf`}
                    target="_blank"
                  >
                    MyDeal API Documentation <IconExternalLink />
                  </Anchor>
                </List.Item>
              </List>
              <Text>
                <Text span fw={600}>
                  3.
                </Text>{" "}
                Once your technical team has completed the sandbox
                implementation and testing please notify the MyDeal Team.
              </Text>
              <Text>
                <Text span fw={600}>
                  4.
                </Text>{" "}
                MyDeal will verify the sandbox test results and provide you with
                the MyDeal Seller ID and API token for live environment.
              </Text>
              <Text fw={0}>
                <Text span fw={600}>
                  5.
                </Text>{" "}
                Once your products have been successfully launched on MyDeal you
                will receive an email notification and you will be able to
                access the Seller Portal by logging in using your username and
                password.
              </Text>
            </Stack>
          </Card>
        </Stack>
      );
    }
  }
};
