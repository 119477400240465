import { FormTextField, IFocusableForm } from "@mydeal/ui-mantine";
import React, { useEffect } from "react";
import { IFreightSchemeModel } from "./FreightForm.types";

interface IConfigShippitProps {
  parentForm: IFocusableForm<Partial<IFreightSchemeModel>>;
}

export const ConfigShippit: React.FC<IConfigShippitProps> = ({
  parentForm,
}) => {
  const apiUrl = parentForm.getValues().ApiUrl;
  useEffect(() => {
    const newValue = "https://www.shippit.com/api/3/";
    if (apiUrl !== newValue) {
      parentForm.setFieldValue("ApiUrl", newValue);
    }
  }, [apiUrl, parentForm]);

  return (
    <>
      <FormTextField
        label="API Url:"
        withAsterisk
        fieldName="ApiUrl"
        parentForm={parentForm}
        disabled
      />

      <FormTextField
        label="Password / Token:"
        withAsterisk
        fieldName="ApiPassword"
        parentForm={parentForm}
      />
    </>
  );
};
