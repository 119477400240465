import React, { createContext, useState, FC } from "react";
import { ITicketsSummaryNotificationModel } from "./enquiryInbox.types";

export type TicketSummaryContextType = {
  ticketSummaryModel: ITicketsSummaryNotificationModel | null;
  setTicketSummaryModel: React.Dispatch<
    React.SetStateAction<ITicketsSummaryNotificationModel | null>
  >;
};

export type TicketSummaryContextProps = {
  children: React.ReactNode;
};

export const TicketSummaryContext = createContext<TicketSummaryContextType>(
  {} as TicketSummaryContextType
);

export const TicketSummaryContextProvider = ({
  children,
}: TicketSummaryContextProps) => {
  const [ticketSummaryModel, setTicketSummaryModel] =
    useState<ITicketsSummaryNotificationModel | null>(null);

  return (
    <TicketSummaryContext.Provider
      value={{ ticketSummaryModel, setTicketSummaryModel }}
    >
      {children}
    </TicketSummaryContext.Provider>
  );
};

export default TicketSummaryContext;
