import { PageTitle } from "@components/shared";
import { UserManagementEditor } from "./UserManagementEditor";
import { Card } from "@mantine/core";

export const UserManagement = () => {
  return (
    <>
      <PageTitle
        title="User Management"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "Administration" },
          { name: "User Management" },
        ]}
      />

      <Card withBorder mih={300} p="xl">
        <UserManagementEditor />
      </Card>
    </>
  );
};
