import {useCallback, useMemo, useRef, useState} from "react";
import * as yup from "yup";
import { useDataProvider, useNotification } from "@mydeal/core";
import { adminService } from "@services";
import {
  AuthenticatedForm,
  DynamicDataTable, 
  FormSelect,
  IDynamicDataTable,
  IDynamicFetchParameters,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import {IDataTableRequestModel, IUserManagementModel} from "@types";
import {Text, Tooltip, SelectItem} from "@mantine/core";
import {
  IconCheck,
  IconHelpCircle,
  IconShieldLock,
  IconUserShield,
  IconX,
} from "@tabler/icons-react";
import { openConfirmModal } from "@mantine/modals";

const UserManagementSchema = yup.object<{
  [Property in keyof IUserManagementModel]: any;
}>({});

export const UserManagementEditor = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _adminService = useMemo(
    () => adminService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const form = useFocusableForm<IUserManagementModel>({
    schema: UserManagementSchema,
    initialValues: {},
  });

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sort }: IDynamicFetchParameters) => {
      var dataTableRequest: IDataTableRequestModel = {
        PageIndex: pageIndex,
        PageSize: pageSize,
      };
      return await _adminService.getUsers(dataTableRequest);
    },
    [_adminService]
  );

  const dtRef = useRef<IDynamicDataTable>(null);

  const refreshTable = useCallback(() => {
    setTimeout(() =>
      {
        if (dtRef.current) {
          dtRef.current.refresh();
        }
      },
      1000);
  }, []);

  const setAdmin = useCallback(
    async (userId: string, admin: boolean, email: string) => {
      openConfirmModal({
        title: (
          <Text fw="bold" fz="sm">
            Please Confirm
          </Text>
        ),
        children: (
          <Text size="sm">{`Are you sure you want change admin status for: ${email}`}</Text>
        ),
        labels: { confirm: "Yes", cancel: "No" },
        onConfirm: () => {
          _adminService.setUserAdmin(userId, admin);
          refreshTable();
        },
      },
      );
    },
    [_adminService, refreshTable]
  );
  
  const settingTypes = (admin: boolean) => {
    let settingTypes: SelectItem[] = [
      { label: admin ? "Remove Admin" : "Make Admin", value: "1" },
      { label: "Clear MFA", value: "2" },
      { label: "Remove", value: "3" },
    ];
    
     return settingTypes
  }

  const [selected, setSelected] = useState(null);

  const handleChange = (selectedOption: any, row: any) => {
    setSelected(selectedOption);
    switch (selectedOption) {
      case 1: {
        return setAdmin(row.UserId, !row.IsAdmin, row.Email);
      }
      case 2: {
        return clearMfa(row.UserId);
      }
      case 3: {
        return removeUser(row.UserId, row.Email);
      }
    }
  };

  //const clearMfa = useCallback(
  //  async (userId: string) => {
  //    await _adminService.clearUserMfa(userId);
  //    refreshTable();
  //  },
  //  [_adminService, refreshTable]
  //);
  const clearMfa = useCallback(
    async (userId: string) => {
      openConfirmModal({
        title: (
          <Text fw="bold" fz="sm">
            Please Confirm
          </Text>
        ),
        children: (
          <Text size="sm">Are you sure you want to clear MFA this user?</Text>
        ),
        labels: { confirm: "Yes", cancel: "No" },
        onConfirm: () => {
          _adminService
            .clearUserMfa(userId)
            .then((result: any) => {
              notify?.({
                type: "success",
                message: "User MFA cleared.",
                description: "",
              });
              refreshTable();
            })
            .catch((err) => {
              const {
                response: {
                  data: { ErrorMessage, title },
                },
              } = err;
              notify?.({
                type: "error",
                message:
                  ErrorMessage ??
                  "Unable to process the request. Please try again later.",
                description: ErrorMessage ?? title,
              });
            });
        },
      });
    },
    [_adminService, notify, refreshTable]
  );
  const removeUser = useCallback(
    (userId: string, email: string) => {
      openConfirmModal({
        title: (
          <Text fw="bold" fz="sm">
            Please Confirm
          </Text>
        ),
        children: (
          <Text size="sm">{"Are you sure you want to remove the user: " + email}</Text>
        ),
        labels: { confirm: "Yes", cancel: "No" },
        onConfirm: () => {
          _adminService
            .removeUser(userId)
            .then((result: any) => {
              notify?.({
                type: "success",
                message: "User removed from company.",
                description: "",
              });
              refreshTable();
            })
            .catch((err) => {
              const {
                response: {
                  data: { ErrorMessage, title },
                },
              } = err;
              notify?.({
                type: "error",
                message:
                  ErrorMessage ??
                  "Unable to process the request. Please try again later.",
                description: ErrorMessage ?? title,
              });
            });
        },
      });
    },
    [_adminService, notify, refreshTable]
  );

  return (
    <AuthenticatedForm
      dataProviderInstance={dataProviderInstance}
      form={form}
      readOnly
      align="stretch"
    >
      <DynamicDataTable<any, any>
        sortable={false}
        fetchData={fetchData}
        getData={(response) => response.Users}
        getCount={(response) => response.Total}
        mantineTableBodyRowProps={{}}
        controlRef={dtRef}
        columns={[
          {
            fieldName: "Email",
            header: "Email",
            minSize: 100,
            cellTemplate: (value: any) => <Text>{value}</Text>,
          },
          {
            fieldName: "IsAdmin",
            header: "Is Admin",
            maxSize: 50,
            cellTemplate: (value: any) => (value ? <IconCheck /> : <IconX />),
          },
          {
            fieldName: "LastLoginDatePretty",
            header: "Last Login Date",
            maxSize: 20,
            cellTemplate: (value: any) => <Text>{value}</Text>,
          },
          {
            fieldName: "IsCurrentUser",
            header: "Settings",
            maxSize: 30,
            cellTemplate: (value: any, row: any) => {
              if (value)
                return (
                  <Tooltip label="Cannot demote or remove your own account.">
                  <IconHelpCircle size={16} />
                  </Tooltip>
                );
                else
                  return (
                    <FormSelect
                      withinPortal={true}
                      parentForm={form}
                      fieldName={row}
                      data={settingTypes(row.IsAdmin)}
                      autoFocus={true}
                      onChange={(selectedOption) => handleChange(selectedOption, row)}
                      withAsterisk
                      placeholder="User Settings"
                      numericValue
                      />
                  );
            }
          }
        ]}
      />
    </AuthenticatedForm>
  );
};
