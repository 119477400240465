import { Card, Text, createStyles, CardProps, Title } from "@mantine/core";
import { ReactNode } from "react";

const useStyles = createStyles((theme) => ({
  card: {
    position: "relative",
    height: 145,
    backgroundColor:
      theme.colorScheme === "dark" ? theme.colors.dark[7] : theme.white,
    overflow: "visible",
  },

  title: {
    display: "block",
    marginBottom: `calc(${theme.spacing.xs} / 2)`,
  },

  action: {
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[6]
        : theme.colors.gray[0],
    ...theme.fn.hover({
      backgroundColor:
        theme.colorScheme === "dark"
          ? theme.colors.dark[5]
          : theme.colors.gray[1],
    }),
  },
}));

interface ActionCardProps extends Omit<CardProps, "children"> {
  link?: string;
  title?: string;
  description?: string;
  header: string;
  children?: string | ReactNode;
}

export function ActionCard({
  className,
  header,
  link,
  title,
  description,
  children,
  ...rest
}: ActionCardProps &
  Omit<React.ComponentPropsWithoutRef<"div">, keyof ActionCardProps>) {
  const { classes, cx, theme } = useStyles();
  const linkProps = {
    href: link,
    target: "_blank",
    rel: "noopener noreferrer",
  };

  return (
    <>
      <Title order={4} mb="xs">
        {header}
      </Title>
      <Card
        withBorder
        radius="md"
        className={cx(classes.card, className)}
        {...rest}
      >
        {title && (
          <Text className={classes.title} size="lg" weight={500}>
            {title}
          </Text>
        )}
        <Text size="sm" color="dimmed" lineClamp={4}>
          {description}
        </Text>
        {children}
      </Card>
    </>
  );
}
