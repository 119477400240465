import { IDataContextProvider } from "@mydeal/core";

export const dashboardService = (dataProvider: IDataContextProvider) => ({
  loadDashboard: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/dashboard`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  importStuff: async (resource: string, entity: any) => {
    return dataProvider
      .upload({ resource: resource, variables: entity })
      .then((result) => {
        return result?.data;
      });
  },

  exportStuff: async (resource: string) => {
    return dataProvider
      .download({
        resource: `${resource}`,
      })
      .then((result) => {
        return result;
      });
  },
});
