import { useContext, useMemo, useRef } from "react";
import {
  Anchor,
  createStyles,
  Group,
  Menu,
  Text,
  UnstyledButton,
  Badge,
} from "@mantine/core";
import { IconBellRinging, IconAlertCircle } from "@tabler/icons-react";
import {
  useAuthenticatedApi,
  useDataProvider,
  useRouterContext,
} from "@mydeal/core";
import { accountService, enquiryInboxService } from "@services";
import { TicketSummaryContext } from "@pages/enquiryInbox";
import { ITicketsSummaryNotificationModel } from "../../../pages/enquiryInbox/enquiryInbox.types";
import { ShippingSettingsContext } from "@pages/shipping";
import { useRollbar } from "@rollbar/react";
import Rollbar from "rollbar";

const useStyles = createStyles((theme) => ({
  header: {
    justifyContent: "space-around",
  },
  logoContainer: {
    width: 150,
    [`@media (max-width: ${theme.breakpoints.sm})`]: {
      width: 100,
    },
  },
  notificationBadge: {
    marginBottom: "20px",
    marginLeft: "-12px",
    padding: 2,
    minWidth: 20,
  },

  notificationMenuItem: {
    backgroundColor: "orange",
    height: "200px",
    "&:hover": {
      backgroundColor: "orange",
    },
  },

  headerLinks: {
    padding: '10px 10px',
    borderRadius: '5px',
    '&:hover': {
      backgroundColor: '#F8F9FA',
    }
  }
}));

export const NotificationsHeader = () => {
  const { classes, cx } = useStyles();
  const { Link } = useRouterContext();
  const rollbar = useRollbar();
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _enquiryInboxService = useMemo(
    () => enquiryInboxService(dataProviderInstance),
    [dataProviderInstance]
  );
  const _accountService = useMemo(
    () => accountService(dataProviderInstance),
    [dataProviderInstance]
  );
  const shippingSettingsContext = useContext(ShippingSettingsContext);
  const ticketSummaryContext = useContext(TicketSummaryContext);
  const refreshNotificationsInterval = useRef<{ timer?: NodeJS.Timer }>({});

  const fetchShippingSettings = () => {
    _accountService.loadShippingSettings().then((result) => {
      shippingSettingsContext.setShippingSettingsModel(result || null);
    });
  };

  const fetchTicketSummary = () => {
    _enquiryInboxService
      .loadSummary()
      .then((result) => {
        if (ticketSummaryContext && result) {
          const res: ITicketsSummaryNotificationModel = {
            OpenTicketsCount: result.openTicketCount,
            OpenEscalationsCount: result.openEscalationCount,
            HelpCenterAPIConnectErrorFlag: result.helpCenterAPIConnectErrorFlag,
            GetSummary: fetchTicketSummary,
          };
          ticketSummaryContext.setTicketSummaryModel(res);
        }
      })
      .catch((err) => {
        rollbar.error(err);
      });
  };

  useAuthenticatedApi(dataProviderInstance, () => {
    fetchShippingSettings();
    fetchTicketSummary();
  });

  return (
    <>
      <Menu
        width={260}
        position="bottom"
        transitionProps={{ transition: "pop-top-right" }}
        offset={0}
      >
        <Menu.Target>
          <UnstyledButton>
            <Group 
              spacing={7}
              className={classes.headerLinks}
            >
              <IconBellRinging size={14} />
              <Group>
                <Text weight={500} size="sm" sx={{ lineHeight: 1 }} mr={3}>
                  Notifications
                </Text>
              </Group>
              {ticketSummaryContext &&
                ticketSummaryContext.ticketSummaryModel &&
                ticketSummaryContext.ticketSummaryModel
                  .HelpCenterAPIConnectErrorFlag && (
                  <Badge
                    size="xs"
                    color="orange"
                    variant="filled"
                    className={classes.notificationBadge}
                  >
                    1
                  </Badge>
                )}
              {ticketSummaryContext &&
                ticketSummaryContext.ticketSummaryModel &&
                !ticketSummaryContext.ticketSummaryModel
                  .HelpCenterAPIConnectErrorFlag &&
                ticketSummaryContext.ticketSummaryModel.OpenTicketsCount >
                  0 && (
                  <Badge
                    size="xs"
                    color="#F04A1C"
                    variant="filled"
                    className={classes.notificationBadge}
                  >
                    {ticketSummaryContext.ticketSummaryModel.OpenTicketsCount}
                  </Badge>
                )}
              {/* {ticketSummaryContext && ticketSummaryContext.ticketSummaryModel && (
                <Badge
                  size="xs"
                  color="#F04A1C"
                  variant="filled"
                  className={classes.notificationBadge}
                >
                  {ticketSummaryContext.ticketSummaryModel?.OpenTicketsCount}
                </Badge>
              )} */}
            </Group>
          </UnstyledButton>
        </Menu.Target>
        {ticketSummaryContext && ticketSummaryContext.ticketSummaryModel && (
          <Menu.Dropdown>
            {ticketSummaryContext.ticketSummaryModel
              .HelpCenterAPIConnectErrorFlag ? (
              <Menu.Item icon={<IconAlertCircle size={14} stroke={1.5} />}>
                <Text>
                  Unable to connect to helpcenter provider API, please refresh
                  the page.
                </Text>
              </Menu.Item>
            ) : !ticketSummaryContext.ticketSummaryModel
                .HelpCenterAPIConnectErrorFlag &&
              ticketSummaryContext.ticketSummaryModel.OpenTicketsCount > 0 ? (
              <Anchor
                component={Link}
                to={`/enquiryinbox/open-tickets`}
                className={classes.notificationMenuItem}
              >
                <Menu.Item icon={<IconAlertCircle size={14} stroke={1.5} />}>
                  You have{" "}
                  {ticketSummaryContext.ticketSummaryModel.OpenTicketsCount}{" "}
                  open ticket(s).
                </Menu.Item>
              </Anchor>
            ) : (
              <Menu.Item icon={<IconAlertCircle size={14} stroke={1.5} />}>
                <Text>No New Notifications.</Text>
              </Menu.Item>
            )}
          </Menu.Dropdown>
        )}
      </Menu>
    </>
  );
};
