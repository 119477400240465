import { IDataContextProvider } from "@mydeal/core";

export const infoService = (dataProvider: IDataContextProvider) => ({
  loadOrderHelp: async (bidDealId: BigInt) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/info/${bidDealId}`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  loadEnquiryTypes: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/info/enquiryTypes`,
      })
      .then((result) => {
        return result?.data;
      });
  },
  orderHelp: async (model: any) => {
    const baseUrl = dataProvider.getApiUrl();    
    var url = `${baseUrl}/info/orderHelp`;
    
    return dataProvider
      .custom?.({
        method: "post",
        url: url,
        payload: model,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      })
      .then((result) => {
        return result?.data;
      });  
  },
});
