import { Loader, Select, SelectItem, SelectProps } from "@mantine/core";
import { IconSearch } from "@tabler/icons-react";
import { forwardRef, useCallback, useEffect, useState } from "react";

export interface ILocationFieldProps
  extends Omit<SelectProps, "data" | "onChange"> {
  minimumInputLength?: number;
  onChange?: (value?: ILocationItem) => void;
}

export interface ILocationItem extends SelectItem {
  id: string;
  postcode: string;
  state: string;
  suburb: string;
}

export const LocationField = forwardRef<HTMLInputElement, ILocationFieldProps>(
  (
    {
      minimumInputLength,
      onChange,
      value,
      placeholder,
      ...rest
    },
    ref
  ) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [searchValue, setSearchValue] = useState(value || "");
    const [location, setLocation] = useState<ILocationItem>();
    const [data, setData] = useState<ILocationItem[]>([]);

    useEffect(() => setSearchValue(value || ""), [value]);

    const searchAsync = useCallback((inputValue: string) => {
      return new Promise<any[]>((resolve, reject) => {
        if (minimumInputLength && inputValue.length < minimumInputLength) {
          reject();
        } else {
          fetch(
            `https://www.mydeal.com.au/home/getpostalsuburb?postcodeorsuburb=${inputValue}`
          )
            .then((res) => res.json())
            .then((json) => {
              resolve(json);
            })
            .catch((err: any) => {
              reject(err.Message);
            })
            .finally(() => {
              setLoading(false);
            });
        }
      });
    }, []);

    const handleChange = (val: string) => {
      setSearchValue(val);
      setData([]);

      if (!val || val.trim().length === 0 || val.includes("@")) {
        setLoading(false);
      } else {
        setLoading(true);
        searchAsync(val)
          .then((locations: ILocationItem[]) => {
            setData(locations);
          })
          .finally(() => setLoading(false));
      }
    };

    return (
      <>
        <Select
          value={location?.value}
          placeholder={value || placeholder}
          data={data}
          searchValue={searchValue}
          searchable
          onSearchChange={handleChange}
          clearable
          rightSection={
            loading ? <Loader size={16} /> : <IconSearch size={16} />
          }
          onChange={(newValue) => {
            const selected = data.find((x) => x.value === newValue);
            if (selected) {
              setSearchValue(selected.value);
            }
            setLocation(selected);
            onChange?.(selected);
          }}
          {...rest}
          ref={ref}
        />
      </>
    );
  }
);
