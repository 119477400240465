import { bool } from "prop-types";
import { boolean } from "yup";

export enum VoucherState {
  Processing = 0,
  CardApproved = 1,
  CardDeclined = 2,
  Refunded = 3,
  Purchased = 4,
}
export enum DealType {
  Normal = 0,
  SupplierMisc = 2,
  SupplierFee = 6,
  SupplierSubscriptionFee = 7,
  SupplierShopnEarnCreditChargeBack = 10,
  SupplierCouponMisc = 11,
}

export interface IContactUsViewModel {
  Enquiry: string;
  OrderNumber: string;
  Comments: string;
  SellerName: string;
  OrderGroupNumber: string;
  Attachments?: File[];
}

export interface IAddressViewItem {
  AddressLine1: string;
  AddressLine2: string;
  PostalCode: string;
  PostalState: string;
  Suburb: string;
  Country: string;
}
export interface IAddonOrderViewItem {
  BidDealId: bigint;
  InvoiceName: string;
  Status: VoucherState;
  IsPaid: boolean;
  Amount: number;
}

export interface IOrderViewItem {
  CustomerName: string;
  ShippingAddress: IAddressViewItem;
  CompanyName: string;
  OrderDate: Date;
  Status: VoucherState;
  OrderValue: number;
  PhoneNo: string;
  ProductSKU: string;
  FreightAmount: number;
  OrderAmount: number;
  OrderQty: number;
  CompanyTickUsed: boolean;
  TrackingNo: string;
  Courier: string;
  SupplierNotes: string;
  SelectedOption: string;
  IsPosted: boolean;
  DealId: bigint;
  BidDealId: bigint; // Order no.
  HasAssocaitedFreight: boolean;
  IsDeliveryOnHold: boolean;
  TotalAmount: number;
  AcceptedTime: Date;
  Expires: Date | null;
  InvoiceName: string;
  IsPaid: boolean; // money released to vendor
  NoteCount: bigint;
  AddonOrderViewItems: IAddonOrderViewItem[];
  AddonOrderViewItemsCount: number;
  DealType: DealType;
  Reference: string;
  MainDeal: string; //Deal Name
  CustomerEmail: string;
  GoogleProductCategory: string;
  SellerName: string;
  SellPricePerUnit: number;
  TotalSalePrice: number;
  DispatchDate: Date | null;
  BidDealGroupId: bigint | null;
  IsCombinedFreightOrder: boolean;
  CompanyId: bigint;
  Url: string;
  CustomerMarketplace: string;
}
