import { useCallback, useMemo, useState } from "react";
import * as yup from "yup";
import {
  useAuthenticatedApi,
  useDataProvider,
  useRouterContext,
} from "@mydeal/core";
import { clientsService } from "@services";
import { IDealOrdersViewModel } from "./types";
import { format } from "date-fns";
import { PageTitle } from "@components/shared";
import {
  FormRadioGroup,
  IDynamicFetchParameters,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import {
  Alert,
  Anchor,
  Button,
  Card,
  Checkbox,
  Divider,
  Flex,
  Grid,
  Group,
  LoadingOverlay,
  Radio,
  Stack,
  Text,
} from "@mantine/core";
import { IconAlertCircle, IconDownload } from "@tabler/icons-react";
import { OrderTable } from "@components/shared/OrderTable";

interface IRouteParams {
  dealId?: string;
}

const bookmarkCurrentPage = () => {
  try {
    var cjTitle = document.title;
    var cjHref = window.location.href;

    const sidebar = (window as any).sidebar;

    if (typeof sidebar == "object" && typeof sidebar.addPanel == "function") {
      sidebar.addPanel(cjTitle, cjHref, ""); // Gecko
      return false;
    } else {
      var ua = navigator.userAgent.toLowerCase();
      var str = "";
      var isWebkit = ua.indexOf("webkit") !== -1;
      var isMac = ua.indexOf("mac") !== -1;

      if (ua.indexOf("konqueror") !== -1) {
        str = "CTRL + B"; // Konqueror
      } else if ((window as any).home || isWebkit || isMac) {
        str = (isMac ? "Command/Cmd" : "CTRL") + " + D"; // Netscape, Safari, iCab, IE5/Mac
      }
      alert(str ? "Press " + str + " to bookmark this page." : str);
    }
  } catch (err) {
    alert(err);
  }
};

export const ClientList = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _clientsService = useMemo(
    () => clientsService(dataProviderInstance),
    [dataProviderInstance]
  );
  const [model, setModel] = useState<IDealOrdersViewModel>();
  const [loading, setLoading] = useState<boolean>(true);
  const [downloading, setDownloading] = useState<boolean>(false);
  const { useParams } = useRouterContext();
  const { dealId } = useParams<IRouteParams>();

  const fetchData = useCallback(
    async ({ pageSize, pageIndex }: IDynamicFetchParameters) => {
      const result = await _clientsService.list({
        dealId: dealId,
        start: pageSize * pageIndex,
        length: pageSize,
      });
      if (result) {
        setModel(result as IDealOrdersViewModel);
      }
      return result;
    },
    [_clientsService, dealId]
  );

  useAuthenticatedApi(dataProviderInstance, () => {
    setLoading(false);
  });

  const form = useFocusableForm({
    schema: yup.object(),
    initialValues: {
      postal: "true",
      pdf: "true",
      Unfulfilled: true,
      RefundAndOnhold: false,
    },
  });

  const formValues = form.getValues();

  const downloadFile = () => {
    var DealID = dealId ?? -1111;

    var IsPosted = "False";
    var Pdf = "False";

    if (model?.Deal.DealID === -1111 && model?.dealType === "Both") {
      if (formValues.postal === "true") {
        IsPosted = "True";
      }
    } else if (model?.Deal.DealID === -1111 && model.dealType === "Posted") {
      IsPosted = "True";
    }

    if (formValues.pdf === "true") {
      Pdf = "True";
    }

    var onlyFulfilled = "True";
    if (formValues.Unfulfilled) {
      onlyFulfilled = "False";
    }

    var includeRefundAndOnhold = "False";
    if (formValues.RefundAndOnhold) {
      includeRefundAndOnhold = "True";
    }

    var url = "ClientList/";

    if (Pdf === "True") {
      url += "PdfDownload?";
    } else {
      url += "CsvDownload?";
    }
    url += "DealID=" + DealID;
    url += "&";
    url += "IsPosted=" + IsPosted;
    url += "&";
    url += "onlyFulfilled=" + onlyFulfilled;
    url += "&";
    url += "includeRefundAndOnhold=" + includeRefundAndOnhold;

    setDownloading(true);
    _clientsService.downloadList(url).finally(() => setDownloading(false));
  };

  return (
    <>
      <PageTitle
        title={
          model?.Deal.DealID === -1111
            ? "All Customer Orders"
            : "Customer Orders"
        }
        description={
          model?.Deal.DealID !== -1111 ? (
            <Text fz="lg">{`${model?.Deal.MainDeal} - ${model?.Deal.SKU}`}</Text>
          ) : (
            <></>
          )
        }
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "My Deals", route: "/mydeals" },
          { name: "Client List" },
        ]}
      >
        <Anchor type="button" fz="md" onClick={bookmarkCurrentPage}>
          Bookmark this site
        </Anchor>
      </PageTitle>
      <Card withBorder p="xl" mih={300}>
        <LoadingOverlay visible={loading || downloading} />
        {!loading && (
          <Stack>
            {model && (
              <>
                {model.Deal.Posted && (
                  <Grid>
                    <Grid.Col md={4} sm={12}>
                      <Alert title="Promotional Period:">
                        <Text fw="bold">
                          {model.Deal.DealStartTime == null
                            ? "No Start"
                            : format(
                                new Date(model.Deal.DealStartTime),
                                "dd/MM/yyyy"
                              )}{" "}
                          -{" "}
                          {model.Deal.DealEndTime == null
                            ? "No End"
                            : format(
                                new Date(model.Deal.DealEndTime),
                                "dd/MM/yyyy"
                              )}
                        </Text>
                      </Alert>
                    </Grid.Col>
                    <Grid.Col md={4} sm={12}>
                      <Alert title="Total Customers/Orders outstanding:">
                        <Text color="red" fw="bold">
                          {model.PendingOrderCount}
                        </Text>
                      </Alert>
                    </Grid.Col>
                  </Grid>
                )}

                <Card withBorder>
                  <Flex align="center" gap="xl">
                    <>
                      {/* {model.Deal.DealID === -1111 &&
                        model.dealType === "Both" && (
                          <>
                            <FormRadioGroup
                              fieldName="postal"
                              parentForm={form}
                            >
                              <Group>
                                <Radio
                                  name="postal"
                                  label="Posted"
                                  value="true"
                                />
                              </Group>
                            </FormRadioGroup>
                          </>
                        )}
                      <Divider orientation="vertical" /> */}
                      <FormRadioGroup fieldName="pdf" parentForm={form}>
                        <Group>
                          <Radio label="PDF" value="true" />
                          <Radio label="CSV" value="false" />
                        </Group>
                      </FormRadioGroup>
                      <Divider orientation="vertical" />
                      <Group spacing="xs">
                        <Checkbox
                          label="Unfulfilled Orders Only"
                          {...form.getInputProps("Unfulfilled", {
                            type: "checkbox",
                          })}
                        />

                        {model.Deal.DealID === -1111 && (
                          <Checkbox
                            label="Include Refunded & On Hold Orders"
                            {...form.getInputProps("RefundAndOnhold", {
                              type: "checkbox",
                            })}
                          />
                        )}
                      </Group>
                      <Button
                        leftIcon={<IconDownload size={16} />}
                        onClick={() => {
                          downloadFile();
                        }}
                      >
                        Download
                      </Button>
                    </>
                  </Flex>
                </Card>

                {model.Deal.Posted && (
                  <Alert
                    color="yellow"
                    title="NOTE:"
                    icon={<IconAlertCircle size={16} />}
                  >
                    Shipping Status, tracking and courier details are visible to
                    customers. Notes field is private.
                  </Alert>
                )}
              </>
            )}
            <OrderTable
              fetchData={fetchData}
              getData={(response) => response.vouchers}
              getCount={(response) => response.OrderCount}
              showClientListLink={false}
            />
          </Stack>
        )}
      </Card>
    </>
  );
};
