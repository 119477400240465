import { useState } from "react";
import { AppShell } from "@mantine/core";
import { PublicHeader } from "./PublicHeader";
import { PublicFooter } from "./PublicFooter";
import { PublicNav } from "./PublicNav";
import { Outlet } from "@mydeal/react-router";

export const PublicLayout = () => {
  const [opened, setOpened] = useState(false);

  const toggleOpen = () => {
    setOpened(!opened);
  };

  return (
    <AppShell
      navbarOffsetBreakpoint="sm"
      padding="md"
      header={<PublicHeader toggleOpen={toggleOpen} opened={opened} />}
      navbar={<PublicNav opened={opened} />}
      footer={<PublicFooter />}
      styles={(theme) => ({
        main: {
          paddingLeft: theme.spacing.md,
          paddingTop: "100px",

          backgroundColor:
            theme.colorScheme === "dark"
              ? theme.colors.dark[8]
              : theme.colors.gray[0],
        },
      })}
    >
      <Outlet />
    </AppShell>
  );
};
