import { createStyles, Text, Avatar, Group, TypographyStylesProvider, Paper, Title, ActionIcon, Blockquote } from '@mantine/core';

const useStyles = createStyles((theme) => ({
  comment: {
    padding: `${theme.spacing.lg} ${theme.spacing.xl}`,
    position: "relative",
    backgroundColor:
      theme.colorScheme === "dark"
        ? theme.colors.dark[8]
        : theme.colors.gray[0],
  },

  body: {
    paddingLeft: 54,
    paddingTop: theme.spacing.sm,
    fontSize: theme.fontSizes.sm,
  },

  content: {
    fontSize: theme.fontSizes.md,
    '& > p:last-child': {
      marginBottom: 0,
    },
  },

  quoteIcon: {
    position: "absolute",
    fontSize: "40px",
    top: "0"
  },

  authorTitle: {
    fontSize: theme.fontSizes.xl,
  }
}));

interface CommentHtmlProps {
  title: string;
  body: string;
  authorname: string;
  authortitle: string;
}

export function CommentHtml({ title, body, authorname, authortitle }: CommentHtmlProps) {
  const { classes, cx, theme } = useStyles();
  return (
    <Paper radius="lg" className={classes.comment}>
      <Blockquote cite="" className={classes.quoteIcon} color = {theme.primaryColor}>
      </Blockquote>
      <TypographyStylesProvider className={classes.body}>
        <Title order={4} className={classes.authorTitle} mt={0}>{title}</Title>
        <div className={classes.content} dangerouslySetInnerHTML={{ __html: body }} />
        <Group pt="md" spacing="xs">
          <Avatar size={30} radius="xl" mr="xs" color={theme.primaryColor} />
          <Text size="sm" color="dimmed" >{authorname} ({authortitle})</Text>
        </Group>

      </TypographyStylesProvider>

    </Paper>
  );
}