import { IDataContextProvider } from "@mydeal/core";
import {
  IFreightResponse,
  StandardTemplate,
} from "@pages/shipping/manageFreight/FreightForm.types";
import { IShippingAssignmentModel } from "@types";

export const shippingService = (dataProvider: IDataContextProvider) => ({
  freightList: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/ManageFreight`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  getFreightById: async (
    freightSchemeID: number,
    freightSchemeCompanyID?: number
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/ManageFreight/${freightSchemeID}`,
        query: freightSchemeCompanyID
          ? { freightSchemeCompanyID: freightSchemeCompanyID }
          : undefined,
      })
      .then((result) => {
        return result?.data as IFreightResponse;
      });
  },

  addFreight: async (data: any) => {
    var formData = new FormData();
    for (var key in data) {
      if (data[key]) {
        var val = data[key];
        if (Array.isArray(val)) {
          val.forEach((v) => formData.append(key, v));
        } else {
          formData.append(key, data[key]);
        }
      }
    }

    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/ManageFreight`,
        payload: formData,
        headers: {
          "Content-Type": "multipart/form-data",
          Accept: "*/*",
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  getShippingOffer: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/ManageFreight/ShippingOffer`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  updateShippingOffer: async (model: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/ManageFreight/ShippingOffer`,
        payload: model,
      })
      .then((result) => {
        return result?.data;
      });
  },

  getCombinedShipping: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/ManageFreight/CombinedShipping`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  updateCombinedShipping: async (model: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/ManageFreight/CombinedShipping`,
        payload: model,
      })
      .then((result) => {
        return result?.data;
      });
  },

  getShippingAssignment: async () => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/MyAccount/ShippingAssignment`,
      })
      .then((result) => {
        return result?.data as IShippingAssignmentModel;
      });
  },

  updateShippingAssignment: async (model: any, onboarding: boolean = false) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "patch",
        url: `${baseUrl}/MyAccount/ShippingAssignment`,
        payload: model,
        query: {
          onboarding,
        },
      })
      .then((result) => {
        return result?.data;
      });
  },

  downloadRateTableFile: async (
    freightSchemeID: number,
    standardTemplate: StandardTemplate
  ) => {
    return dataProvider.download({
      resource: `ManageFreight/RateTableFile?freightSchemeID=${freightSchemeID}&standardTemplate=${standardTemplate}`,
    });
  },

  downloadPostcodeFile: async (postcodeExclusionGroupID: number) => {
    return dataProvider.download({
      resource: `ManageFreight/PostcodeFile?postcodeExclusionGroupID=${postcodeExclusionGroupID}`,
    });
  },

  deletePostcodeFile: async (
    freightSchemeID: number,
    postcodeExclusionGroupID: number
  ) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider.custom?.({
      method: "delete",
      url: `${baseUrl}/ManageFreight/PostcodeFile`,
      query: {
        freightSchemeID,
        postcodeExclusionGroupID,
      },
    });
  },
});
