import { Stack, Title } from "@mantine/core";
import { ProductSettingsEditor } from "@pages/myaccount/ProductSettingsEditor";

export const OnboardingProductSetting = () => {
  return (
    <Stack spacing="lg">
      <Title order={2}>Product Settings</Title>
      <ProductSettingsEditor onboarding={true} />
    </Stack>
  );
};
