export interface IDashboardModel {
  EarningsLast30Days: number;
  TotalPendingOrderCount: number;
  TotalUnusedVoucherCount: number;
  LastProductSyncAt: string | null;
  ProductSyncFrequency: number | null;
  CompanyProfileUrl: string;
  CompanyName: string;
  CompanyCategoryImage: string;
}

export interface ICouponPromoModel {
  StartDate: string;
  ExpiryDate: string;
  DiscountPresentage: number;
  MinCartValue: number;
}

export interface IPromoEventModel {
  CompanyID: number;
  HasPromoEvents: boolean;
  SellerUrl: string;
  Model: CouponModel[];
}

export interface CouponModel {
  CouponID: number;
  DiscountPercentage: number;
  ExpiryDate: string | Date;
  MinCartValue: number;
  PromotionalEventId: number;
  StartDate: string | Date;
  Status: number;
}
export enum CouponStatus {
  Active = 0,
  Pending = 1,
  Expired = 2,
}
