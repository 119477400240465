import { FormTextField, IFocusableForm } from "@mydeal/ui-mantine";
import React from "react";
import { IFreightSchemeModel } from "./FreightForm.types";

interface IConfigNetoProps {
  parentForm: IFocusableForm<Partial<IFreightSchemeModel>>;
}

export const ConfigNeto: React.FC<IConfigNetoProps> = ({ parentForm }) => {
  return (
    <>
      <FormTextField
        label="API Url:"
        withAsterisk
        fieldName="ApiUrl"
        parentForm={parentForm}
      />

      <FormTextField
        label="UserName:"
        withAsterisk
        fieldName="ApiUserName"
        parentForm={parentForm}
      />

      <FormTextField
        label="Password / Token:"
        withAsterisk
        fieldName="ApiPassword"
        parentForm={parentForm}
      />
    </>
  );
};
