import { IDataContextProvider } from "@mydeal/core";

export const orderService = (dataProvider: IDataContextProvider) => ({
  getById: async (dealId: number) => {
    return await dataProvider
      .getOne({ resource: `order`, id: dealId })
      .then((result) => {
        return result?.data;
      });
  },

  requestPayment: async (data: any) => {
    return await dataProvider
      .create({ resource: `Order/AddonPayment`, variables: data })
      .then((result) => {
        return result?.data;
      });
  },

  cancelAddonPayment: async (addonPaymentReceiptId: any) => {
    const baseUrl = dataProvider.getApiUrl();
    return await dataProvider
      .custom?.({
        method: "delete",
        url: `${baseUrl}/order/AddonPayment/${addonPaymentReceiptId}`,
      })
      .then((result) => {
        return result?.data;
      });
  },

  getAllOrderNotes: async (data: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "get",
        url: `${baseUrl}/ClientList/GetAllSupplierNotes/${data}`,
      })
      .then((result) => {
        return result?.data;
      });
  },
  addSellerNote: async (data: any) => {
    return await dataProvider
      .create({ resource: `ClientList/SaveSupplierNote`, variables: data })
      .then((result) => {
        return result?.data;
      });
  },

  getRefundDetails: async (dealId: number) => {
    return await dataProvider
      .getOne({ resource: `order/refund`, id: dealId })
      .then((result) => {
        return result?.data;
      });
  },

  saveRefund: async (data: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/order/refund`,
        payload: data,
      })
      .then((result) => {
        return result?.data;
      });
  },

  updateDispatchInfo: async (data: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/ClientList/UpdateDispatchInfo`,
        payload: data,
      })
      .then((result) => {
        return result?.data;
      });
  },

  removeDispatchInfo: async (bidDealGroupId?: number, bidDealId?: number) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/ClientList/UpdateDispatchInfo`,
        payload: { bidDealGroupId, bidDealId, TrackingNumber: "", Courier: "" },
      })
      .then((result) => {
        return result?.data;
      });
  },

  updateShippingAddress: async (data: any) => {
    const baseUrl = dataProvider.getApiUrl();

    return await dataProvider
      .custom?.({
        method: "post",
        url: `${baseUrl}/Order/ShippingAddress`,
        payload: data,
      })
      .then((result) => {
        return result?.data;
      });
  },
});
