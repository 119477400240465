import {
  Table,
} from "@mantine/core";
import { useNotification } from "@mydeal/core";
import { RefundHistoryModel } from "./types";
import { useState } from "react";
import { format } from "date-fns";
import moment from "moment";

interface IRefundHistoryTableProps {
    refundHistoryModels: RefundHistoryModel[];
}

export const RefundHistoryTable = ({
    refundHistoryModels,
}: IRefundHistoryTableProps) => {
  
  const { open: notify } = useNotification();
  const [loading, setLoading] = useState<boolean>(false);
  const [step, setStep] = useState<number>(1);
  return (
    <>      
        <Table withBorder withColumnBorders striped>
          <thead>
            <tr className="bg-light">
              <th>Date</th>
              <th>Order No.</th>
              <th>Refund Amount</th>
              <th>Payment Method</th>
              <th>Summary</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody>
            {refundHistoryModels && refundHistoryModels.map(
              (refundOrder: any, index: number) => (
                <tr key={index}>
                  <td>                    
                    {moment(refundOrder.TransactionDate).format("DD/MM/YYYY hh:mm:ss A")}
                  </td>
                  <td>
                    {refundOrder.RefundHistoryOrderItemModels.map(
                      (bidDeal: any, b_index: number) => (
                        <span key={b_index}>
                          {bidDeal.BidDealId}
                          <br />
                        </span>
                      )
                    )}
                  </td>
                  <td>
                    {refundOrder.RefundHistoryOrderItemModels.map(
                      (bidDeal: any, b_index: number) => (
                        <span key={b_index}>
                          {bidDeal.CustomerPrice.toFixed(2)}
                          <br />
                        </span>
                      )
                    )}
                  </td>
                  <td>{refundOrder.PaymentMethod}</td>
                  <td>
                    <strong>{refundOrder.RefundReason}</strong>
                    <div style={{ wordBreak: "break-word" }}>
                      {refundOrder.RefundNoteContent}
                    </div>
                  </td>
                  <td>{refundOrder.Approved ? "Approved" : "Processing"}</td>
                </tr>
              )
            )}
          </tbody>
        </Table>
    
    </>
  );
};
