import { useCallback, useMemo, useRef, useState } from "react";
import * as yup from "yup";
import { useDataProvider, useNotification } from "@mydeal/core";
import { adminService } from "@services";
import {
  AuthenticatedForm,
  DynamicDataTable,
  FormSubmitButton,
  FormTextField,
  IDynamicDataTable,
  IDynamicFetchParameters,
  useFocusableForm,
} from "@mydeal/ui-mantine";
import { IDataTableRequestModel, IInvitationsModel } from "@types";
import { Text, Button, Grid } from "@mantine/core";
import { IconSend, IconX } from "@tabler/icons-react";
import { Label } from "@components/shared";

const InvitationsSchema = yup.object<{
  [Property in keyof IInvitationsModel]: any;
}>({
  InviteEmail: yup
    .string()
    .label("Email Address")
    .nullable()
    .required()
    .matches(
      /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
      "Enter a valid e-mail address."
    ),
});

export const InvitationsEditor = () => {
  const dataProvider = useDataProvider();
  const dataProviderInstance = useMemo(() => dataProvider(), [dataProvider]);
  const _adminService = useMemo(
    () => adminService(dataProviderInstance),
    [dataProviderInstance]
  );
  const { open: notify } = useNotification();
  const form = useFocusableForm<IInvitationsModel>({
    schema: InvitationsSchema,
    initialValues: {
      InviteEmail: "",
    },
  });
  const [submitting, setSubmitting] = useState(false);

  const fetchData = useCallback(
    async ({ pageSize, pageIndex, sort }: IDynamicFetchParameters) => {
      var dataTableRequest: IDataTableRequestModel = {
        PageIndex: pageIndex,
        PageSize: pageSize,
      };
      return await _adminService.getInvitations(dataTableRequest);
    },
    [_adminService]
  );

  const dtRef = useRef<IDynamicDataTable>(null);

  const refreshTable = useCallback(() => {
    if (dtRef.current) {
      dtRef.current.refresh();
    }
  }, []);

  const onSubmit = useCallback(
    async (data: IInvitationsModel) => {
      try {
        await _adminService.inviteUser(data.InviteEmail);
        return { SuccessMessage: "Invitation sent." };
      } finally {
        setSubmitting(false);
      }
    },
    [_adminService]
  );

  const afterSubmit = useCallback(() => {
    form.reset();
    refreshTable();
  }, [form, refreshTable]);

  const deleteInvitation = useCallback(
    async (invitationId: string) => {
      await _adminService.deleteInvitation(invitationId);
      notify?.({
        type: "success",
        message: "Invitation to account deleted.",
        description: "",
      });
      refreshTable();
    },
    [_adminService, refreshTable]
  );

  return (
    <AuthenticatedForm
      dataProviderInstance={dataProviderInstance}
      form={form}
      onSubmit={onSubmit}
      afterSubmit={afterSubmit}
      readOnly
      align="stretch"
    >
      <Grid mb="xs">
        <Grid.Col md={3} sm={4}>
          <Label>Invite user to your company:</Label>
        </Grid.Col>
        <Grid.Col md={6} sm={4}>
          <FormTextField
            placeholder="Enter email address"
            fieldName="InviteEmail"
            parentForm={form}
          />
        </Grid.Col>
        <Grid.Col md={3} sm={4}>
          <FormSubmitButton
            fullWidth
            loading={submitting}
            disabled={!form.isDirty()}
          >
            <IconSend size={16} />
            Send Invite
          </FormSubmitButton>
        </Grid.Col>
      </Grid>
      <DynamicDataTable<any, any>
        sortable={false}
        fetchData={fetchData}
        getData={(response) => response.Invitations}
        getCount={(response) => response.Total}
        mantineTableBodyRowProps={{}}
        controlRef={dtRef}
        columns={[
          {
            fieldName: "Email",
            header: "Email",
            minSize: 100,
            cellTemplate: (value: any) => <Text>{value}</Text>,
          },
          {
            fieldName: "CreatedAtPretty",
            header: "Invitation Date",
            minSize: 15,
            cellTemplate: (value: any) => <Text>{value}</Text>,
          },
          {
            fieldName: "InvitationId",
            header: "Action",
            size: 100,
            cellTemplate: (value: any) => {
              return (
                <Button onClick={() => deleteInvitation(value)}>
                  <IconX size={16} />
                  Delete
                </Button>
              );
            },
          },
        ]}
      />
    </AuthenticatedForm>
  );
};
