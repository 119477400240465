import { Grid, createStyles, Container, TypographyStylesProvider } from '@mantine/core';
import CaseStudies01 from "../../assets/images/landingTabs/casestudies/luxorlinen_thumbnail.png";
import CaseStudies02 from "../../assets/images/landingTabs/casestudies/todo_thumbnail.png";
import CaseStudies03 from "../../assets/images/landingTabs/casestudies/aca_thumbnail.png";
import { ArticleCard } from "@components/shared";


const useStyles = createStyles((theme) => ({
  caseWrapper: {
    marginTop: `calc(${theme.spacing.xl} * 2)`,
  },
  content: {
    fontSize: theme.fontSizes.sm,
  }
}));

export function TabCaseStudies() {
  const { classes } = useStyles();
  return (
    <Container fluid className={classes.caseWrapper} >
      <Grid>
        <Grid.Col xs={4}>
          <ArticleCard
            image={CaseStudies01}
            link="#"
            title="MyDeal.com.au made a compelling value proposition!"
            author="Jumbo eMall"
          >
            <div className={classes.content}>
              <p>Jumbo eMall offers a large variety of bedding and bathroom products directly to consumers at competitive prices. With a unique combination of design, quality and price, their products are sourced through a reliable network of suppliers, who can obtain a range of quality products and versatile designs. Through cutting out costly overheads and retail middlemen, Jumbo eMall are able to offer the most competitive prices in the market.</p>
              <p>Since joining MyDeal.com.au, it has had a “modest net positive impact” on their growth. As one of MyDeal.com.au’s leading suppliers, we interviewed Andrew from Jumbo eMall on their growth story and their positive experience in working with MyDeal.com.au: </p>
              <h3>How and why did your business start?</h3>
              <p>Jumbo eMall started as a way to complement our parent company, which is in the business of manufacturing and distributing Australian made bedding products. We saw an opportunity to not only increase our sales by offering complementary products, but also to do so at very competitive prices.</p>
              <h3>Tell us about the early days. How has your business transformed from when you first started?</h3>
              <p>In our early days, Jumbo eMall was only an experiment. We offered a relatively small variety of products and handled a modest amount of volume. Over time and due to customer demand, we have expanded our range, and also our customer base.</p>
              <h3>What are your proudest milestone achievements and why?</h3>
              <p>Our proudest achievement was when we achieved positive cash flow from what began as an experiment. Positive cash flow answered our question of whether or not we are able to provide value to customers, and proved that Jumbo eMall is a net value creating business.</p>
              <h3>Why did you come on board with MyDeal.com.au?</h3>
              <p>MyDeal.com.au made a compelling value proposition in two ways. Firstly, it allowed us to reach a new market and broadened our customer base. Secondly, because MyDeal.com.au handles customer service and website management, it allows us to maintain our low overhead costs.</p>
              <h3>How has MyDeal.com.au impacted your business?</h3>
              <p>Since it allows us to access a larger number of potential customers, MyDeal.com.au has had a modest net positive impact on our growth.

                In terms of online sales, our growth has been robust and steady for the past three years. My initial assessment is that MyDeal.com.au is responsible for part of that growth.</p>
              <h3>Would you recommend MyDeal.com.au to other online sellers and why?</h3>
              <p>Yes, I would recommend that other online sellers give MyDeal.com.au a go. I have two reasons for this. The first is that other sellers can also benefit from the increased reach and customers that MyDeal.com.au attracts. The second reason is that the greater the number of sellers on MyDeal.com.au, the greater the network effect. That is, more sellers will attract more customers, which then attracts more sellers and so on. The number of potential customers for Jumbo eMall and other sellers thus grows.</p>
            </div>
          </ArticleCard>
        </Grid.Col>
        <Grid.Col xs={4}>
          <ArticleCard
            image={CaseStudies02}
            link="#"
            title="Great customer service, good listings and advertising"
            author="Panatech"
          >
            <div className={classes.content}>
              <p>With their strong knowledge of home appliances engineering and design, Panatech are the creators of the TODO brand, affordable home appliances for the Australian and New Zealand market, differentiated by the extensive local technical support they provide for their buyers. With design, branding, importing and wholesale services, they have been able to create a high value product range which ensures customers pay for real quality and material.</p>
              <p>They have experienced “an average growth of 80% year on year, for every financial year for past 3 years. Additionally, they were able to grow their product range from “800 to more than 10,000 SKUS.”</p>
              <p>Since listing with MyDeal.com.au to make the transition from brick and mortar to e-commerce, they have become one of MyDeal.com.au’s key suppliers. We spoke to their Sales Director Sharon Liu about their success and growth experienced with MyDeal.com.au:</p>
              <h3>What customer need and problem does your business solve?</h3>
              <p>Introducing new home appliances to AUS/NZ through TODO brand and offering extensive local technical support for our buyers.</p>
              <h3>Tell us about the early days. How has your business transformed from when you first started?</h3>
              <p>We accumulated strong home appliances engineering/ design knowledge from servicing top of the range branded appliances and decided to create our own brand to offer affordable products for Australia/ NZ customer.</p>
              <h3>What are your proudest milestone achievements and why?</h3>
              <p>Establishing our own Brand TODO – making sure our customers pay for real quality and material and not merely paying loyalty to premium branding.</p>
              <h3>Why did you come on board with MyDeal.com.au?</h3>
              <p>To transition from shopping centre shop fronts to e-commerce</p>
              <h3>How has MyDeal.com.au impacted your business?</h3>
              <p>Strong customer feedback through MyDeal.com.au customer service teams to keep us on guard with our quality control.</p>
              <h3>What do you love about working with MyDeal.com.au?</h3>
              <p>Fast customer service.</p>
              <h3>What benefits have you experienced since working with MyDeal.com.au?</h3>
              <p>Great customer service, good listings and advertising.</p>
            </div>
          </ArticleCard>
        </Grid.Col>
        <Grid.Col xs={4}>
          <ArticleCard
            image={CaseStudies03}
            link="#"
            title="MyDeal.com.au has helped our business grow"
            author=" ACA Trading"
          >
            <div className={classes.content}>
              <p>Since launching in 2011, ACA trading has been providing Australian consumers with bathroom and kitchenware products to make those renovations that little bit easier. With a rapidly growing warehouse, their business continues to thrive.</p>
              <p>Since listing on MyDeal.com.au, they “have noticed visible business growth”, which has contributed to them to tripling their business in the past three years.</p>
              <h3>What customer need and problem does your business solve?</h3>
              <p>We provide a large range of products to customers completing renovations or building a new house. They can buy all bathroom hardware in one go.</p>
              <h3>Tell us about the early days. How has your business transformed from when you first started?</h3>
              <p>We started in 2011 with only 2 people and now we have more than 10. Over time we have had to move twice to bigger warehouses to adjust to our rapid growth.</p>
              <h3>What are your proudest milestone achievements and why?</h3>
              <p>We moved to a new warehouse last year, which is 2500 square meters. It validates that our products have become more popular in Australia.</p>
              <h3>How has MyDeal.com.au impacted your business (growth and other)?</h3>
              <p>MyDeal.com.au has helped us sell more and helped our business grow.
                MyDeal is a great group with honesty and high efficiency.</p>
              <h3>How much have you grown in the past three years?</h3>
              <p>Our business has increased at least 3 times than the past three years.</p>
              <h3>Would you recommend MyDeal.com.au to other online sellers and why?</h3>
              <p>Yes, we will, MyDeal cover a big range of products and are easy to deal with!</p>
            </div>
          </ArticleCard>
        </Grid.Col>
        <Grid.Col xs={4}></Grid.Col>
        <Grid.Col xs={4}></Grid.Col>
        <Grid.Col xs={4}></Grid.Col>
      </Grid>
    </Container>
  );
}