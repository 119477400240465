import { PageTitle } from "@components/shared";
import { ChangePasswordEditor } from "./ChangePasswordEditor";
import { Card } from "@mantine/core";

export const ChangePassword = () => {
  return (
    <>
      <PageTitle
        title="Change Password"
        breadcrumbs={[
          { name: "Home", route: "/dashboard" },
          { name: "User Settings" },
          { name: "Change Password" },
        ]}
      />

      <Card withBorder mih={300} p="xl">
        <ChangePasswordEditor onboarding={false} />
      </Card>
    </>
  );
};
