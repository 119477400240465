import React, { createContext, useCallback, useState } from "react";
import { useLocalStorage } from "@mantine/hooks";

export interface ITestingConfig {
  TestingMode: boolean;
}

export type TestingConfigContextType = {
  testingConfigModel: ITestingConfig;
  setTestingConfigModel: React.Dispatch<React.SetStateAction<ITestingConfig>>;
};

export type TestingConfigContextProps = {
  children: React.ReactNode;
};

export const TestingConfigContext = createContext<TestingConfigContextType>(
  {} as TestingConfigContextType
);

export const TestingConfigContextProvider = ({
  children,
}: TestingConfigContextProps) => {
  const [testingMode, setTestingMode] = useLocalStorage({
    key: "testing-mode",
    defaultValue: "false",
  });

  const [testingConfigModel, setTestingConfigModel] = useState<ITestingConfig>({
    TestingMode: testingMode === "true",
  });

  const onTestingConfigModelChange = useCallback<
    React.Dispatch<React.SetStateAction<ITestingConfig>>
  >(
    (value) => {
      setTestingConfigModel(value);
      const newValue =
        typeof value === "function" ? value(testingConfigModel) : value;
      setTestingMode(newValue.TestingMode ? "true" : "false");
    },
    [testingConfigModel, setTestingMode]
  );

  return (
    <TestingConfigContext.Provider
      value={{
        testingConfigModel,
        setTestingConfigModel: onTestingConfigModelChange,
      }}
    >
      {children}
    </TestingConfigContext.Provider>
  );
};

export default TestingConfigContext;
