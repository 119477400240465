import restDataProvider from "@mydeal/data-rest";
import {
  ReactRouter,
  ReactRouterProvider,
  useLocation,
  redirect,
} from "@mydeal/react-router";

import axios from "axios";

import { LoadingOverlay } from "@mantine/core";

import { useAuth0Provider } from "@components/auth/useAuth0Provider";
import { MyDealAuth0Provider } from "@providers/MyDealAuth0Provider";
import { RollbarContext } from "@rollbar/react";
import { Provider as RollbarProvider, ErrorBoundary } from "@rollbar/react";
import { TicketSummaryContextProvider } from "./pages/enquiryInbox";
import { ShippingSettingsContextProvider } from "@pages/shipping";
import { OnboardingStatusContextProvider } from "@pages/onboarding/onboardingStatusContext";
import { TestingConfigContextProvider } from "@utils/testingConfigContext";
import { IMyDealAppWrapperProps, MyDealMantine } from "@mydeal/ui-mantine";
import { layouts } from "./routes";
import { redirects } from "./routes/redirects";

const AppLoading = () => {
  return <LoadingOverlay visible={true} />;
};

const OutletWrapper = ({ children }: any) => {
  const location = useLocation();
  return (
    <RollbarContext context={location.pathname}>{children}</RollbarContext>
  );
};

const AppWrapper: React.FC<IMyDealAppWrapperProps> = ({ children }) => {
  return (
    <TestingConfigContextProvider>
      <OnboardingStatusContextProvider>
        <ShippingSettingsContextProvider>
          <TicketSummaryContextProvider>
            {children}
          </TicketSummaryContextProvider>
        </ShippingSettingsContextProvider>
      </OnboardingStatusContextProvider>
    </TestingConfigContextProvider>
  );
};

export const App = () => {
  const runtimeConfig = window?.__RUNTIME_CONFIG__ || {};

  axios.interceptors.response.use(
    (response) => {
      return response;
    },
    async (error) => {
      const originalRequest = error.config;
      if (error.response) {
        if (error.response.status === 401 && !originalRequest._retry) {
          originalRequest._retry = true;

          await redirect("/login");
          return error.response;
        }
      }
      return Promise.reject(error);
    }
  );

  const rollbarConfig = {
    accessToken: runtimeConfig.REACT_APP_ROLLBAR_TOKEN,
    environment: runtimeConfig.REACT_APP_ENVIRONMENT,
    captureUncaught: true,
    payload: {
      context: "test",
    },
    server: {
      root: window.location.origin,
    },
  };

  return (
    <RollbarProvider config={rollbarConfig}>
      <ErrorBoundary>
        <MyDealMantine
          routerProvider={ReactRouterProvider}
          router={
            <ReactRouter
              type="browser"
              useAuthProvider={useAuth0Provider}
              AuthProvider={MyDealAuth0Provider}
              LoadingComponent={AppLoading}
              dataProviders={{
                default: () => restDataProvider("/api/v1", axios),
              }}
              routes={[...layouts, ...redirects]}
              outletWrapper={OutletWrapper}
            />
          }
          appWrapperComponent={AppWrapper}
        />
      </ErrorBoundary>
    </RollbarProvider>
  );
};

export default App;
